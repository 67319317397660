export class baseData{
    name = [];
    sessTime: any;

    constructor(name: string) {
        this.name.push({val: name, time: new Date()});
        this.sessTime = new Date().getTime();
    }

    endTracking() {
        const currTime = new Date().getTime() - this.sessTime;
        this.sessTime = [{val: currTime, time: new Date()}];
    }
}