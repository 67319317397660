import { Component, Input, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';
import { ModalController, NavController } from '@ionic/angular';
import { HomeService } from '@services/home.service';

@Component({
  selector: 'app-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.scss'],
})
export class TrainingsComponent extends Utilities implements OnInit {

  @Input() more?: boolean = false;

  activities: any;
  currentActivities: any[];
  cobrand: any;
  authUser: any;
  startTrainingOpacity: string;
  trainingText: string;

  constructor(
    public modalController: ModalController,
    public navCtrl: NavController,
    private homeService: HomeService
  ) { super(); }

  ngOnInit() {
    this.getAuthUser();
    this.getApps();
    this.configureStyles();
    this.setTrainings();
  }

  getAuthUser(): void {
    this.homeService.getAuthUser().then((data: any) => {
      this.authUser = data;
    }).catch(err => {
      throw new Error(err);
    })
  }

  getApps(): void {
    this.activities = this.homeService.getActivities();
  }

  configureStyles(): void {
    this.homeService.getCobranding().subscribe(cobrand => {
      this.cobrand = cobrand;
    });;
  }

  setTrainings(): void {
    if (this.more) {
      this.trainingText = "More Training";
      this.currentActivities = this.activities.more;
    }
    else {
      this.trainingText = "Today's Training";
      this.currentActivities = this.activities.today;
    }
  }

  trainingClicked(): void {
    this.navCtrl.navigateForward('apps-menu');
  }

  hoverTraining(opacity: string): void {
    this.startTrainingOpacity = opacity;
  }
}
