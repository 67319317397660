<!-- content -->
<div class="container {{ containerClass }}">
  <div class="background-padded {{ itemClass }}">
    <!-- swiper -->
    <swiper 
      [config]="swiperConfig" 
      class="be-swiper" 
      (indexChange)="onIndexChange($event)">
      <div *ngFor="let instruction of instructions">
        <div class="instruction" [innerHTML]="instruction.instruction"></div>
        <img 
          *ngIf="instruction.url" 
          [src]="instruction.url"
          alt=""
          class="instruction-img">
      </div>  
    </swiper>
    <!-- swiper /-->
  </div>

</div>

<div class="background-padded">
  <app-button data-cy="instructions-next" text="Next" (click)="moveToNext()"></app-button>
  <app-button data-cy="instructions-prev" disabled={{disablePrev}} text="Previous" (click)="moveToPrev()"></app-button>
</div>
<!-- content /--> 
