import { baseData } from "./base.model"

export class BrainGameData extends baseData {
    triviaAcc  = [] as {val: number, time: Date}[];
    triviaTime = [] as {val: any, time: Date}[];
    gameScore  = [] as {val: number, time: Date}[];
    gameTime   = [] as {val: any, time: Date}[];
    gameName   = [] as {val: string, time: Date}[];
    difficulty = [] as {val: string, time: Date}[];
    leaderboard = [] as {val: string, time: Date}[];

    constructor() {
        super('BrainGame');
    }
}