import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() controlName: FormControl;
  @Input() message: string;
  @Input() labelName: string;
  @Input() errorMessage: string;
  @Input() isValidation = true;
  @Input() isDropDown: false;
  // IDK what this does, used in mainly subscriber things
  @Input() isAdmin: boolean;

  @Output() isError: EventEmitter<any> = new EventEmitter();

  isInvalid: boolean;

  constructor() { }

  ngOnInit() {
    if (this.controlName) {
      this.controlName.valueChanges.subscribe(() => {
        this.isInvalid = this.validateChanges();
      });
    }
  }

  /**
   * Verifies if the controlName is valid.
   * @returns Whether the changes are valid.
   */
  validateChanges(): boolean {
    let error: boolean;

    if (!this.isDropDown) {
      error = (this.controlName && this.controlName.invalid && this.controlName.dirty);
    } else {
      error = (this.controlName && this.controlName.invalid &&
        this.controlName.dirty && this.controlName.touched);
    }
    
    if (error) {
      this.isError.emit(this.labelName);
    }

    return error;
  }
  
  /**
   * Checks the validity of the the name control value in terms of pattern.
   * @returns Whether the name is valid.
   */
  get isPattern(): boolean {
    if (this.controlName.errors) {
      return this.controlName.errors.hasOwnProperty('pattern');
    }
    return null;
  }

  /**
   * Checks the validity of the the name control value in terms of minimum length.
   * @returns Whether the name is valid.
   */
  get isMinLength(): boolean {
    if (this.controlName.errors) {
      return this.controlName.errors.hasOwnProperty('minlength');
    }
    return null;
  }
}
