import { Component, Input, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';
import { OutsideTrainingEnum } from '@enums/OutsideTrainingEnum';
import { AdminService } from '@services/admin.service';
import { DB_CONFIG } from '@app/app.firebase.config';
import { AuthenticationService } from '@services/authentication.service';
import { User } from '@models/user.interface';
import { OutsideTraining } from '@interfaceoutside_training.interface';
import { OutsideTrainingService } from '@services/outside-training.service';

@Component({
  selector: 'app-add-outside-training',
  templateUrl: './add-outside-training.component.html',
  styleUrls: ['./add-outside-training.component.scss'],
})
export class AddOutsideTrainingComponent extends Utilities implements OnInit {

  @Input() outsideTrainings: OutsideTraining[] = [];
  @Input() selectedDay: number = 0;

  categorySettings = this.trainingService.categorySettings;
  
  newOutsideTraining = {
    name: '',
    categories: [{ id: OutsideTrainingEnum.Mental, selected: false }, { id: OutsideTrainingEnum.Social, selected: false }, {
        id: OutsideTrainingEnum.Stress, selected: false }, { id: OutsideTrainingEnum.Physical, selected: false }],
  } as OutsideTraining;

  errorMsg: string;

  constructor(
    private adminService: AdminService,
    private authService: AuthenticationService,
    private trainingService: OutsideTrainingService
  ) { super(); }

  ngOnInit() { }

  /**
   * Modifies the category of the outside training selected and clears any error message
   * @param index the index of the selected category
   */
  categoryClicked(index: number): void {
    const category = this.newOutsideTraining.categories[index];
    category.selected = !category.selected;

    this.clearErrorMsg();
  }

  /**
   * Adds the new outside training into the outside training array and into the database if
   * a training name has been entered and at least one category has been selected
   */
  submitOutsideTraining(): void {
    if (!this.checkForErrors()) {
      const today = new Date();

      this.authService.getUser().then(user => {
        this.newOutsideTraining.user_id = user.id;
        this.newOutsideTraining.created_date = new Date(today.setDate(today.getDate() - this.selectedDay));

        this.adminService.saveEntry(this.newOutsideTraining, DB_CONFIG.outside_training_endpoint).then(id => {
          this.addOutsideTraining(id);
          this.closeModal();
        }).catch((err) => {
          this.errorMsg = 'There has been an error saving your file.\n Please try again later.';
        });
      });
    }
  }

  /**
   * Adds the outside training to the outside training array
   * @param id the database document id for the outside training 
   */
  addOutsideTraining(id: string) {
    this.newOutsideTraining.id = id;
    this.outsideTrainings.unshift(this.newOutsideTraining);
  }

  /**
   * Checks if a training name has been entered and at least one category has been selected
   * @returns true if there are errors in the new outside training and false if otherwise
   */
  checkForErrors(): boolean {
    this.newOutsideTraining.name = this.toTitleCase((<HTMLInputElement>document.getElementById('training-name')).value);
    const selectedCategories = this.newOutsideTraining.categories.filter(category => category.selected);

    if (this.newOutsideTraining.name == '') {
      this.errorMsg = 'Enter A Training Name';
      return true;
    }
    else if (selectedCategories.length == 0) {
      this.errorMsg = 'Select A Category';
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Clears the error message 
   */
  clearErrorMsg(): void {
    this.errorMsg = '';
  }

}
