import { Component, Input, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';
import { NavController } from '@ionic/angular';
import { AdminService } from '@services/admin.service';
import { AuthenticationService } from '@services/authentication.service';
import { HomeService } from '@services/home.service';


@Component({
  selector: 'app-skip-training',
  templateUrl: './skip-training.component.html',
  styleUrls: ['./skip-training.component.scss'],
})
export class SkipTrainingComponent extends Utilities implements OnInit {

  @Input() app?: any;
  @Input() skippedApps? = [];

  @Input() game?: any;

  authUser: any;
  transform: string;
  selectedReason: String = 'Reason For Skipping';
  errorMsg: string;
  name: string;
  
  reasons = [
    "It's too difficult",
    "It's too easy",
    'I do this too much',
    "I don't have enough time",
    "It's boring",
    "It doesn't work"
  ];

  constructor(
    public navCtrl: NavController,
    private authService: AuthenticationService,
    private homeService: HomeService
  ) { super(); }

  ngOnInit() {
    this.getUser();
    this.setName();
  }

  getUser() {
    this.authService.userSubject.subscribe(user => {
      if (user) {
        this.authUser = user;
      }
    })
  }

  /**
   * Stores the name of the current app or game
   */
  setName(): void {
    this.name = this.app ? this.app.name : this.game.name;
  }

  /**
   * Saves the skip and closes the modal unless a reason was not selected
   */
  toApp(): void {
    if (this.selectedReason != 'Reason For Skipping') {
      this.saveSkip();
      this.closeModal();
    }
    else {
      this.errorMsg = 'Select a Reason for Skipping';
    }
  }

  flipArrow(): void {
    this.transform = 'translate(-50%,-50%) rotate(-180deg)';
  }

  flipArrowBack(): void {
    this.transform = 'translate(-50%,-50%) rotate(0deg)';
  }

  /**
   * Clears the error message
   */
  clearErrorMsg(): void {
    this.errorMsg = '';
  }

  /**
   * Clears the error message and stores the selected reason
   */
  reasonSelected(): void {
    this.clearErrorMsg();
    this.selectedReason = this.reasons[((<HTMLInputElement>document.getElementById('skip-select')).value)];
  }

  /**
   * Emits the reasiong why the user skipped the app for an app skipped or a game skipped
   */
  saveSkip(): void {
    if (this.app) {
      this.skippedApps.find(skippedApp => skippedApp.name == this.app.name).skips++
      this.homeService.skipAppModalClosed.next(this.selectedReason);
    }
    else {
      this.homeService.skipGameModalClosed.next(this.selectedReason);
    }
  }
}
