
<ion-col style="display: flex; flex-direction: column; justify-content: center ">
  <div class="legend-container">
    <div class="compliance-legend"></div>
    <p style="font-size: 24px; color: black">Compliance</p>
  </div>
  <div class="legend-container">
    <div class="bc-legend"></div>
    <p style="font-size: 24px; color: black">Brain Charge Score</p>
  </div>
</ion-col>