

<div class="leaderboard" *ngIf="teamSummaryDisplay">
  <div class="bordered padding-bottom-10 text-center">
    <div class="d-flex">
      <div class="col-6 d-flex">
        <div class='col-3'>
        </div>
  
        <div class='col-9 text-actually-left'>
          <ion-text><strong>Name</strong></ion-text>
        </div>
      </div>
  
      <div class="col-6 d-flex">
        <div class="col-6">
          <ion-text><strong>Date</strong></ion-text>
        </div>
        <div class="col-6">
          <ion-text><strong>Score</strong></ion-text>
        </div>
      </div>
    </div>
  </div>
  
  <div class="bordered padding-bottom-10 text-center" *ngFor="let item of teamSummaryDisplay; let i = index">
    <div class="top-row d-flex" [ngClass]="{'boldguy': i === currentIndex}">
      <div class="col-6 d-flex">
        <div class='col-3'>
          <div class='badge' [ngClass]="{'badge-gold': i === 0, 'badge-silver': i === 1, 'badge-bronze': i === 2}">
            <ion-text>{{ i + 1 }}</ion-text>
          </div>
        </div>

        <div class='col-9 center-it'>
          <ion-text><strong>{{ item.user_name }}</strong></ion-text>
        </div>
      </div>

      <div class="col-6 d-flex">
        <div class="col-6 center-it">
          <ion-text><strong>{{ item.display_date }}</strong></ion-text>
        </div>
        <div class="col-6 center-it">
          <ion-text><strong>{{ item.overall_score?.toFixed(2) }}</strong></ion-text>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="text-center" *ngIf="!teamSummaryDisplay">
  <h1>No Leaderboard for {{game}} at your current difficulty level ({{difficulty}}).</h1>
</div>