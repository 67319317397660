import { Component, OnInit, ViewChild, Input, AfterViewInit, EventEmitter, Output } from '@angular/core';

import { CountdownComponent } from 'ngx-countdown';

import { EventBusService } from '@services/event-bus.service';

import { roundTimer } from '@app/app.constants.config';

@Component({
  selector: 'app-game-timer',
  templateUrl: './game-timer.component.html',
  styleUrls: ['./game-timer.component.scss'],
})
export class GameTimerComponent implements OnInit, AfterViewInit {
  @ViewChild('countdown', { static: true }) counter: CountdownComponent;
  @Input() countDownTime: number;
  @Output() timeUp: EventEmitter<any> = new EventEmitter();

  config: any;
  roundTimer: any;
  
  constructor(private eventBusService: EventBusService) { }

  ngOnInit() {
    this.setConfig();
    this.subscribeToEvents();
    this.roundTimer = roundTimer;
  }

  ngAfterViewInit() {
    this.eventBusService.resetTimer.subscribe((value: string) => {
      if (value) {
        this.countDownTime = this.roundTimer[value];
        this.setConfig();
        this.resetTimer();
      }
    });

    this.eventBusService.pauseTimer.subscribe(() => {
      this.counter.pause();
    });

    this.eventBusService.resumeTimer.subscribe(() => {
      this.counter.resume();
    });
  }

  /**
   * Configures the count down timer.
   */
  setConfig(): void {
    this.config = {
      leftTime: this.countDownTime
    };
  }

  /**
   * Subscribes to all events that are emitted.
   */
  subscribeToEvents(): void {
    // starts the timer
    this.eventBusService.onPageLoaded.subscribe(() => {
      setTimeout(() => {
        this.counter.begin();
      }, 1000);
    });
  }

  /**
   * Emits that the time is up.
   */
  timerFinishedEventHandler(): void {
    this.timeUp.emit();
  }

  /**
   * Resets the timer.
   */
  resetTimer(): void {
    this.counter.restart();
  }
}
