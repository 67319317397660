<div class="cont">
  <app-close (click)="closeModal()"></app-close>
  <app-title text="Remove Outside Training"></app-title>
  <hr>
  <ion-text class="label">Delete an activity by selecting the trash can. Change categories by touching the grid.</ion-text>
  <div class='text-center' style='color: red; margin: -15px auto 5px auto' *ngIf='errorMsg'>
    <span>{{errorMsg}}</span>
  </div>
  <div class="centered">
    <div class="trainings">
      <ion-grid>
        <ion-row *ngFor="let newOutsideTraining of newOutsideTrainings; let i = index" class="spacing">
          <ion-col class="centered">
            <ion-text>{{newOutsideTraining.name}}</ion-text>
          </ion-col>
          <ion-col *ngFor="let category of newOutsideTraining.categories; let j = index" class="cell" [ngStyle]="category.selected && {'background-color': categorySettings[j].background }" [style.borderColor]='categorySettings[j].iconColor' (click)="cellClicked(i, j)">
            <ion-icon name='{{categorySettings[j].icon}}'></ion-icon>
            <ion-text class="type">{{categorySettings[j].id}}</ion-text>
          </ion-col>
          <ion-col class="centered">
            <ion-icon class="trash" name="trash" (click)="deleteOutsideTraining(i)"></ion-icon>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
  <app-button text="Submit" (click)='submitOutsideTraining()'></app-button>
</div>
