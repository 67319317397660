import { EventEmitter, Injectable } from '@angular/core';
import { Utilities } from '@common/utilities';
import { Assessment } from '@models/assessment.model';
import moment from 'moment';
import { Subject } from 'rxjs';
import { Quiz } from '@models/quiz.model';
import { DB_CONFIG } from '../app.firebase.config';
import { AdminService } from './admin.service';
import { AuthenticationService } from './authentication.service';
import { HomeService } from './home.service';
import { take } from 'rxjs/operators';
import { MoodEnum } from '@enums/MoodEnum';
import _ from 'underscore';
import { Game } from '@common/models/game.model';
import { TrackerProcessingService } from './tracker-processing.service';
import { gameConfig } from '@app/app.constants.config';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService extends Utilities {
  assessments = {assessments:[], id:''} as {assessments: Assessment[], id: string};

  current_quiz = new Subject<number>();
  current_progress = new Subject<number>();
  setup_done = new Subject<boolean>();

  game1 = 'gSgsIQkQuIlnkiJKSauo';
  game2 = 'dknSLlDHDb0QemzGIEf8';
  game3 = 'GvUFkD4qWS3JFVorWtYb';
  report;
  showGameTitle = true;
  showPracticeTitle = false;
  showFixationPoint = false;
  showReadyTitle = false;
  showQuestionGrid = true;
  showAnswerGrid = false;
  showQuiz3Continue = false;
  isComplete = false;
  isCorrect = true;

  assessmentObject: any;
  selectedCell = [];
  quizIds = [];
  level: number;
  quizThreeIndex = [];
  timerId: string;
  showPlay = true;
  showPractice: boolean;
  showHowTo = true;
  gameIndex = 0;
  quizIndex = 0;
  gameId: string;
  quizObject = Array<Quiz>();
  gameObject = {} as Game;
  quiz = {} as Quiz;
  gameScore = 0;
  quizStartTime = 0;
  gameAccuracy = [];
  reactionTime = [];
  questionCategory = [];
  assessmentScore = [];
  utGameConfig = gameConfig; // Rename this from gameConfig because clashes in other files

  returningModalClosed = new EventEmitter<any>();

  reportScale = [
    // {
    //   number: 0,
    //   lt_percent: 0,
    //   description: 'Challenge'
    // },
    {
      number: 1,
      lt_percent: 5,
      description: 'Challenge'
    },
    {
      number: 2,
      lt_percent: 10,
      description: 'Challenge'
    },
    {
      number: 3,
      lt_percent: 20,
      description: 'Fair'
    },
    {
      number: 4,
      lt_percent: 30,
      description: 'Fair'
    },
    {
      number: 5,
      lt_percent: 40,
      description: 'Good'
    },
    {
      number: 6,
      lt_percent: 50,
      description: 'Good'
    },
    {
      number: 7,
      lt_percent: 60,
      description: 'Very Good'
    },
    {
      number: 8,
      lt_percent: 80,
      description: 'Very Good'
    },
    {
      number: 9,
      lt_percent: 90,
      description: 'Excellent'
    },
    {
      number: 10,
      lt_percent: 100,
      description: 'Excellent'
    },
  ];

  loaded = new Subject();
  user;
  weeklyReports;
  grid = 'Grid';
  symmetrical = 'Symmetrical';

  constructor(
  private adminService: AdminService,
  private authService: AuthenticationService,
  private homeService: HomeService,
  private trackerService: TrackerProcessingService,
  private ngFunctions: AngularFireFunctions
  ) { super(); this.initial();}

  initial() {
    // this.testFalcons();
    this.authService.userSubject.subscribe(us => {
      this.user = us;
      if(this.assessments.id === ''){
        
        if(us ){this.getAssessments(us.id);}
        else{this.getAssessments('6o4sJfTYKnxp6ick8LBC');}
      }
    })
  }

  async nextQuiz(number?){
    this.current_progress.next(0);
    if(number){
      this.current_quiz.next(number);
    } else {
      const quiz_index = await this.current_quiz.toPromise();
      this.current_quiz.next(quiz_index + 1);
    }
    
  }

  testFalcons() {
    this.adminService.getEntries(DB_CONFIG.user_endpoint).subscribe(async (users) => {
      const falcons = users.filter(user => {return user.subscriber_id === '5e9a3bf6-87dd-428f-8ac7-5389dd74188d'});
      const promises = falcons.map(async (falcon_user) => {
        const assessments = await this.adminService.getEntryById(falcon_user.id, DB_CONFIG.assessment_endpoint) as any;
        if(assessments){
          const reports = await this.calculateReport(assessments);
          return {reports, falcon_user, ass: assessments.assessments};
        } else {
          return null;
        }
      });
      let reports = await Promise.all(promises);
      reports = reports.filter(n=> n);
      reports = _.sortBy(reports, report => {return report.falcon_user.lastName})
      reports.map(reported => {
        if(reported.reports.length === 0){
          if(reported.ass[reported.ass.length-1].results_data){
            console.log(`${reported.falcon_user.name} tried ${reported.ass.length} times quiz ${reported.ass[reported.ass.length-1].results_data.length}`);
          } else {
            console.log(`${reported.falcon_user.name} tried ${reported.ass.length} times`);
          }
          return;
        }
        const report = reported.reports[0];
        console.log(`${report.overall.toFixed(1)} ${reported.falcon_user.name}`)
        console.log(`${report.focus.display} ${report.speed.display} ${report.strategy.display} ${report.memory.display} ${report.wisdom.display}`)
      })
      return;
    })
  }

  duplicateAssessment() {
    const ass = this.assessments.assessments[this.assessments.assessments.length - 1];
    this.duplicateObj(ass, 2).then(assess => {
      this.assessments.assessments = this.assessments.assessments.concat(assess);
      this.saveAssessment(ass).then(() => {console.log('success')});
    });
    
  }

  duplicateObj(assessment: Assessment, amount: number){
    return new Promise<Assessment[]>((res,rej) => {
      let objArr = [];
      for(let i = 0; i < amount; i++) {
        objArr.push(assessment);
      }
      res(objArr);
    });
  }

  /** Call to firebase to get the specified assessment id */
  getAssessmentById(assessId?: string) {
    // return this.assessments[this.assessments.assessments.length-1];
  return new Promise((res,rej)=>{
    const len = this.assessments.assessments.length;
    res(this.assessments.assessments[len-1]);
  });
  }

  getAssessments(userId?: string) {
    return new Promise<any>(async (res,rej) => {
      if(this.assessments.assessments.length > 0 && userId === this.assessments.id) {res(this.assessments);}
      let assessment;
      if(userId){
        assessment = await this.adminService.getEntryById(userId, DB_CONFIG.assessment_endpoint)
      } else {
        assessment = await this.adminService.getEntryByUserId(DB_CONFIG.assessment_endpoint)
      }
      
      // if no assessment data return empty array
      if(!assessment) {
        assessment = {assessments: [], id: userId};
      }
      // Removes incomplete assessments from your assessment data
      // const promises = assessment.assessments.map(ass => {
      //   if(ass.is_complete){return ass;}
      // })
      // await Promise.all(promises);
      // const test = promises.filter(pro => {return !!pro;});
      // assessment.assessments = test;
      // END


      this.assessments = assessment;
      this.loaded.next();
      // this.duplicateAssessment();
      res(assessment);
    })
    
  }

  /**
   * creates a new assessment based on the mood given
   * @param mood starting mood to assessment
   */
  async newAssessment(mood: string) {
    const assess = {} as Assessment;
    assess.is_complete = false;
    assess.game_ids = [
      this.game1,
      this.game2,
      this.game3
    ];
    assess.mood = mood;
    assess.created_date = new Date().toISOString();
    this.assessments.assessments.push(assess);
    await this.adminService.saveEntryById(this.assessments, DB_CONFIG.assessment_endpoint);
    return true;
  }

  /**
   * Saves the assessment to the database.
   * @param assessment The assessment object.
   * @returns The document reference id.
   */
  async saveAssessment(assessment: Assessment) {
    assessment.end_time = new Date().toISOString();
    this.assessments.assessments[this.assessments.assessments.length-1] = assessment;
    // Email Admins if assessment is completed
    if(assessment.is_complete){
      const msg = `Assessment Completed for user ${this.user.name}`
      const email = {
        // to: 'ellen@brainevolved.com',
        to: ['clarkeyt624@gmail.com', 'ellen@brainevolved.com'],
        message: {
          html: msg,
          subject: `${this.user.name} Assessment Finished`
        }
      }
      this.adminService.saveEntry(email, DB_CONFIG.email_endpoint);
    }
    await this.adminService.saveEntryById(this.assessments, DB_CONFIG.assessment_endpoint);
  }

  /**
   * Gets the latest assessments by the user id.
   * @param userId The user id.
   * @returns A promise with the assessment history array.
   */
  getLatestAssessmentsByUserId(userId: string) {
    return this.adminService.getEntryById(userId, DB_CONFIG.assessment_endpoint);
  }
  
  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  average(arr) {
    return _.reduce(arr, function(memo, num)
    {
      return memo + num;
    }, 0) / arr.length;
  }

  async calculateReport(assessmentsData?) {
    
    if(!assessmentsData){
      assessmentsData = this.assessments;
    }
    let assessments = assessmentsData.assessments;
    let save = false;

    const promises = assessments.map(async (ass, index: number) => {
      if(!ass.results_data) {return null;}
      if(!ass.is_complete) {return null;}
      if(ass.results_data.length < 3) {return null;}
      // if(index === 0){return null}
      
      // Check if the report is existed
      if(ass.report && ass.date !== 'Invalid date') {
        return ass.report;
      }
      else {
        let addSub = this.ngFunctions.httpsCallable('reportCalculation');
        return new Promise((resolve, reject) => {
          addSub({ assessment: ass, index }).pipe(take(1)).subscribe(
            report => {
              ass['report'] = report;
              // Save report to the assessments
              this.assessments.assessments[index] = ass;
              save = true;
              resolve(report);
            },
            error => reject(error)
          );
        });
      }
    });

    const final = await Promise.all(promises);
    this.weeklyReports = null;
    // Remove null values in the array
    const realFinal = final.filter(n => {return n != null}) as any[];
    // Save the assessments with all the reports
    if(save){
      await this.adminService.saveEntryById(assessmentsData, DB_CONFIG.assessment_endpoint);
    }

    return realFinal;
  }

  /**
   * Gets compliances from 3 months before whatever date is given
   * REWRITE TO GIVE IT ABILITY TO GO 3 MONTHS BEFORE A GIVEN DATE RATHER THAN x MONTHS BEFORE
   * @param monthsBack more accurately the amount of 12 weeks before to go
   * @returns 
   */
  getCompliancesBy3Months(date: string) {
    const currSunday = moment().day(7).dayOfYear();
    const dateSunday = moment(date).day(7).dayOfYear();
    const yearDiff = moment().day(7).year() - moment(date).day(7).year();
    const dateDiff = (((yearDiff * 365) - dateSunday) + currSunday)/7;

    // calculates compliance sum
    return this.getAppsBy3Months(dateDiff).then(async (appsByWeek) => {
      const promises = appsByWeek.map(async (appWeek) => {
        let sum = 0;
        const promises2 = appWeek.map(app => {
          sum += app.compliancePercentage;
        })
        await Promise.all(promises2);
        return sum/appWeek.length;
      })
      const sumArr = await Promise.all(promises);
      let sums = 0;
      sumArr.map(sum => {
        sums += sum
      })
      return sums/sumArr.length;
    })
  }

  async getAppsBy3Months(startIndex){
    // gets all compliances from the past twelve weeks
    const promises = [];
    for(let i = startIndex; i < (startIndex+12); i++) {
      promises.push(this.homeService.getApps2(i));
    }
    const final = await Promise.all(promises);
    return final;
  }
  

  /**
  * Add Quiz Limit and Randomize & Generate Quizes for Games
  * @param quizObj an array of quiz items
  * @param gameId the game id
  */
   public async randomizeQuiz(quizObj: Quiz[], gameId: string) {
    const gameSetting = this.getGameLimit(gameId);
    const j       = gameSetting.combination;
    const qlimit  = gameSetting.quizlimit;
    const patLimit = Math.round((qlimit / j));
    const temp    = [];
    let tempAll   = []; // All Quizes including quiz generated on the front end
    let symArray  = [], gridArray = [];
    const min = 2, max = 5; // Min Level 2; Max Level 5
    this.level = Math.floor(Math.random() * (max - min + 1)) + min; // Randomize Sequence

    // Only Execute Logic if Quiz Exists
    if (quizObj === null) {
      return;
    }

    for (const qObject of quizObj) {

      if (temp == null) {
        temp.push(qObject);
      } else {

        /*QUIZ 3 (SYMMETRICAL) LOGIC */
        if (qObject.game_id === this.game3) {
          if (qObject.category === this.grid) {
            gridArray.push(qObject);
          } else if (qObject.category === this.symmetrical) {
            symArray.push(qObject);
          }
        }

        /*QUIZ 1 & 2  LOGIC */
        // Only Add Unique Shape and Color to Temp Object
        if (!temp.find(x => x.coordinates === qObject.coordinates && (tempAll.length <= qlimit) && gameId !== this.game3)) {

          temp.push(qObject);
          // Game 1 Distribute the same amount of shape and color
          // Game 2 Keep Amount that came back from db
          if (gameId === this.game2) {
            tempAll.push(qObject);

          } else {
            for (let i = 0; i < patLimit; i++) {
              // Ensure the number of quiz created doesn't exceeds the limit
              if (tempAll.length < qlimit) {
                tempAll.push(qObject);
              }
            } // EndFor
          }
        
        } // EndIf
      }
    }// EndFor

    /*QUIZ 3 (SYMMETRICAL) LOGIC */
    if (gameId === this.game3) {

      tempAll = Array<Quiz>();

      // Randomize Arrays
      gridArray  = await this.randomizeArrayAsync(gridArray);
      symArray   = await this.randomizeArrayAsync(symArray);
      /*** BUILD SEQUENCE BASE AND LEVEL AND CONCATENATE FINAL ARRAY *******/
      tempAll = await this.concatLevels(tempAll, gridArray, symArray);
      
    } else {
      // Shuffle All Shape and Color && Everday Problem
      tempAll = await this.randomizeArray(tempAll);
      if (gameId === this.game1) {
        tempAll = this.buildPattern(tempAll);
      }
    }
    // this.hidLoading();

    return tempAll;

  }

  /**
   * Build Quiz Pattern Based on Color: RRBBRRBB
   * @param quizArray an array of quiz
   */
   private buildPattern(quizArray: Quiz[]): any {
    const tempAll = Array<Quiz>();
    const redShape = Array<Quiz>();
    const blueShape = Array<Quiz>();

    // Separate Colors
    quizArray.forEach((quiz, quizIndex) => {
      if (quiz.color === 'red') {
        redShape.push(quiz);
      } else  if (quiz.color === 'blue') {
        blueShape.push(quiz);
      }
    });

    // Adding Quiz Base on Color Shape In Pattern BBRRBBRR:
    // Where B = blue and R = red
    for (let i = 0; i < (quizArray.length) / 2; i++) {
      if (redShape.length !== 0) {
        tempAll.push(redShape.shift());
        tempAll.push(redShape.shift());
        tempAll.push(blueShape.shift());
        tempAll.push(blueShape.shift());
      } else {
        break;
      }
      
    }
    return tempAll;
    
  }

  /**
   *  Randomize & Generate Quizes Base on Level
   * @param gridArray grid array
   * @param symArray symmetry array
   * @param level numeric value representing the level
   */
  private async concatLevels(tempAll: Quiz[], gridArray: Quiz[], symArray: Quiz[]): Promise<Quiz[]> {
    let levelArray = [2,2,2,3,3,3,4,4,4,5,5,5];
    while(levelArray.length > 0) {
      const index = Math.floor(Math.random() * levelArray.length);
      let symGrid = Array<Quiz>();
      const randomLevel = levelArray[index];
      symGrid   = await this.generateGridSequence(gridArray, symArray, randomLevel);
      Array.prototype.push.apply(tempAll, symGrid); // Merge All Sequence
      levelArray.splice(index, 1);
    }
    return tempAll;

    /*
    * Im leaving this code here because it was a randomization function
    * that literally acted like shuffling cards but one front then one back
    * and on sorted cards that is not random lol also is three times as many lines as the correct solution
    // Build Grid and Symmetrical Sequence
    // 3 Sequence Per Level
    for (let i = 0; i < 3; i++) {
      let symGrid = Array<Quiz>();
      const randomLevel = 0;
      gridArray = this.randomizeArray(gridArray);
      symGrid   = this.generateGridSequence(gridArray, symArray, randomLevel);
      
      // Randomizing Memory Level
      if (!toggle) {
        const tempRan = Array<Quiz>();
        
        // Push Memory Level At the Front
        Array.prototype.push.apply(tempRan, symGrid); // Merge
        Array.prototype.push.apply(tempRan, tempAll); // Merge
        tempAll = null;
        tempAll = tempRan;

        toggle = true;

      } else {

        Array.prototype.push.apply(tempAll, symGrid); // Merge All Sequence
        toggle = false;
      }

    }
    */
  }

  /**
   * Generate Grid Sequence for Quiz3
   * @param gridArray grid array
   * @param symArray symmetry array
   * @param level numeric value representing the level
   */
  private async generateGridSequence(gridArray: Quiz[], symArray: Quiz[], level: number): Promise<Quiz[]> {
    const tempAll = Array<Quiz>();
    let s = 0;
    let g = 0;
    let answer = '';
    let gridObj;

    gridArray = await this.randomizeArrayAsync(gridArray);
    symArray = await this.randomizeArrayAsync(symArray);

    for (const grid of gridArray) {
      if (!tempAll.find(x => x.coordinates === grid.coordinates)) {
        if (symArray.length > 0) {
          grid.memory_level = level; // Add Memory Level
          tempAll.push(grid); // Add Grid First

          // Only Add Remaining Grid & Symmetrical If Symmetrical Exist
          if (symArray[g] != null) {
            symArray[s].memory_level = level; // Add Memory Level
            tempAll.push(symArray[s]);
            s = s + 1; // Number of Symmetrical Added

            // Build Answer and seperate with delimiter
            answer = (answer === '') ? grid.coordinates : answer + ';' + grid.coordinates;

            // Add Final Grid in Round if the level is met
            if (s === level - 1) {
              gridArray[g + 1].memory_level = level; // Add Memory Level
              tempAll.push(gridArray[g + 1]);
              answer = (answer === '') ? gridArray[g + 1].coordinates : answer + ';' + gridArray[g + 1].coordinates;
              gridObj = this.buildAnswerObj(answer);
              gridObj.quiz = true;
              tempAll.push(gridObj);

              break;
            }
          }
        } else {
          break;
        }
        g = g + 1; // Increment

      }
    } // EndFor
    return tempAll;
  }

  /**
   * Build the AnswerObj
   * @param answer string representing the answer
   */
   public buildAnswerObj(answer: string): Quiz {
    const quiz = new Quiz();
    quiz.answer = answer;
    quiz.game_id = this.game3;
    quiz.category = this.grid;

    return quiz;
  }

   /**
   * Get Game Limits From Constant
   * @param gameId the game id
   */
  public getGameLimit(gameId: string): any {
    for (const gameSetting of this.utGameConfig) {
      if (gameId === gameSetting.gameId) {
        return gameSetting;
      }
    }
  }

  async checkUserForAvailability() {
    await this.getAssessments(this.user.id);
    return new Promise((res,rej) => {
      this.authService.getAuthUser().pipe(take(1)).subscribe(user => {
        if(user[0].assessmentDate){
          const now = moment(user[0].assessmentDate).startOf('d').add('12', 'w');
          const threeMonths = moment();
          const max = moment.max(now, threeMonths);
          if(threeMonths === max){
            res(true);
          } else {
            res(false);
          }
        } else {
          res(true);
        }
      })
    })
    
  }

  updateUserAssessmentDate() {
    this.authService.getAuthUser().pipe(take(1)).subscribe(users => {
      const user = users[0];
      if(user.assessmentDate){
        user.assessmentDate = new Date().toJSON();
        this.adminService.saveEntryById(user, DB_CONFIG.user_endpoint);
      } else {
        user.assessmentDate = new Date().toJSON();
        this.adminService.saveEntryById(user, DB_CONFIG.user_endpoint);
      }
    })
  }

  
}
