import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TrackerProcessingService } from '@services/tracker-processing.service';

@Component({
  selector: 'app-trajectory-comparison',
  templateUrl: './trajectory-comparison.component.html',
  styleUrls: ['./trajectory-comparison.component.scss'],
})
export class TrajectoryComparisonComponent implements OnInit, OnChanges {
    // Speed
    currentSpeed = 3;
    previousSpeed = 1;

    @Input() scoreFactor = {
      title: 'Speed', 
      score: Math.round(3/10),
      description: 'how quickly you can make use of information when it is presented to you',
      less_than: 'you may notice that you are more easily overwhelmed by tasks, and it takes you longer to complete complicated tasks',
      inbetween: 'you may notice that you are slightly less easily overwhelmed by tasks, and it takes you slightly less time to complete complicated tasks',
      greater_than: 'you may notice that you are less easily overwhelmed by tasks, and it takes you less time to complete complicated tasks',
      average: 'closer to your peer average (5)',
      ideal: 'closer to ideal',
      perfect: 'You should strive to continue to maintain your Speed abilities.',
      reason: 'tasks feel less overwhelming, and you will be able to complete complicated tasks more quickly',
      reason_perfect: 'continue to help make tasks feel less overwhelming, and you will be able to consistently complete complicated tasks more quickly',
      priority: 'balancing how quickly and accurately you complete Yahootie and Brain Game',
      category: 1,
      img: 'assets/images/report/runningicon.svg',
      current: this.currentSpeed,
      previous: this.previousSpeed,
      improvement: 0,
      below: 'You are more overwhelmed by tasks or can do tasks requiring brain resources more quickly',
      between: 'You are about as overwhelmed by tasks and can do tasks requiring brain resources in about the same amount of time',
      above: 'You are less overwhelmed by tasks or can do tasks requiring brain resources more quickly',
      help: 'you feel less overwhelmed by tasks and complete them more quickly',
      low: 'one of your lowest scored areas, so this will be prioritized for you in Brain Charge',
      middle: 'not one of your lowest scored areas, but there is still room for improvement, so you will still see items related to it in Brain Charge',
      high: 'scored at the maximum possible level, so you will only see a few items related to it in Brain Charge to help you maintain this high level of performance',
      startCompliance: 400,
    };

    trajectoryColor = '#ffb8a7';
    trajectoryText = '';

    comparisonColor = '#ffb8a7';
    comparisonText = '';

    goalColor = '#ffb8a7';
    goalText = '';
    goalScore = '';

    @Input() allData = []

    datas = []
  
  constructor() {}

  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.allData.currentValue !== changes.allData.previousValue) {this.barsOverTimeConfig(this.allData);}
    this.scoreFactor.improvement = Math.floor((this.scoreFactor.current-this.scoreFactor.previous)/this.scoreFactor.previous*100);
    this.trajectoryConfig(this.scoreFactor.score);
    this.comparisonConfig(this.scoreFactor.score);
    this.goalConfig(this.scoreFactor.score);
    this.goalScoreConfig(this.scoreFactor.score);
  }

  async barsOverTimeConfig(data) {
    if(!data) {
      return;
    }
    const promises = data.map(dat => {
      this.datas.push({val: dat.display, date: dat.date});
      return true;
    });
    await Promise.all(promises);
    // needed to make ngOnChanges fire for arrays
    this.datas = this.datas.slice();
  }

  trajectoryConfig(score: number) {
    // red
    if(score < 7) {
      this.trajectoryColor = '#ffb8a7';
      this.trajectoryText = this.scoreFactor.less_than;
    // yellow
    } else if (score < 9) {
      this.trajectoryColor = '#f3ecd4';
      this.trajectoryText = this.scoreFactor.inbetween;
    // green
    } else {
      this.trajectoryColor = '#CDFFBB';
      this.trajectoryText = this.scoreFactor.greater_than;
    }
  }

  comparisonConfig(score: number) {
    // red
    if(score < 5) {
      this.comparisonColor = '#ffb8a7';
      this.comparisonText = this.scoreFactor.less_than;
    // yellow
    } else if (score < 7) {
      this.comparisonColor = '#f3ecd4';
      this.comparisonText = this.scoreFactor.inbetween;
    // green
    } else {
      this.comparisonColor = '#CDFFBB';
      this.comparisonText = this.scoreFactor.greater_than;
    }
  }

  goalConfig(score: number) {
    // red
    if(score < 5) {
      this.goalColor = '#ffb8a7';
      this.goalText = this.scoreFactor.average;
      this.goalScore = this.scoreFactor.low;
    // yellow
    } else if (score < 9.9) {
      this.goalColor = '#f3ecd4';
      this.goalText = this.scoreFactor.ideal;
      this.goalScore = this.scoreFactor.middle;
    // green
    } else {
      this.goalColor = '#CDFFBB';
      this.goalText = this.scoreFactor.perfect;
      this.goalScore = this.scoreFactor.high;
    }
  }

  goalScoreConfig(score: number) {
    // red
    if(score < 3) {
      this.goalScore = this.scoreFactor.low;
    // yellow
    } else if (score < 10) {
      this.goalScore = this.scoreFactor.middle;
    // green
    } else {
      this.goalScore = this.scoreFactor.high;
    }
  }
}
