import { Injectable } from '@angular/core';
import { DB_CONFIG } from '@app/app.firebase.config';
import { AppEnum } from '@enums/AppEnum';
import { PuzzleGameEnum } from '@enums/PuzzleGameEnum';
import { SpecialStats } from '@models/special-stats.model';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AdminService } from './admin.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class StreaksService {

  streaks: SpecialStats
  streakUpdated = new Subject();
  user

  constructor(
    private adminService: AdminService,
    private authService: AuthenticationService
  ) { this.init() }

  init() {
    this.authService.userSubject.subscribe(user => {
      if(user ){
        this.user = user;
        this.loadStreaks(user.id);
      }
      
    })

    this.streakUpdated.subscribe(val => {
      this.loadStreaks(this.user.id)
    })
  }

  /**
   * Gets the current streaks of a user, creates new streaks for users without any.
   * @param userID The user id.
   */
  getStreaks(userID?: string) {
    if(!userID){
      if(!this.user){
        userID = 'id';
      } else {
        userID = this.user.id;
      }
      
    }
    return new Promise((res, rej) => {
      this.loadStreak(userID).then(data => {
        this.streaks = data;
        res(data);
      });
    });
  }

  /**
   * Gets the current streaks of a user, creates new streaks for users without any.
   * @param userID The user id.
   */
  private loadStreaks(userID: string) {
    return new Promise((res, rej) => {
      this.loadStreak(userID).then(data => {
        this.streaks = data;
        res(data);
      });
    });
  }

  /**
   * Retrieves data from the database for the streaks.
   * @param userID The user id.
   */
  private loadStreak(userID: string): Promise<SpecialStats> {
    return new Promise((res, rej) => {
      // retrieve streaks from db
      const table = DB_CONFIG.app_special_stats_endpoint;
      this.adminService.getEntryById(userID, table).then(async (streakies: any) => {
        // either returns stored streaks or creates new ones
        if(streakies) {
          res(streakies);
        } else {
          const streaks = await new SpecialStats(userID);
          res(streaks);
        }
      });
    })
  }

  /**
   * Accepts a streak object and saves it to the AdminService service and the database.
   * @param streaks The streaks to save.
   */
  setStreaks(streaks: SpecialStats): void {
    const table = DB_CONFIG.app_special_stats_endpoint;
    this.adminService.saveEntryById(streaks, table).then(data => {
      this.streakUpdated.next();
      return true;
    }).catch(err => {
      return false;
    });
  }

  /**
   * only used by brain game and yahootie
   */
  newStat(stat: number, field: string[]) {
    switch(field[0]) {
      case AppEnum.BrainGame: {
        if(!field[1]) {
          throw new Error('no game for brain game streaks chosen');
        }
        switch(field[1]) {
          case PuzzleGameEnum.Lemonade: {
            this.streaks.BrainGame.Lemonade.recent = stat;
            if(this.streaks.BrainGame.Lemonade.top < stat) {
              this.streaks.BrainGame.Lemonade.top = stat;
            }
            break;
          }
          case PuzzleGameEnum.LargeSudoku: {
            if(!this.streaks.BrainGame.Large_Sudoku) {
              this.streaks.BrainGame = {...this.streaks.BrainGame, Large_Sudoku: {recent: stat, top: stat}};
            }
            this.streaks.BrainGame.Large_Sudoku.recent = stat;
            if(this.streaks.BrainGame.Large_Sudoku.top < stat) {
              this.streaks.BrainGame.Large_Sudoku.top = stat;
            }
            break;
          }
          case PuzzleGameEnum.GuessWho: {
            if(!this.streaks.BrainGame.The_Fame_Game) {
              this.streaks.BrainGame = {...this.streaks.BrainGame, The_Fame_Game: {recent: stat, top: stat}};
            }
            this.streaks.BrainGame.The_Fame_Game.recent = stat;
            if(this.streaks.BrainGame.The_Fame_Game.top < stat) {
              this.streaks.BrainGame.The_Fame_Game.top = stat;
            }
            break;
          }
          case PuzzleGameEnum.Sudoku: {
            this.streaks.BrainGame.Sudoku.recent = stat;
            if(this.streaks.BrainGame.Sudoku.top < stat) {
              this.streaks.BrainGame.Sudoku.top = stat;
            }
            break;
          }
          case PuzzleGameEnum.Sliding: {
            if(!this.streaks.BrainGame.Puzzle_Master) {
              this.streaks.BrainGame = {...this.streaks.BrainGame, Puzzle_Master: {recent: stat, top: stat}};
            }
            this.streaks.BrainGame.Puzzle_Master.recent = stat;
            if(this.streaks.BrainGame.Puzzle_Master.top < stat) {
              this.streaks.BrainGame.Puzzle_Master.top = stat;
            }
            break;
          }
          case PuzzleGameEnum.Tower: {
            if(!this.streaks.BrainGame.Brain_Defense) {
              this.streaks.BrainGame = {...this.streaks.BrainGame, Brain_Defense: {recent: stat, top: stat}};
            }
            this.streaks.BrainGame.Brain_Defense.recent = stat;
            if(this.streaks.BrainGame.Brain_Defense.top < stat) {
              this.streaks.BrainGame.Brain_Defense.top = stat;
            }
            break;
          }
          case PuzzleGameEnum.Trail: {
            if(!this.streaks.BrainGame.Synapse_Scramble) {
              this.streaks.BrainGame = {...this.streaks.BrainGame, Synapse_Scramble: {recent: stat, top: stat}};
            }
            this.streaks.BrainGame.Synapse_Scramble.recent = stat;
            if(this.streaks.BrainGame.Synapse_Scramble.top < stat) {
              this.streaks.BrainGame.Synapse_Scramble.top = stat;
            }
            break;
          }
        }
        this.streaks.BrainGame.Overall.recent = stat;
        if(this.streaks.BrainGame.Overall.top < stat) {
          this.streaks.BrainGame.Overall.top = stat;
        }
        break;
      }
      case AppEnum.Yahootie: {
        this.streaks.Yahootie.recent = stat;
        if(this.streaks.Yahootie.top < stat) {
          this.streaks.Yahootie.top = stat;
        }
        break;
      }
    }

    this.setStreaks(this.streaks);
  }
}
