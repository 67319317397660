<svg viewBox="0 0 36 36">
  <path class="circle-bg"
    d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
  />
  <path class="percentage" id={{nameId}}
    d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
    [ngStyle]="{'stroke-dasharray': dashArray, 'stroke': color}"
  />
</svg>
