<div class="cont">
  <app-close (click)="closeModal()"></app-close>
  <app-title text="Outside Training"></app-title>
  <div class="centered">
    <ion-text class="num">{{trainingCount}}</ion-text>
    <ion-text class="training-label">Outside {{trainingText}}</ion-text>
    <ion-grid>
      <div class="trainings">
        <ion-row>
          <ion-col *ngFor="let setting of categorySettings">
            <ion-row
              *ngFor="let list of setting.list"
              class="cell centered"
              [ngStyle]="list && {'background-color': setting.background, 'border-color': setting.iconColor }">
              <ion-text>{{list}}</ion-text>
            </ion-row>
          </ion-col>
        </ion-row>
      </div>
      <ion-row class="spacing">
        <ion-col class="category" *ngFor="let categorySetting of categorySettings">
          <ion-icon name='{{categorySetting.icon}}'></ion-icon>
          <ion-text class="type">{{categorySetting.id}}</ion-text>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>
