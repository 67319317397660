import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '@services/guard/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { 
    path: 'app-details/:appTitle', 
    loadChildren: () => import('./app-details/app-details.module').then(m => m.AppDetailsPageModule),
    canActivate: [AuthGuardService] },
  { 
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'apps-menu',
    loadChildren: () => import('./apps-menu/apps-menu.module').then( m => m.AppsMenuPageModule),
    canActivate: [AuthGuardService]
  },
  // Assessment
  {
    path: 'assessment',
    loadChildren: () => import('./assessment/pages/assessment.module').then(m => m.AssessmentPageModule),
    canActivate: [AuthGuardService]
  },
  // brain game routes
  {
    path: 'braingame/game-selection',
    loadChildren: () => import('./brain-game/game-select/game-select.module').then(m => m.GameSelectPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'braingame/lemonade',
    loadChildren: () => import('./brain-game/lemonade/wrapper/wrapper.module').then( m => m.WrapperPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'braingame/sliding/configure-puzzle',
    loadChildren: () => import('./brain-game/puzzle/configure-puzzle/configure-puzzle.module').then(m => m.ConfigurePuzzlePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'braingame/game-select',
    loadChildren: () => import('./brain-game/game-select/game-select.module').then( m => m.GameSelectPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'braingame/sliding',
    loadChildren: () => import('./brain-game/sliding/wrapper/wrapper.module').then( m => m.WrapperPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'braingame/sudoku',
    loadChildren: () => import('./brain-game/sudoku/wrapper/wrapper.module').then( m => m.WrapperPageModule),
    canActivate: [AuthGuardService]
  },
  
  {
    path: 'braingame/large-sudoku',
    loadChildren: () => import('./brain-game/large-sudoku/large-sudoku.module').then( m => m.LargeSudokuPageModule)
  },
  {
    path: 'braingame/tower-defense',
    loadChildren: () => import('./brain-game/tower-defense/tower-defense.module').then(m => m.HomePageModule),
    canActivate: [AuthGuardService]
  },
  { 
    path: 'braingame/trail-making',
    loadChildren: () => import('./brain-game/trail-making-test/trail-making-test.module').then(m => m.TrailMakingTestPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'braingame/guess-who',
    loadChildren: () => import('./brain-game/historical-figure/historical-figure.module').then( m => m.HistoricalFigurePageModule)
  },

  // HAPPY PLACE BEGINS
  {
    path: 'happy-place/home',
    loadChildren: () => import('./happy-place/pages/video-player/video-player.module').then(m => m.VideoPlayerPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'happy-place/player',
    loadChildren: () => import('./happy-place/pages/video-player/video-player.module').then(m => m.VideoPlayerPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'happy-place/upload',
    loadChildren: () => import('./happy-place/pages/upload/upload.module').then(m => m.UploadPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'happy-place/finish',
    loadChildren: () => import('./happy-place/pages/finish/finish.module').then(m => m.FinishPageModule),
    canActivate: [AuthGuardService]
  },
  // HAPPY PLACE ENDS

  // Clubhouse
  {
    path: 'clubhouse',
    loadChildren: () => import('./clubhouse/pages/clubhouse.module').then(m => m.ClubhouseModule),
    canActivate: [AuthGuardService]
  },
  // {
  //   path: 'clubhouse/meeting/select',
  //   loadChildren: () => import('./clubhouse/pages/meeting/select/meeting-select.module').then(m => m.MeetingSelectPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'clubhouse/presentation/previous',
  //   loadChildren: () => import('./clubhouse/pages/presentation/previous/presentation-previous.module').then( m => m.PresentationPreviousPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'clubhouse/presentation/upcoming',
  //   loadChildren: () => import('./clubhouse/pages/presentation/upcoming/presentation-upcoming.module').then( m => m.PresentationUpcomingPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'clubhouse/admin/meeting/upcoming',
  //   loadChildren: () => import('./clubhouse/pages/admin/meeting/upcoming/meeting-upcoming.module').then( m => m.MeetingUpcomingPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'clubhouse/rollcall',
  //   loadChildren: () => import('./clubhouse/pages/rollcall/rollcall.module').then(m => m.RollcallPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'clubhouse/successes',
  //   loadChildren: () => import('./clubhouse/pages/successes/successes.module').then(m => m.SuccessesPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'clubhouse/presentation',
  //   loadChildren: () => import('./clubhouse/pages/presentation/presentation.module').then(m => m.PresentationPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'clubhouse/finish',
  //   loadChildren: () => import('./clubhouse/pages/finish/finish.module').then(m => m.FinishPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'clubhouse/zoom/link',
  //   loadChildren: () => import('./clubhouse/pages/zoom/link/link.module').then(m => m.LinkPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'clubhouse/zoom/rollcall',
  //   loadChildren: () => import('./clubhouse/pages/zoom/rollcall/rollcall.module').then(m => m.RollcallPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'settings/presentation/upload',
  //   loadChildren: () => import('./clubhouse/pages/admin/presentation/form/presentation-form.module').then(m => m.PresentationFormPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'clubhouse/admin/presentation/form/:id',
  //   loadChildren: () => import('./clubhouse/pages/admin/presentation/form/presentation-form.module').then(m => m.PresentationFormPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // End Clubhouse

  // Begin Settings

  {
    path: 'settings/activities',
    loadChildren: () => import('./settings/pages/activities/activities.module').then(m => m.ActivitiesPageModule),
    canActivate: [AuthGuardService]
  },
  { 
    path: 'settings/general/terms',
    loadChildren: () => import('./settings/pages/terms/terms.module').then(m => m.TermsPageModule),
    canActivate: [AuthGuardService]
  },
  { 
    path: 'settings/general/import',
    loadChildren: () => import('./settings/pages/general/import/import.module').then(m => m.ImportPageModule),
    canActivate: [AuthGuardService]
  },
  { 
    path: 'settings/general/export',
    loadChildren: () => import('./settings/pages/general/export/export.module').then(m => m.ExportPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin/user-tool',
    loadChildren: () => import('./settings/general/user-tool/user-tool.module').then( m => m.UserToolPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin/image-testing',
    loadChildren: () => import('./settings/general/image-testing/image-testing.module').then( m => m.ImageTestingPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin/subscriber',
    loadChildren: () => import('./settings/subscriber/subscriber.module').then( m => m.SubscriberPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'settings/question-testing',
    loadChildren: () => import('./settings/general/question-testing/question-testing.module').then(m => m.QuestionTestingModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'settings/sliding-form',
    loadChildren: () => import('./settings/pages/braingame/sliding/sliding-form/sliding-form.module').then(m => m.SlidingFormPageModule),
    canActivate: [AuthGuardService]
  },
  // Settings Ends

  // Yahootie Begins
  {
    path: 'yahootie/game-configuration',
    loadChildren: () => import('./yahootie/pages/game/configuration/game-configuration.module').then(m => m.GameConfigurationPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'yahootie/players',
    loadChildren: () => import('./yahootie/pages/players/players.module').then(m => m.PlayersPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'yahootie/instruction',
    loadChildren: () => import('./yahootie/pages/instruction/instruction.module').then(m => m.InstructionPageModule),
    canActivate: [AuthGuardService]
  },
  { 
    path: 'yahootie/game',
    loadChildren: () => import('./yahootie/pages/game/game/game.module').then(m => m.GamePageModule),
    canActivate: [AuthGuardService]
  },
  // Home Page Routes
  { 
    path: 'landing',
    loadChildren: () => import('./home/landing/landing.module').then(m => m.LandingPageModule),
    canActivate: [AuthGuardService]
  },
  { 
    path: 'training',
    loadChildren: () => import('./home/training/training.module').then(m => m.TrainingPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'training/summary',
    loadChildren: () => import('./home/training/summary/summary.module').then(m => m.SummaryPageModule),
    canActivate: [AuthGuardService]
  },
  { 
    path: 'community',
    loadChildren: () => import('./home/community/community.module').then(m => m.CommunityPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'progress',
    loadChildren: () => import('./home/progress/progress.module').then( m => m.ProgressPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'progress/:id',
    loadChildren: () => import('./home/progress/progress.module').then( m => m.ProgressPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule),
    canActivate: [AuthGuardService],
    data: { expectedRole: 'admin' }
  },
  {
    path: 'subscriber',
    loadChildren: () => import('./subscriber/subscriber.module').then( m => m.SubscriberPageModule),
    canActivate: [AuthGuardService],
    data: { expectedRole: 'subscriber' }
  },
  // {
  //   path: 'highlights',
  //   loadChildren: () => import('./home/highlights/highlights.module').then( m => m.HighlightsPageModule)
  // },
  {
    path: 'profile',
    loadChildren: () => import('./home/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'register',
    loadChildren: () => import('./login/register/register.module').then( m => m.RegisterPageModule)
  },

  // IMPORTANT MUST STAY AT THE END
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
  

  

  

  

  

  

  

  

  



];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
