import { Component, OnInit } from '@angular/core';
import { DB_CONFIG } from '@app/app.firebase.config';
import { ModalController } from '@ionic/angular';
import { AdminService } from '@services/admin.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.page.html',
  styleUrls: ['./disclaimer.page.scss'],
})
export class DisclaimerPage implements OnInit {
  
  // received from the parent, HomePage
  user;

  constructor(
    private modalCtrl: ModalController,
    private auth: AuthenticationService,
    private adminService: AdminService
  ) { }

  ngOnInit() { }

  /**
   * Sends the data to the AdminService, agrees the user 
   * to the terms, and closes the modal.
   */
  agreedTerms(): void {
    this.user.agreedTerms = true;
    this.adminService.updateEntry(this.user, DB_CONFIG.user_endpoint);
    this.closeModal();
  }

  /**
   * Signs out the user, and closes the modal.
   */
  declineTerms(): void {
    this.auth.signOut();
    this.closeModal();
  }

  /**
   * Closes the modal.
   */
  closeModal(): void {
    this.modalCtrl.dismiss();
  }
}
