export const DB_CONFIG = {
    answer_endpoint: 'answers',
    apps_endpoint: 'apps',
    app_special_stats_endpoint: 'app_special_stats',
    apps_skipped_endpoint: 'apps_skipped',
    assessment_endpoint: 'assessment',
    brain_game_leaderboard_endpoint: 'brain_game_leaderboard',
    bg_responses_endpoint: 'bg_responses',
    brain_charge_scores_endpoint: 'brain_charge_scores',
    clubhouse_tracking_endpoint: 'clubhouse_tracking',
    data_tracker_endpoint: 'data_tracker',
    did_you_know_endpoint: 'did_you_know',
    email_endpoint: 'mail',
    exercise_endpoint: 'exercise',
    featured_activities: 'featured_activities',
    game_endpoint: 'game',
    games_endpoint: 'games',
    games_skipped_endpoint: 'games_skipped',
    global_level_endpoint: 'global_level',
    happy_place_endpoint: 'happy_place',
    happy_place_tracking_endpoint: 'happy_place_tracking',
    highlights_endpoint: 'highlights',
    level_endpoint: 'levels',
    llm_feedback_endpoint: 'llm_feedback',
    meeting_endpoint: 'meeting',
    mood_sleep_endpoint: 'mood_sleep',
    overall_session_endpoint: 'overall_sessions',
    outside_training_endpoint: 'outside_training',
    presentation_endpoint: 'presentations',
    question_endpoint: 'questions',
    quiz_endpoint: 'quiz',
    sliding_endpoint: 'sliding',
    stat_tracker_endpoint: 'stat_tracker',
    sub_activities_endpoint: 'sub_activities',
    subscriber_endpoint: 'subscriber',
    summary_answer_endpoint: 'summary_answers',
    user_endpoint: 'users',
    yahootie_config_endpoint: 'yahootie_config',
    yahootie_questions_endpoint: 'yahootie_questions',
    yahootie_result_endpoint: 'yahootie_result',
    yahootie_result_report_endpoint: 'yahootie_result_report',
    yahootie_score_endpoint: 'yahootie_score',
    
    // old tables
    team_endpoint: 'team',
    team_player_endpoint: 'team_player',
    event_endpoint: 'event',
    profile_endpoint: 'profile',
    result_endpoint: 'result',
};    



import { TEST_MODE } from "./app.constants.config";

// export let FIREBASE_CONFIG = {
// apiKey: 'AIzaSyBYz0ax8-oNXo3xEg_KYf_fJ9eZhskVipI',
// authDomain: 'brain-evolved-dev.firebaseapp.com',
// databaseURL: 'https://brain-evolved-dev.firebaseio.com',
// projectId: 'brain-evolved-dev',
// storageBucket: 'brain-evolved-dev.appspot.com',
// messagingSenderId: '989222737402',
// appId: "1:439321294046:web:d58142a90f057aef93fe4f"
// };

export let FIREBASE_CONFIG = {
    apiKey: "AIzaSyDG45UMcbswv-6sSYjp0MgyK2lGLsig47M",
    authDomain: "brain-evolved.firebaseapp.com",
    databaseURL: "https://brain-evolved.firebaseio.com",
    projectId: "brain-evolved",
    storageBucket: "brain-evolved.appspot.com",
    messagingSenderId: "439321294046",
    appId: "1:439321294046:web:d58142a90f057aef93fe4f",
    };

if(TEST_MODE){
    FIREBASE_CONFIG.apiKey = 'AIzaSyBYz0ax8-oNXo3xEg_KYf_fJ9eZhskVipI';
    FIREBASE_CONFIG.authDomain = 'brain-evolved-dev.firebaseapp.com';
    FIREBASE_CONFIG.databaseURL = 'https://brain-evolved-dev.firebaseio.com';
    FIREBASE_CONFIG.projectId = 'brain-evolved-dev',
    FIREBASE_CONFIG.storageBucket = 'brain-evolved-dev.appspot.com';
    FIREBASE_CONFIG.messagingSenderId = '989222737402';
    FIREBASE_CONFIG.appId = "1:439321294046:web:d58142a90f057aef93fe4f";
}

