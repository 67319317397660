import { Injectable } from '@angular/core';
import { DB_CONFIG } from '../app.firebase.config';
import { map } from 'rxjs/operators';
import { Utilities } from '@common/utilities';
import { IExercise } from '@common/interface/exercise.interface';
import { IGameConfiguration } from '@common/interface/game-configuration.interface';
import { ExerciseTypeEnum } from '@enums/ExerciseTypeEnum';
import { GameService } from '@services/game.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ExerciseService {

  private exerciseRef: AngularFirestoreCollection<IExercise>;
  private lastExercise: any;
  private difficulty: any;
  private gameConfig: IGameConfiguration;
  private currentRound: any; // independent variable from the one that displays in the footer
  
  constructor(
    private afs: AngularFirestore,
    private gameService: GameService
  ) { }

  /**
   * Get all the exercises from the database.
   * @returns All the exercises.
   */
  getAllExercises(): Observable<any> {
    this.exerciseRef = this.afs.collection<IExercise>(
      DB_CONFIG.exercise_endpoint);
    return this.getExerciseSnapshot();
  }

  /**
   * Gets the user's exercise data.
   * @returns The user's exercise data.
   */
  getExerciseSnapshot(): Observable<any> {
    return this.exerciseRef.snapshotChanges()
      .pipe(
        map(actions => {
          const data = Utilities.mapActions(actions);
          return data;
        })
      );
  }

  /**
   * Gets the current game config to determine which exercises to load.
   */
  getGameConfig(): void {
    this.currentRound = 1; // sets current round to 1;
    this.gameService.gameConfigSubject
      .subscribe((gameConfig: IGameConfiguration) => {
        if (gameConfig) {
          this.gameConfig = gameConfig;
          this.difficulty = gameConfig.difficulty;
        }
      });
  }

  /**
   * Gets the exercises by type.
   * @param type An enum representing the type of exercise.
   * @param limit The numeric value representing the dataset limit.
   * @param an The difficulty level as an enum.
   */
  getExercisesByType(type: ExerciseTypeEnum, limit: number): Observable<IExercise[]> {
    this.getGameConfig();
    // filters the firestore collection by GroupID depending on what difficulty the user selects
    this.exerciseRef = this.afs.collection<IExercise>(
        DB_CONFIG.exercise_endpoint,
        ref => ref.where(this.difficulty + 'GroupId', '>=', this.currentRound + '-1')
          .limit(limit));
    
    this.currentRound += 1; // adds one after we get the first round

    return this.exerciseRef.snapshotChanges()
        .pipe(
          map(actions => {
            return actions.map(a => {
            // Get document data
            const data = a.payload.doc.data() as IExercise;
  
            this.lastExercise = a.payload.doc;
  
            // Get document id
            const id = a.payload.doc.id;
  
            // Use spread operator to add the id to the document data
            return { id, ...data };
          });
        })
      );
  }

  /**
   * Get the next exercises by type.
   * @param type An enum representing the type of exercise.
   * @param limit The numeric value representing the dataset limit.
   * @returns The next exercises.
   */
  getNextExercisesByType(type: ExerciseTypeEnum, limit: number): Observable<IExercise[]> {
    if (this.currentRound > 3) { // if the current round is higher than what we have in the DB, we want to set it back to the beginning
      this.currentRound = 1;
    }
    // filters the firestore collection by GroupID depending on what difficulty the user selects
    this.exerciseRef = this.afs.collection<IExercise>(
      DB_CONFIG.exercise_endpoint,
      ref => ref.where(this.difficulty + 'GroupId', '>=', this.currentRound + '-1')
        .startAfter(this.lastExercise)
        .limit(limit));
    
    this.currentRound += 1; // increases the current round by one once we get the next round
        
    return this.exerciseRef.snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            // Get document data
            const data = a.payload.doc.data() as IExercise;
            
            this.lastExercise = a.payload.doc;

            // Get document id
            const id = a.payload.doc.id;

            // Use spread operator to add the id to the document data
            return { id, ...data };
          });
        })
      );
   }
}

