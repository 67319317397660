import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-grey-screen',
  templateUrl: './grey-screen.component.html',
  styleUrls: ['./grey-screen.component.scss'],
})
export class GreyScreenComponent implements OnInit, AfterViewInit {

  @Input() message = '';
  @Input() button_message = '';
  @Input() loading = false;
  @Output() button_click: EventEmitter<any> = new EventEmitter();
  @Output() loading_finish: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    if(this.loading) {
      // document.getElementById('grey_screen_loading').style.transform = 'rotate(1080deg)';
    }
  }
  
  async ngAfterViewInit() { 
    if(this.loading){
      const wait = (delay, ...args) => new Promise(resolve => setTimeout(resolve, delay, ...args));
      await wait(250).then( () => {
        document.getElementById('grey_screen_loading').style.transform = 'rotate(1080deg)';
      });
      await wait(10000).then( () => {
        this.loading_finish.emit(true);
      });
    }
    
  }

  click_function() {
    this.button_click.emit(true)
  }
}
