import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import {
  SwiperConfigInterface,
  SwiperPaginationInterface,
  SwiperComponent
} from 'ngx-swiper-wrapper';


import { NavController } from '@ionic/angular';
import { Utilities } from 'src/app/common/utilities';
import { Router } from '@angular/router';
import { MeetingService } from 'src/app/services/meeting.service';

@Component({
  selector: 'app-swiper-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class SwiperAdminComponent extends Utilities
  implements OnInit, AfterViewInit {
  @ViewChild(SwiperComponent, { static: true }) swiper?: SwiperComponent;

  @Input() instructions = [];
  @Input() showHeader = true;
  @Output() instructionEnd: EventEmitter<any> = new EventEmitter();
  @Output() removeSlider: EventEmitter<any> = new EventEmitter();

  swiperConfig: SwiperConfigInterface = {};
  swiperIndex = 0;

  disablePrev = true;
  showTitle: boolean;
  showRemove: boolean;
  height: number;
  width: number;
  

  /**
   * not using this but leaving it for reference
   */
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination'
  };

  constructor(
    public navCtrl: NavController,
    private router: Router,
    private meetingService: MeetingService,
    ) {
    super();
  }

  ngOnInit() {
    this.showRemove = this.router.url.indexOf('admin') > - 1;
    this.showRemove = this.router.url.indexOf('view') < - 1;
  }

  ngAfterViewInit() {
    // swiper configuration
    this.swiperConfig = {
      slidesPerView: 1,
      pagination: this.pagination,
      loop: false,
      effect: 'slide',
    };
    setTimeout(() => this.changeScreenSize(), 1000);
  }

  /**
   * Changes the orientation of the screen based on the window's inner height.
   */
  changeScreenSize(): void {
    this.meetingService.orientationChange.subscribe(data => {
      const slides = document.getElementsByClassName('slides');
      // portrait
      if (data) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < slides.length; i++) {
          const slide = slides[i] as HTMLElement;
          if (slide.className.indexOf('slides pos-rel') > -1) {
            slide.style.height = '' + (window.innerHeight / 3) + 'px';
          }
        }
      } else {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < slides.length; i++) {
          const slide = slides[i] as HTMLElement;
          if (slide.className.indexOf('slides pos-rel') > -1) {
            slide.style.height = '' + (window.innerHeight - 90) + 'px';
          }
        }
      }
    });
  }

  /**
   * Stores the index of the current question in the swiper 
   * to determine when the end has been reached.
   */
  onIndexChange(index: number): void {
    this.swiperIndex = index;
  }

  /**
   * Emits that the instructions have ended.
   */
  onInstructionEnded(): void {
    this.instructionEnd.emit();
  }

  /**
   * Moves to the next slide if we have not reached the end of the instructions. 
   * Otherwise emit an event so the parent component can take action.
   */
  moveToNext(): void {
    if (this.swiperIndex + 1 !== this.instructions.length) {
      // move to the next slide
      this.swiper.directiveRef.nextSlide();
      this.disablePrev = false;
    } else {
      this.onInstructionEnded();
    }
  }

  /**
   * Moves to the prev slide if we have not reached the end
   * of the instructions, otherwise disable the previous button.
   */
  moveToPrev(): void {
    if (this.swiperIndex > 0) {
      // move to the prev slide
      this.swiper.directiveRef.prevSlide();
      this.disablePrev = this.swiperIndex === 0;
    }
  }

  removeSlide(): void {
    this.removeSlider.emit(this.swiperIndex);
  }
}

