import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { brainGameDefaultConfig } from '@app/app.constants.config';
import { DB_CONFIG } from '@app/app.firebase.config';
import { AppEnum } from '@enums/AppEnum';
import { GameDifficultyEnum } from '@enums/DifficultyEnum';
import { PuzzleGameEnum } from '@enums/PuzzleGameEnum';
import { NavController } from '@ionic/angular';
import { Answer } from '@models/answer.model';
import { BrainGameReport } from '@models/bgReport.interface';
import { Question } from '@models/question.model';
import { User } from '@models/user.interface';
import { BehaviorSubject, Subject } from 'rxjs';
import { AdminService } from './admin.service';
import { AuthenticationService } from './authentication.service';
import { DataTrackingService } from './data-tracking.service';
import { HighlightsService } from './highlights.service';
import { LeaderboardService } from './leaderboard.service';
import { LemonadeService } from './lemonade.service';
import { OverallSessionService } from './overall-session.service';
import { StreaksService } from './streaks.service';
import { Trivia2Service } from './trivia2.service';
import { BrainGameService } from './brain-game.service';

@Injectable({
  providedIn: 'root'
})
export class BrainGameControllerService {

  //Sliding Puzzle
  all_images_tiles = [];
  currentChoice = 0;

  game = 'Lemonade' as PuzzleGameEnum;
  difficulty = GameDifficultyEnum.Medium as GameDifficultyEnum;
  response: BrainGameReport = {} as BrainGameReport;
  responseArr = {} as {responses: BrainGameReport[]};
  roundNum = 1;
  questionsThisRound = 0;
  questionsTotal = 0;
  leaderIndex = -1;
  user: User


  // All subjects are here
  score = new BehaviorSubject<number>(0);
  timerPaused = new BehaviorSubject<boolean>(false);
  inGameFlag = false;

  // lemonade
  instructionsRead = new Subject();
  gameDone = new Subject();
  endscreenDone = new Subject();

  // wrapper
  triviaStart = new Subject(); 
  gameStart = new Subject();
  answersStart = new Subject(); 
  leaderboardStart = new Subject();
  closeLeaderboardModal = new Subject();

  resetTimer = new Subject();
  timerLoaded = new Subject();
  timeUp = new Subject();

  questionIsAnswered = new BehaviorSubject<boolean>(null);


  constructor(
    private leaderService: LeaderboardService,
    private authService: AuthenticationService,
    private navCtrl: NavController,
    private triviaService: Trivia2Service,
    private dataService: DataTrackingService,
    public lemonService: LemonadeService,
    private streakService: StreaksService,
    private adminService: AdminService,
    private highlightService: HighlightsService,
    private overallSessionService: OverallSessionService,
    private brainGameService: BrainGameService
  ) { this.init()}

  init() {
    this.authService.userSubject.subscribe(user => {
      if (user) {
        this.user = user;
      } else {
        this.user = {
          id           : 'AGN40DzRsUyvZ2oczYvb',
          subscriber_id: 'uhCKO66TfPPUCRpCu4Ok'
        } as User;
      }
      this.response = new BrainGameReport(this.user.id, this.user.name);
      this.adminService.getEntryById(user.id, DB_CONFIG.bg_responses_endpoint).then(data => {
        data?this.responseArr = data:this.responseArr.responses = [];
      });
    });

    

    this.triviaService.questionGotAnswered.subscribe((parameterObj: any) => {
      this.questionAnswered(parameterObj.answer, parameterObj.question)
    })
  }

  async finishGame(score, game_time?) {
    const trivia_score = this.response.trivia_score;
    this.response.game_score = score;
    this.response.overall_score = score + trivia_score;
    this.leaderIndex = await this.leaderService.insert(this.response);
    this.response['num_of_rounds'] = this.roundNum;
    this.gameDone.next();
    if(game_time){
      this.response.game_time = game_time;
    }
    this.saveData();
  }

  /**
   * Sets up the Brain Game when it loads.
   * @param diff The difficulty of the game.
   * @param game The game selected.
   */
  bgOnLoad(diff: GameDifficultyEnum, game: PuzzleGameEnum): void {
    // only used for debugging purposes so i dont have to login to the game select page
    this.startTrivia(game);
  }

  /**
   * Resets the properties to for the next game.
   */
  cleanse(): void {
    if(!this.user){throw new Error(`User not loaded`)}
    this.response = new BrainGameReport(this.user.id, this.user.name);
    this.roundNum = 1;
    this.questionsThisRound = 0;
    this.questionsTotal = 0;
    this.leaderIndex = -1;
    this.triviaService.cleanse();
    this.score.next(0);
    this.resetTimer.next();

    if (this.game == PuzzleGameEnum.Lemonade) {
      this.lemonService.clear();
    }
  }

  getResponsesByDate(){

  }

  endOfPath() {
    this.highlightService.endOfPath(AppEnum.BrainGame, this.response);
    this.dataService.addValue('leaderboard', this.leaderIndex);
    this.dataService.endOfPath();
  }

  // Report Calculations

  private trueSums(arr) {
    return new Promise<number>(async (res,rej) => {
      let sum = 0;
      for(let obj of arr) {
        if(obj.correct) {
          await sum++;
        }
      }
      res(sum);
    })
  }

  /**
   * Saves the game score and accuracy to the data service.
   */
  async saveData() {
    this.streakService.newStat(this.response.overall_score, [AppEnum.BrainGame, this.game]);
    if(!this.response['num_of_rounds']){this.response['num_of_rounds'] = brainGameDefaultConfig.number_of_rounds;}
    this.response['leaderboard'] = this.leaderIndex;
    this.responseArr['id'] = this.user.id;
    this.response.game_name = this.game;
    this.response.difficulty = this.difficulty;
    const promises = this.response.question_answer.map((qa,index) => {
      this.response.question_answer[index] = {question: qa.question.id, answer: qa.answer.id, correct: qa.answer.is_correct};
      return;
    })
    await Promise.all(promises);
    const qa_len =  this.response.question_answer.length;
    const triviaAcc  = qa_len ? (await this.trueSums(this.response.question_answer)) / qa_len : 0;
    this.response['triviaAcc'] = triviaAcc;
    this.responseArr.responses.push(this.response);
    this.overallSessionService.saveOverallSessionApp(AppEnum.BrainGame);
    await this.adminService.saveEntryById(this.responseArr, DB_CONFIG.bg_responses_endpoint);
    return;
  }

  /**
   * Starts the trivia for the game.
   * @param difficulty The difficulty of the game.
   * @param game The game selected.
   */
  async startTrivia(game: PuzzleGameEnum){
    this.cleanse();
    const difficulty = await this.getDifficulty(game);
    this.setDiff(difficulty);
    this.setGame(game);
    this.leaderService.getLeaderboard(game, difficulty, this.user.subscriber_id);

    // this should be replaced using the trivia page itself to record its time to the response whenever trivia 
    // is called it should work and add to a response that gets reset at new game choice or end of game
    this.dataService.startTimer('triviaTime');
  }

  /**
   * Starts the trivia for the game.
   * @param difficulty The difficulty of the game.
   * @param game The game selected.
   */
  async startGame(game: PuzzleGameEnum){
    this.cleanse();
    const difficulty = await this.getDifficulty(game);
    this.setDiff(difficulty);
    this.setGame(game);
    this.leaderService.getLeaderboard(game, difficulty, this.user.subscriber_id);

    // games should be different because they would all have to be done individually
    // this.dataService.startTimer('gameTime');
  }

  /**
   * When play button gets Clicked
   * @param route route to go to
   * @param game game to play(Lemonade,Sudoku,etc)
   */
  async getDifficulty(game: PuzzleGameEnum) {
    const recommendedLevelsData = this.brainGameService.recommendedLevelsData
    const difficulty = await recommendedLevelsData.find(gamer => {return gamer.game === game}).difficulty;
    return difficulty;
  }

  /**
   * Adds x points to the score variable.
   * @param points The amount of points to add to the score.
   */
  updateScore(points: number , inGame?): void {
    const currScore = this.score.value;
    // game flag is whether were playing the game or trivia
    if (this.inGameFlag || inGame) {
      this.response.game_score += points;
      this.response.round_game_score += points;
    } else {
      this.response.trivia_score += points;
      this.response.round_trivia_score += points;
    }
    this.response.overall_score += points;
    this.score.next(currScore + points);
  }

  /**
   * Logs the response. Called after x questions have been answered 
   * but can be called manually.
   */
  storeResponse(): void {
    console.log(this.response);
  }

  /**
   * Handles all responses for when a question gets answered.
   * @param answer The answer given by the user.
   * @param question The question answered by the user.
   */
  questionAnswered(answer: Answer, question: Question): void {
    if(Object.keys(this.response).length === 0){
      // throw new Error('no response re log');

      // this.gameStart.next(); 
      // return;

      this.response.question_answer = [];
    }
    if(!answer.is_correct){answer.is_correct = false;}
    this.response.question_answer.push({answer, question});
    if (answer.id === question.answer_id) {
      this.updateScore(brainGameDefaultConfig.point);
      this.questionIsAnswered.next(true);
    } else {
      this.questionIsAnswered.next(false);
    }
    this.questionsThisRound++;
    this.questionsTotal++;
    // if we've answered a multiple of 4 rounds store the response
    if (this.questionsThisRound % brainGameDefaultConfig.number_of_questions === 0) {
      this.navAway('game');
    }
  }

  /**
   * Sets the difficulty for TriviaService.
   * @param difficulty The difficulty to set the service to.
   */
  setDiff(difficulty: GameDifficultyEnum): void {
    this.difficulty = difficulty;
    this.triviaService.difficulty = difficulty;
    switch(difficulty) {
      case GameDifficultyEnum.Easy:
        this.dataService.addValue('difficulty', 1);
        break;
      case GameDifficultyEnum.Medium:
        this.dataService.addValue('difficulty', 2);
        break;
      case GameDifficultyEnum.Hard:
        this.dataService.addValue('difficulty', 3);
        break;
    }
  }

  /**
   * Sets the game for the TriviaService.
   * @param game The game to set the service to.
   */
  setGame(game: PuzzleGameEnum) {
    this.game = game;
    this.response.game_name = game;
  }

  /**
   * Navigates to the specified page.
   * @param flag The destination to go ('trivia', 'game', 'answers', or 'leaderboard').
   * @throws An error if the method is called with a bad flag.
   */
  async navAway(flag: string) {
    switch(flag) {
      case 'trivia':
        if(this.roundNum > brainGameDefaultConfig.number_of_rounds) {
          this.cleanse();
        }
        this.resetTimer.next(true);
        this.timerPaused.next(false);
        this.questionsThisRound = 0;
        this.triviaStart.next();
        this.inGameFlag = false;
        this.dataService.startTimer('triviaTime');
        break;
      case 'game':
        this.response.trivia_time += await this.dataService.endTimer('triviaTime');
        this.gameStart.next();
        this.timerPaused.next(true);
        this.inGameFlag = true;
        break;
      case 'answers':
        this.timerPaused.next(true);
        this.answersStart.next();
        this.response.overall_score = this.response.trivia_score + this.response.game_score;
        if(this.roundNum === brainGameDefaultConfig.number_of_rounds){this.leaderIndex = await this.leaderService.insert(this.response);}
        break;
      case 'leaderboard':
        this.leaderboardStart.next();
        this.saveData();
        this.inGameFlag = false;
        break;
      default: 
        throw new Error('Not recognized flag by me');
    }
  }
}
