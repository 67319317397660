import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  onPageLoaded = new EventEmitter<any>();
  onTimerExpired = new EventEmitter<any>();
  resetTimer = new EventEmitter<any>();
  resumeTimer = new EventEmitter<any>();
  pauseTimer = new EventEmitter<any>();
  restart = new EventEmitter<any>();

  onActivityFinished = new EventEmitter<any>();
  onQuestionsFinished = new EventEmitter<any>();
  onQuestionTimerExpired = new EventEmitter<any>();
  onActivityTimerExpired = new EventEmitter<any>();

  selectedPlayer = new EventEmitter<any>();
  onContinueGame = new EventEmitter<any>();
  configLoaded = new EventEmitter<any>();
  roundCompleted = new EventEmitter<any>();
  switchedToGame = new EventEmitter<number>();
  listItemClicked = new EventEmitter<any>();
  modalCloseClicked = new EventEmitter();

  scoreSubject = new BehaviorSubject<number>(0);
  
  constructor() { }
}
