import { StatNumbers } from "./statNums.model";

export class statNumTracker {

    Assessment= {
        triSquareSwitchCost: {...new StatNumbers()},
        triSquareAccuracy: {...new StatNumbers()},
        d2dTime: {...new StatNumbers()},
        d2dAccuracy: {...new StatNumbers()},
        squaresAccuracy: {...new StatNumbers()},
        symmAccuracy: {...new StatNumbers()},
    };

    // do I divide into different categories by game, YES
    BrainGame= 
    {
        triviaAcc : {...new StatNumbers()},
        triviaTime: {...new StatNumbers()},
        lemonScore : {...new StatNumbers()},
        largeSudokuScore: {...new StatNumbers()},
        slidingScore : {...new StatNumbers()},
        sudokuScore : {...new StatNumbers()},
        towerScore : {...new StatNumbers()},
        trailScore: {...new StatNumbers()},
        difficulty: {...new StatNumbers()},
        gameTime  : {...new StatNumbers()},
        sessTime  : {...new StatNumbers()},
        leaderboard  : {...new StatNumbers()},
        synapse_trivia: {...new StatNumbers()},
        number_of_rounds: {...new StatNumbers()},
        question_number: {...new StatNumbers()},
    };

    
    Clubhouse =
    {
        presentationsReviewed: {...new StatNumbers()},
    }

    HappyPlace = 
    {
        moodNum: {...new StatNumbers()},
    }

    Yahootie = 
    {
        exerciseTime: {...new StatNumbers()},
        questionTime: {...new StatNumbers()},
        Simple_MathTime: {...new StatNumbers()},
        Complex_MathTime: {...new StatNumbers()},
        PatternTime: {...new StatNumbers()},
        StroopTime: {...new StatNumbers()},
        FlankerTime: {...new StatNumbers()},
        questionAcc : {...new StatNumbers()},
        wrongYahootie : {...new StatNumbers()},
        missedYahootie : {...new StatNumbers()},
        correctYahootie : {...new StatNumbers()},
        skips : {...new StatNumbers()},
        questionNum : {...new StatNumbers()},
        sessTime: {...new StatNumbers()},
        score: {...new StatNumbers()},
    };

    General = 
    {
        homeScreenTime: {...new StatNumbers()},
        instructionTime: {...new StatNumbers()},
        appDetailScreenTime: {...new StatNumbers()},
        moodNum: {...new StatNumbers()},
        sleepTime: {...new StatNumbers()},
        bounces: {...new StatNumbers()},
    };


    // instantiated on creation
    userId: string;
    created_date: Date;
    modified_date: Date;
    id: string

    constructor(userId: string) {
        this.userId = userId;
        this.id = userId;
        this.newTracker();
    }

    private newTracker() {
        this.Assessment = 
        {
            triSquareSwitchCost: {...new StatNumbers()},
            triSquareAccuracy: {...new StatNumbers()},
            d2dTime: {...new StatNumbers()},
            d2dAccuracy: {...new StatNumbers()},
            squaresAccuracy: {...new StatNumbers()},
            symmAccuracy: {...new StatNumbers()},
        };
        this.BrainGame = 
        {
            triviaAcc: {...new StatNumbers()},
            triviaTime: {...new StatNumbers()},
            lemonScore : {...new StatNumbers()},
            slidingScore : {...new StatNumbers()},
            sudokuScore : {...new StatNumbers()},
            towerScore : {...new StatNumbers()},
            trailScore : {...new StatNumbers()},
            largeSudokuScore: {...new StatNumbers()},
            difficulty: {...new StatNumbers()},
            gameTime: {...new StatNumbers()},
            sessTime: {...new StatNumbers()},
            leaderboard: {...new StatNumbers()},
            synapse_trivia: {...new StatNumbers()},
            number_of_rounds: {...new StatNumbers()},
            question_number: {...new StatNumbers()},
        };
        this.Clubhouse =
        {
            presentationsReviewed: {...new StatNumbers()},
        }
        this.HappyPlace = 
        {
            moodNum: {...new StatNumbers()},
        }
        this.Yahootie = 
        {
            exerciseTime: {...new StatNumbers()},
            questionTime: {...new StatNumbers()},
            Simple_MathTime: {...new StatNumbers()},
            Complex_MathTime: {...new StatNumbers()},
            PatternTime: {...new StatNumbers()},
            StroopTime: {...new StatNumbers()},
            FlankerTime: {...new StatNumbers()},
            questionAcc : {...new StatNumbers()},
            wrongYahootie : {...new StatNumbers()},
            missedYahootie : {...new StatNumbers()},
            correctYahootie : {...new StatNumbers()},
            skips : {...new StatNumbers()},
            questionNum : {...new StatNumbers()},
            sessTime: {...new StatNumbers()},
            score: {...new StatNumbers()},
        };
        this.General = 
        {
            homeScreenTime: {...new StatNumbers()},
            instructionTime: {...new StatNumbers()},
            appDetailScreenTime: {...new StatNumbers()},
            moodNum: {...new StatNumbers()},
            sleepTime: {...new StatNumbers()},
            bounces: {...new StatNumbers()},
        };

    }
}