import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BrainGameControllerService } from '@services/brain-game-controller.service';
import { GameService } from '@services/game.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-bg-header',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
})
export class BGHeaderComponent implements OnInit {

  @Input() isYahootie?: boolean;
  
  score: string;
  pause: boolean = false;
  reset = this.bgController.resetTimer;
  round: number;
  position: string;

  @Output() timeExpire: EventEmitter<any> = new EventEmitter();
  
  constructor(
    private bgController: BrainGameControllerService,
    private gameService: GameService,
  ) { }

  ngOnInit() {
    this.isYahootie ? this.setYahootieHeader() : this.setBrainGameHeader();
  }
  
  /**
   * emit an event will be subscribed to,
   * to execute an action
   */
   timeUpEventhandler(): void {
    this.isYahootie ? this.timeExpire.emit() : this.bgController.timeUp.next();
  }

  setBrainGameHeader(): void {
    this.position = 'absolute';
    
    this.bgController.score.subscribe(score => {
      this.score = score.toFixed(2);
    });
    this.bgController.timerPaused.subscribe((pause) => {
      this.pause = pause;
      this.round = this.bgController.roundNum;
    });
  }

  setYahootieHeader(): void {
    this.position = 'relative';

    this.gameService.yahootieSubject.subscribe(yahootieSubject => {
      this.score = yahootieSubject.overall_score.toFixed(2);
      this.round = yahootieSubject.currentRound;
    });
  }
}
