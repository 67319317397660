<div class="brain-body">
  <!-- header -->
  <app-homeheader></app-homeheader>
  <!-- header /-->
  <div class="centered-cont">
    <ion-card>
      <app-bg-header
        *ngIf="showQuestion" 
        [isYahootie]=true 
        (timeExpire)="timeUpEventHandler()">
      </app-bg-header>
      
      <app-question
        *ngIf="showQuestion"
        [inpQuestions]="questions"
        data-cy='yahootieQuestions'>
      </app-question>
      
      <!-- shows the current exercise -->
      <h1 *ngIf="showExercise">Exercises</h1>
      <app-activity 
        *ngIf="showExercise" 
        [activityType]="activityType" 
        [content]="exercises"
        [difficulty]="gameConfig?.difficulty">
      </app-activity>

      <app-leveling-screen 
        *ngIf="leveling"
        [App]="appEnum.Yahootie"
        [Game]="appEnum.Yahootie"
        [score]="score"
        (extraClicked)="showLeaderBoard()"
        (doneClicked)="done()"
        (retryClicked)="retry()"></app-leveling-screen>
      
    </ion-card>
  </div>
  <app-footer></app-footer>
</div>