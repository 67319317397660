import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss']
})
export class BrandingComponent implements OnInit {
  @Input() isFooter = true;
  @Input() size = 11;
  @Input() appTitle ? = 'Brain Charge';

  constructor() { }

  ngOnInit() {
  }

}
