<ion-footer>
  <ion-toolbar color="tertiary">
    <ion-buttons
      slot="start"
      class="padding-10 full-width flex-space-between">

      <!-- questions -->
      <div
        class="content-center be-tab"
        [ngClass]="{'highlight': routeStatusArray.isQuestionsRoute}"
        *ngIf="user?.role === role.Admin">
        <ion-button class="tab-button" (click)="navigateRoute('braingame/admin/question')">
          <ion-icon slot="icon-only" name="chatboxes"></ion-icon>
        </ion-button>
        <div class="button-label">Questions</div>
      </div>
      <!-- questions /-->

      <!-- answers -->
      <div
        class="content-center be-tab"
        [ngClass]="{'highlight': routeStatusArray.isAnswersRoute}"
        *ngIf="user?.role === role.Admin">
        <ion-button class="tab-button" (click)="navigateRoute('braingame/admin/answer')">
          <ion-icon slot="icon-only" name="megaphone"></ion-icon>
        </ion-button>
        <div class="button-label">Answers</div>
      </div>
      <!-- answers /-->

       <!-- Account -->
       <div
        class="content-center be-tab"
        [ngClass]="{'highlight': routeStatusArray.isUsersRoute}"
        *ngIf="user?.role === role.Subscriber">
        <ion-button 
          class="tab-button" 
          (click)="navigateRoute('braingame/admin/subscriber-view/' + user.subscriber_id)">
          <ion-icon 
            slot="icon-only" 
            name="contact">
          </ion-icon>
        </ion-button>
        <div class="button-label">Account</div>
      </div>
      <!-- Account /-->

      <!-- events -->
      <div
        class="content-center be-tab"
        [ngClass]="{'highlight': routeStatusArray.isEventsRoute}">
        <ion-button class="tab-button" (click)="navigateRoute('braingame/admin/event')">
          <ion-icon slot="icon-only" name="map"></ion-icon>
        </ion-button>
        <div class="button-label">Events</div>
      </div>
      <!-- events /-->

      <!-- Config -->
      <div
        class="content-center be-tab"
        [ngClass]="{'highlight': routeStatusArray.isConfigRoute}">
        <ion-button class="tab-button" (click)="navigateRoute('braingame/admin/config')">
          <ion-icon slot="icon-only" name="settings"></ion-icon>
        </ion-button>
        <div class="button-label">Config</div>
      </div>
      <!-- Config /-->

      <!-- Users -->
      <div
        class="content-center be-tab"
        [ngClass]="{'highlight': routeStatusArray.isUsersRoute}">
        <ion-button class="tab-button" (click)="navigateRoute('braingame/admin/users')">
          <ion-icon slot="icon-only" name="people"></ion-icon>
        </ion-button>
        <div class="button-label">Users</div>
      </div>
      <!-- Users /-->

      <!-- Brain Game -->
      <div
        class="content-center be-tab"
        [ngClass]="{'highlight': routeStatusArray.isUsersRoute}"
        *ngIf="user?.role === role.Subscriber">
        <ion-button 
          class="tab-button" 
          (click)="navigateRoute(routePathEnum.Game)">
          <ion-icon 
            slot="icon-only" 
            name="logo-game-controller-b">
          </ion-icon>
        </ion-button>
        <div class="button-label">Brain Game</div>
      </div>
      <!-- Brain Game /-->
    </ion-buttons>
  </ion-toolbar>
</ion-footer>