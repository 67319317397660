<ion-grid>
  <ion-row>
    <ion-col>
      <ion-card>
        <ion-img src="{{source}}"></ion-img>
      
        <ion-card-content>
          <ion-title class="padding-left-0">{{name}}</ion-title>
          {{description}}
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
