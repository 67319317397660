import { Component, Input, OnInit } from '@angular/core';
import { Chart, ChartConfiguration, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
@Component({
  selector: 'app-assessment-score-chart',
  templateUrl: './assessment-score-chart.component.html',
  styleUrls: ['./assessment-score-chart.component.scss'],
})
export class AssessmentScoreChartComponent implements OnInit {

  @Input() assessments = [];

  CompChart2;

  constructor() { }

  ngOnInit() {
    Chart.register(...registerables)
    const chart= <HTMLCanvasElement> document.getElementById('myChart2');
    this.CompChart2 = new Chart(chart, this.mySecondChartOptions);
    this.assessments.forEach((data,index) => {
      if(!data.report){return;}
      const ave = data.report.overall;
      this.addData(this.CompChart2, data.display_date, ave.toFixed(1));
    })
  }

  addData(chart, label, data) {
    chart.data.labels.push(label);
    chart.data.datasets.forEach((dataset) => {
        dataset.data.push(data);
    });
    chart.update();
  }

  
  mySecondChartOptions = {
    plugins: [ChartDataLabels],
    type: 'line',
    data: {
      labels: [],
      datasets: [{ 
        label: 'Brain Charge Score',
        data: [],
        fill: false,
        borderColor: 'rgb(242, 140, 40)',
        backgroundColor: 'rgb(242, 140, 40)',
      }]
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          font: {
            size: 20,
            weight: 900
          },
          align: "top",
          color: "black"
        },
        legend: {
          display: false
        }
      },
      scales: {
        y: {
          max: 10,
          min: 0
        },
        x: {
          offset: true,
          display: false,
          grid: {
            lineWidth: 0
          }
        }
      }
    }
  }as ChartConfiguration;
}
