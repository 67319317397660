import { Component, OnInit, Input, AfterViewInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from '@interfaceuser.interface';
import { ModalController, NavController } from '@ionic/angular';
import { AssessmentService } from '@services/assessment.service';
import { AuthenticationService } from '@services/authentication.service';
import { DataTrackingService } from '@services/data-tracking.service';
import { HomeService } from '@services/home.service';
import { OverallSessionService } from '@services/overall-session.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-homeheader',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit, DoCheck {

  authUser: any = {} as IUser;
  cobranding: any;
  isCollapsed: boolean = true;
  
  pathname = '';
  secondaryDark: string;
  baseDivStyle: any;

  todaysActivities = [];
  paths = [
    {
      name: 'Home',
      url: 'landing',
      path: false,
      icon: 'home',
    },
    {
      name: 'Training',
      url: 'training',
      path: false,
      icon: 'barbell',
      divider: true
    },
    {
      name: 'Progress',
      url: 'progress',
      path: false,
      icon: 'bar-chart',
    },
    {
      name: 'Community',
      url: 'community',
      path: false,
      icon: 'people',
    },
    {
      name: 'User',
      url: 'profile',
      path: false,
      icon: 'person',
    }
  ];

  imageWidth = `calc(100% - 780px)`;

  showNavigation = true;

  constructor(
    private router: Router,
    private navController: NavController,
    private dataService: DataTrackingService,
    private homeService: HomeService,
    public modalController: ModalController,
    public overallSessionService: OverallSessionService,
    private assService: AssessmentService,
    private authService: AuthenticationService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    // this.configureStyles(this.cobranding.primary);
    this.configureStyles();
    await this.getAuthUser();
    this.determinePath();
    this.getApps();
    this.paths[this.paths.length - 1].name = this.authUser.name;

    this.showNavigation = this.router.url.indexOf('subscriber') === -1;
  }

  ngDoCheck(): void {
    // const tester = document.getElementsByClassName('path-container');
    // for(let i = 0; i < tester.length; i++) {
    //   let el = tester[i] as any;
    //   el = el.offsetWidth;
    //   if(el > 50) {
    //     this.imageWidth = `calc(100% - ${el + 1}px)`;
    //   }
    // }
  }

  /**
   * Gets the apps from the HomeService.
   */
  getApps(): void {
    const activities = this.homeService.getActivities();
    this.todaysActivities = activities.today;
  }

  /**
   * Gets the authenticated user from the HomeService.
   * @returns A promise that the authenticated user has been received.
   */
  async getAuthUser(): Promise<boolean> {
    return new Promise((res,rej) => {
      this.homeService.getAuthUser().then((data: any) => {
        this.authUser = data;
        res(true);
      }).catch(err => {
        res(false);
        // rej('no user');
        // throw new Error(err);
      })
    });
  }

  /**
   * Gets the cobranding styles from the HomeService.
   */
  configureStyles(): void {
    this.homeService.getCobranding().subscribe(cobrand => {
      if(cobrand){
        this.cobranding = cobrand;
      } else {
        this.cobranding = {
          primary: '#1d5357'
        }
      }
      
    });
  }

  // configureStyles(primary: string) {
  //   let hexval = this.convertToHexObject(primary);
  //   hexval = this.darkenRGB(hexval);
  //   this.secondaryDark = this.convertHexToString(hexval);
  //   // this.baseDivStyle = {color: primary, 'border-bottom': '6px solid' + primary};
  // }

  /**
   * Converts a hex color to string format.
   * @param hex The color in hex format.
   * @returns The hex color in a string format.
   */
  convertHexToString(hex: any): string {
    const keys = Object.keys(hex);
    let hexStr = '#';
    for (const key of keys) {
      hexStr = hexStr.concat(hex[key].toString(16));
    }
    return hexStr;
  }

  /**
   * Darkens the RBG values.
   * @param rgb The original RGB color.
   * @returns The darkened RBG color.
   */
  darkenRGB(rgb: any): any {
    const keys = Object.keys(rgb);
    for (const key of keys) {
      rgb[key] -= 32;
      if (rgb[key] < 0) {
        rgb[key] = 0;
      }
    }
    return rgb;
  }

  /**
   * Converts a color string to a RGB object.
   * @param colorStr The color in a string format.
   * @returns The color in an RGB format.
   */
  convertToHexObject(colorStr: string): {red: number, green: number, blue: number} {
    const red = parseInt(colorStr.substring(1, 3), 16);
    const green = parseInt(colorStr.substring(3, 5), 16);
    const blue = parseInt(colorStr.substring(5), 16);
    return {red, green, blue};
  }

  /**
   * Determines the router URL of the paths. 
   */
  determinePath(): void {
    this.paths[0].path = this.router.url.indexOf('landing') > -1;
    this.paths[1].path = this.router.url.indexOf('training') > -1;
    this.paths[2].path = this.router.url.indexOf('progress') > -1;
    this.paths[3].path = this.router.url.indexOf('community') > -1;
  }

  /**
   * Navigates to the specified path's page.
   * @param path The string of the page to navigate to.
   */
  async nav(path: any) {
    // home is current page
    if(this.paths[0].path) {
      this.dataService.endTimer('homeScreenTime', true);
    }
    
    if (!path.path) {
      if(path.url === 'login'){
        this.logout();
      } else {
        const avail = await this.assService.checkUserForAvailability();
        if(avail) {
          window.alert('You need to finish an Assessment to continue training');
          return;
        } else{
          if(this.userService.isUserAssessmentOnly()){
            window.alert('You are an assessment only user. contact ellen@brainevolved.com for more info');
            this.logout();
            this.navController.navigateForward('login');
            return;
          }
        }
      }
      
      this.navController.navigateForward(path.url);
    }
  }

  /**
   * Navigates to the landing page.
   */
  navHome(): void {
    this.navController.navigateForward('landing');
  }

  /**
   * Logs the user out of the application through the AuthenticationService.
   */
  logout(): void {
    this.authService.signOut();
  }
}
