<div class="custom-selector" *ngIf="parent" [formGroup]='parent'>
  <select 
  class="select-popover"
  name="date-select" 
  formControlName="{{ name }}"
  (change)="onItemSelected($event);flipArrowBack()" 
  (select)="flipArrowBack()"
  (focus)="flipArrow()" 
  (blur)="flipArrowBack()">
    <option *ngFor="let item of selectionOptions; let i = index" value="{{ item }}" [selected]="i === valueIndex">{{item}}</option>
  </select>
  <span class="custom-arrow" [ngStyle]="{'transform': transform }"></span>
</div>

<div class="custom-selector" *ngIf="!parent">
  <select 
  class="select-popover"
  name="date-select" 
  (change)="onItemSelected($event);flipArrowBack()" 
  (select)="flipArrowBack()"
  (focus)="flipArrow()" 
  (blur)="flipArrowBack()">
    <option *ngFor="let item of selectionOptions; let i = index" value="{{ item }}" [selected]="i === valueIndex">{{item}}</option>
  </select>
  <span class="custom-arrow" [ngStyle]="{'transform': transform }"></span>
</div>
