<div class="cont" style="overflow-y: scroll;">
  <app-close (click)="closeModal()"></app-close>
  <!-- sleep training -->
  <app-title text="Your Sleep"></app-title>
  <app-date-title text='{{ week.text }}'></app-date-title>
  <ion-grid>
    <ion-row>
      <ion-col>
          <ion-text class="avg">Average Sleep</ion-text>
          <div class="light"></div>
          <div class="circle-container">
            <ion-text class="num">{{ hours.toFixed(1) }}</ion-text>
            <ion-text class="hours-label">Hours</ion-text>
          </div>
      </ion-col>
      <ion-col size="7">
        <app-training-list improve="sleep" [trainingItems]=trainingItems (close)="closeSelf()"></app-training-list>
      </ion-col>
    </ion-row>
  </ion-grid>
  <!-- sleep training /-->
  <hr>
  <!-- sleep pattern -->
  <h2>Sleep Pattern</h2>
  <ion-grid class="sleep-pattern">
    <ion-row>
      <ion-col size='2'>
        <ion-row>
          <ion-col>
          <ion-text class="pattern-hour-label">≥12 hrs</ion-text>
        </ion-col>
        <ion-col>
          <div class="pattern-hour-line"></div>
        </ion-col>
        </ion-row>
        <ion-row class="bottom-hour">
          <ion-col>
          <ion-text class="pattern-hour-label">0 hrs</ion-text>
        </ion-col>
        <ion-col>
          <div class="pattern-hour-line"></div>
        </ion-col>
        </ion-row>
      </ion-col>
      <ion-col *ngFor='let sleepDay of sleepDays' class="center-col">
        <div *ngIf='sleepDay.val != undefined' class="pattern-rectangle">
          <div class="pattern-circle" [ngStyle]='{ "border": sleepDay.border, "margin-top": sleepDay.marginTop }'>
            <ion-text [ngClass]="{ 'greater-than-tweleve': sleepDay.val == '≥12' }" class="pattern-hours-number">{{ sleepDay.val }}</ion-text>
            <ion-text class="pattern-hours">Hours</ion-text>
          </div>
        </div>
        <ion-icon *ngIf='sleepDay.val == undefined' name="close" class="not-reported"></ion-icon>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size='2'></ion-col>
      <ion-col *ngFor='let sleepDay of sleepDays'>
        <ion-text class="date-label">{{ sleepDay.dayOfWeek }}</ion-text>
        <ion-text class="date-label">{{ sleepDay.date }}</ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
  <!-- sleep pattern /-->
  <!-- sleep pattern key -->
  <ion-grid>
    <ion-row class="key-row">
      <ion-col>
        <div class="key-icon optimal"></div>
        <ion-text>Optimal</ion-text>
      </ion-col>
      <ion-col>
        <div class="key-icon okay"></div>
        <ion-text>Okay</ion-text>
      </ion-col>
      <ion-col>
        <div class="key-icon non-optimal"></div>
        <ion-text>Non-optimal</ion-text>
      </ion-col>
      <ion-col>
        <ion-icon name="close" class="close-key"></ion-icon>
        <ion-text>Not reported</ion-text>
      </ion-col>
    </ion-row>
  </ion-grid> 
  <!-- sleep pattern key /-->
</div>
