<app-admin-header [title]="'Answer'"
[isModal]="true"></app-admin-header>

<ion-content>

  <!-- form -->
  <form [formGroup]="form" novalidate>
    <ion-grid class="flex-wrapper">

      <ion-row class="ion-justify-content-center">
        <ion-col size="11">

          <!-- main error message -->
          <app-error
            [errorMessage]="errorMessage"
            [isValidation]="false">
          </app-error>
          <!-- main error message /-->

          <!-- description -->
          <ion-item class="be-item">
            <ion-label class="admin" position="stacked">Details*</ion-label>

            <ion-input
              type="text"
              formControlName="details"
              class="admin be-input">
            </ion-input>

            <!-- error message -->
            <app-error
              [controlName]="form.get('details')"
              labelName="Details"
              [group]="form">
            </app-error>
            <!-- error message /-->
          </ion-item>
          <!-- description /-->

          <!-- category -->
          <ion-item class="be-item">
            <ion-label class="admin" position="stacked">Category*</ion-label>

            <app-select
              [parent]="form"
              [name]="'category'"
              [selectionOptions]="categoryArray"
              class="app-select">
            </app-select>

            <!-- error message -->
            <app-error
              [controlName]="form.get('category')"
              labelName="Category"
              [group]="form">
            </app-error>
            <!-- error message /-->
          </ion-item>
          <!-- category /-->

          <!-- sub category -->
          <ion-item class="be-item">
            <ion-label class="admin" position="stacked">Sub Category*</ion-label>

            <app-select
              [parent]="form"
              [name]="'sub_category'"
              [selectionOptions]="subCategoryArray"
              class="app-select">
            </app-select>

            <!-- error message -->
            <app-error
              [controlName]="form.get('sub_category')"
              labelName="Sub Category"
              [group]="form">
            </app-error>
            <!-- error message /-->
          </ion-item>
          <!-- sub category /-->

        </ion-col>

      </ion-row>
      <!-- description /-->

      <!-- button -->
      <ion-row class="ion-justify-content-center" class="flex-grow">
        <ion-col size="11">
          <app-button text="Save" (click)="save()"></app-button>
        </ion-col>
      </ion-row>
      <!-- button /-->
    </ion-grid>
  </form>
  <!-- form /-->

</ion-content>

