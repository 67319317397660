export class StatNumbers {
    mean: number;
    stdDev: number;
    n: number;

    constructor() {
        this.mean = 0;
        this.stdDev = 0;
        this.n = 0;
    }
}
