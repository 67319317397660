import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Utilities } from '@common/utilities';
import { HomeService } from '@services/home.service';
import { ModalController, NavController } from '@ionic/angular';
import { TrackerProcessingService } from '@services/tracker-processing.service';
import { ScoreFactorComponent } from '@app/home/components/modal/factor/score/score-factor.component';
import moment from 'moment';
import { Assessment } from '@models/assessment.model';
import { AssessmentService } from '@services/assessment.service';
import { NavigationEnum } from '@enums/NavigationEnum';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.page.html',
  styleUrls: ['./report.page.scss'],
})
export class ReportPage extends Utilities implements OnInit {

  transform;

  Assessments = this.assessmentService.assessments.assessments;
  // .concat([
  // // used for baseline display on date header
  //   {
  //     created_date: null,
  //     display_date: null,
  //     currently_displayed: true,
  //   },
  // ]);
  AssIndex = 0;

  showBaseline = true;
  showOverall = false;
  // week: any;

  baselineOverall = 0;
  baselinePlusOverall = 0;

  overallScore = 3.8;
  previousScore = 1.8;
  improvement = Math.floor((this.overallScore-this.previousScore)/this.previousScore*100);

  overallColor = '';
  previousColor : string;

  todayDate = moment().format('MM/DD/YY');

  overallCompliance: 33;


  scoreFactors = [
    {
      title: 'Speed', 
      score: 0,
      description: 'how quickly you can make use of information when it is presented to you',
      less_than: 'you may notice that you are more easily overwhelmed by tasks, and it takes you longer to complete complicated tasks',
      inbetween: 'you may notice that you are slightly less easily overwhelmed by tasks, and it takes you slightly less time to complete complicated tasks',
      greater_than: 'you may notice that you are less easily overwhelmed by tasks, and it takes you less time to complete complicated tasks',
      average: 'closer to your peer average (5)',
      ideal: 'closer to ideal',
      perfect: 'abilities',
      reason: 'tasks feel less overwhelming, and you will be able to complete complicated tasks more quickly',
      reason_perfect: 'continue to help make tasks feel less overwhelming, and you will be able to consistently complete complicated tasks more quickly',
      priority: 'balancing how quickly and accurately you complete Yahootie and Brain Game',
      category: 1,
      img: 'assets/images/report/runningicon.svg',
      below: 'you are more overwhelmed by tasks or can do tasks requiring brain resources more quickly',
      between: 'you are about as overwhelmed by tasks and can do tasks requiring brain resources in about the same amount of time',
      above: 'you are less overwhelmed by tasks or can do tasks requiring brain resources more quickly',
      help: 'you feel less overwhelmed by tasks and complete them more quickly',
      low: 'one of your lowest scored areas, so this will be prioritized for you in Brain Charge',
      middle: 'not one of your lowest scored areas, but there is still room for improvement, so you will still see items related to it in Brain Charge',
      high: 'scored at the maximum possible level, so you will only see a few items related to it in Brain Charge to help you maintain this high level of performance',
      startCompliance: 400,
    },
    {
      title: 'Wisdom', 
      score: 0,
      description: 'your knowledge of the world around you',
      less_than: 'you may notice that you cannot remember as much about the world',
      inbetween: 'you may notice that you remember slightly more about the world',
      greater_than: 'you may notice that you remember more about the world',
      average: 'closer to average',
      ideal: 'closer to ideal',
      perfect: 'abilities',
      reason: 'you remember more about the world',
      reason_perfect: 'continue to help you remember more about the world',
      priority: 'attending and reviewing Clubhouse presentations and focusing on the trivia component of Brain Game games',
      category: 2,
      img: 'assets/images/report/intelligenceicon.svg',
      below: 'you cannot remember as much about the world',
      between: 'you remember about the same amount about the world',
      above: 'you remember more about the world',
      help: 'you remember more about the world',
      low: 'one of your lowest scored areas, so this will be prioritized for you in Brain Charge',
      middle: 'not one of your lowest scored areas, but there is still room for improvement, so you will still see items related to it in Brain Charge',
      high: 'scored at the maximum possible level, so you will only see a few items related to it in Brain Charge to help you maintain this high level of performance',
      startCompliance: 200,
    },
    {
      title: 'Strategy', 
      score: 0,
      description: 'your ability to plan and identify and remember items for a short time to help you reach a goal',
      less_than: 'you may notice that you find it harder to complete tasks that involve strategy and multi-tasking, such as making dinner and answering a phone call at the same time',
      inbetween: 'you may notice that you are slightly better at completing tasks that involve strategy and multi-tasking, such as making dinner and answering a phone call at the same time',
      greater_than: 'you may notice that you are better at completing tasks that involve strategy and multi-tasking, such as making dinner and answering a phone call at the same time',
      average: 'closer to average',
      ideal: 'closer to ideal',
      perfect: 'abilities',
      reason: 'you plan and multi-task more easily and efficiently',
      reason_perfect: 'continue to help you plan and multi-task more easily and efficiently',
      priority: 'the non-trivia parts of Brain Game and the pattern recognition questions in Yahootie',
      category: 3,
      img: 'assets/images/report/strategyicon.svg',
      below: 'you find it harder to complete tasks that involve strategy and multi-tasking, such as making dinner and answering a phone call at the same time',
      between: 'you are about the same at completing tasks that involve strategy and multi-tasking, such as making dinner and answering a phone call at the same time',
      above: 'you are better at completing tasks that involve strategy and multi-tasking, such as making dinner and answering a phone call at the same time',
      help: 'you improve your ability to complete tasks that involve strategy and multi-tasking, such as making dinner and answering a phone call at the same time',
      low: 'one of your lowest scored areas, so this will be prioritized for you in Brain Charge',
      middle: 'not one of your lowest scored areas, but there is still room for improvement, so you will still see items related to it in Brain Charge',
      high: 'scored at the maximum possible level, so you will only see a few items related to it in Brain Charge to help you maintain this high level of performance',
      startCompliance: 100,
    },
    {
      title: 'Memory', 
      score: 0,
      description: 'how well you remember things you learned recently and long ago',
      less_than: 'you may notice that things do not seem to stay in your memory as well and you are not as good at recalling facts and stories',
      inbetween: 'you may notice that things seem to stay in your memory slightly better and you are slightly better at recalling facts and stories',
      greater_than: 'you may notice that thing seem to stay in your member better and you are better at recalling fats and stories',
      average: 'closer to average',
      ideal: 'closer to ideal',
      perfect: 'abilities',
      reason: 'things stay in your memory better and help you recalling facts and stories more quickly, completely, and accurately',
      reason_perfect: 'continue to help things stay in your memory better and recall facts and stories more quickly, completely, and accurately',
      priority: 'the Brain Game as a whole, the math-based questions in Yahootie, and using Happy Place more frequently',
      category: 4,
      img: 'assets/images/report/memoryicon.svg',
      below: 'things do not seem to stay in your memory as well and you are not as good at recalling facts and stories',
      between: 'things seem to stay in your memory about the same amount of time and you are about the same at recalling facts and stories',
      above: 'things seem to stay in your memory better and you are better at recalling facts and stories',
      help: 'items stay in your memory better and improve your ability to recall facts and stories',
      low: 'one of your lowest scored areas, so this will be prioritized for you in Brain Charge',
      middle: 'not one of your lowest scored areas, but there is still room for improvement, so you will still see items related to it in Brain Charge',
      high: 'scored at the maximum possible level, so you will only see a few items related to it in Brain Charge to help you maintain this high level of performance',
      startCompliance: 300,
    },
    {
      title: 'Focus', 
      score: 0,
      description: 'how well you can concentrate',
      less_than: 'you may notice that you are more easily distracted',
      inbetween: 'you may notice that you are slightly less easily distracted',
      greater_than: 'you may notice that you are better able to ignore distractions',
      average: 'closer to average',
      ideal: 'closer to ideal',
      perfect: 'abilities',
      reason: 'you ignore distractions',
      reason_perfect: 'continue to help you ignore distractions',
      priority: 'the non-trivia parts of Brain Game, the color and direction questions in Yahootie, and on learning the mindfulness techniques taught in Happy Place',
      category: 5,
      img: 'assets/images/report/focusicon.svg',
      below: 'you are more easily distracted',
      between: 'you are about as easily distracted',
      above: 'you are better able to ignore distractions',
      help: 'you improve your ability to ignore distractions',
      low: 'one of your lowest scored areas, so this will be prioritized for you in Brain Charge',
      middle: 'not one of your lowest scored areas, but there is still room for improvement, so you will still see items related to it in Brain Charge',
      high: 'scored at the maximum possible level, so you will only see a few items related to it in Brain Charge to help you maintain this high level of performance',
      startCompliance: 250,
    },
  ];

  overallData = [];

  trajectoryColor = '#ffb8a7';
  trajectoryBorder = '';
  trajectoryTitle = '';

  constructor(
    public navCtrl: NavController,
    public modalController: ModalController,
    private assessmentService: AssessmentService,
    private userService: UserService
  ) { super();}

  async ngOnInit() {
    this.subscribeToReportLoading();
    if(this.userService.isUserAssessmentOnly()){
      window.alert('Assessment only active. For details, email ellen@brainevolved.com.')
    }
    
    // this.sorts();
  }

  @ViewChild('contentToPrint', { static: false }) contentToPrint: ElementRef;

  printContent() {
    const printContents = this.contentToPrint.nativeElement.innerHTML;
    const originalContents = document.body.innerHTML;
    
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  sorts() {
    this.summarySort();
    this.imgSort();
    this.metricsSort();
    this.metricSortConfig();
    this.trajectoryConfig(this.improvement);
    
    this.overallColor = this.setColor(this.overallScore);
    this.previousColor = this.setColor(this.previousScore);
  }

  async subscribeToReportLoading() {
    await this.assessmentService.getAssessments()
    this.Assessments = this.assessmentService.assessments.assessments;
    this.Assessments = this.Assessments.filter(ass => {
      return ass.is_complete;
    })
    this.Assessments.map((ass,index) => {
      if(!ass.is_complete){ this.Assessments.splice(index, 1)}
      if(index == 0) {
        ass['display_date'] = "Baseline";
        ass['currently_displayed'] = false;
        return;
      }
      ass['display_date'] = moment(ass.created_date).format('MM/DD/YY');
      ass['currently_displayed'] = false;
    })

    const report = await this.assessmentService.calculateReport();
    report.forEach(repor => {
      this.overallData.push({val: repor.overall.toFixed(1), date: repor.date});
    })
    this.baselineOverall = report[0].overall;
    this.scoreFactors.map(async (scoreFactor) => {
      if(scoreFactor.score > 10) {scoreFactor.score = 10;}
      scoreFactor['todayColor']=this.setColor(scoreFactor['current']);
      scoreFactor['startColor']=this.setColor(scoreFactor['previous']);
      scoreFactor.score = report[0][scoreFactor.title.toLowerCase()].display;
      scoreFactor['current'] = report[0][scoreFactor.title.toLowerCase()].display;
      scoreFactor['previous'] = 0;
      scoreFactor['allData'] = [];
      const promises = report.map(repor => {
        if(!repor){return;}
        scoreFactor['allData'].push({...repor[scoreFactor.title.toLowerCase()], date: repor.date});
      });
      await Promise.all(promises);
      scoreFactor['allData'] = scoreFactor['allData'].slice();
      // this.tabClicked(report.length - 1);
    });
    this.tabClicked({target: {value: this.Assessments.length-1}});
    this.sorts();
  }

  trajectoryConfig(improvement: number) {
    // red
    if (improvement < -5) {
      this.trajectoryColor = '#ffb8a7';
      this.trajectoryBorder = '3px solid #d8674b'
      this.trajectoryTitle = 'Declined'
      // yellow
    } else if (improvement <= 5) {
      this.trajectoryColor = '#f3ecd4';
      this.trajectoryBorder = '3px solid #d9bd60'
      this.trajectoryTitle = 'Stayed the Same'
      // green
    } else {
      this.trajectoryColor = '#CDFFBB';
      this.trajectoryBorder = '3px solid #b2f059'
      this.trajectoryTitle = 'Improved'
    }
  }

  setColor(score:number) {
    if(score >= 4 && score <= 6) {
      return 'warning';
    } 
    else if (score <= 3) {
      return 'danger';
    }
    else if (score >= 6) {
      return 'success'
    }
  }

  

  showScoreFactor(scoreFactor: { title: string; score: number; }): void {
    this.presentModal(ScoreFactorComponent, 'factor-modal', {
      title: scoreFactor.title,
      // week: this.week.text,
      score: scoreFactor.score
    });
  }

  async tabClicked(event: {target: {value: number}}) {
    if(!event.target){throw new Error('event passed in is not in the correct format')}
    const flag = event.target.value;
    this.Assessments.map(ass => {
      ass['currently_displayed'] = false;
    })
    this.AssIndex = flag;
    const ass = this.Assessments[flag];
    if(ass['display_date'] === "Baseline") {
      this.showBaseline = true;
      this.showOverall = false;
    } else {
      this.previousScore = this.Assessments[flag-1].report.overall.toFixed(1);
      this.overallScore = ass.report.overall.toFixed(1);
      this.improvement = Math.floor((this.overallScore-this.previousScore)/this.previousScore*100);
      this.showBaseline = false;
      this.showOverall = true;
    }
    this.Assessments[flag]['currently_displayed'] = true;
    
    const promises = this.scoreFactors.map(scoreFactor => {
      scoreFactor.score       = ass.report[scoreFactor.title.toLowerCase()].display;
      scoreFactor['current']  = ass.report[scoreFactor.title.toLowerCase()].display;

      if(this.Assessments[flag-1]){
        scoreFactor['previous'] = this.Assessments[flag-1].report[scoreFactor.title.toLowerCase()].display;
        scoreFactor['improvement'] = Math.floor((scoreFactor['current'] - scoreFactor['previous']) / scoreFactor['previous'] * 100);
      }
      
    });
    await Promise.all(promises);
    this.sorts();
    if(ass['report']) {this.baselinePlusOverall = ass['report'].overall}
  }

  summarySorted = {
    ideal: [],
    onTarget: [],
    below: [],
  }
  summarySort() {
    this.summarySorted = {
      ideal: [],
      onTarget: [],
      below: [],
    }
    this.scoreFactors.forEach(scoreFactor => {
      if(scoreFactor.score >= 10) {
        this.summarySorted.ideal.push(scoreFactor.title);
      } else if (scoreFactor.score < 5) {
        this.summarySorted.below.push(scoreFactor.title);
      } else {
        this.summarySorted.onTarget.push(scoreFactor.title);
      }
    }) 
  }

  imgSorted = {
    below: [],
    biggestImprovement: this.scoreFactors[0]
  }
  imgSort() {
    this.imgSorted = {
      below: [],
      biggestImprovement: this.scoreFactors[0]
    }
    let biggestDiff = 0;
    this.scoreFactors.forEach(scoreFactor => {
      if (scoreFactor.score < 5) {
        this.imgSorted.below.push(scoreFactor);
      }
      const tempDiff = scoreFactor['improvement']
      if (tempDiff > biggestDiff) {
        biggestDiff = tempDiff
        this.imgSorted.biggestImprovement=scoreFactor
      }
    }) 
  }

  metricSorted = {
    ideal: [],
    middle: [],
    below: [],
  }

  metricText = ''

  metricsSort() {
    this.metricSorted = {
      ideal: [],
      middle: [],
      below: [],
    }
    this.scoreFactors.forEach(scoreFactor => {
      if(scoreFactor.score < 5) {
        this.metricSorted.below.push(scoreFactor.title);
      } else if (scoreFactor.score >= 5 && scoreFactor.score < 8) {
        this.metricSorted.middle.push(scoreFactor.title);
      } else if (scoreFactor.score > 8) {
        this.metricSorted.ideal.push(scoreFactor.title);
      }
    }) 
  }

  metricSortConfig() {
    if (this.metricSorted.below.length > 0) {
      this.metricText = 'Individual areas are still at a level where you are likely to notice significant daily challenges.'
    } else if (this.metricSorted.middle.length > 0) {
      this.metricText = 'Individual areas are still at a level where you are likely to notice daily challenges compared to when you were younger.'
    } else if (this.metricSorted.below.length === 0 && this.metricSorted.middle.length === 0 && this.metricSorted.ideal.length > 0) {
      this.metricText = 'You likely do not notice daily challenges in individual areas.'
    }
  }

  startTraining() {
    this.navigateToPath('landing', NavigationEnum.Forward);
  }

   /**
   * Flips the arrow to point down
   */
   flipArrow(): void {
    this.transform = 'translate(-50%,-50%) rotate(-180deg)';
  }

  /**
   * Flips the arrow to point back up
   */
  flipArrowBack(): void {
    this.transform = 'translate(-50%,-50%) rotate(0deg)';
  }

}
