import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OverallSession } from '@models/overall_session.model';
import { AppEnum } from '@enums/AppEnum';
import { NavController, Platform } from '@ionic/angular';
import { DataTrackingService } from '@services/data-tracking.service';
import { OverallSessionService } from '@services/overall-session.service';
import moment from 'moment';
import * as _ from 'underscore';
import { HomeService } from '@services/home.service';
import { Utilities } from '@common/utilities';

@Component({
  selector: 'app-training-list',
  templateUrl: './training-list.component.html',
  styleUrls: ['./training-list.component.scss'],
})
export class TrainingListComponent extends Utilities implements OnInit {

  @Input() improve: string;
  @Input() trainingItems: { app: AppEnum; guide: string; }[];
  @Input() assessmentsOn: false;

  @Output() close = new EventEmitter();
  
  overallSession = {} as OverallSession;
  compliance = false;
  deviceType: any;
  authUser: any;

  constructor(
    public navCtrl: NavController,
    private homeService: HomeService,
    private dataService: DataTrackingService,
    private overallSessionService: OverallSessionService,
    private platform: Platform,
    private router: Router
  ) { super(); }

  ngOnInit() {
    this.deviceType = this.platform.platforms();
    this.getAuthUser();
  }

  getAuthUser(): void {
    this.homeService.getAuthUser().then((data: any) => {
      this.authUser = data;
    }).catch(err => {
      throw new Error(err);
    })
  }

  goToApp(appName: AppEnum): void {
    if (!this.authUser) {
      return;
    }

    this.close.emit();

    this.dataService.createAppObject(appName);
    // this.saveOverallSession(appName);
    switch (appName) {
      case AppEnum.HappyPlace: {
        this.router.navigate(['app-details/happyplace'], {state: { data: {
          name: 'HappyPlace',
          compliance: this.compliance,
        }}});
        break;
      }
      case AppEnum.Clubhouse: {
        this.router.navigate(['app-details/clubhouse'], {state: { data: {
          name: 'Clubhouse',
          compliance: this.compliance,
        }}});
        break;
      }
      case AppEnum.Yahootie: {
        this.router.navigate(['app-details/yahootie'], {state: { data: {
          name: 'Yahootie',
          compliance: this.compliance,
        }}});
        break;
      }
      case AppEnum.Assessment: {
        this.navCtrl.navigateForward('assessment/user-setup');
        break;
      }
      case AppEnum.BrainGame: {
        this.router.navigate(['app-details/braingame'], {state: { data: {
          name: 'Brain Game',
          compliance: this.compliance,
        }}});
        break;
      }
      case AppEnum.Settings: {
        this.navCtrl.navigateForward('settings');
        break;
      }
    }
  }
    
  saveOverallSession(appName): void {
    this.overallSession.user_id = this.authUser.id;
    this.overallSession.subscriber_id = this.authUser.subscriber_id;
    this.overallSession.app_id = appName;
    this.overallSession.start_date = moment().toDate();
    this.overallSession.end_date = null;
    if (this.deviceType[0]) {
      if (_.contains(this.deviceType, 'tablet')) {
        this.overallSession.device_type = 'tablet';
      } else if (_.contains(this.deviceType, 'mobile')) {
        this.overallSession.device_type = 'mobile';
      } else if (_.contains(this.deviceType, 'desktop')) {
        this.overallSession.device_type = 'desktop';
      } else {
        this.overallSession.device_type = 'none';
      }
    } else {
      this.overallSession.device_type = 'none';
    }
  }
}
