<div class="training-cont">
  <!-- actual colored box we see -->
    <!-- Training title + images -->
    <div class='inner-cont'>  
      <div class='today-text-cont'>
        <ion-text class='today-text'>{{trainingText}}</ion-text>
      </div>
    </div>
    <app-button fontSize="6rem" margin="10px" padding="60px 30px" text="Begin Training" (click)="navCtrl.navigateForward('apps-menu')"></app-button>
</div>
