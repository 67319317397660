<div class="cont" data-cy="leaderboard">
  <app-close (click)="closeModal()"></app-close>
  <h1>Results</h1>
  <div class="container" style="height: 60%">
    <!-- titles -->
    <div class="row" style="border-bottom: solid 1px blue">
      <div class="col-sm-3 col-border">

      </div>
      <div class="col-sm-3 col-border">
        {{ firstScore.name }} Score
      </div>
      <div class="col-sm-3 col-border">
        {{ secondScore.name }} Score
      </div>
      <div class="col-sm-3 col-border font-weight-bold">
        Total
      </div>
    </div>

    <!-- this round -->
    <div class="row">
      <div class="col-sm-3 col-border">
        This Round
      </div>
      <div class="col-sm-3 col-border">
        {{ firstScore.value }}
      </div>
      <div class="col-sm-3 col-border">
        {{ secondScore.value }}
      </div>
      <div class="col-sm-3 col-border font-weight-bold">
        {{ roundScore }}
      </div>
    </div>

    <!-- prior rounds -->
    <div class="row" *ngIf="roundNum != 1">
      <div class="col-sm-3 col-border">
        Prior Rounds
      </div>
      <div class="col-sm-3 col-border">
        {{ prior.trivia }}
      </div>
      <div class="col-sm-3 col-border">
        {{ prior.game }}
      </div>
      <div class="col-sm-3 col-border font-weight-bold">
        {{ prior.total }}
      </div>
    </div>
  </div>

  <!-- Current Score -->
  <div class="container pt-0" style="text-align: right;">
    <div class="current-score-cont">
      <span class="mr-3">
        Current Score:
      </span>
      <span class="mr-3 ml-3">
        {{ overallScore }}
      </span>
    </div>
    
  </div>
  <app-button data-cy='ContinueSummary' text="Continue" (click)="closeModal()"></app-button>
</div>
