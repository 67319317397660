<div class="cont">
  <app-close (click)="closeModal()"></app-close>
  <!-- compliance -->
  <app-title text="Compliance">Compliance</app-title>
  <app-date-title text={{week.text}}></app-date-title>
  <ion-grid>
    <ion-row>
      <ion-col>
        <app-compliance [score]="overallScore" [weeksBack]="weeksBack"></app-compliance>
      </ion-col>
      <ion-col>
        <div>
          <ion-text class="focus-text">
            Compliance is the following:
          </ion-text>
          <div>
            <div>
              <ion-text class="app-focus">
                An analysis of how closely you have been sticking to your workout.
              </ion-text>
            </div>
            <div>
              <ion-text class="app-focus">
                This measure is starts every sunday and keeps track of where you should be day by day.
              </ion-text>
            </div>
            <div>
              <ion-text class="app-focus">
                The goal is to maintain a 100% score.
              </ion-text>
            </div>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <!-- compliance /-->
  <hr>
  <!-- compliance week -->
  <ion-text class="compliance">Compliance: <ion-text class="percent">{{compliancePercentage}}%</ion-text></ion-text>
  <ion-grid class="compliance-grid">
    <ion-row>
      <ion-col *ngFor='let app of compliances; let i = index' class="compliance-col">
        <ion-icon *ngIf='app.compliancePercentage === 0' name="close" class="not-reported"></ion-icon>
        <div *ngIf='app.compliancePercentage > 0' class="rectangle">
          <div [ngClass]="{ goal: app.compliancePercentage == 100 }" class="goal-rectangle goal-not-met" [style.height]="getCompHeight(app)"></div>
        </div>
        <div *ngIf='i < compliances.length - 1' class="divider"></div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col *ngFor='let app of compliances; let i = index'>
        <ion-text class="name-label">{{app.name}}</ion-text>
        <div *ngIf='i < compliances.length - 1' class="divider d-divider"></div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <!-- compliance week /-->
  <!-- compliance key -->
  <ion-grid>
    <ion-row class="key-row">
      <ion-col>
        <div class="key-icon goal"></div>
        <ion-text>Goal reached</ion-text>
      </ion-col>
      <ion-col>
        <div class="key-icon goal-not-met"></div>
        <ion-text>Goal not met</ion-text>
      </ion-col>
      <ion-col>
        <ion-icon name="close" class="close-key"></ion-icon>
        <ion-text>Not participated</ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
  <!-- compliance key /-->
</div>
