import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { CountdownModule } from 'ngx-countdown';
import { HeaderComponent } from './components/headers/main/header.component';
import { BrandingComponent } from './components/branding/branding.component';
import { ErrorComponent } from './components/error/error.component';
import { FormActionsComponent } from './components/form-actions/form-actions.component';
import { SelectComponent } from './components/select/select.component';
import { DeleteComponent } from './components/buttons/delete/delete.component';
import { GlobalHeaderComponent } from './components/headers/global-header/global-header.component';
import { InstructionComponent } from './components/instruction/instruction.component';
import { GetReadyComponent } from './components/get-ready/get-ready.component';
import { GameTimerComponent } from './components/game-timer/game-timer.component';
import { BrainGameLeaderboardComponent } from './components/modal/brain-game/leaderboard/brain-game-leaderboard.component';
import { BrainGameResultsComponent } from './components/modal/brain-game/results/brain-game-results.component';
import { ActivityComponent } from './components/activity/activity.component';
import { AddComponent } from './components/buttons/add/add.component';
import { MeetingComponent } from './components/headers/meeting/meeting.component';
import { SwiperAdminComponent } from './components/swipers/admin/admin.component';
import { VideoComponent } from './components/video/video.component';
import { EditComponent } from './components/buttons/edit/edit.component';
import { AdminTabComponent } from './components/admin-tab/admin-tab.component';
import { GoogleAnalyticsComponent } from './components/google-analytics/google-analytics.component';
import { TimerComponent } from './components/timer/timer.component';
import { UsersComponent } from './components/modal/users/users.component';
import { AnswerFormComponent } from './components/modal/answer/form/answer-form.component';
import { AdminHeaderComponent } from './components/headers/admin/admin-header.component';
import { TriviaHeaderComponent } from './components/headers/brain-game/trivia/trivia-header.component';
import { MainHeaderComponent } from './components/headers/brain-game/main-header/main-header.component';
import { InstructionsHeaderComponent } from './components/headers/brain-game/instructions-header/instructions-header.component';
import { AnswerListComponent } from './components/modal/answer/list/answer-list.component';
import { HomepageComponent } from './components/headers/homepage/homepage.component';
import { MoodsleepComponent } from './components/modal/moodsleep/moodsleep.component';
import { ImageComponent } from './components/image/image.component';
import { FooterComponent } from './components/footer/footer.component';
import { CloseComponent } from './components/close/close.component';
import { LegalComponent } from './components/modal/legal/legal.component';
import { ButtonComponent } from './components/buttons/main/button/button.component';
import { GreyScreenComponent } from './components/grey-screen/grey-screen.component';
import { ReactiveBoxComponent } from './components/reactive-box/reactive-box.component';
import { BackComponent } from './components/back/back.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { VoiceSelectionFlyoutComponent } from '@app/happy-place/pages/voice-selection-flyout/voice-selection-flyout.component';
import { ButtonCollectionComponent } from './components/buttons/collection/collection.component';
import { LevelingScreenComponent } from './components/leveling-screen/leveling-screen.component';
import { LevelDisplayComponent } from './components/leveling-screen/level-display/level-display.component';
import { LevelingBarComponent } from './components/leveling-screen/leveling-bar/leveling-bar.component';
import { ScoreDisplayComponent } from './components/leveling-screen/score-display/score-display.component';
import { SummaryDisplayComponent } from './components/leveling-screen/summary-display/summary-display.component';
import { GameScoreModalComponent } from './components/leveling-screen/helpers/game-score/game-score.component';
import { XpEarnedModalComponent } from './components/leveling-screen/helpers/xp-earned/xp-earned.component';
import { LeaderboardComponent } from './components/leaderboard/leaderboard.component';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    CountdownModule,
    NgbCollapseModule
  ],
  declarations: [
    HeaderComponent,
    BrandingComponent,
    ErrorComponent,
    FormActionsComponent,
    SelectComponent,
    DeleteComponent,
    GlobalHeaderComponent,
    InstructionComponent,
    GetReadyComponent,
    GameTimerComponent,
    ActivityComponent,
    BrainGameLeaderboardComponent,
    BrainGameResultsComponent,
    AddComponent,
    MeetingComponent,
    SwiperAdminComponent,
    VideoComponent,
    AdminTabComponent,
    EditComponent,
    GoogleAnalyticsComponent,
    TimerComponent,
    UsersComponent,
    AnswerFormComponent,
    AdminHeaderComponent,
    TriviaHeaderComponent,
    MainHeaderComponent,
    InstructionsHeaderComponent,
    AnswerListComponent,
    HomepageComponent,
    MoodsleepComponent,
    ImageComponent,
    FooterComponent,
    CloseComponent,
    LegalComponent,
    ButtonComponent,
    GreyScreenComponent,
    ReactiveBoxComponent,
    BackComponent,
    VoiceSelectionFlyoutComponent,
    ButtonCollectionComponent,
    LevelingScreenComponent,
    LevelDisplayComponent,
    LevelingBarComponent,
    ScoreDisplayComponent,
    SummaryDisplayComponent,
    GameScoreModalComponent,
    XpEarnedModalComponent,
    LeaderboardComponent
  ],
  exports: [
    HeaderComponent,
    HomepageComponent,
    BrandingComponent,
    ErrorComponent,
    FormActionsComponent,
    SelectComponent,
    DeleteComponent,
    GlobalHeaderComponent,
    InstructionComponent,
    GetReadyComponent,
    GameTimerComponent,
    ActivityComponent,
    LeaderboardComponent,
    BrainGameLeaderboardComponent,
    BrainGameResultsComponent,
    AddComponent,
    MeetingComponent,
    SwiperAdminComponent,
    VideoComponent,
    AdminTabComponent,
    EditComponent,
    GoogleAnalyticsComponent,
    TimerComponent,
    UsersComponent,
    AnswerFormComponent,
    AdminHeaderComponent,
    TriviaHeaderComponent,
    MainHeaderComponent,
    InstructionsHeaderComponent,
    AnswerListComponent,
    MoodsleepComponent,
    ImageComponent,
    FooterComponent,
    CloseComponent,
    LegalComponent,
    ButtonComponent,
    GreyScreenComponent,
    ReactiveBoxComponent,
    BackComponent,
    VoiceSelectionFlyoutComponent,
    ButtonCollectionComponent,
    LevelingScreenComponent,
    LevelDisplayComponent,
    LevelingBarComponent,
    ScoreDisplayComponent,
    SummaryDisplayComponent,
    LeaderboardComponent
  ]
})
export class AppCommonModule {}
