import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { EventBusService } from '@services/event-bus.service';
import { AuthenticationService } from '@services/authentication.service';

import { RoleEnum } from '@enums/RoleEnum';

import { IUser } from '@interface/user.interface';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() isModal: boolean;
  @Input() cssClass = 'primary';

  isForm: boolean;
  showSearch: boolean;
  hasMenu: boolean;
  user: IUser;
  defaultUrl: string;
  subscription = new Subscription();

  constructor(
    public router: Router,
    private eventBusService: EventBusService,
    private authService: AuthenticationService,
  ) { }

  ngOnInit() {
    const userSub = this.authService.userSubject.subscribe((user: IUser) => {
      if (user) {
        this.user = user;
        
        this.hasMenu = this.router.url.indexOf('question') > -1 ||
          (this.user.role === RoleEnum.Subscriber &&
            this.router.url.indexOf('event') > -1);
        
        if (this.user.role === RoleEnum.Subscriber) {
          this.defaultUrl = '/admin/event';
        } else {
          this.defaultUrl = '/admin/question';
        }
      }
    });
    this.subscription.add(userSub);

    this.isForm = this.router.url.indexOf('form') > - 1;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  
  /**
   * Sets the show search to true.
   */
  onSearch(): void {
    this.showSearch = true;
  }

  /**
   * Emits the the modal has been closed.
   */
  onCloseModal(): void {
    this.eventBusService.modalCloseClicked.emit();
  }

}
