import { Injectable } from '@angular/core';
import { Quiz } from '@models/quiz.model';
import { DB_CONFIG } from '../app.firebase.config';
import { map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuizService {
  private quizCollection: AngularFirestoreCollection<Quiz>;

  constructor(private afs: AngularFirestore) { }

  /**
   * Gets the specified quiz based on the game id and type.
   * @param gameId The game id.
   * @param type The type of quiz (practice, instruction, or quiz).
   * @param qlimit The question limit.
   * @returns The quiz.
   */
  getQuizByType(gameId: string, type: string, qlimit: number): Observable<Quiz[]> {
    let pract = false;
    let ins   = false;
    let quiz  = false;

    if (type === 'practice') {
      pract = true;
    }
    if (type === 'instruction') {
      ins = true;
    }
    if (type === 'quiz') {
      quiz = true;
    }

    // checks to see if it is game three
    if (gameId === 'GvUFkD4qWS3JFVorWtYb') {
      // if it is an instruction or a practice, we need to get them in a specific order from the firebase
      if (ins === true || pract === true) {
        this.quizCollection = this.afs.collection(DB_CONFIG.quiz_endpoint,
          ref => ref.where('game_id',     '==', gameId)
                    .where('quiz',        '==', quiz)
                    .orderBy('order')
        );
      // else, it is a quiz and we can get them in a random order
      } else {
        this.quizCollection = this.afs.collection(DB_CONFIG.quiz_endpoint,
          ref => ref.where('game_id',     '==', gameId)
                    .where('practice',    '==', pract)
                    .where('instruction', '==', ins)
                    .where('quiz',        '==', quiz)
                    .limit(qlimit)
        );
      }
    } else {
    // if it is not quiz three then we can get them in a random order as well
      this.quizCollection = this.afs.collection(DB_CONFIG.quiz_endpoint,
        ref => ref.where('game_id',     '==', gameId)
                  .where('practice',    '==', pract)
                  .where('instruction', '==', ins)
                  .where('quiz',        '==', quiz)
                  .limit(qlimit)
      );
    }

    return this.quizCollection.snapshotChanges().pipe(
      map((actions:any) => {
        return actions.map(a => {
          // Get document data
          const data = a.payload.doc.data() as Quiz;

          // Get document id
          const id = a.payload.doc.id;

          // Use spread operator to add the id to the document data
          return { id, ...data };
        });
      })
    );
  }

}
