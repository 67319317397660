<div class="level-display">
  
  <div class="level-image-container" id="level-text">
    <img class="level-image" *ngIf="isEmptyObject(avatar)" src="../../../../../assets/images/logo.png" alt="Level background" />
    <img class="level-image" *ngIf="!isEmptyObject(avatar)" [src]="'../../../../../assets/images/' + avatar" alt="Level w/avatar background" />
    <!-- <div class="level-text">
      <span class="level-label">Level</span>
      <span class="level-number">{{level}}</span>
    </div> -->
    <!-- <div>
      <span class="user-title-text">{{title}}</span>
    </div> -->
  </div>

  <div *ngIf="!noText">
    <div>
      <h2 *ngIf="!leveled" class="level-heading">Great Work!</h2>
      <h2 *ngIf="leveled" class="level-heading">Leveled Up!</h2>
      <p>{{subtext}}</p>
    </div>
  </div>


</div>

<!-- <button (click)="levelUp()">Level ++</button> -->

