import { Component, Input, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';
import { AppEnum } from '@enums/AppEnum';

@Component({
  selector: 'app-score-factor',
  templateUrl: './score-factor.component.html',
  styleUrls: ['./score-factor.component.scss'],
})
export class ScoreFactorComponent extends Utilities implements OnInit {

  @Input() title: string = 'Speed';
  @Input() week: string;
  @Input() score: number;

  currentTrainingOption: { type: string; trainingItems: { app: AppEnum; guide: string; }[]; left: string; };
  assessmentOn: boolean = true;

  trainingOptions = [
    {
      type: 'Speed',
      trainingItems: [
        {
          app: AppEnum.BrainGame,
          guide: 'Concentrate on scoring as many points as possible on the parts of the games that do not involve trivia (e.g., the number of points you get from correctly solving puzzles) and on answering trivia questions as quickly as possible'
        },
        {
          app: AppEnum.Yahootie,
          guide: 'Focus on correctly answering the mental questions as quickly as possible'
        }
      ],
      left: '250px'
    },
    {
      type: 'Intelligence',
      trainingItems: [
        {
          app: AppEnum.BrainGame,
          guide: 'Concentrate on answering all trivia questions correctly'
        },
        {
          app: AppEnum.Clubhouse,
          guide: 'Review the presentations from meetings that you have already attended. If you have not attended a Clubhouse meeting, attend one.'
        }
      ],
      left: '200px'
    },
    {
      type: 'Problem Solving',
      trainingItems: [
        {
          app: AppEnum.BrainGame,
          guide: 'Concentrate on scoring as many points as possible on the parts of the games that do not involve trivia (e.g., the number of points you get from correctly solving puzzles)'
        },
        {
          app: AppEnum.Yahootie,
          guide: 'Focus on correctly answering the mental questions as quickly as possible'
        }
      ],
      left: '155px'
    },
    {
      type: 'Memory',
      trainingItems: [
        {
          app: AppEnum.BrainGame,
          guide: 'Concentrate on scoring as many points as possible on the parts of the games that do not involve trivia (e.g., the number of points you get from correctly solving puzzles)'
        },
        {
          app: AppEnum.Yahootie,
          guide: 'Focus on correctly answering the mental questions as quickly as possible and on identifying as many “yahooties” as possible'
        }
      ],
      left: '230px'
    },
    {
      type: 'Focus',
      trainingItems: [
        {
          app: AppEnum.BrainGame,
          guide: ' Concentrate on scoring as many points as possible on the parts of the games that do not involve trivia (e.g., the number of points you get from correctly solving puzzles)'
        },
        {
          app: AppEnum.Yahootie,
          guide: 'Focus on correctly answering the mental questions as quickly as possible'
        }
      ],
      left: '250px'
    }
  ];

  constructor() {
    super();
  }

  ngOnInit() {
    this.setCurrentTrainingOption();
  }

  /**
   * Sets the training items and the left of the brain image.
   */
  setCurrentTrainingOption(): void {
    this.currentTrainingOption = this.trainingOptions.find(item => this.title === item.type);
  }

  /**
   * Closes the modal.
   */
  closeSelf(): void {
    this.closeModal();
  }
}
