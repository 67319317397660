import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AppCommonModule } from '@common/app-common.module';
import { ComplianceComponent } from './charts/compliance/compliance.component';
import { BrainChargeMeterComponent } from './brain-charge-meter/brain-charge-meter.component';
import { DividerComponent } from './divider/divider.component';
import { TitleComponent } from './titles/title/title.component';
import { DateTitleComponent } from './titles/date-title/date-title.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { TileComponent } from './tile/tile.component';
import { ComplianceFlyoutComponent } from './modal/flyout/compliance/compliance-flyout.component';
import { SleepFlyoutComponent } from './modal/flyout/sleep/sleep-flyout.component';
import { MoodFactorComponent } from './modal/factor/mood/mood-factor.component';
import { ScoreFactorComponent } from './modal/factor/score/score-factor.component';
import { MoodAverageComponent } from './average/mood/mood-average.component';
import { SleepAverageComponent } from './average/sleep/sleep-average.component';
import { TrainingListComponent } from './training-list/training-list.component';
import { OutsideTrainingComponent } from './outside-training/outside-training.component';
import { AddOutsideTrainingComponent } from './modal/outside-training/add/add-outside-training.component';
import { RemoveOutsideTrainingComponent } from './modal/outside-training/remove/remove-outside-training.component';
import { OutsideTrainingFlyoutComponent } from './modal/flyout/outside-training/outside-training-flyout.component';
import { PercentageCircleComponent } from './percentage-circle/percentage-circle.component';
import { TrainingsComponent } from './trainings/trainings.component';
import { SkipTrainingComponent } from './modal/skip-training/skip-training.component';
import { DidyouknowComponent } from './didyouknow/didyouknow.component';

@NgModule({
  declarations: [
    ComplianceComponent,
    BrainChargeMeterComponent,
    DividerComponent,
    TitleComponent,
    DateTitleComponent,
    DateRangeComponent,
    TileComponent,
    ComplianceFlyoutComponent,
    SleepFlyoutComponent,
    MoodFactorComponent,
    ScoreFactorComponent,
    MoodAverageComponent,
    SleepAverageComponent,
    TrainingListComponent,
    OutsideTrainingComponent,
    AddOutsideTrainingComponent,
    RemoveOutsideTrainingComponent,
    OutsideTrainingFlyoutComponent,
    PercentageCircleComponent,
    TrainingsComponent,
    SkipTrainingComponent,
    DidyouknowComponent
  ],
  exports: [
    ComplianceComponent,
    BrainChargeMeterComponent,
    DividerComponent,
    TitleComponent,
    DateTitleComponent,
    DateRangeComponent,
    TileComponent,
    ComplianceFlyoutComponent,
    SleepFlyoutComponent,
    MoodFactorComponent,
    ScoreFactorComponent,
    MoodAverageComponent,
    SleepAverageComponent,
    TrainingListComponent,
    OutsideTrainingComponent,
    AddOutsideTrainingComponent,
    RemoveOutsideTrainingComponent,
    OutsideTrainingFlyoutComponent,
    PercentageCircleComponent,
    TrainingsComponent,
    SkipTrainingComponent,
    DidyouknowComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    AppCommonModule
  ]
})
export class HomeComponentsModule { }
