<!-- trivia header -->
<div class="background-primary-green game-header">
  <ion-grid class="full-height">
    <ion-row class="full-height flex-vertical-center">
      <!-- branding -->
      <ion-col size="3" class="ion-text-center">
        <div class="brain-game-logo small"></div>        
      </ion-col>
      <!-- branding /-->

      <!-- timer & score -->
      <ion-col size="6" class="content content-center" *ngIf="showTimer">
        <div class="game-status">
          <span class="timer block">
            <app-timer
              configId="configId"
              (timeUp)="timeUpEventhandler($event)"
              [useConfig] = "useConfig"
              [originalTime] = "originalTime">
            </app-timer>
          </span>
          <span class="score"> SCORE: <span class='score' id='score'>{{ score }}</span> </span>
        </div>
      </ion-col>
      <!-- timer & score /-->

      <!-- title -->
      <ion-col
        size="{{ showTimer ? 3 : 6 }}"
        class="flex content-center">
        <div class="text-center">
          <div class="font-tertiary">Round {{ roundNumber }}</div>
        </div>
      </ion-col>
      <!-- title /-->

      <!-- score -->
      <ion-col
        size="3"
        class="content-center ion-text-right"
        *ngIf="!showTimer">
        <div class="title full-width">
          <div>SCORE</div>
          <div>{{ score }}</div>
        </div>
      </ion-col>
      <!-- score /-->

    </ion-row>
  </ion-grid>
</div>
<!-- trivia header /-->