import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TriviaPage } from './trivia/component/component.component';
import { AppCommonModule } from '@common/app-common.module';
import { AnswersComponent } from './components/answers/answers.component';
import { BGHeaderComponent } from './components/header/game/game.component';
import { BGInstructionsComponent } from './components/instructions/instructions.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { EndScreenComponent } from './components/end-screen/end-screen.component';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    AppCommonModule,
    SwiperModule
  ],
  declarations: [
    TriviaPage,
    AnswersComponent,
    BGHeaderComponent,
    BGInstructionsComponent,
    EndScreenComponent
  ],
  exports: [
    TriviaPage,
    AnswersComponent,
    BGHeaderComponent,
    BGInstructionsComponent,
    EndScreenComponent
  ]
})
export class BrainGameCommonModule {}
