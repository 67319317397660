export class Quiz {
  id: string;
  title: string;
  image: string;
  answer: string;
  points: number;
  practice: boolean;
  options: string[];
  game_id: string;
  coordinates: string;
  description: string;
  instruction: boolean;
  quiz: boolean;
  category: string;
  memory_level: number;
  color: string;
}
