<ion-header>
  <!-- toolbar -->
  <ion-toolbar [color]="cssClass">

    <ion-buttons slot="start">
      <!-- <ion-menu-button *ngIf="hasMenu && !isForm"></ion-menu-button> -->

      <!-- *ngIf="(!hasMenu || isForm) && !isModal" -->
      <ion-back-button
        [defaultHref]="defaultUrl"
        class="zoom">
      </ion-back-button>

      <ion-button
        *ngIf="isModal"
        class="zoom"
        (click)="onCloseModal()">
        <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title class="text-small">{{ title }}</ion-title>

    <ion-buttons slot="end" class="margin-right-15">
    </ion-buttons>
  </ion-toolbar>
  <!-- toolbar /-->
</ion-header>
