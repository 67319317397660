import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { HomeService } from '@services/home.service';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  
  @Input() backgroundColor?: string;
  @Input() borderColor?: string;
  @Input() borderRadius?: string;
  @Input() color?: string;
  @Input() disabled?: boolean;
  @Input() fontSize?: string;
  @Input() margin?: string;
  @Input() opacity?: string;
  @Input() padding?: string;
  @Input() size?: string;
  @Input() text?: string = "";
  @Input() path?: string;
  
  border: string;
  cobranding: any;
  width: string = "100%";
  attempts = 0;

  constructor(
    private homeService: HomeService,
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    this.configureStyles();
    this.attempts = 0;
  }

  ngOnChanges() {
    this.setDisabled();
  }

  clicked() {
    if(this.path){this.navCtrl.navigateForward(this.path);}
  }

  /**
   * Gets the cobranding styles from the HomeService.
   */
  configureStyles(): void {
    this.homeService.getCobranding().subscribe(cobrand => {
      if(cobrand) {
        this.cobranding = cobrand;
        this.setVariables();
      } else {
        this.setVariables();
      }
    });;
  }

  setVariables() {
    this.setBackgroundColor(); 
    this.setBorder(); 
    this.setTitleCase(); 
    this.setColor(); 
    this.setPadding(); 
    this.setWidth(); 
  }
  
  /**
   * Sets the button text to title case.
   */
  setTitleCase(): void {
    let textWords = this.text.toLowerCase().split(' ');
    textWords = textWords.map(textWord => textWord.charAt(0).toUpperCase() + textWord.slice(1));
    this.text = textWords.join(' ');
  }

  /**
   * Sets the background color, by default to the cobranding dark primary color, 
   */
  setBackgroundColor(): void {
    if(!this.cobranding && !this.backgroundColor) {
      this.backgroundColor = '#1D5357';
    }
    this.backgroundColor = this.backgroundColor === undefined ? this.cobranding?.darkPrimary : this.backgroundColor;
  }

  /**
   * Sets the border color, by default none. 
   */
  setBorder(): void {
    if (this.borderColor != undefined) {
      this.border = `solid ${this.borderColor}`;
    }
    else if (this.backgroundColor == "white" || this.backgroundColor == "transparent") { 
      if(this.cobranding){
        this.border = `solid ${this.cobranding.darkPrimary}`;
      } else {
        this.border = `solid #0D4347`;
      }
      
    }
    else {
      this.border = `solid #0D4347`;
    }
  }

  /**
   * Sets whether the button is disabled or not.
   */
  setDisabled(): void {
    if (this.disabled != undefined) {
      this.disabled = this.disabled.toString().toLowerCase() === 'true' 
    }
  }

  /**
   * Sets the color of the text to the cobranding dark primary 
   * if the background color is white or transparent.
   */
  setColor(): void {
    if ((this.backgroundColor == "white" || this.backgroundColor == "transparent") && this.color === undefined) {
      if(this.cobranding){
        this.color = this.cobranding.darkPrimary;
      } else {
        this.color = `#0D4347`;
      }
    } 
  }

  /**
   * Sets the padding of the button based on if the button has a border to keep the sizes 
   * between a button with a border and one without the same.
   */
  setPadding(): void {
    if (!this.padding) {
      if (this.border === undefined || this.border === "none") {
        this.padding = "20px 30px"
      }
      else {
        this.padding = "17px 30px"
      }
    }
  }

  /**
   * Sets the width of the button if the size input equals small.
   */
  setWidth(): void {
    if (this.size === "small") {
      this.width = "20%"
    }
  }
}
