<div class="cont">
  <ion-grid>
    <ion-row>
      <ion-col>
        <h2>Outside Training</h2>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="centered">
        <app-button *ngIf="trainingCount > 0" backgroundColor="white" text="- Remove Training" (click)="showRemoveTraining()"></app-button>
      </ion-col>
      <ion-col class="centered">
        <div class="circle-container" (click)=showTrainingFlyout()>
          <ion-text [ngClass]="{ 'less-left': trainingCount >= 10 }" class="training-num">{{ trainingCount }}</ion-text>
          <ion-text class="training-label">Outside <br>{{ trainingText }}</ion-text>
          <div class="percentage-container">
            <app-percentage-circle 
              *ngFor="let categorySetting of categorySettings" 
              data-cy='{{ categorySetting.id }}'
              id="{{ categorySetting.id }}"
              name='{{ categorySetting.id }}'
              color='{{ categorySetting.iconColor }}'
              percentage='{{ categorySetting.percent }}'>
            </app-percentage-circle>
          </div>
        </div>
      </ion-col>
      <ion-col class="centered">
        <app-button text="+ Add Training" (click)="showAddTraining()"></app-button>
      </ion-col>
    </ion-row>
    <hr>
    <ion-row class="category-row">
      <ion-col class="centered" *ngFor="let categorySetting of categorySettings">
        <div class="category" [ngStyle]='{ "background-color": categorySetting.background }'>
          <ion-icon name='{{ categorySetting.icon }}' [ngStyle]='{ "background-color": categorySetting.iconColor }'></ion-icon>
          <ion-text class="count">{{ categorySetting.count }}</ion-text>
          <ion-text class="type">{{ categorySetting.id }}</ion-text>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
