import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  Input,
  OnDestroy
} from '@angular/core';
import { NavController, IonSlides } from '@ionic/angular';

import { Utilities } from '@common/utilities';
import { IExercise } from '@common/interface/exercise.interface';

import { ExerciseService } from '@services/exercise.service';
import { EventBusService } from '@services/event-bus.service';

import { ExerciseTypeEnum } from '@enums/ExerciseTypeEnum';
import { GameDifficultyEnum } from '@enums/DifficultyEnum';
import { IPlayer } from '@common/interface/players.interface';

import { Subscription } from 'rxjs';

import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/city/index.css';
import video from 'video.js';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { DataTrackingService } from '@services/data-tracking.service';
import { GameService } from '@services/game.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: [
    './activity.component.scss',
  ],
})
export class ActivityComponent extends Utilities implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(IonSlides, { static: true }) slides: IonSlides;
  @Input() activityType: ExerciseTypeEnum;
  @Input() content: IExercise[] = [];
  @Input() difficulty: GameDifficultyEnum;
  @Output() timeUp: EventEmitter<any> = new EventEmitter();

  slideOpts = {
    initialSlide: 0,
    speed: 400
  };
  exercises: IExercise[];
  swiperIndex = 0;
  
  subscription = new Subscription();

  video: any;
  
  constructor(
    private eventBusService: EventBusService,
    public exerciseService: ExerciseService,
    public navCtrl: NavController,
    private dataService: DataTrackingService,
    private gameService: GameService
    ) { super(navCtrl); }

  ngOnInit() {
    this.dataService.startTimer('exerciseTime');
    this.slides.lockSwipes(true);
  }

  ngAfterViewInit() {
    const contentLength = this.content.length; // checks to see how many videos are in the array
    for (let  i = 0; i < contentLength; i++) {
      video(document.getElementById(('my-video-' + i)), {}); // initalizes each video in the array as a videoJS video
    }

    setTimeout(() => {
      this.eventBusService.onPageLoaded.emit();
    }, 1000);

    this.subscription.add(
      this.eventBusService.onActivityTimerExpired.subscribe(() => {
        this.moveToNext();
      })
    );
  }

  ngOnDestroy() {
    this.gameService.endTimers('exerciseTime');
    this.subscription.unsubscribe();
  }

  /**
   * Moves the next slide if we have not reached the end
   * of the instructions. Otherwise emit an event so the
   * parent component can take action.
   */
  moveToNext(): void {
    this.slides.getActiveIndex().then(index => {
      this.checkVideoPlaying(index); // makes sure the video is paused before advancing
      this.swiperIndex = index;
      if (this.swiperIndex + 1 !== this.content.length) {
        this.slides.lockSwipes(false);
        this.slides.slideNext();
        this.slides.lockSwipes(true);
        this.eventBusService.resetTimer.emit(this.activityType);
      } else {
        this.eventBusService.onActivityFinished.emit(this.activityType);
      }
    });
  }

  /**
   * Tracks the exercises. 
   * @param index The number index of the exercise.
   * @param item The exercise object.
   * @returns The index.
   */
  trackByFn(index: any, item: any): any {
    return index;
  }

  /**
   * Checks if the current video is playing and pauses it while moving to the next slide.
   * Ensures multiple videos are not playing at the same time.
   * @param index The video index number to be paused.
   */
  checkVideoPlaying(index: number): void {
    const video = document.getElementsByTagName("video");
    video.item(index).pause();
  }

  /**
   * Expands the exercise description when the button is clicked
   * @param name A string of the activity
   */
  expandDetails(name: string): void {
    const button = document.getElementById(name + '-btn');
    const details = document.getElementById(name);
    details.classList.toggle('collapse');
    
    // changes the button from + to - when the button is clicked and text is expanded.
    if (button.innerHTML === '+') {
      button.innerHTML = '-';
    } else {
      button.innerHTML = '+';
    }
  }
}
