import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ModalController, NavController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';

import { ActivatedRoute } from '@angular/router';

import * as _ from 'underscore';
import { SimpleTimer } from 'ng2-simple-timer';

import { Utilities } from '@common/utilities';
import { Assessment } from '@common/models/assessment.model';
import { Quiz } from '@common/models/quiz.model';
import { Game } from '@common/models/game.model';

import { HomeService } from '@services/home.service';
import { AssessmentService } from '@services/assessment.service';
import { QuizService } from '@services/quiz.service';
import { GameService } from '@services/game.service';
import { gameConfig } from '../../../app.constants.config';
import { take } from 'rxjs/operators';


import {
  SwiperConfigInterface,
  SwiperComponent
} from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-quiztwo',
  templateUrl: './quiz-two.component.html',
  styleUrls: ['./quiz-two.component.scss']
})
export class QuiztwoComponent extends Utilities implements OnInit, AfterViewInit {
  @ViewChild(SwiperComponent, { static: true }) swiper?: SwiperComponent;
  swiperConfig: SwiperConfigInterface = {};
  progress: number = 0;
  swiperHeight: string = "85%";
  everydayProblemsResultsObject: any;

  showGameTitle     = this.assessmentService.showGameTitle;
  showPracticeTitle = this.assessmentService.showPracticeTitle;
  showFixationPoint = this.assessmentService.showFixationPoint;
  showReadyTitle    = this.assessmentService.showReadyTitle;
  showQuestionGrid  = this.assessmentService.showQuestionGrid;
  showAnswerGrid    = this.assessmentService.showAnswerGrid;
  showQuiz3Continue = this.assessmentService.showQuiz3Continue;
  isCorrect         = this.assessmentService.isCorrect;
  showPlay          = this.assessmentService.showPlay;
  showPractice      = this.assessmentService.showPractice;
  showHowTo         = this.assessmentService.showHowTo;

  assessmentObject  = this.assessmentService.assessmentObject;
  game2             = this.assessmentService.game2;
  timerId           = this.assessmentService.timerId;
  gameIndex         = this.assessmentService.gameIndex;
  quizIndex         = this.assessmentService.quizIndex;
  gameId            = this.assessmentService.gameId;
  quizObject        = this.assessmentService.quizObject;
  gameObject        = this.assessmentService.gameObject;
  quiz              = this.assessmentService.quiz;
  quizStartTime     = this.assessmentService.quizStartTime;
  assessmentScore   = this.assessmentService.assessmentScore;
  utGameConfig      = this.assessmentService.utGameConfig;

  gameScore         = 0;
  quizIds           = [];
  gameAccuracy      = [];
  reactionTime      = [];
  questionCategory  = [];

  constructor(
    public navCtrl: NavController,
    public modalController: ModalController,
    private route: ActivatedRoute,
    private assessmentService: AssessmentService,
    private st: SimpleTimer,
    private router: Router,
    public loadingCtrl: LoadingController,
    public quizService: QuizService,
    public gameService: GameService,
    private homeService: HomeService
  ) { super(navCtrl); }

  ngOnInit() {
    // dont you dare remove the below without a plan, 
    // it saves data from previous quizzes making the results data object sometimes fail
    this.assessmentScore = [];
    const assessmentId = this.route.snapshot.paramMap.get('id');
    // this.numbers = Array(16).fill(16).map((x: any, i: number) => i); // [0,1,2,3,4]
    this.getAssessmentById(assessmentId);
    this.setProgressColor();
    
    this.assessmentService.current_quiz.next(2);
  }

  ngAfterViewInit() {
    // swiper configuration
    this.swiperConfig = {
      slidesPerView: 1,
      loop: false,
      effect: 'slide',
      speed: 1,
      noSwiping: true,
      noSwipingClass: 'be-no-swiping'
    };
  }
  
  /**
   * Checks if the user selected answer is correct.
   * @param i The index of the quiz.
   * @param option The selected option.
   * @param answer The answer to the quiz.
   */
  checkAnswer(i: any, option: string, answer: string): void {

    if (i !== null) {
      this.quizIndex = i;
    } else {
      this.quizIndex = this.quizIndex + 1;
    }

    this.updateProgress();
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    const j = this.quizIndex + 1;
    const gameSetting = this.assessmentService.getGameLimit(this.quizObject[this.quizIndex].game_id);
    const timeStopped = new Date();
    this.st.unsubscribe(this.timerId);

    if (answer) {
      if (!this.isQuestionReal(this.quizObject[this.quizIndex].practice,
        this.quizObject[this.quizIndex].instruction)
        && option !== answer) {
        this.isCorrect = false;
      } else {
        this.isCorrect = true;
      }
    }

    if (this.isQuestionReal(this.quizObject[this.quizIndex].practice,
      this.quizObject[this.quizIndex].instruction)) {
      if (this.quizObject[this.quizIndex].category !== 'Grid') {
        const reactionTime = timeStopped.getTime() - this.quizStartTime;
        if (answer && option === answer) {
          this.gameScore = this.gameScore + this.quizObject[this.quizIndex].points;
          this.gameAccuracy.push(true);
        } else {
          this.gameAccuracy.push(false);
        }
        this.questionCategory.push(this.quizObject[this.quizIndex].category);
        this.reactionTime.push(reactionTime);
        this.quizIds.push(this.quizObject[this.quizIndex].id);
      }
    }

    if (this.isCorrect) {
      if (j < this.quizObject.length) {
        /*Check the quizObject if the next index is a new game display game header
        * save points for the game id then swipe
        */
        if (this.quizObject[this.quizIndex].game_id !== this.quizObject[j].game_id) {
          this.assessmentScore.push({game_id: this.quizObject[this.quizIndex].game_id,
            game_score: this.gameScore, game_accuracy: this.gameAccuracy, reaction_time: this.reactionTime,
            switch_status: null, category: this.questionCategory, quiz_ids: this.quizIds});
          // For each new game we reset the game score
          
          // Grab the game id for the next game so we can display the appropriate information
          this.gameId = this.quizObject[j].game_id;
          this.getGameById(this.gameId);
          this.swiper.directiveRef.nextSlide();
          this.showGameTitle = true;
          this.showPracticeTitle = false;
        } else {
          if (this.quizObject[this.quizIndex].instruction && this.quizObject[j].practice) {
            this.showPracticeTitle = true;
          }

          if (!this.quizObject[this.quizIndex].quiz && this.quizObject[j].quiz) {
            this.showReadyTitle = true;
          }

          if (this.isQuestionReal(this.quizObject[j].practice,
            this.quizObject[j].instruction) && !this.showReadyTitle) {
            if (gameSetting.fixationPoint) {
              this.showFixationPoint = true;
              this.st.newTimer('1sec', 1, true);
              this.timerId = this.st.subscribe('1sec', () => {
                this.showFixationPoint = false;
                this.st.unsubscribe(this.timerId);
                const d = new Date();
                this.quizStartTime = d.getTime();
                if (gameSetting.questionTime !== 0) {
                  this.st.newTimer('5sec', gameSetting.questionTime, true);
                  this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(null,
                    this.quizObject[this.quizIndex].answer, ' '));
                }
              });
            } else {
              const d = new Date();
              this.quizStartTime = d.getTime();
              if (gameSetting.questionTime !== 0) {
                this.st.newTimer('5sec', gameSetting.questionTime, true);
                this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(null,
                  this.quizObject[this.quizIndex].answer, ' '));
              }
            }
          }

          if (!this.quizObject[j].answer && !this.showReadyTitle && !this.showPracticeTitle) {
            this.st.newTimer('5sec', 1, true);
            this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(null,
              null, null));
          }
          this.swiper.directiveRef.nextSlide();
        }
      } else {
        const assObj = {
          game_id: this.quizObject[this.quizIndex].game_id,
          game_score: this.gameScore, 
          game_accuracy: this.gameAccuracy, 
          reaction_time: this.reactionTime,
          category: this.questionCategory, 
          quiz_ids: this.quizIds
      }
        // For the last game we need to store the score and show the finished page
        this.everydayProblemsResultsObject = this.calculateSummary(assObj);
        const mergedObject = {...assObj, ...this.everydayProblemsResultsObject};
        this.assessmentObject.game_score = this.assessmentObject.game_score + assObj.game_score;
        this.assessmentObject.results_data.push(mergedObject);
        this.assessmentService.saveAssessment(this.assessmentObject);
        this.assessmentService.nextQuiz(3);
      }
    }
  }

  /**
   * Gets the assessment by the id.
   * @param id A string of the assessment id.
   */
  getAssessmentById(id: string): void {
    this.showLoading();
    this.assessmentService.getAssessmentById(id).then((data: Assessment) => {
      this.assessmentObject = data;

      // Fetch Quizzes for Each Game
      this.getQuizzesForGame(this.game2);

      this.gameId = this.assessmentObject.game_ids[1];
      this.getGameById(this.gameId);
      this.hidLoading();
    });
  }
  
  /**
   * Navigates to the third quiz.
   */
  startQuiz3(): void {
    this.assessmentService.nextQuiz(3);
  }

  /**
   * Gets all the quiz questions based on the game's id, type and limit.
   * @param gameId A string of the game's id.
   */
  getQuizzesForGame(gameId: string): void {
    const quiz        = 'quiz';
    this.utGameConfig   = gameConfig;
    this.quizObject = [];
    
    /*************************************** ADD QUIZZES *************************************************/
    this.quizService.getQuizByType(gameId, quiz, 50).pipe(take(1)).subscribe((quizObj: Quiz[]) => {
      this.randomizeQuiz(quizObj).then(quiz => {
        this.quizObject = this.quizObject.concat(quiz);
      })
      
      
      this.hidLoading();

    });
  }

  /**
   * Adds the quiz limit, randomizes and generates quizes for the games
   * @param quizObj The array of quiz questions.
   * @param gameId A string of the game's id.
   */
  async randomizeQuiz(quizObj: Quiz[]) {
    if (quizObj === null) {
      return;
    }

    let temp    = [];
    const index = this.assessmentService.assessments.assessments.length%2;
    const quizByCat = _.groupBy(quizObj, 'category');
    const cats = Object.keys(quizByCat);
    const promises = cats.map(cat => {
      temp.push(quizByCat[cat][index]);
    })
    await Promise.all(promises);
    
    temp = await this.randomizeArray(temp);
    return temp;
  }

  /**
   * Gets the game by the inputted id.
   * @param id The game's id.
   */
  getGameById(id: any): void {
    if (id) {
      this.gameService.getGameById(id).then((data: Game) => {
        this.gameObject = data;
      });
    }
  }

  
  @ViewChild('mySwiper') mySwiper: SwiperComponent;

  /**
   * Hides the game title from the user.
   * @param i The index of the quiz.
   */
  hideGameTitle(i: any): void {
    setTimeout(() => {
      this.mySwiper.directiveRef.update();
    }, 1000);
    this.quizIndex = i;
    this.showGameTitle = false;
    this.gameIndex = i + 1;
    const gameSetting = this.assessmentService.getGameLimit(this.quizObject[this.quizIndex].game_id);
    this.swiperHeight = "95.6%";

    if (this.quizObject[this.quizIndex].practice) {
      this.showPracticeTitle = true;
      // ensure that we pull and display info here
    } else {
      this.showPracticeTitle = false;
      if (this.isQuestionReal(this.quizObject[this.quizIndex].practice, this.quizObject[this.quizIndex].instruction)) {
        const d = new Date();
        this.quizStartTime = d.getTime();
        if (gameSetting.questionTime !== 0) {
          this.st.newTimer('5sec', gameSetting.questionTime, true);
          this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(this.quizIndex,
            this.quizObject[this.quizIndex].answer, null));
        }
      }

      if (!this.quizObject[this.quizIndex].answer) {
        if (this.quizObject[this.quizIndex].instruction) {
          this.showQuiz3Continue = true;
        } else {
          this.st.newTimer('5sec', 1, true);
          this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(null,
            null, null));
        }
      }
    }
  }

  /**
   * Ensures the practice title is hidden for the quiz.
   * @param i The index of the quiz/page.
   */
  hidePracticeTitle(i: any): void {
    this.quizIndex = i;
    this.showGameTitle = false;
    this.showPracticeTitle = false;
    this.showHowTo = false;
    this.showPractice = true;
    if (!this.quizObject[this.quizIndex].answer) {
      this.st.newTimer('5sec', 1, true);
      this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(this.quizIndex,
        null, null));
    }
  }

  /**
   * Hides the result title.
   * @param i The index of the quiz.
   */
  hideResultTitle(i: any): void {
    this.quizIndex = i;
    this.showReadyTitle = false;
    this.showPractice = false;
    this.showPlay = false;
    
    const gameSetting = this.assessmentService.getGameLimit(this.quizObject[this.quizIndex].game_id);

    if (this.isQuestionReal(this.quizObject[i].practice, this.quizObject[i].instruction)) {
      if (this.quizObject[this.quizIndex].answer) {
        const d = new Date();
        this.quizStartTime = d.getTime();
        if (gameSetting.questionTime !== 0) {
          this.st.newTimer('5sec', gameSetting.questionTime, true);
          this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(this.quizIndex,
            this.quizObject[this.quizIndex].answer, ''));
        }
      }
    }
  }

  /**
   * Checks if the question is a real question.
   * @param a The first boolean value.
   * @param b The second boolean value.
   */
  isQuestionReal(a: boolean, b: boolean): boolean {
    if (!a && !b) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Sets the quiz index.
   * @param i The number to set the quiz index to.
   */
  trackIndex(i: number) {
    this.quizIndex = i;
  }

  /**
   * Calculates the summary of the current quiz.
   * @param scoreObject Contains the score settings such as game accuracy.
   * @returns An analysis of the user's quiz.
   */
  calculateSummary(scoreObject): any {
    const everydayProblemAnalysis = {total_time: 0};
    scoreObject.reaction_time.forEach((time) => {
      everydayProblemAnalysis.total_time += time;
    });
    return everydayProblemAnalysis;
  }

  /**
   * Updates the progress percentage.
   */
  updateProgress(): void {
    const progress = (this.quizIndex+1) / this.quizObject.length;
    this.assessmentService.current_progress.next(progress);
  }

  /**
   * Sets the progress bar with the cobranding color.
   */
  setProgressColor(): void {
    var sheet = document.styleSheets[0];
    this.homeService.getCobranding().subscribe(cobrand => {
      sheet.insertRule(`ion-progress-bar{--progress-background:${cobrand.darkPrimary}}`);
    });
  }
}

