<div class="head">
  <ion-grid>
    <ion-row>
      <ion-col size="12" size-sm="4" class="justify-content-end">
        <ion-text><strong>Round:</strong> {{round}}</ion-text>
      </ion-col>
      <ion-col size="12" size-sm="4" class="justify-content-center">
        <app-timer
          [pause]='pause'
          [reset]='reset'
          [isYahootie]='isYahootie'
          (timeUp)='timeUpEventhandler()'>
        </app-timer>      
      </ion-col>
      <ion-col size="12" size-sm="4" class="justify-content-start">
        <ion-text><strong>Score:</strong> {{score}}</ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
  <hr>
</div>