<ion-grid class='bc-table'>
  <ion-row class='table-title'>
    <ion-col size='4'>
      <!-- spacer -->
    </ion-col>
    <ion-col size='4'>
      Prior Week
    </ion-col>
    <ion-col size='4' class='in-front'>
      Selected Week
    </ion-col>
  </ion-row>
  <ion-row class='bc-row'>
    <ion-col size='4' class='table-title'>
      Brain Charge
    </ion-col>
    <ion-col size='4' class='table-value'>
      {{firstBCScore}}
    </ion-col>
    <ion-col size='4' class='table-value'>
      {{secondBCScore}}<span class='table-subscript'>(+{{bcImprovement}})</span>
    </ion-col>
  </ion-row>
  <ion-row class='comp-row'>
    <ion-col size='4' class='table-title'>
      Compliance
    </ion-col>
    <ion-col size='4' class='table-value'>
      {{firstCompliancePercentage}}%
    </ion-col>
    <ion-col size='4' class='table-value'>
      {{secondCompliancePercentage}}%<span class='table-subscript'>(+{{complianceImprovement}})</span>
    </ion-col>
  </ion-row>
  <!-- blue square to signify current week -->
  <div class='current-week-square'>
    <div class='blue-square'></div>
  </div>
</ion-grid>