
<!-- wrapper -->
<div class="centered d-flex justify-content-center">
  <ion-card style="max-width: 1080px; min-height: 75vh;">
    <h1 *ngIf="gameIndex === 0">Assessment Quiz One <span class="unbold">(of three)</span></h1>
    <swiper #mySwiper [config]="swiperConfig" class="be-swiper flex-stretch-vertical" [style.height]='swiperHeight'>
      <div class="be-no-swiping" *ngFor="let quiz of quizObject; let i = index">
        <!-- game title -->
        <div *ngIf="showGameTitle" class="full-height center-with-footer">
          <div
            class="game-image"
            [ngStyle]="{
              'background-image': 'url(' + gameObject.imageUrl + ')'
            }">
          </div>

          <div class="container assessment-content">
            <div class="background-padded">
              <!-- title -->
              <div class="step-title">
                {{ gameObject.name }}
              </div>
              <!-- title /-->

              <!-- instructions -->
              <div class="step-instructions">
                <div
                  class="margin-top-10"
                  [innerHTML]="gameObject.description">
                </div>
              </div>
              <!-- instructions /-->
            </div>
          </div>

          <ion-footer class="sticky-footer ion-padding">
            <app-button attr.data-cy='Next' text="Next" (click)="hideGameTitle(i)"></app-button>
          </ion-footer>
        </div>
        <!-- game title -->

        <!-- lets practise -->
        <div *ngIf="showPracticeTitle" class="full-height">
          <div
            class="container content-center-vertical text-center full-width center-with-footer">
            <div class="background-padded full-width" style="padding-bottom: 100px;">
              <!-- title -->
              <div class="practice-title">Let's Practice!</div>
              <!-- title /-->
            </div>
          </div>

          <ion-footer class="sticky-footer ion-padding">
            <app-button attr.data-cy="Next" text="Next" (click)="hidePracticeTitle(i)"></app-button>
          </ion-footer>
        </div>
        <!-- lets practise -->

        <!-- ready for quiz -->
        <div *ngIf="showReadyTitle" class="full-height">
          <div class="container">
            <div class="background-padded assessment-content">
              <!-- title -->
              <div class="step-title">Ready?</div>
              <!-- title /-->
              <!-- instructions -->
              <div class="step-instructions">
                <p>Tap the "L" and "R" buttons as quickly as you can.</p>
                <p>You will only get a few seconds to answer each question during the quiz so be ready to answer quickly.</p>
                <p>When you are ready to start the quiz, tap the "Start Quiz" button below.</p>
              </div>
              <!-- instructions /-->
            </div>
          </div>
          <!-- Start Quiz button -->
          <ion-footer class="sticky-footer ion-padding">
              <app-button attr.data-cy="Start_Quiz" id="Start_Quiz" text="Start Quiz" (click)="hideResultTitle(i)"></app-button>
          </ion-footer>
          <!-- Start Quiz button /-->
        </div>
        <!-- ready for quiz -->

        <!-- quiz -->
        <div
          class="full-height"
          *ngIf="
            !showPracticeTitle &&
            !showGameTitle &&
            !showReadyTitle &&
            !showFixationPoint">
          <div
            class="container center-with-footer"
            [ngClass]="{ 'full-height': quiz.image}">
            <!-- title -->
            <div
              class="step-title background-padded"
              *ngIf="showPlay">
              <span *ngIf="showHowTo">How To Play</span>
              <span *ngIf="showPractice">Practice</span>              
            </div>

            <!-- what is this???? -->
            <div
              class="step-title background-padded"
              *ngIf="!showPlay">
              <div *ngIf="!showPractice && !showHowTo">&nbsp;</div>
            </div>

            <!-- quiz title -->
            <div
              class="step-title margin-top-0 background-padded"
              *ngIf="quiz.title && !showPlay">
              <ion-text>{{ quiz.title }}</ion-text>
            </div>
            <!-- quiz title /-->
            <!-- title /-->

            <!-- coordinates -->
            <div
              class="coordinates background-padded"
              [ngClass]="{ 'auto-height': quiz.image }"
              *ngIf="quiz.category != 'Grid'">
              <div *ngIf="!quiz.image" [ngClass]="quiz.coordinates"></div>
              <div *ngIf="quiz.image">
                <img src="{{ quiz.image }}" />
              </div>
            </div>
            <!-- coordinates /-->

            <!-- description -->
            <div class="description background-padded">
              <ion-text>{{ quiz.description }}</ion-text>
            </div>
            <!-- description /-->

            <!-- correct answer -->
            <div
              *ngIf="!isCorrect && quiz.category !== 'Grid'"
              class="text-center background-padded">
              <ion-text class="correct">The correct answer is {{ quiz.answer }}</ion-text>
            </div>
            <!-- correct answer /-->

            <!-- quiz -->
            <div 
              class="button-spacing background-padded"
              [ngClass]="{'margin-bottom-95': quiz.options?.length > 2}">
              <ion-grid style="width: 90%">
                <ion-row *ngIf="quiz.options?.length === 2">
                  <ion-col
                    size = '6'
                    *ngFor="let option of quiz.options; let o = index"
                    class="justify-content-center d-flex">
                    <button
                      id="dynamicid_{{ option }}"
                      attr.data-cy="dynamicid_{{ option }}"
                      class="be-button-tertiary round-button"
                      [ngClass]="{  'error-btn':(errorR && o === 1) || (errorL && o === 0)}"
                      size="large"
                      (click)="checkAnswer(i, option, quiz.answer)">
                      {{ option }}
                    </button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
            <!-- quiz /-->

            <div *ngIf="quiz.options.length === 0">
              <app-button text="Continue" (click)="checkAnswer(i, null, null)"></app-button>
            </div>
          </div>
        </div>
        <!-- quiz -->

        <!-- fixation point -->
        <div *ngIf="showFixationPoint" class="content-center-vertical full-height">
          <div
            class="container content-center-vertical full-width center-with-footer">
            <div class="background-padded ion-text-center full-width">
              <ion-icon class="text-large" name="add"></ion-icon>
            </div>
          </div>
        </div>
        <!-- fixation point -->
      </div>
    </swiper>
    <!-- swiper /-->
  </ion-card>
</div>
<!-- ion content /-->
