<!-- global header -->
<ion-header>
  
  <!-- toolbar -->
  <ion-toolbar [color]="headerColor">
    <!-- back button -->
    <ion-buttons slot="start" *ngIf="showBack">
      <ion-back-button
        defaultHref="/user"
        class="zoom">
      </ion-back-button>
    </ion-buttons>
    <!-- back button /-->

    <!-- title and logo -->
    <ion-title class="text-small">
      <div class="flex flex-vertical-center">
        <div class="{{ logoClass }}" *ngIf="showLogo"></div> 
        <div *ngIf="headerTitle">{{ headerTitle }}</div>

        <div class="center-with-logo" *ngIf="showTimer">
          <app-game-timer            
            [countDownTime]="countDownTime"
            (timeUp)="timerExpiredHandler($event)">
          </app-game-timer>
        </div>
      </div>
    </ion-title>
    <!-- title and logo /-->

  </ion-toolbar>
  <!-- toolbar /-->

</ion-header>
<!-- global header /-->