import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { TrackerProcessingService } from '@services/tracker-processing.service';

@Component({
  selector: 'app-brain-charge-meter',
  templateUrl: './brain-charge-meter.component.html',
  styleUrls: ['./brain-charge-meter.component.scss'],
})
export class BrainChargeMeterComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() score = 0;
  @Input() compliance = 0;

  descriptor = 'Challenge';
  
  baseRed = {
    red: 186,
    green: 65,
    blue: 58,
  };
  baseGreen = {
    red: 98,
    green: 136,
    blue: 70,
  };
  baseYellow = {
    red: 244,
    green: 206,
    blue: 115,
  };

  radius = 123;
  loadTimer = 15;

  pointer = {
    left: '',
    bottom: '',
    backgroundColor: '',
    transform: ''
  };

  constructor(
    private progressService: TrackerProcessingService
  ) { }

  ngOnInit() {
    // this.init();
  }

  ngOnChanges() {
    this.init();
  }

  ngAfterViewInit() {
    this.init();
  }

  /**
   * Initalizes the title, percentage and moves the pointer
   */
  async init(): Promise<void> {
    // const test = Number.parseFloat((this.progressService.bcScore.bcScore / 10) + '').toFixed(1);
    const score_num = this.score
    if(score_num > 10){this.score = 10;}
    const truePercent = score_num / 10;
    this.scoreToTitle();
    let percent = 0;
    this.movePointer(percent);
    const wait = (delay: number, ...args: undefined[]) => new Promise(resolve => setTimeout(resolve, delay, ...args));
    while(percent < truePercent) {
      await wait(this.loadTimer).then( () => {
        percent += 0.01;
        this.movePointer(percent);
      });
    }
  }

  /**
   * Moves the pointer while changing the color based on where it is
   * @param percent The portion of the meter to move the pointer to
   */
  movePointer(percent: number): void {
    const xy = this.calculatePosition(percent, this.radius);
    const angle = this.calculateAngle(percent);
    let color = this.calculateColor(percent) as any;
    const x = (xy.x + this.radius - 5).toFixed(0) + 'px';
    const y = (xy.y + this.radius).toFixed(0) + 'px';
    color = this.convertRGBToString(color);

    this.pointer.left = x;
    this.pointer.bottom = y;
    this.pointer.backgroundColor = color;
    this.pointer.transform = `rotate(${angle}deg)`;
  }

  /**
   * Calculates the vertical and horizontal position of the pointer
   * @param percentage The portion of the meter that the pointer is on
   * @param radius The halfway size of the meter
   * @returns The x-axis and y-axis numbers of the position as an object
   */
  calculatePosition(percentage: number, radius: number): { x: number; y: number; } {
    const radians = (3.14159) * percentage;
    const x = -(Math.cos(radians) * radius);
    const y = Math.sin(radians) * radius;
    return {x,y};
  }

  /**
   * Calculates the angle of the pointer
   * @param percentage The portion of the meter that the pointer is on
   * @returns The angle as a number
   */
  calculateAngle(percentage: number): number {
    return (180 * percentage) - 90;
  }

  /**
   * Calculates the color of the pointer and changes it
   * @param percentage The portion of the meter that the pointer is on
   * @returns An object with the RGB colors
   */
  calculateColor(percentage: number): { red: number; green: number; blue: number; } {
    // red to yellow
    if(percentage < 0.5) {
      percentage *= 2;
      const change = this.diffRGB(this.baseYellow, this.baseRed);
      const percentChange = this.multRGB(change, percentage);
      return this.addRGB(percentChange, this.baseRed);
    // yellow to green
    } else {
      percentage -= 0.5;
      percentage *= 2;
      const change = this.diffRGB(this.baseGreen, this.baseYellow);
      const percentChange = this.multRGB(change, percentage);
      return this.addRGB(percentChange, this.baseYellow);
    }
  }

  /**
   * Converts the RGB object into a RBG string
   * @param hex The hex RGB
   * @returns The RGB hex in a string
   */
  private convertRGBToString(hex: any): string {
    const keys = Object.keys(hex);
    let hexStr = '#';
    for (const key of keys) {
      if(hex[key] < 16) {
        hexStr = hexStr.concat('0');
      } 
      hexStr = hexStr.concat(hex[key].toString(16));
    }
    return hexStr;
  }

  /**
   * Calculates the difference between two RGB objects 
   * @param rgb An object with the RGB colors
   * @param rgb2 An object with the RGB colors
   * @returns The difference between the RBGs in an object similar to the parameters
   */
  private diffRGB(rgb, rgb2): { red: number; green: number; blue: number; } {
    const keys = Object.keys(rgb);
    let final = {
      red: 0,
      green: 0,
      blue: 0,
    }
    for (const key of keys) {
      final[key] = rgb[key] - rgb2[key];
    }
    return final;
  }

  /**
   * Calculates the addition between two RGB objects 
   * @param rgb An object with the RGB colors
   * @param rgb2 An object with the RGB colors
   * @returns The addition between the RBGs in an object similar to the parameters
   */
  private addRGB(rgb, rgb2): { red: number; green: number; blue: number; } {
    const keys = Object.keys(rgb);
    let final = {
      red: 0,
      green: 0,
      blue: 0,
    }
    for (const key of keys) {
      final[key] = parseInt((rgb[key] + rgb2[key]).toFixed(0));
    }
    return final;
  }

  /**
   * Calculates the multiplication between two RGB objects 
   * @param rgb An object with the RGB colors
   * @param rgb2 An object with the RGB colors
   * @returns The addition between the RBGs in an object similar to the parameters
   */
  private multRGB(rgb, num): { red: number; green: number; blue: number; } {
    const keys = Object.keys(rgb);
    let final = {
      red: 0,
      green: 0,
      blue: 0,
    }
    for (const key of keys) {
      final[key] = rgb[key] * num;
    }
    return final;
  }

  /**
   * Sets the description based on the score
   */
  scoreToTitle(): void {
    const score_num = this.score
    if(score_num <= 2) {
      this.descriptor = 'Challenge';
    } else if (score_num <= 4) {
      this.descriptor = 'Fair';
    } else if (score_num <= 6) {
      this.descriptor = 'Good';
    } else if (score_num <= 8) {
      this.descriptor = 'Very Good';
    } else {
      this.descriptor = 'Excellent';
    }

  }
}
