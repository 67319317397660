import { Component, OnInit, AfterViewInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { ModalController, NavController, LoadingController, AlertController, ToastController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';

import * as _ from 'underscore';

import { Utilities } from '@common/utilities';
import { Assessment } from '@common/models/assessment.model';
import { Quiz } from '@common/models/quiz.model';
import { Game } from '@common/models/game.model';

import { NavigationEnum } from '@enums/NavigationEnum';

import { AssessmentService } from '@services/assessment.service';
import { QuizService } from '@services/quiz.service';
import { GameService } from '@services/game.service';
import { gameConfig } from '../../../app.constants.config';

import { SwiperConfigInterface, SwiperComponent, SwiperDirective} from 'ngx-swiper-wrapper';
import { AuthenticationService } from '@services/authentication.service';
import { SubscriberModel } from '@common/models/subscriber.model';
import { take } from 'rxjs/operators';
import { HomeService } from '@services/home.service';
import * as confetti from 'canvas-confetti';

@Component({
  selector: 'app-quizthree',
  templateUrl: './quiz-three.component.html',
  styleUrls: ['./quiz-three.component.scss']
})
export class QuizthreeComponent extends Utilities implements OnInit, AfterViewInit {
  @ViewChild(SwiperComponent, { static: true }) swiper?: SwiperComponent;
  navigationEnum = NavigationEnum;
  swiperConfig: SwiperConfigInterface = {};
  userId;
  progress: number = 0;
  swiperHeight: string;
  symmetry_accuracy = [];
  squares_accuracy = [];
  symm_rt = [];
  squares_rt = [];

  // How long the memory item should be displayed
  gridMemoryTime = 1200;
  showGameTitle     = this.assessmentService.showGameTitle;
  showPracticeTitle = this.assessmentService.showPracticeTitle;
  showFixationPoint = this.assessmentService.showFixationPoint;
  showReadyTitle    = this.assessmentService.showReadyTitle;
  showQuestionGrid  = this.assessmentService.showQuestionGrid;
  showAnswerGrid    = this.assessmentService.showAnswerGrid;
  showQuiz3Continue = this.assessmentService.showQuiz3Continue;
  isComplete        = this.assessmentService.isComplete;
  isCorrect         = this.assessmentService.isCorrect;

  assessmentObject  = this.assessmentService.assessmentObject;
  selectedCell      = this.assessmentService.selectedCell;
  game3             = this.assessmentService.game3;
  quizIds           = this.assessmentService.quizIds;
  quizThreeIndex    = this.assessmentService.quizThreeIndex;
  timerId           = this.assessmentService.timerId;
  showPlay          = this.assessmentService.showPlay;
  showPractice      = this.assessmentService.showPractice;
  showHowTo         = this.assessmentService.showHowTo;
  gameIndex         = this.assessmentService.gameIndex;
  quizIndex         = this.assessmentService.quizIndex;
  gameId            = this.assessmentService.gameId;
  quizObject        = this.assessmentService.quizObject;
  gameObject        = this.assessmentService.gameObject;
  quiz              = this.assessmentService.quiz;
  gameScore         = 0;
  quizStartTime     = this.assessmentService.quizStartTime;
  questionCategory  = [];
  utGameConfig      = this.assessmentService.utGameConfig;
  grid              = this.assessmentService.grid;
  
  confettiBackground= false;
  memoryLevels = [];
  quizThreeAnswers = [];
  numbers;

  constructor(
    public navCtrl: NavController,
    public modalController: ModalController,
    private route: ActivatedRoute,
    private assessmentService: AssessmentService,
    public loadingCtrl: LoadingController,
    public alertController: AlertController,
    public authService: AuthenticationService,
    public toastController: ToastController,
    public quizService: QuizService,
    public gameService: GameService,
    private homeService: HomeService,
    private renderer2: Renderer2,
    private elementRef: ElementRef,
  ) { super(navCtrl); }

  ngOnInit() {
    this.gameScore = 0;//
    this.questionCategory = [];
    const assessmentId = this.route.snapshot.paramMap.get('id');
    this.numbers = Array(16).fill(16).map((x: any, i: number) => i); // [0,1,2,3,4]
    this.selectedCell = Array(16).fill(false);
    //window.alert('init');
    this.getAssessmentById();
    this.getUser();
    this.setProgressColor();
    this.assessmentService.current_quiz.next(3);
  }

  @ViewChild(SwiperDirective) directiveRef?: SwiperDirective;
  @ViewChild('mySwiper') mySwiper: SwiperComponent;
  ngAfterViewInit() {
    //window.alert('after_init');
    // swiper configuration
    this.swiperConfig = {
      slidesPerView: 1,
      loop: false,
      effect: 'slide',
      speed: 1,
      noSwiping: true,
      noSwipingClass: 'be-no-swiping'
    };
    //window.alert('after_init2');
  }

  /**
   * Gets the authenticated user from the AuthenticationService.
   */
  getUser(): void {
    this.authService.getAuthUser().pipe(take(1)).subscribe( user => {
      if(user[0]) {
        this.userId = user[0].id;
      }
    })
  }

  /**
   * Checks if the user selected answer is correct.
   * @param i The index of the quiz.
   * @param option The selected option.
   * @param answer The answer to the quiz.
   */
  checkAnswer(i: any, option: string, answer: string) {

    if (i !== null) {
      this.quizIndex = i;
    } else {
      this.quizIndex = this.quizIndex + 1;
    }

    this.updateProgress();
    const j = this.quizIndex + 1;
    const gameSetting = this.assessmentService.getGameLimit(this.quizObject[this.quizIndex].game_id);
    const timeStopped = new Date();
    // this.st.unsubscribe(this.timerId);

    // is there is an answer
    if (answer) {
      // and this is a practice round and the answer is incorrect
      if (!this.isQuestionReal(this.quizIndex) && option !== answer) {
        this.isCorrect = false; // show the user that their answer was incorrect
      } else {
        this.isCorrect = true; // else show the user that their answer was correct
      }
    }

    // if the quiz object is neither a practice or an instruction
    if (this.isQuestionReal(this.quizIndex)) {

      // if the quiz object is not a grid
      if (this.quizObject[this.quizIndex].category !== this.grid) {
        const reactionTime = timeStopped.getTime() - this.quizStartTime;

        // if there is an answer and the user selected the correct option
        if (answer && option === answer) {
          // add how many points the question is worth to the game score
          this.gameScore = this.gameScore + this.quizObject[this.quizIndex].points;
          this.symmetry_accuracy.push(true);
        } else {
          this.symmetry_accuracy.push(false);
        }
        this.questionCategory.push(this.quizObject[this.quizIndex].category);
        this.symm_rt.push(reactionTime);
        this.quizIds.push(this.quizObject[this.quizIndex].id);
      }
    }

    // if the answer that they selected is correct
    if (this.isCorrect) {
      if (j < this.quizObject.length) {
        /*Check the quizObject if the next index is a new game display game header
        * save points for the game id then swipe
        */
        if (this.quizObject[this.quizIndex].game_id !== this.quizObject[j].game_id) {
          // Grab the game id for the next game so we can display the appropriate information
          this.gameId = this.quizObject[j].game_id;
          this.getGameById(this.gameId);
          this.swiper.directiveRef.nextSlide();
          this.showGameTitle = true;
          this.showPracticeTitle = false;
        } else {
          if (this.quizObject[this.quizIndex].instruction && this.quizObject[j].practice) {
            this.showPracticeTitle = true;
          }

          if (!this.quizObject[this.quizIndex].quiz && this.quizObject[j].quiz) {
            this.showReadyTitle = true;
          }

          if (this.isQuestionReal(j) && !this.showReadyTitle) {
            if (gameSetting.fixationPoint) {
              this.showFixationPoint = true;
              const d = new Date();
              this.quizStartTime = d.getTime();
              // this.st.newTimer('1sec', 1, true);
              // this.timerId = this.st.subscribe('1sec', () => {
              //   this.showFixationPoint = false;
              //   this.st.unsubscribe(this.timerId);
              //   const d = new Date();
                
              //   if (gameSetting.questionTime !== 0) {
              //     this.st.newTimer('5sec', gameSetting.questionTime, true);
              //     this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(null,
              //       this.quizObject[this.quizIndex].answer, ' '));
              //     }
              // });
            } else {
              const d = new Date();
              this.quizStartTime = d.getTime();
              // if (gameSetting.questionTime !== 0) {
              //   this.st.newTimer('5sec', gameSetting.questionTime, true);
              //   this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(null,
              //     this.quizObject[this.quizIndex].answer, ' '));
              // }
            }
          }

          // happens on symmetry questions
          if (!this.quizObject[j].answer && !this.showReadyTitle && !this.showPracticeTitle) {
            // this.st.newTimer('5sec', 1, true);
            // this.quizThreeIndex.push(j);
            // this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(null,
            //   null, null));
          }

          // TODO: Logic to check if its answer page and start the timer
          // goes to next slide after a symmetry question
          this.goToNextSlide(i);
        }
        // Add logic to check answer andupdate score if correct
      } else {
        // Make a call to store the points for the game here
        this.isComplete = true;
        this.assessmentService.nextQuiz(4);
      }
    }
  }

  isMobile(): boolean {
    const userAgent = navigator.userAgent;

    // This is a simple regex to check for mobile devices. You might need a more comprehensive check depending on your requirements.
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  }

  /**
   * Gets the assessment by the id.
   * @param id A string of the assessment id.
   */
  async getAssessmentById() {
    // window.alert('1A'); //DOESNT WORK
    await this.showLoading();
    if(this.isMobile()){
      window.alert('On to quiz 3'); // WORKS
    }
    this.assessmentService.getAssessmentById().then(async (data: Assessment) => {
      this.assessmentObject = data;
      // Fetch Quiz
      // window.alert('1E'); // WORKS
      this.getQuizzesForGame(this.game3);
      // window.alert('1D');
      this.gameId = this.assessmentObject.game_ids[2];
      this.getGameById(this.gameId);
      // window.alert('1B');
      this.hidLoading();
      // window.alert('1C');
    });
  }

  /**
   * Gets all the quiz questions based on the game's id, type and limit.
   * @param gameId A string of the game's id.
   */
  async getQuizzesForGame(gameId: string) {
    
    // window.alert('3A');
    this.quizObject = [];
    const quiz = 'quiz';
    const practice = 'practice';
    const instruction = 'instruction';
    this.utGameConfig = gameConfig;

    // Get Eash Game Limits
    const gameSetting = await this.assessmentService.getGameLimit(gameId);
    const tries = 3;
    const sleepTimer = 1000;
    for ( let i = 0; i < tries; i++) {
      if(gameSetting){
        break;
      }
      await this.sleep(sleepTimer);
    }
    /*************************************** ADD INSTRUCTIONS AND PRACTICE *************************************************/
    // note from eric: this was changed in the quiz 3 service so instructions and practice would get pulled together in one single query.
    // this made it easier to pull the practice and instruction in a specific order.
    this.quizService.getQuizByType(gameId, instruction, gameSetting.inslimit).pipe(take(1)).subscribe((insObj: Quiz[]) => {
        Array.prototype.push.apply(this.quizObject, insObj);
        // window.alert('3C');
    });

    /*************************************** ADD QUIZZES *************************************************/
    this.quizService.getQuizByType(gameId, quiz, gameSetting.quizlimit).pipe(take(1)).subscribe(async (quizObj: Quiz[]) => {

      let tempAll = []; // Contains All Quizzes including quizzes generated on the front end
      // Add Limit and Randomize Quizes for Triangle and Squares Game and Third Quiz
      tempAll = await this.assessmentService.randomizeQuiz(quizObj, gameId); // Randomize Quiz
      Array.prototype.push.apply(this.quizObject, tempAll);
      // window.alert('3D');
    });

  }

  /**
   * Requests the pin from the coach.
   */
  async enterCoachPin(): Promise<void> {
    this.confetti();
    this.assessmentService.updateUserAssessmentDate();
    this.navigateToPath('progress/2', NavigationEnum.Forward);

  }
  
  /**
   * Gets the game by the inputted id.
   * @param id The game's id.
   */
  getGameById(id: any): void {
    if (id) {
      //window.alert('4A');
      this.gameService.getGameById(id).then((data: Game) => {
        // window.alert('4B');
        this.gameObject = data;
      });
    }
  }

  /**
   * Hides the game title from the user.
   * @param i The index of the quiz.
   */
  hideGameTitle(i: any): void {
    // setTimeout(() => {
    //   this.mySwiper.directiveRef.update();
    // }, 1000);
    this.quizIndex = i;
    this.showGameTitle = false;
    this.gameIndex = i + 1;
    const gameSetting = this.assessmentService.getGameLimit(this.quizObject[this.quizIndex].game_id);
    this.swiperHeight = "95.6%";

    if (this.quizObject[this.quizIndex].practice) {
      this.showPracticeTitle = true;
      // ensure that we pull and display info here
    } else {
      this.showPracticeTitle = false;
      if (this.isQuestionReal(this.quizIndex)) {
        const d = new Date();
        this.quizStartTime = d.getTime();
        // if (gameSetting.questionTime !== 0) {
        //   this.st.newTimer('5sec', 4, true);
        //   this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(this.quizIndex,
        //     this.quizObject[this.quizIndex].answer, null));
        // }
      }

      // adds the continue button to the instruction slider before playing quiz 3
      if (!this.quizObject[this.quizIndex].answer) {
        if (this.quizObject[this.quizIndex].instruction) {
          this.quizThreeIndex.push(this.quizIndex);
          this.showQuiz3Continue = true;
        } else {
          // this.st.newTimer('5sec', 1, true);
          this.quizThreeIndex.push(this.quizIndex);
          // this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(null,
          //   null, null));
        }
      }
    }
    // after the title screen
    setTimeout(() => this.autoGoToNextSlide(i), this.gridMemoryTime);
  }

  /**
   * Ensures the practice title is hidden for the quiz.
   * @param i The index of the quiz/page.
   */
  hidePracticeTitle(i: any): void {
    setTimeout(() => {
      this.mySwiper.directiveRef.update();
    }, 1000);
    this.quizIndex = i;
    this.showGameTitle = false;
    this.showPracticeTitle = false;
    this.showHowTo = false;
    this.showPractice = true;
    this.updateProgress();

    if (!this.quizObject[this.quizIndex].answer) {
      // this.st.newTimer('5sec', 3, true);
      this.quizThreeIndex = [];
      this.quizThreeIndex.push(this.quizIndex);
      // this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(this.quizIndex,
      //   null, null));
    }

    setTimeout(() => this.autoGoToNextSlide(i), this.gridMemoryTime);
  }

  /**
   * Hides the result title.
   * @param i The index of the quiz.
   */
  hideResultTitle(i: any): void {
    this.quizIndex = i;
    this.showReadyTitle = false;
    this.showPractice = false;
    this.showPlay = false;
    const gameSetting = this.assessmentService.getGameLimit(this.quizObject[this.quizIndex].game_id);
    this.updateProgress();
    if(this.quizStartTime === 0) { this.quizStartTime = new Date().getTime()}

    if (this.isQuestionReal(this.quizIndex)) {
      if (this.quizObject[this.quizIndex].answer) {
        const d = new Date();
        this.quizStartTime = d.getTime();
        // if (gameSetting.questionTime !== 0) {
        //   this.st.newTimer('5sec', gameSetting.questionTime, true);
        //   this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(this.quizIndex,
        //     this.quizObject[this.quizIndex].answer, ''));
        // }
      } else {
        // if no answer retured we know its a grid for displaying
        // //window.alert('1');
        this.quizThreeIndex = [];
        this.quizThreeIndex.push(this.quizIndex);
        // this.st.newTimer('5sec', 1, true);
        // this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(this.quizIndex,
        //   null, null));
      }
    }

    setTimeout(() => this.autoGoToNextSlide(i), this.gridMemoryTime);
  }

  /**
   * Checks if the question is a practice or instruction question through NOR.
   * @param i The number of the quiz index.
   */
  isQuestionReal(i: number): boolean {
    return !(this.quizObject[i].practice || this.quizObject[i].instruction);
  }

  /**
   * Sets the quiz index.
   * @param i The number to set the quiz index to.
   */
  trackIndex(i: number): void {
    this.quizIndex = i;
  }

  /**
   * Goes to the next slide automatically for grid memory recall questions
   * because there is no continue button to click
   * @param i The number of the quiz index.
   */
  autoGoToNextSlide(i: number): void {
    // !showTitles so the timer doesn't start while they're open
    if (this.quizObject[i].category === 'Grid' && !this.showQuiz3Continue && 
    this.quizObject[i].answer === '' && !this.showReadyTitle && !this.showPracticeTitle) {
      setTimeout(() => this.goToNextSlide(i), this.gridMemoryTime);
    }
  }

  /**
   * Goes to the next slide.
   * @param i The number of the quiz index.
   */
  goToNextSlide(i: number): void {
    const x = document.getElementsByClassName('brain-body');
    x[0].scrollTo(0,0)
    const j = i + 1;

    this.updateProgress();
    this.showQuiz3Continue = true;
    this.isCorrect = true;

    // if the next question matches the description of a grid memory recall, dont show the continue button
    if (this.quizObject[j].category === 'Grid' && this.quizObject[j].coordinates !== '' && !this.quizObject[j].instruction) {
      this.showQuiz3Continue = false;
    }

    if (this.quizObject[j].answer !== '') {
      this.showQuiz3Continue = false;
    }

    // adds the practice title if instruction and practice are both true
    if (this.quizObject[i].instruction && this.quizObject[j].practice) {
      this.showPracticeTitle = true;
    }

    // if the current slider is not a quiz and the next one is, show the ready title
    if (!this.quizObject[this.quizIndex].quiz && this.quizObject[j].quiz) {
      this.showReadyTitle = true;
    }

    this.isCorrect = true;
    this.selectedCell = [];
    this.quizThreeAnswers = [];

    // happens after each time a grid coordinate is shown
    if (!this.quizObject[j].answer && !this.showReadyTitle && !this.showPracticeTitle) {
      //   this.st.newTimer('5sec', 1, true);
      this.quizThreeIndex.push(j);
      //   this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(null,
      //      null, null));
    }
    // after instruction continue and grid continue
    this.swiper.directiveRef.nextSlide();
    this.autoGoToNextSlide(j);
  }
  
  /**
   * Handles the user selecting a grid.
   * @param number The selected cell number.
   * @param cords The coordinates of the grid.
   * @param indexOfQuiz The number of the quiz index.
   */
  selectedGrid(number: number, cords: number, indexOfQuiz: number): void {
    if (this.quizObject[indexOfQuiz].answer) {
      if (this.quizThreeAnswers.length < this.quizThreeIndex.length || this.quizObject[indexOfQuiz].practice) {
        const ansInd = this.quizThreeAnswers.indexOf(number + 1);
        if (ansInd === -1) {
          this.quizThreeAnswers.push(number + 1);
        } else {
          this.quizThreeAnswers.splice(ansInd, 1);
        }
        this.selectedCell[number] = !this.selectedCell[number];
        if (this.isQuestionReal(this.quizIndex)) {
          const timeStopped = new Date();
          const reactionTime = timeStopped.getTime() - this.quizStartTime;
          this.squares_rt.push(reactionTime);
        }
      }
      if (this.quizThreeIndex.length === this.quizThreeAnswers.length) {
        this.checkGridAnswer(indexOfQuiz);
      }
    }
  }

  /**
   * Checks the grid answer.
   * @param index The number of the quiz index.
   */
  async checkGridAnswer(index: any) {
    const j = index + 1;
    this.updateProgress();
    // loop through the quiz objects that are grid displays
    this.quizThreeIndex.forEach((item, quizIndex) => {
      // if it is not a practice or an instruction question (if it is a real quiz question)
      if (this.isQuestionReal(item)) {
        // if the user answers the grid question correctly
        if (this.quizObject[item].coordinates === String(this.quizThreeAnswers[quizIndex])) {
          // add the points to the game score
          this.gameScore = this.gameScore + this.quizObject[item].points;
          // and increase the accuracy of the uer
          this.squares_accuracy.push(true);
        } else {
          if (!this.quizThreeAnswers[quizIndex]) {
            // this.squares_accuracy.push(0);
          }
          // reduce the accuracy of the user
          this.squares_accuracy.push(false);
        }
        this.questionCategory.push(this.quizObject[item].category);
        this.quizIds.push(this.quizObject[item].id);
        this.memoryLevels.push(this.quizThreeIndex.length);
      }
    });
    if (!this.isQuestionReal(index)) {
      if(this.quizThreeAnswers.length === 0) {
        this.isCorrect = false;
      }
      let ansStr = '';
      this.quizThreeAnswers.forEach(async (answer, ind) => {
        ansStr += `${answer}`
        if(ind === this.quizThreeAnswers.length - 1){
          if(ansStr === this.quizObject[index].answer){
            this.confetti();
            this.isCorrect = true;
          } else {
            this.isCorrect = false;
          }
        } else {
          ansStr += ';';
        }
      })
    }
    

    // if the user answered correctly
    if (this.isCorrect) {

      this.selectedCell = [];
      if (j < this.quizObject.length) {
        /*Check the quizObject if the next index is a new game display game header
        * save points for the game id then swipe
        */
        if (this.quizObject[index].game_id !== this.quizObject[j].game_id) {
          // Grab the game id for the next game so we can display the appropriate information
          this.gameId = this.quizObject[j].game_id;
          this.getGameById(this.gameId);
          this.goToNextSlide(j - 1);
          this.showGameTitle = true;
          this.showPracticeTitle = false;
        } else {
          if (this.quizObject[index].instruction && this.quizObject[j].practice) {
            this.showPracticeTitle = true;
          }
          if (!this.quizObject[index].quiz && this.quizObject[j].quiz) {
            this.showReadyTitle = true;
          }
          this.quizThreeIndex = [];
          this.quizThreeAnswers = [];
          // happens after we successfully complete a practice run
          if (!this.quizObject[j].answer && !this.showReadyTitle && !this.showPracticeTitle) {
            // this.st.newTimer('5sec', 1, true);
            // this.quizThreeIndex.push(j);
            // this.timerId = this.st.subscribe('5sec', () => this.checkAnswer(j,
            //   null, null));
          }
          if (this.isCorrect) {
            this.confettiBackground = true;
            await this.sleep(2000);
            this.confettiBackground = false;
            // after you get a grid right and fake? and correct
            this.goToNextSlide(j - 1);
          }

        }
      } else {
        // For the last game we need to store the score and show the finished page
        const assObj = {
          game_id: this.quizObject[this.quizIndex].game_id,//unneccessary probably
          game_score: this.gameScore, // a summation of squares and symmetry trues
          symmetry_accuracy: this.symmetry_accuracy, 
          squares_accuracy: this.squares_accuracy,
          category: this.questionCategory, 
          quiz_ids: this.quizIds, //unneccessary probably
          memory_level: this.memoryLevels, //doesn't seem to work
          squares_reaction_time: this.squares_rt,
          symm_reaction_time: this.symm_rt
        }
        // Make a call to store the points for the game here
        const mergedObject = assObj;
        this.assessmentObject.game_score = this.assessmentObject.game_score + assObj.game_score;
        this.assessmentObject.results_data.push(mergedObject);
        if (this.assessmentObject.results_data.length === 3) {
          this.assessmentObject.is_complete = true;
        } else {
          //window.alert('big problem with your assessment, contact support');
          throw new Error('should always have 3 result_datas')
        }
        this.assessmentService.saveAssessment(this.assessmentObject);
        this.isComplete = true;
        this.assessmentService.nextQuiz(4);
      }
    }
  }

  /**
   * Update the accuracy and count of the current level.
   * @param currentLevel The current level.
   * @param reactionTime The reaction time of the level from the scoreObject.
   * @param gameAccuracy The game accuracy of the level from the scoreObject.
   * @returns The updated current level.
   */
  private updateLevel(currentLevel, reactionTime) {
    if(!currentLevel) {
      return null;
    }
    currentLevel.timeToComplete = currentLevel.timeToComplete + Number(reactionTime);

    currentLevel.totalCount++;

    return currentLevel;
  }

  /**
   * Updates the progress percentage.
   */
  updateProgress(): void {
    const progress = (this.quizIndex+1) / this.quizObject.length;
    this.assessmentService.current_progress.next(progress);
  }

  /**
   * Sets the progress bar with the cobranding color.
   */
  setProgressColor(): void {
    //window.alert('2A');
    var sheet = document.styleSheets[0];
    this.homeService.getCobranding().subscribe(cobrand => {
      //window.alert('2B');
      sheet.insertRule(`ion-progress-bar{--progress-background:${cobrand.darkPrimary}}`);
      //window.alert('2C');
    });
  }

  createdCanvas = null;


  /**
   * requires special css see file for more details
   */
  confetti(): void {
    if(!this.createdCanvas) {
      this.createdCanvas = this.renderer2.createElement('canvas');
      this.renderer2.appendChild(this.elementRef.nativeElement, this.createdCanvas);
    }
    const myConfetti = confetti.create(this.createdCanvas, {
      resize: true // will fit all screen sizes
    });
 
    myConfetti({
      particleCount: 100,
      spread: 160
    });
  }
}
