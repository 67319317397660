import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Utilities } from '@common/utilities';
import moment from 'moment';

@Component({
  selector: 'app-bars-over-time',
  templateUrl: './bars-over-time.component.html',
  styleUrls: ['./bars-over-time.component.scss'],
})
export class BarsOverTimeComponent extends Utilities implements OnInit, OnChanges {

  @Input() datas = [] as {val, date}[];
  @Input() units = "";
  @Input() max = 10;
  @Input() min = 0;
  @Input() dateFormat = 'MM/DD/YY';
  gradient = 'xxx'

  blankData = {
    val: undefined, 
    date: "Not Reported",
  }

  // in the future map this to gradients
  @Input() keys = [
    {
      name: "Optimal", 
      color: "#3f851e",
      lt: 75
    },
    {
      name: "Okay", 
      color: "#f6b341",
      lt: 50
    },
    {
      name: "Not-optimal", 
      color: "#c94a43",
      lt: 25
    },
  ] as {
    name: string,
    color: string,
    lt: number
  }[];

  constructor() { super(); }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.datas.length > 0) {
      this.format();
    }
  }

  format() {
    this.formatDates();
    this.moveCircles();
    while(this.datas.length < 5) {
      this.datas.push(this.blankData);
    }
  }

  /**
   * likely unneeded but here for now
   * @returns an array of promises for when all blanks have been removed
   */
  async replaceBlanks() {
    return this.datas.map(async (data, index) => {
      if(data.val) {
        return true;
      } else {
        // rewrite this part
        delete this.datas[index];
        return false;
      }
    });
  }


  formatDates() {
    this.datas.map((data,index) => {
      if(data.date === this.blankData.date) {return;}
      if(!data.date) { data.date = 'Invalid Date'; return}
      data.date = moment(data.date).format(this.dateFormat);
    })
  }

  moveCircles() {
    const circHeight = 50;
    const recHeight = 200;
    const range = this.max - this.min;
    const startingPoint = -(circHeight/2);
    const endPoint = recHeight + startingPoint;
    this.datas.map(async (data,index) => {
      const percent = data.val/range;
      data['top'] = (endPoint - (percent*recHeight)) +'px';
      const colors = await this.findColors(percent)
      data['border_color'] = "solid 4px " + this.rgbToHex(this.pickHex(colors[0], colors[1], percent));
    })
  }

  findColors(percent) {
    let color1;
    let color2;
    return new Promise((res,rej) => {
      this.keys.forEach((key, index) => {
        if(key.lt < percent * 100) {
          color1 = this.hexToRgb(key.color);
          if(index === 0) {
            color2 = this.hexToRgb(key.color);
          } else {
            color2 = this.hexToRgb(this.keys[index-1].color);
          }
          res([color2,color1]);
        } else if (index === this.keys.length-1) {
          const color = this.hexToRgb(key.color);
          res([color, color])
        }
      })
    })
  }

}
