import { Component, OnInit } from '@angular/core';
import { LevelingService } from '@services/leveling.service';

@Component({
  selector: 'app-xp-earned',
  templateUrl: './xp-earned.component.html',
  styleUrls: ['./xp-earned.component.scss'],
})
export class XpEarnedModalComponent implements OnInit {

  base = 0;
  completion = 0;
  sum = 0;
  multiplier = 0;
  total_xp = 0;

  constructor(
    private lvlService: LevelingService
  ) { }

  ngOnInit() {
    this.lvlService.summary.subscribe(xpVals => {
      this.total_xp = xpVals.totalPoints;
      this.base = xpVals.baseValue;
      this.completion = xpVals.completionBonus;
      this.multiplier = xpVals.performanceMetric;
      this.sum = this.base + this.completion;
    })
  }

  closeModal() {
    this.lvlService.modalClosed.emit();
  }

}
