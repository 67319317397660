import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-title',
  templateUrl: './date-title.component.html',
  styleUrls: ['./date-title.component.scss'],
})
export class DateTitleComponent implements OnInit {

  @Input() text: 'string';

  constructor() { }

  ngOnInit() {}

}
