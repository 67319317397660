import { Component, Input, OnInit } from '@angular/core';
import { BrainGameControllerService } from '@services/brain-game-controller.service';
import { Utilities } from '@common/utilities';
import { YahootieResultsReport } from '@models/yahootie-results-report.model';
import { IGameConfiguration } from '@interfacegame-configuration.interface';
import { GameService } from '@services/game.service';
import { any } from 'Cypress/types/bluebird';

@Component({
  selector: 'app-brain-game-results',
  templateUrl: './brain-game-results.component.html',
  styleUrls: ['./brain-game-results.component.scss'],
})
export class BrainGameResultsComponent extends Utilities implements OnInit {

  @Input() isYahootie?: boolean = false;
  
  roundNum: number;

  firstScore: {
    name: string,
    value: string
  }

  secondScore: {
    name: string,
    value: string
  };
  prior = {
    trivia: '0',
    game: '0',
    total: '0'
  };

  roundScore: string;
  overallScore: string;

  constructor(
    private bgController: BrainGameControllerService,
    private gameService: GameService,
  ) { super(); }

  ngOnInit() {
    this.isYahootie ? this.setYahootieResults() : this.setBrainGameResults();
  }

  /**
   * Sets the trivia and Brain Game score, round number, round score, 
   * and overall score for Brain Game.
   */
  setBrainGameResults(): void {
    const currentSummary = this.bgController.response;

    this.firstScore = {
      name: 'Trivia',
      value: currentSummary.round_trivia_score.toFixed(2)
    };

    this.secondScore = {
      name: this.bgController.game,
      value: currentSummary.round_game_score.toFixed(2)
    };

    this.roundNum = this.bgController.roundNum;

    if(this.roundNum !== 0) {
      const trivia = (currentSummary.trivia_score - currentSummary.round_trivia_score).toFixed(2);
      const game   = (currentSummary.game_score   - currentSummary.round_game_score).toFixed(2);
      const total  = (parseFloat(trivia) + parseFloat(game)).toFixed(2);
      this.prior   = {trivia, game, total}
    }
    this.roundScore = (currentSummary.round_game_score + currentSummary.round_trivia_score).toFixed(2);
    this.overallScore = currentSummary.overall_score.toFixed(2);
  }

  /**
   * Sets the question and Yahootie score, round number, round score, 
   * and overall score for Yahootie.
   */
  setYahootieResults(): void {
    const currentSummary = this.gameService.yahootieResults
    this.firstScore = {
      name: 'Question',
      value: this.gameService.yahootieResults.round_question_score.toFixed(2)
    };

    this.secondScore = {
      name: 'Yahootie',
      value: this.gameService.yahootieResults.round_yahootie_score.toFixed(2)
    };

    this.roundNum = this.gameService.yahootieResults.currentRound;
    if(this.roundNum !== 0) {
      const trivia = (currentSummary.question_score - currentSummary.round_question_score).toFixed(2);
      const game   = (currentSummary.yahootie_score - currentSummary.round_yahootie_score).toFixed(2);
      const total  = (parseFloat(trivia) + parseFloat(game)).toFixed(2);
      this.prior   = {trivia, game, total}
    }
    this.roundScore = (this.gameService.yahootieResults.round_question_score + this.gameService.yahootieResults.round_yahootie_score).toFixed(2);
    this.overallScore = this.gameService.yahootieResults.overall_score.toFixed(2);
  }
}
