<div class="instruction-container">
  <!-- content -->
  <div class="container {{ containerClass }} justify-content-center" style='padding: 0'>
    <div class="main-title text-center">
      <span class="margin-top-15" *ngIf="!preGameTitle">INSTRUCTIONS</span>
      <p style="margin: 0" *ngIf="preGameTitle">{{ preGameTitle }}</p>
    </div>

    <div class="{{ itemClass }}">
      <div style="height: 30vh">
        <div style='text-align: center; margin: auto; font-size: 1.5rem;' class="instruction" [innerHTML]="instruction.instruction"></div>
        <img 
          *ngIf="instruction.url" 
          [src]="instruction.url"
          alt=""
          class="instruction-img">
      </div>  
    </div>

  </div>
  <!-- content /--> 

  <div class='button-cont'>
    <app-button data-cy="next-bg-instructions" text="Next" (click)="moveToNext()"></app-button>
    <app-button data-cy="prev-bg-instructions" disabled={{disablePrev}} text="Previous" (click)="moveToPrev()"></app-button>
  </div>
</div>

