import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { NavController } from '@ionic/angular';
import { AuthenticationService } from '../../../../services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() headerTitle?: string;
  @Input() height?: string;
  @Input() showBack = false;
  @Input() headerClass = 'background-brainevolved';
  @Input() appTitle?: string;
  @Input() logoClass = 'logo';

  showNothing: boolean;
  onLogin: boolean;

  constructor(
    public navCtrl: NavController,
    private authService: AuthenticationService,
    public router: Router
  ) { }

  ngOnInit() {
    this.onLogin = this.router.url.indexOf('login') > -1;
  }

  /**
   * Goes back to the previous page.
   */
  goBack(): void {
    this.navCtrl.back();
  }

  /**
   * Logs the user out of the application through the AuthenticationService.
   */
  logout(): void {
    this.authService.signOut();
  }
}
