import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { DataTrackingService } from '@services/data-tracking.service';

import {
  SwiperConfigInterface,
  SwiperPaginationInterface,
  SwiperComponent
} from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.scss'],
})
export class InstructionComponent implements OnInit, AfterViewInit {
  @ViewChild(SwiperComponent, { static: true }) swiper?: SwiperComponent;

  @Input()
  instructions = [];

  @Input()
  preGameTitle: string;

  @Input()
  gameClass = 'secondary';

  @Input()
  containerClass?: string;

  @Input()
  itemClass?: string;

  @Input()
  buttonClass = 'be-button-primary';

  @Output()
  instructionEnd: EventEmitter<any> = new EventEmitter();

  swiperConfig: SwiperConfigInterface = {};
  swiperIndex = 0;

  disablePrev = true;
  showTitle: boolean;

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination'
  };
  
  constructor(
    // private dataService: DataTrackingService
  ) { }

  ngOnInit() {
    // this.dataService.startTimer('instructionTime');
  }

  ngAfterViewInit() {
    // swiper configuration
    this.swiperConfig = {
      slidesPerView: 1,
      pagination: this.pagination,
      loop: false,
      effect: 'slide'
    };
  }

  /**
   * Stores the index of the current question in the swiper 
   * to determine when the end has been reached.
   */
  onIndexChange(index: number): void {
    this.swiperIndex = index;
  }

  /**
   * Emits that the last of the instructions are displayed.
   */
  onInstructionEnded(): void {
    // this.dataService.endTimer('instructionTime', true);
    this.instructionEnd.emit();
  }

  /**
   * Moves to the next slide if we have not reached the end of the instructions. 
   * Otherwise emit an event so the parent component can take action.
   */
  moveToNext(): void {
    if (this.swiperIndex + 1 !== this.instructions.length) {
      // move to the next slide
      this.swiper.directiveRef.nextSlide();
      this.disablePrev = false;
    } else {
      this.onInstructionEnded();
    }
  }

  /**
   * Moves to the prev slide if we have not reached the end
   * of the instructions, otherwise disable the previous button.
   */
  moveToPrev(): void {
    if (this.swiperIndex > 0) {
      // move to the prev slide
      this.swiper.directiveRef.prevSlide();
      this.disablePrev = this.swiperIndex === 0;
    }
  }
}
