import { Component, OnInit, OnDestroy } from '@angular/core';

import { NavController, AlertController, ToastController, ModalController } from '@ionic/angular';
import { Utilities } from '@app/common/utilities';
import { Answer } from '@app/common/models/answer.model';
import { AdminService } from '@app/services/admin.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-answer-list',
  templateUrl: './answer-list.component.html',
  styleUrls: ['./answer-list.component.scss'],
})
export class AnswerListComponent extends Utilities implements OnInit, OnDestroy {
  errorMessage: string;
  answers: Answer[];
  lastAnswer: Answer;
  answerId: string;
  subscription = new Subscription();

  constructor(
    private adminService: AdminService,
    private modalCtrl: ModalController,
    public navCtrl: NavController,
    public alertController: AlertController,
    public toastController: ToastController
  ) { super() ; }

  ngOnInit() {
    this.getAnswers();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Gets all the answers from the database.
   */
  getAnswers(): void {
    const ansSub = this.adminService.getAnswers().subscribe((answerData: Answer[]) => {
      // clear the array
      this.answers = [];
      this.answers = this.convertDataTimestamps(answerData);
    });
    this.subscription.add(ansSub);
  }

  /**
   * Deletes an answer based on the id.
   * @param answer The answer to delete.
   */
  onDelete(answer?: Answer) {
    this.answerId = answer ? answer.id : null;

    this.presentAlertConfirm(
      'This answer might be linked to an event. <br />' +
      'Are you sure you want to remove it?',
      'Confirm Remove!'
    );
  }

  /**
   * Navigates to the answer form.
   * @param answer The answer to dismiss.
   */
  onAdd(answer): void {
    this.modalCtrl.dismiss(answer);
  }

  /**
   * Gets the next set of answers and appends it to the array.
   * @param infiniteScroll Ionic's infinite scroll object.
   */
  doInfinite(infiniteScroll: any): void {
    const infSub = this.adminService.getNextSetOfAnswers().subscribe((answerData: Answer[]) => {
      this.convertDataTimestamps(answerData).forEach(data => {
        this.answers.push(data);
      });

      infiniteScroll.target.complete();
    });
    this.subscription.add(infSub);
  }

}
