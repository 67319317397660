import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';
import { ModalController } from '@ionic/angular';
import { MoodEnum } from '@enums/MoodEnum'
import { MoodFactorComponent } from '../../modal/factor/mood/mood-factor.component';

@Component({
  selector: 'app-mood-average',
  templateUrl: './mood-average.component.html',
  styleUrls: ['./mood-average.component.scss'],
})
export class MoodAverageComponent extends Utilities implements OnInit, OnChanges {

  @Input() mood: MoodEnum = MoodEnum.VerySad;
  
  currentMoodSetting = {
    mood: MoodEnum.VerySad,
    text: 'Awful',
    background: 'linear-gradient(to bottom, #ffffff, rgba(186, 65, 58, 0.6) 99%)',
    face: 'verysad'
  };
  
  moodImageLocation: string = '';
  week: string;

  moodSettings = [
    {
      mood: MoodEnum.VerySad,
      text: 'Awful',
      background: 'linear-gradient(to bottom, #ffffff, rgba(186, 65, 58, 0.6) 99%)',
      face: 'verysad'
    },
    {
      mood: MoodEnum.Sad,
      text: 'Down',
      background: 'linear-gradient(to bottom, #ffffff, rgba(231, 151, 98, 0.6) 99%)',
      face: 'sad'
    },
    {
      mood: MoodEnum.Meh,
      text: 'Okay',
      background: 'linear-gradient(to bottom, #ffffff, rgba(244, 206, 115, 0.6))',
      face: 'meh'
    },
    {
      mood: MoodEnum.Happy,
      text: 'Good',
      background: 'linear-gradient(to bottom, #ffffff, rgba(156, 200, 74, 0.6))',
      face: 'smile'
    },
    {
      mood: MoodEnum.VeryHappy,
      text: 'Great',
      background: 'linear-gradient(to bottom, #ffffff, rgba(98, 136, 70, 0.6))',
      face: 'veryhappy'
    },
  ];

  constructor(
    public modalController: ModalController
  ) { super(); }

  ngOnInit() {
    this.setCurMood();
  }

  ngOnChanges() {
    this.setCurMood();
  }

  /**
   * Sets the current mood settings based on the current mood
   */
  setCurMood(): void {
    this.currentMoodSetting = this.moodSettings.find(moodSetting => this.mood == moodSetting.mood);
    this.moodImageLocation = '../../../../../assets/images/mood/' + this.currentMoodSetting.face + '.png';
  }

  /**
   * Presents the Mood Factor modal
   */
  showMoodFactor(): void {
    this.presentModal(MoodFactorComponent, 'factor-modal', {
      mood: this.mood,
      week: this.week
    });
  }

  /**
   * Sets the week text based on the date range component
   * @param event The week in a string format
   */
  setWeek(event: string): void {
    this.week = event;
  }
}
