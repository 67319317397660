import { Component, Input, OnInit } from '@angular/core';
import { SleepFlyoutComponent } from '../../modal/flyout/sleep/sleep-flyout.component';
import { Utilities } from '@common/utilities';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-sleep-average',
  templateUrl: './sleep-average.component.html',
  styleUrls: ['./sleep-average.component.scss'],
})
export class SleepAverageComponent extends Utilities implements OnInit {

  @Input() hours: number;

  @Input() week: any;

  constructor(
    public modalController: ModalController
  ) { super(); }

  ngOnInit() { }

  /**
   * Presents the Sleep flyout
   */
  showSleepFlyout(): void {
    this.presentModal(SleepFlyoutComponent, 'flyout-modal', {
      hours: this.hours,
      week: this.week
    });
  }

    /**
   * Sets the week based on the date range component
   * @param event The week in a date range format
   */
  setWeek(event: any) {
    this.week = event;
  }
}
