<div class="modal_box">
  <p class="modal_box-para">You already have an assessment started.
    <br>
    Would you like to return to it?
  </p>
  
  <div class='modal_box-btns'>
    <app-button class="return_btn" text='Return' (click)='clicked("return")'></app-button>
    <app-button class="new_btn" text='New' (click)='clicked("new")'></app-button>
  </div>
</div>