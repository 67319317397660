import { Component, OnDestroy, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';
import { LevelingService } from '@services/leveling.service';
import { Subscriber } from 'rxjs';
import { XpEarnedModalComponent } from '../helpers/xp-earned/xp-earned.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-leveling-bar',
  templateUrl: './leveling-bar.component.html',
  styleUrls: ['./leveling-bar.component.scss'],
})
export class LevelingBarComponent extends Utilities implements OnInit, OnDestroy {

  //needs to be a percentage of the maxXp
  currentXp = 0;
  maxXp = 100;

  currentLvl = 0;
  maxLvl = 1;

  displayXp = 0;
  levelAndXp;
  subscription = new Subscriber();

  constructor(
    private levelService: LevelingService,
    public modalController: ModalController
  ) { super(); }

  ngOnInit() {
    this.levelService.modalClosed.subscribe(x => {
      if(this.modalx){
        this.closedModal();
      }
      
    })
    let init = 0;
    let init2 = 0;
    this.subscription.add(this.levelService.playerLevelAndXp.subscribe(lvlNXP => {
      if(init === 0){
        init++;
        this.maxXp = this.levelService.getXpRequired(lvlNXP.level);
        this.currentLvl = lvlNXP.level;
        this.maxLvl = lvlNXP.level+1;
        this.currentXp = (lvlNXP.currentXp / this.maxXp) * 100;
      } else {
        this.levelAndXp = lvlNXP;
        this.leveling();
      }
    }));

    this.subscription.add(this.levelService.summary.subscribe(summ => {
      this.displayXp = summ.totalPoints;
    }))

    this.subscription.add(this.levelService.timeToAddXp.subscribe(xp => {
      if(init2 === 0){
        init2++;
        this.leveling();
      } else {
        this.leveling();
      }
      // add xp function here
      
    }));
    // this.addXpSlowly(50);
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

  async leveling() {
    let levelsUp = this.levelService.levelsUp;
    let level = this.levelAndXp.level - levelsUp;
    while(levelsUp > 0){
      level++;
      // fill leveling bar
      this.currentXp = 100;

      // wait
      await this.sleep(1500);

      // levelUp Process + empty bar
      this.levelService.levelUp.emit();
      this.currentXp = 0;
      this.maxXp = this.levelService.getXpRequired(level);
      this.currentLvl = level;
      this.maxLvl = level +1;
      await this.sleep(4000);
      levelsUp--;
      
    }
    await this.sleep(1500);
    this.maxXp = this.levelService.getXpRequired(level);
    this.currentXp = (this.levelAndXp.currentXp / this.maxXp) * 100;
  }

  setXpZero(){
    this.currentXp = 0;
  }

  modalx;
  async seeHelper() {
    this.modalx = await this.modalController.create({
      component: XpEarnedModalComponent,
      cssClass: 'xpEarned-modal',
      showBackdrop: true,
    });

    this.modalx.present();
  }

  closedModal() {
    this.modalx.dismiss();
  }

}
