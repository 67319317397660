import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-current-score-summary',
  templateUrl: './current-score-summary.component.html',
  styleUrls: ['./current-score-summary.component.scss'],
})
export class CurrentScoreSummaryComponent implements OnInit, OnChanges {
  
  @Input() overallScore = 0;
  overallScoreText = ''

  constructor() { }

  ngOnInit() {
    this.overallScoreConfig(this.overallScore)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.overallScoreConfig(this.overallScore)
  }

  overallScoreConfig(score: number) {
    if (score < 5) {
      this.overallScoreText = 'were likely experiencing daily challenges';
    } else if (score < 8) {
      this.overallScoreText = 'were likely noticing that things are not as easy as they used to be';
    } else {
      this.overallScoreText = 'have not noticed large changes in your ability to complete daily tasks from a cognitive perspective';
    }
  }

}
