import { Component, OnInit, AfterViewInit, ViewChild, Input, OnDestroy, Renderer2, ElementRef } from '@angular/core';
import { NavController, ModalController, AlertController, ToastController, LoadingController, IonSlides } from '@ionic/angular';
import * as _ from 'underscore';
import { Subscription } from 'rxjs';
import { GameService } from '@services/game.service';
import { EventBusService } from '@services/event-bus.service';
import { IPlayer } from '@common/interface/players.interface';
import { IQuestion } from '@common/interface/question.interface';
import { IResult } from '@common/interface/result.interface';
import { IGameConfiguration } from '@common/interface/game-configuration.interface';
import { YahootieResultsReport } from '@models/yahootie-results-report.model';
import { YahootieStatusEnum } from '@enums/YahootieStatusEnum';
import { PlayersComponent } from '../players/players.component';
import { Utilities } from '@common/utilities';
import { yahootiePoint } from '@app/app.constants.config';
import { AuthenticationService } from '@services/authentication.service';
import { DataTrackingService } from '@services/data-tracking.service';
import { AdminService } from '@services/admin.service';
import { DB_CONFIG } from '@app/app.firebase.config';
import * as confetti from 'canvas-confetti';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent extends Utilities implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(IonSlides, { static: true }) slides: IonSlides;
  @Input() inpQuestions: IQuestion[];
  content: IQuestion[];
  
  slideOpts = {
    initialSlide: 0,
    speed: 400
  };
  swiperIndex = 0;
  disablePrev = true;
  yahootieFlag = false;
  yahootieStatus: YahootieStatusEnum = YahootieStatusEnum.UnAnswered;
  answerCorrect = false;
  result = {} as IResult;
  config = {} as IGameConfiguration;
  yahootieResults = {} as YahootieResultsReport;
  questionData = [];
  quesCount = 0;
  subscription = new Subscription();
  alphabet: string[]  = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  initialContent: IQuestion[];
  players: IPlayer[];
  questions: IQuestion[];
  usedQuestions = [];
  nextQuestionsData: IQuestion[];
  playersScores = [];
  selectedPlayers = [];
  points: number;
  questionId: string;
  prevQuestionId = '';
  currentItemDisplayed: string;
  question: any;
  authUser: any;
  questionTypeTimer = '';
  yahootieStatusEnum = YahootieStatusEnum;
  yahootieDisabled: boolean = false;

  constructor(
    private gameService: GameService,
    public navCtrl: NavController,
    public modalController: ModalController,
    public alertController: AlertController,
    public toastController: ToastController,
    private eventBusService: EventBusService,
    private adminService: AdminService,
    public loadingCtrl: LoadingController,
    private authService: AuthenticationService,
    private dataService: DataTrackingService,
    private renderer2: Renderer2,
    private elementRef: ElementRef
  ) { super(navCtrl); }

  ngOnInit() {
    this.content = this.randomizeArray(this.inpQuestions); // randomizes the array that we're given from the parent GamePage component
    this.initialContent = this.inpQuestions;
    this.subscribeToEvents();
    this.slides.lockSwipes(true);
    this.getAuthUser();
    this.isYahootie(this.quesCount).then((res: boolean) => {
      this.yahootieFlag = res;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.eventBusService.onPageLoaded.emit();
    }, 1000);

    this.subscription.add(
      this.eventBusService.onQuestionTimerExpired.subscribe(() => {
        this.gameService.endTimers(this.questionTypeTimer);
        this.eventBusService.onQuestionsFinished.emit();
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Gets the authenticated user from the AuthenticationService.
   */
  getAuthUser(): void {
    this.authService.userSubject.subscribe((authUser: any) => {
      if (authUser) {
        this.authUser = authUser;
      }
    });
  }

  /**
   * Subscribes to the game configuration and the Yahootie results report.
   */
  subscribeToEvents(): void {
    
    // get game config info
    this.subscription.add(
      this.gameService.gameConfigSubject
        .subscribe((config: IGameConfiguration) => {
          this.config = config;
        })
    );

    this.gameService.yahootieSubject
    .subscribe((res: YahootieResultsReport) => {
      this.yahootieResults = res;
    });
    
    // updates the score
    this.subscription.add(this.eventBusService.selectedPlayer
      .subscribe(playerInfo => {
        this.result = {} as IResult; // re-initialize results
        if(this.yahootieResults.questionsAsked.length === 0 || 
          this.yahootieResults.questionsAsked[this.yahootieResults.questionsAsked.length-1].id !== this.question.id) {
          this.yahootieResults.questionsAsked.push(this.question);
          this.gameService.dataTrackerNeeds.questionNum++;
        }
        if(this.answerCorrect) {
          // correct question could get double counted when pressing yahootie after
          if(this.gameService.dataTrackerNeeds.correctQuestion < this.gameService.dataTrackerNeeds.questionNum){
            this.gameService.dataTrackerNeeds.correctQuestion++;
          }
          if(this.yahootieStatus === YahootieStatusEnum.InCorrect) {
            this.gameService.dataTrackerNeeds.wrongYahootie++;
          } else if(this.yahootieStatus === YahootieStatusEnum.Correct) {
            this.gameService.dataTrackerNeeds.correctYahootie++;
          } else {
            if(this.yahootieFlag){
              this.gameService.dataTrackerNeeds.missedYahootie++;
            }
          }
        }

        
        // iterate the list and check id against current player id
        // if its the current player check if the answer is correct
        // and assign a score
        _.each(this.playersScores, (player: any, index: number) => {
          if (player.id === playerInfo.id) {

            // Setup Results
            this.result.questionId      = this.questionId;
            this.result.user_id         = player.id;
            this.result.sessionId       = this.config.id;
            this.result.correct         = this.answerCorrect;
            this.result.points          = this.points;
            this.result.yahootie        = this.yahootieStatus;
            this.result.prevQuestionId  = this.prevQuestionId;

            if (this.answerCorrect || this.yahootieStatus === YahootieStatusEnum.Correct) {
              this.playersScores[index].score = this.playersScores[index].score + this.points;
              this.result.points = this.playersScores[index].score;
              this.gameService.saveScores(this.playersScores);
            }
          }
        });

        _.each(this.yahootieResults.players, (player: any, index: number) => {
          if (player.id === playerInfo.id) {
            if (this.yahootieResults.players[index].questions.length === 0) {
              if(!this.question.type || !this.question.id){
                console.error(this.question);
              }
              this.yahootieResults.players[index].questions.push({
                questionId : this.question.id, 
                category : this.question.type,
                accuracy : [this.answerCorrect]
              });
            } else if(this.yahootieResults.players[index].questions[this.yahootieResults.players[index].questions.length -1].questionId === this.question.id){
              this.yahootieResults.players[index].questions[this.yahootieResults.players[index].questions.length -1].accuracy.push(this.answerCorrect);
            } else {
              if(!this.question.type || !this.question.id){
                console.error(this.question);
              }
              this.yahootieResults.players[index].questions.push({
                questionId : this.question.id, 
                category : this.question.type,
                accuracy : [this.answerCorrect]
              });
            }
            
          }
        });
        this.gameService.saveYahootieResults(this.yahootieResults);
      })
    );
  }
  
  /**
   * Handles the answer selection.
   * @param answer The selected answer to the question.
   * @param question The question.
   * @param indexOfQuestion The index of the question.
   */
  onAnswerSelect(answer: string, question: any): void {
    this.question = question;
    this.points = question.points;
    this.questionId = question.id;
    this.answerCorrect = (question.answer === answer) ? true : false;
    this.playersScores = this.gameService.getPlayerScores();

    this.eventBusService.pauseTimer.emit();
    // singleplayer mode
    if (this.playersScores.length === 1) {
      this.eventBusService.selectedPlayer.emit(this.authUser);
    } else {
      this.presentModal(PlayersComponent);
    }

    if (this.answerCorrect) {
      this.gameService.endTimers(this.questionTypeTimer);
      this.gameService.yahootieResults.overall_score += question.points;
      this.gameService.yahootieResults.round_question_score += question.points;
      this.gameService.yahootieResults.question_score += question.points;
      this.gameService.saveYahootieResults(this.gameService.yahootieResults);
      this.yahootieStatus = YahootieStatusEnum.UnAnswered;
      this.yahootieDisabled = false;

      // Execute Logic
      this.presentToast('Answer correct!!', null, 750);

      setTimeout(() => {
        this.moveToNext(true);
        this.eventBusService.resumeTimer.emit();
      }, 500);
      
    } 
    else {
      this.presentToast('Answer incorrect!!', 'be-fail', 750);
      this.eventBusService.resumeTimer.emit();
    }
  }

  /**
   * Moves to the next slide and sets the boolean used to enable/disable the prev button.
   */
  moveToNext(ansCorrect: boolean): void {
    // adds more questions, when at the end
    if (this.quesCount === this.content.length - 2) {
      this.content.push(...this.randomizeArray(this.initialContent));
    }
    this.quesCount += 1;
    if(ansCorrect) {
    } else {
      this.gameService.dataTrackerNeeds.skips++;
    }
    this.isYahootie(this.quesCount).then((res: boolean) => {
      this.yahootieFlag = res;
    });
    this.slides.getActiveIndex().then(index => {
      this.swiperIndex = index;
      this.slides.lockSwipes(false);
      this.slides.slideNext();
      this.slides.lockSwipes(true);
    });
  }

  /**
   * Randomizes the questions array and returns an array with only the amount of questions needed.
   * @param array The questions array from the QuestionService.
   */
  randomizeArray(array: any[]): any[] {
    const randomArr = [];
    
    let breaker = false;
    const questionLen = 8;
    while (randomArr.length < questionLen) {
      // when we reach all questions used, just reset so we get more
      if (this.usedQuestions.length === this.inpQuestions.length) {
        this.usedQuestions = [];
      }
      breaker = false;
      const ranNum = Math.floor(Math.random() * array.length);
      
      // check if that question has been used
      for (const used of this.usedQuestions) {
        if (ranNum === used) {
          breaker = true;
          break;
        }
      }
      if (breaker) {
        continue;
      }

      randomArr.push(array[ranNum]);
      this.usedQuestions.push(ranNum);
    }
    return randomArr;
  }

  /**
   * Get an array of the selected players.
   */
  getSelectedPlayers(): void {
    // TODO: Check If behaviour subject exist for score if not use call and get players and save it
    const players = this.gameService.getSelectedPlayers();
    const sessionId = this.config.id;
    const score = 0;
    
    // iterate the filtered data and set isChecked to false
    _.each(players, (player: IPlayer, index: number) => {
      // create a new object so that when
      // we update isChecked only this object is updated
      this.playersScores.push({ sessionId, ...player, score});
    });
  }

  isYahootie(questIndex: number) {
    return new Promise((res,rej) => {
      if(questIndex === 0) {
        res(false);
      }
      
      const preQuestion   = this.content[questIndex - 1];
      const question      = this.content[questIndex];
      this.questionTypeTimer = question.type + 'Time';
      if(this.questionTypeTimer == 'Simple MathTime') {
        this.questionTypeTimer = 'Simple_MathTime';
      } else if (this.questionTypeTimer == 'Complex MathTime') {
        this.questionTypeTimer = 'Complex_MathTime';
      }
      this.questionId     = question.id;
      this.prevQuestionId = preQuestion.id;
      this.dataService.startTimer(this.questionTypeTimer);

      // check for simple and complex math as they are confusing if not counted as the same
      if(
        (preQuestion.type === 'Simple Math' && question.type === 'Complex Math') ||
        (preQuestion.type === 'Complex Math' && question.type === 'Simple Math') ||
        (preQuestion.type === question.type)
        ){
        
        res(true);
      } else {
        res(false);
      }
    })
  }

  /**
   * Sets the Yahootie Flag based on details in the event.
   * @param event Details about the checkbox.
   * @param questIndex The index of question in the question array.
   */
  yahootieClicked(event: any): void {
    this.eventBusService.pauseTimer.emit();
    this.yahootieStatus = YahootieStatusEnum.UnAnswered;
    
    if (this.yahootieFlag) {
      // this.gameService.dataTrackerNeeds.correctYahootie++;
      this.points         = yahootiePoint;
      this.yahootieStatus = YahootieStatusEnum.Correct;
      // this.answerCorrect  = false;
      this.gameService.yahootieResults.round_yahootie_score += yahootiePoint;
      this.gameService.yahootieResults.yahootie_score += yahootiePoint;
      this.gameService.yahootieResults.overall_score += yahootiePoint;
      this.gameService.saveYahootieResults(this.gameService.yahootieResults);
      this.presentToast('Yahootie!', null, 750);
      this.confetti();
    } 
    else {
      // this.gameService.dataTrackerNeeds.wrongYahootie++;
      this.shakeYahootieButton();
      this.points         = 0;
      this.yahootieStatus = YahootieStatusEnum.InCorrect;
    }

    this.yahootieDisabled = true;
    this.playersScores = this.gameService.getPlayerScores();
    // singleplayer mode
    if (this.playersScores.length === 1) {
      this.eventBusService.selectedPlayer.emit(this.authUser);
    } else {
      this.presentModal(PlayersComponent);
    }    
  }

  createdCanvas = null;

  /**
   * requires special css see file for more details
   */
  confetti(): void {
    if(!this.createdCanvas) {
      this.createdCanvas = this.renderer2.createElement('canvas');
      this.renderer2.appendChild(this.elementRef.nativeElement, this.createdCanvas);
    }
    const myConfetti = confetti.create(this.createdCanvas, {
      resize: true // will fit all screen sizes
    });
 
    myConfetti({
      particleCount: 100,
      spread: 160
    });
  }

  async shakeYahootieButton(){
    const wait = (delay, ...args) => new Promise(resolve => setTimeout(resolve, delay, ...args));
    document.getElementById('yahootieCol').style.animation = 'shake 0.5s';
    await wait(1000);
    document.getElementById('yahootieCol').style.animation = '';
    return;
  }
}
