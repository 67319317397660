import { Component, Input, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';
import { AdminService } from '@services/admin.service';
import { DB_CONFIG } from '@app/app.firebase.config';
import { take } from 'rxjs/operators';
import { OutsideTraining } from '@interfaceoutside_training.interface';
import { OutsideTrainingService } from '@services/outside-training.service';

@Component({
  selector: 'app-remove-outside-training',
  templateUrl: './remove-outside-training.component.html',
  styleUrls: ['./remove-outside-training.component.scss'],
})
export class RemoveOutsideTrainingComponent extends Utilities implements OnInit {

  @Input() outsideTrainings: OutsideTraining[] = [];

  categorySettings = this.trainingService.categorySettings;
  errorMsg: string;
  newOutsideTrainings: OutsideTraining[];
  outsideTrainingForDeletion: OutsideTraining[] = [];
  outsideTrainingForUpdate: OutsideTraining[] = [];

  constructor(
    private adminService: AdminService,
    private trainingService: OutsideTrainingService
  ) { super(); }

  ngOnInit() {
    this.copyOutsideTrainings();
  }

  /**
   * Copys the outside training into a new variable because if the user exits 
   * the modal without saving the changes should not be saved
   */
  copyOutsideTrainings(): void {
    this.newOutsideTrainings = JSON.parse(JSON.stringify(this.outsideTrainings));
  }

  /**
   * Modifies the category of the outside training selected, clears any 
   * error message and adds the outside training to the update array
   * @param outsideTraining the index of the selected outside training to be modified
   * @param category the index of the selected category
   */
  cellClicked(outsideTraining: number, category: number): void {
    const currentCell = this.newOutsideTrainings[outsideTraining].categories[category];
    const trainingName = this.newOutsideTrainings[outsideTraining];
    currentCell.selected = !currentCell.selected;

    if (this.outsideTrainingForUpdate.indexOf(trainingName) == -1) {
      this.outsideTrainingForUpdate.push(trainingName);
    }

    this.clearErrorMsg();
  }

  /**
   * Removes the outside training from the copied array and adds the outside training to 
   * the delete array
   * @param outsideTraining the index of the outside training selected to be deleted 
   */
  deleteOutsideTraining(outsideTraining: number): void {
    const trainingName = this.newOutsideTrainings[outsideTraining];
    this.outsideTrainingForDeletion.push(trainingName);

    this.newOutsideTrainings.splice(outsideTraining, 1);
    this.clearErrorMsg();
  }

  /**
   * Pushes the changes onto the outside trainings array, deletes and updates the outside 
   * trainings in the respective arrays from the database if there are no errors in the 
   * new outside training array
   */
  submitOutsideTraining(): void {
    if (!this.checkForErrors()) {
      this.outsideTrainings.splice(0, this.outsideTrainings.length);

      this.newOutsideTrainings.forEach(newOutsideTraining => {
        this.outsideTrainings.push(newOutsideTraining);
      });

      if (this.outsideTrainingForDeletion.length != 0) {
        /*
        * O(x*(n+1)) where n is length of data and x is len of deletions
        * Dont need get entries as the trainings once we save the whole object rather than just the name of it.
        * They can be assumed to have their ids added to them. 
        * Since thats all delete Entry needs we are done

        this.adminService.getEntries(DB_CONFIG.outside_training_endpoint, 'name')
        /*
        *   pipe(take(1)) is for the purposes of only getting the subscription once
        *   this is normally the procedure because 90% of the time subscriptions cause
        *   issues with causing infinite loops when the database they are accessing change inside
        *   when it describes count of objects, the data we are getting is already 1 array.
        *   So if the length was 10, it would keep updating until it reached 10 updates.
        *
        .pipe(take(this.outsideTrainingForDeletion.length),).subscribe((data: any[]) => {
          for (const training of data) {
            for (const trainingName of this.outsideTrainingForDeletion) {
              if (trainingName == training.name) {
                this.db_document_id.push(training.id);
              }
            }
          }
          if (this.db_document_id.length != 0) {
            for (const document_id in this.db_document_id) {
              this.adminService.deleteEntry(this.db_document_id[document_id], DB_CONFIG.outside_training_endpoint);
            }
          }
        });
        */

        // O(x) where x is len of deletions
        for (let training of this.outsideTrainingForDeletion) {
          this.adminService.deleteEntry(training.id, DB_CONFIG.outside_training_endpoint).then().catch(err => {
            this.errorMsg = 'Error deleting.\n Please try again.';
            // push training back into outside trainings if an error occurs
            this.newOutsideTrainings.push(training);
          });
        }
      }

      if (this.outsideTrainingForUpdate.length != 0) {
        /**
         * change the training.name being stored for update to the whole training object
         * dont need get entries, just increases our reads on the db. 
         * this is a better solution than the previous one, assuming the user deletes more than 2 objects.
         * O(2n) because it goes through the length of the outside trainings twice.
         * DELETE this and one above IF PAST 09/07/2021 OR DONE CHECKING(matt)
        for (const outsideTrainingName of this.outsideTrainingForUpdate) {
          let trainingIndex = "";
          this.adminService.resetGetEntries();
          for (const outsideTrainingIndex in this.newOutsideTrainings) {
            if (outsideTrainingName == this.newOutsideTrainings[outsideTrainingIndex].name) {
              trainingIndex = outsideTrainingIndex;
              break;
            }
          }
          this.adminService.getEntries(DB_CONFIG.outside_training_endpoint, 'name', 'name', outsideTrainingName).pipe(take(1),).subscribe((data: any) => {
            for (const category in data[0].categories) {
              if (this.newOutsideTrainings[trainingIndex].categories[category].selected !== data[0].categories[category].selected) {
                this.newOutsideTrainings[trainingIndex]["id"] = data[0].id;
                this.adminService.updateEntry(this.newOutsideTrainings[trainingIndex], DB_CONFIG.outside_training_endpoint);
                break;
              }
            }
          });
        }
        */

        // O(x) where x is len of edits
        for (let training of this.outsideTrainingForUpdate) {
          this.adminService.updateEntry(training, DB_CONFIG.outside_training_endpoint).then(data => {
            // only update trainings on successful edit to db
            const outsideIndex = this.newOutsideTrainings.findIndex((trainy) => {return trainy.name === training.name});
            this.newOutsideTrainings[outsideIndex] = training;
          }).catch(err => {
            this.errorMsg = 'Error editing.\n Please try again.';
          });
        }
      }
      this.closeModal();
    }
  }

  /**
   * Checks if any outside training in the new array has no categories selected and
   * prints them into an error message
   * @returns true if there are errors in the copied outside training array and false if otherwise
   */
  checkForErrors(): boolean {
    const errorTrainings = [];

    this.newOutsideTrainings.forEach(newOutsideTraining => {
      const selectedCategories = newOutsideTraining.categories.filter(category => category.selected);

      if (selectedCategories.length == 0) {
        errorTrainings.push(newOutsideTraining.name)
      }
    });

    if (errorTrainings.length == 0) {
      return false;
    }
    else if (errorTrainings.length == 1) {
      this.errorMsg = `${errorTrainings} needs at least one category selected.`
      return true;
    }
    else {
      let errorList = '';

      errorTrainings.forEach((errorTraining, i) => {
        if (i == errorTrainings.length - 1) {
          errorList += `and ${errorTraining}`;
        }
        else if (i == errorTrainings.length - 2) {
          errorList += `${errorTraining} `;
        }
        else {
          errorList += `${errorTraining}, `;
        }
      });

      this.errorMsg = `${errorList} need at least one category selected.`
      return true;
    }
  }

  /**
   * Clears the error message 
   */
  clearErrorMsg(): void {
    this.errorMsg = '';
  }
}
