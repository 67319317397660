<ion-row class="improvement-container" >
  <div class="improvement-row improvement-text" [ngStyle]='{ "background-color": trajectoryColor, "border": trajectoryBorder}'>{{trajectoryTitle}}
    <p class="improvement-percent">{{scoreFactor.improvement}}%</p>
  </div>
</ion-row>

<ion-row class="score-chart p-0">
  <ion-col size="11" class="p-0">
    <app-bars-over-time [datas]="datas"></app-bars-over-time>
  </ion-col>
</ion-row>

<ion-row class="overall-para">
  <div class="score-para-container">
    <span *ngIf="scoreFactor.improvement > 5">
      Your <b>{{scoreFactor.title}}</b> score improved dramatically from a
      {{scoreFactor.previous}} to a <b>{{scoreFactor.current}}</b> out of 10.
      An <b>improvement of {{scoreFactor.improvement}}%.</b>
    </span>
    <span *ngIf="scoreFactor.improvement <= 5 && scoreFactor.improvement >= -5">
      Your <b>{{scoreFactor.title}}</b> score
      stayed the same with a <b>slight change of {{scoreFactor.improvement}}%.</b>
    </span>
    <span *ngIf="scoreFactor.improvement < -5">
      Your <b>{{scoreFactor.title}}</b> score declined from a
      {{scoreFactor.previous}} to a <b>{{scoreFactor.current}}</b> out of 10.
      A <b>decline of {{scoreFactor.improvement}}%.</b>
    </span>

  </div>
</ion-row>

<ion-row>
  <ion-col size="6" class="trajectory">
    <div [ngStyle]='{ "background-color": trajectoryColor}' class="trajectory-background">
      <h2>Trajectory</h2>
      <div class="trajectory-text">
        <p>You likely noticed that {{trajectoryText}}.</p>
      </div>
    </div>
  </ion-col>
  
  <ion-col size="6" class="comparison">
    <div [ngStyle]='{ "background-color": comparisonColor}' class="trajectory-background">
      <h2>Comparison to Peers</h2>
      <div class="trajectory-text">
        <p>Compared to your peers, {{comparisonText}}</p>
      </div>
    </div>
  </ion-col>
</ion-row>

<!-- GOAL COMPONENT -->

<ion-row class="goal">
  <div [ngStyle]='{ "background-color": goalColor}' class="trajectory-background">
    <h2>Goal</h2>
    <div class="trajectory-text">
      <p>Your goal for your next training session is to 
        <span *ngIf="!perfect">bring your</span>
        <span *ngIf="perfect">maintain your</span>
        {{scoreFactor.title}} {{goalText}}. This will help
        {{scoreFactor.reason}}. {{scoreFactor.title}} was {{goalScore}}.
        You can also work on {{scoreFactor.title}} by working on {{scoreFactor.priority}}.
      </p>
    </div>
  </div>
</ion-row>