<div class="cont h-100" *ngIf='question' id='trivia-component'>
  <!-- question -->
  <div class="question">
    <!-- text -->
    <div class="text">
      <span>{{ question?.question }}</span>
    </div>
    <!-- text/ -->

    <!-- image  -->
    <div class="image-holder">
      <be-image *ngIf='question.imageUrl' [src]='question.imageUrl' (load)='test($event)'></be-image>
    </div>
    <!-- image/ -->
  </div>
  <!-- question / -->

  <!-- answers -->
  <div class="answers">
    <ion-grid>
      <ion-row>
        <ion-col size="12" size-sm="6" size-md="3" *ngFor='let answer of question?.answers; let i = index' (click)='clickAnswer(answer, question)'>
          <div id='answer_{{i}}' class='answer-btn centered'>
            <b>{{answer.details}}</b>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  
  <!-- answers/ -->

  <!-- correct -->
  <div *ngIf='showResult === 2' class="result correct">
    <h1>CORRECT</h1>
  </div>
  <!-- correct/ -->

  <!-- wrong -->
  <div *ngIf='showResult === 1' class="result wrong">
    <h1>WRONG</h1>
  </div>
  <!-- wrong/ -->
</div>

