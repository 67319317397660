import { Component, OnInit } from '@angular/core';

import { ModalController, NavController } from '@ionic/angular';

import { GameService } from '@services/game.service';
import { EventBusService } from '@services/event-bus.service';

import * as _ from 'underscore';

import { IPlayer } from '@common/interface/players.interface';

import { Utilities } from '@common/utilities';

@Component({
  selector: 'app-players',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.scss'],
})
export class PlayersComponent extends Utilities implements OnInit {
  
  selectedPlayers = [];
  selectedIndex: number;

  constructor(
    private gameService: GameService,
    public modalController: ModalController,
    public navCtrl: NavController,
    private eventBusService: EventBusService
  ) { super(); }

  ngOnInit() {
    this.getSelectedPlayers();
  }

  /**
   * Gets an array of the selected players.
   */
  getSelectedPlayers(): void {
    const players = this.gameService.getSelectedPlayers();

    // iterate the filtered data and set isChecked to false
    _.each(players, (player: IPlayer, index: number) => {
      // create a new object so that when
      // we update isChecked only this object is updated
      this.selectedPlayers.push({...player});
      this.selectedPlayers[index].isChecked = false;
    });
  }

  /**
   * Adds the list of selected players to the SelectedPlayersSubject and closes the modal.
   */
  closePlayerModal(): void {
    this.eventBusService.selectedPlayer.emit(this.selectedPlayers[this.selectedIndex]);
    this.closeModal();
  }

  /**
   * Sets the selected index based on the selected player.
   * @param index The number of the selected player in the players array.
   */
  onChange(index: number): void {
    this.selectedIndex = index;
  }
}
