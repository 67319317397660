import { Injectable } from '@angular/core';
import { Meeting } from '../common/models/meeting.model';
import { Presentation } from '../common/models/presentation.model';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { NavController } from '@ionic/angular';
import { DB_CONFIG } from '../app.firebase.config';
import { User } from '../common/models/user.interface';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClubhouseService } from './clubhouse.service';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {

  private meeting = {
    attendees: [],
    presentation_id: '',
    presentation_name: '',
    subscriber_id: '',
    online: false,
    location_url: '',
    start_date: new Date().toJSON(),
    is_active: true,
    id: '',
  } as Meeting|any;

  limit = 10;
  lastMeeting: any;
  isZoom: boolean;

  private meetingsCollection: AngularFirestoreCollection<Meeting>;
  private meetingDoc: AngularFirestoreDocument<Meeting>;
  orientationChange = new BehaviorSubject<boolean>(false);
  meetingId: string;

  constructor(
    private afs: AngularFirestore,
    private navCtrl: NavController,
    private clubhouseService: ClubhouseService
  ) {
    this.meetingsCollection = this.afs.collection<Meeting>(DB_CONFIG.meeting_endpoint);
  }

  /**
   * Sets the attendees of the meeting.
   * @param users The attending users.
   */
  setAttendees(users: User[]): void {
    users.map(user => {
      this.meeting.attendees.push(user.id);
    })
    // this.meeting.attendees = users;
  }

  /**
   * Adds a user to the attendees for the meeting.
   * @param user The user to be added.
   */
  addAttendee(user: User): void {
    this.meeting.attendees.push(user.id);
  }

  /**
   * Gets the attendees of the current meeting.
   * @returns The attendees of the current meeting.
   */
  getAttendees(): User[] {
    return this.meeting.attendees;
  }

  /**
   * Removes an attendee from the meeting.
   * @param user The user to be removed from the meeting.
   */
  removeAttendee(user: User): void {
    const index = this.meeting.attendees.indexOf(user);

    if (index > -1) {
      this.meeting.attendees.splice(index, 1);
    }
  }

  /**
   * Sets the presentation id of the meeting.
   * @param id The id of the presentation.
   */
  setPresentationId(id: string): void {
    this.meeting.presentation_id = id;
  }

  /**
   * Gets the presentation id of the meeting.
   * @returns The presentation id.
   */
  getPresentationId(): string {
    return this.meeting.presentation_id;
  }

  /**
   * Sets the presentation name of the meeting.
   * @param Name The name of the presentation.
   */
  setPresentationName(Name: string): void {
    this.meeting.presentation_name = Name;
  }

  /**
   * Gets the id of the meeting.
   * @returns The id of the meeting.
   */
  getMeetingId(): string {
    return this.meetingId;
  }

  /**
   * Sets if the meeting is active.
   * @param bool If the meeting is active.
   */
  setIsActive(bool: boolean): void {
    this.meeting.is_active = bool;
  }

  /**
   * Sets the zoom link of the meeting.
   * @param url The zoom link.
   */
  setZoomLink(url: string): void {
    this.meeting.location_url = url;
    this.meeting.online = true;
  }

  /**
   * Gets if the meeting is a zoom meeting.
   * @returns Whether the meeting is a zoom meeting.
   */
  getIsZoom(): boolean {
    return this.isZoom;
  }

  /**
   * Sets if the meeting is a zoom meeting.
   * @param bool If the meeting is a zoom meeting.
   */
  setIsZoom(bool: boolean): void {
    this.isZoom = bool;
  }

  /**
   * Saves the meeting to the meeting collection.
   * @returns The document's id.
   */
  async saveMeeting() {
    this.clubhouseService.saveMeeting(this.meeting)
  }

  /**
   * Gets the meetings from the Firestore.
   * @returns The meetings.
   */
  getMeetings(): Observable<Meeting[]> {
    this.meetingsCollection = this.afs.collection<Meeting>(
      DB_CONFIG.meeting_endpoint,
      ref => ref.orderBy('creationDate').limit(this.limit)
    );

    return this.meetingsCollection.snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            // Get document data
            const data = a.payload.doc.data() as Meeting;

            this.lastMeeting = a.payload.doc;

            // Get document id
            const id = a.payload.doc.id;

            // Use spread operator to add the id to the document data
            return { id, ...data };
          });
        })
      );
  }

  /**
   * Gets the meetings based on the subscriber id.
   * @param id The subscriber id.
   * @returns The meetings.
   */
  getMeetingsBySubscriber(id: string): Observable<Meeting[]> {
    /** get presentations from firestore */
    this.meetingsCollection = this.afs.collection<Meeting>(
      DB_CONFIG.meeting_endpoint,
      ref => ref.where('subscriberId', '==', id).orderBy('creationDate').limit(this.limit)
    );

    return this.meetingsCollection.snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            // Get document data
            const data = a.payload.doc.data() as Meeting;

            this.lastMeeting = a.payload.doc;

            // Get document id
            // tslint:disable-next-line: no-shadowed-variable
            const id = a.payload.doc.id;

            // Use spread operator to add the id to the document data
            return { id, ...data };
          });
        })
      );
  }

  /**
   * Deletes the meeting based on the meeting id.
   * @param id The id of the meeting to delete.
   */
  async deleteMeetingById(id: string): Promise<void> {
    try {
      const res = await this.meetingsCollection.doc(id).delete();
      return res;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Updates the meeting document.
   */
  updateMeeting(): void {
    this.clubhouseService.updateMeeting(this.meeting);
  }
}
