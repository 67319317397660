import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Animation, AnimationController } from '@ionic/angular';

@Component({
  selector: 'app-percentage-circle',
  templateUrl: './percentage-circle.component.html',
  styleUrls: ['./percentage-circle.component.scss'],
})
export class PercentageCircleComponent implements OnInit {

  @Input() name: string;
  @Input() percentage: number;
  @Input() color: string;
  
  animation: Animation;
  prevDashArray: string;
  dashArray: string;
  nameId: string;

  constructor(private animationCtrl: AnimationController) { }

  ngOnInit(): void { 
    this.nameId = this.name + 'id';
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setDashArrays(changes.percentage.previousValue);
    this.createAnimation();
  }

  /**
   * Sets the dash array which is used to paint the outline of the circle
   * 
   * @param previousValue The previous percentage number (0-100)
   */
  setDashArrays(previousValue: number): void {
    this.prevDashArray = `${previousValue}, 100`;
    this.dashArray = `${this.percentage}, 100`;
  }

  /**
   * Creates a new animation of the circle outline being filled in from the
   * previous percentage to the current percentage
   */
  createAnimation(): void {
    this.animation = this.animationCtrl.create()
      .addElement(document.getElementById(this.nameId))
      .duration(1000)
      .easing('ease-in')
      .fromTo('strokeDasharray', this.prevDashArray, this.dashArray);
    
    this.animation.play();
  }
}
