<ion-header>
  <ion-toolbar>
    <ion-title>Terms and Conditions</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding ion-margin">
  <h1 class="main-heading">Brain Evolved Disclaimer</h1>
  <p class="disclaimer-item">I agree that I have read and understood the following disclaimers, which apply to use of Brain Evolved Services (including, but not limited to Brain Charge):</p>
  <h2 class="section-heading">PHYSICAL ACTIVITY DISCLAIMER:</h2>
  <ol class="disclaimer-list">
    <li class="disclaimer-item ion-margin">
      I AGREE AND ACKNOWLEDGE THAT USE OF THE BRAIN EVOLVED INC. (“BRAIN EVOLVED”) SERVICES INCLUDING CERTAIN ASSESSMENTS AND TESTS WILL INVOLVE PHYSICAL ACTIVITY. I FURTHER AGREE AND ACKNOWLEDGE THAT
      THERE ARE CERTAIN RISKS, DANGERS, AND HAZARDS THAT ARE INHERENT IN ANY TYPE OF PHYICAL ACTIVITY. THESE INCLUDE, WITHOUT LIMITATION, PHYSICAL AND MENTAL FATIGUE; SLIPS, FALLS, AND COLLISIONS;
      DIZZINESS AND LOSS OF BALANCE; ENVIRONMENTAL FEATURES AND LIMITATIONS; AND ACCIDENTS OCCURING BEFORE, DURING, AND AFTER ANY SUCH PHYSICAL ACTIVITY. ENCOUNTERING THESE RISKS, DANGERS, AND HAZARDS
      COULD RESULT IN PHYSICAL INJURY AND/OR DAMAGE TO PERSONAL PROPERTY. I UNDERSTAND THAT IT IS NOT POSSIBLE FOR BRAIN EVOLVED TO IDENTIFY OR LIST IN THIS DISCLAIMER EVERY POTENTIAL RISK, DANGER,
      AND/OR HAZARD ASSOCIATED WITH PARTICIPATING IN ANY BRAIN EVOLVED TEST OR ASSESSMENT AND/OR USING ANY BRAIN EVOLVED SERVICES. I UNDERSTAND AND APPRECIATE, HOWEVER, THAT I MIGHT ENCOUNTER THESE
      AND OTHER INHERENT RISKS, DANGERS, AND HAZARDS NOT SPECIFICALLY LISTED IN THIS DISCLAIMER AT ANY TIME THAT I AM BEING TESTED, ASSESSED, AND/OR USING ANY OF BRAIN EVOLVED SERVICES.
    </li>
    <li class="disclaimer-item">
      I ACCEPT AND ASSUME ALL OF THE INHERENT RISKS, DANGERS, AND HAZARDS ASSOCIATED WITH ANY PHYSICAL ACTIVITY RELATED TO PARTICIPATING IN ANY TEST OR ASSESSMENT AND/OR USE OF ANY BRAIN EVOLVED
      SERVICES, WHETHER KNOWN OR UNKNOWN, INCLUDING SPECIFICALLY IDENTIFIED IN THIS DISCLAIMER.
    </li>
    <li class="disclaimer-item">
      I ACCEPT AND ASSUME RESPONSIBILITY FOR MY OWN ACTS, ERRORS, NEGLIGENCE CARELESSNESS, AND/OR RECKLESSNESS WHILE PARTICIPATING IN ANY BRAIN EVOLVED TEST OR ASSESSMENT AND/OR USE ANY BRAIN EVOLVED
      SERVICES.
    </li>
    <li class="disclaimer-item">
      I UNDERSTAND AND ACKNOWLEDGE THAT BRAIN EVOLVED RECOMMENDS AND ENCOURAGES THAT I CONSULT WITH A PHYSICIAN BEFORE PARTICIPATING IN ANY OF THE BRAIN EVOLVED TESTS OR ASSESSMENTS AND/OR USING ANY
      BRAIN EVOLVED SERVICES. IT IS MY RESPONSIBILITY TO DETERMINE WHETHER PARTICIPATING IN ANY OF THE TESTS OR ASSESSMENTS AND/OR USING ANY OF THE SERVICES IS SUITABLE FOR ME AND WHETHER I AM
      PHYSICALLY ABLE TO PARTICIPATE IN ANY SUCH TEST OR ASSESSMENT AND/OR USING ANY SUCH SERVICES.
    </li>
  </ol>
  <h2 class="section-heading">Medical Disclaimer</h2>
  <p class="disclaimer-item">
    NOTHING IN THE USE OF THE BRAIN EVOLVED INC. (“BRAIN EVOLVED”) SERVICES INCLUDING, WITHOUT LIMITATION, THE ADMINISTRATION OF ANY TEST OR ASSESSMENT AS WELL AS DISSEMINATION OR INTERPRETATION OF
    ANY TEST OR ASSESSMENT RESULTS SHOULD BE CONSTRUED AS CONSTITUTING THE PROVISION OF ANY TYPE OF MEDICAL ADVICE OR MEDICAL OPINION OR THE RENDERING OF ANY DEFINITIVE MEDICAL OR PSCYHOLOGICAL
    DIAGNOSIS IN ANY MANNER. BRAIN EVOLVED DOES NOT PROVIDE ANY TYPE OF DEFINITIVE MEDICAL OPINION OR DIAGNOSIS. ITS SERVICES, MATERIALS, TESTS, AND ASSESSMENTS ARE INTENDED FOR INFORMATIONAL AND
    EDUCATIONAL PURPOSES ONLY ANDNOT FOR THE PURPOSE OF RENDERING MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. NO INFORMATION, EVALUATION, ASSESSMENT, OR TEST PROVIDED BY BRAIN EVOLVED SHOULD BE USED TO
    DIAGNOSE, TREAT, PREVENT, AND/OR CURE ANY MEDICAL CONDITION. ANY USER OF ANY BRAIN EVOLVED SERVICES OR ANY PARTICIPANT IN ANY BRAIN EVOLVED TEST AND/OR ASSESSMENT SHOULD NOT ACT UPON ANY
    INFORMATION CONTAINED IN ANY OF THE SERVICES, TESTS, OR ASSESSMENTS ALL USERS SHOULD SEEK PERSONALIZED MEDICAL ADVICE FROM A QUALIFIED AND LICENSED PHYSICIAN.
  </p>
  <ion-grid>
    <ion-row>
      <ion-col>
        <app-button text="Accept Terms" (click)="agreedTerms()"></app-button>
      </ion-col>
      <ion-col>
        <app-button backgroundColor="white" text="Decline Terms" (click)="declineTerms()"></app-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
