import { Component, OnInit, Input } from '@angular/core';
import { NavController, MenuController } from '@ionic/angular';
import { Router } from '@angular/router';
import { User } from 'src/app/common/models/user.interface';
import { MeetingService } from 'src/app/services/meeting.service';

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.scss'],
})
export class MeetingComponent implements OnInit {

  @Input() headerTitle: string;
  @Input() height = 20;

  showMenu: boolean;
  showBack: boolean;
  showNothing: boolean;
  rollcall = true;
  successes = false;
  presentation = false;

  constructor(
    public navCtrl: NavController,
    public router: Router,
    public menu: MenuController,
    private meetingService: MeetingService,
  ) { }

  ngOnInit() {
    this.rollcall = this.router.url.indexOf('rollcall') > - 1;
    this.successes = this.router.url.indexOf('successes') > - 1;
    this.presentation = this.router.url.indexOf('presentation') > - 1;
  }

  /**
   * Saves or updates the meeting if the user is in during rollcall 
   * and navigates the user to the specified page.
   * @param flag The page in string format to navigate to.
   */
  navigate(flag: string): void {
    if ( this.rollcall ) {
      if (this.meetingService.getMeetingId()) {
        // If there already is a meeting, only update the attendees
        this.meetingService.updateMeeting();
      } else {
        // If there is no meeting already saved
        this.meetingService.saveMeeting();
      }
    }
    switch (flag) {
      case ('rollcall'): {
        this.navCtrl.navigateForward('clubhouse/rollcall');
        break;
      }
      case ('successes'): {
        this.navCtrl.navigateForward('clubhouse/successes');
        break;
      }
      case ('present'): {
        this.navCtrl.navigateForward('clubhouse/presentation');
        break;
      }
    }
  }

}
