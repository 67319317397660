import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { LevelingService } from '@services/leveling.service';

@Component({
  selector: 'app-level-display',
  templateUrl: './level-display.component.html',
  styleUrls: ['./level-display.component.scss'],
})
export class LevelDisplayComponent implements OnInit {

  level = 1;
  leveled = false;
  @Input() noText;
  avatar;
  title;
  subtext = '';

  content_subtext = [
    `Congratulations Cognitive Conqueror! You leveled up.`,

    `You have completed the recommended amount of training on this
    path for today!`,

    `Way to put in extra effort! Click Done to see what’s next or Retry to
    play again.`
  ]

  constructor(
    private levelService: LevelingService,
    private authService: AuthenticationService
  ) { }

  Object = new Object;

  ngOnInit() {
    this.subtext = this.content_subtext[2];
    this.subToLevel();
    this.authService.userSubject.subscribe((user: any) => {
      this.avatar = user.avatar;
      this.title = user.title;
    });
  }

  isEmptyObject(obj: object): boolean {
    if(!obj){return true}
    return (Object.keys(obj).length === 0 && obj.constructor === Object) || !this.avatar;
  }
  

  subToLevel(){
    this.levelService.summary.subscribe(x => {
      if(this.levelService.levelsUp) {
        this.levelUp();
        this.leveled = true;

        this.subtext = this.content_subtext[0];
      } else if(x.completionBonus) {
        this.subtext = this.content_subtext[1];
      } else {
        this.subtext = this.content_subtext[2];
      }
      
      return;
    })
  }

  levelUp() {
    this.level++;
    this.shakeLevel();
  }
  //animation: rotate 1s ease-in-out 2;
  async shakeLevel(){
    const wait = (delay, ...args) => new Promise(resolve => setTimeout(resolve, delay, ...args));
    document.getElementById('level-text').style.animation = 'rotate 2s ease-in-out 2';
    await wait(4000);
    document.getElementById('level-text').style.animation = '';
    return;
  }

}
