
<!-- container -->
<div class="flex-wrapper h-100" id='brain-game-answers'>

  <div class="container answer-container full-width background-white h-100">
    <ng-container>
      <h1>Trivia Review</h1>
      <ion-grid class="answer-block" style="height: calc(100% - 90px);">
        <ion-row class="flex-full-height mb-4">
          <!-- question detail -->
          <ion-col size="6" *ngFor="let item of summary; let i = index; let last = last;">
            <div 
              class="container"
              [ngClass]="{'bordered': !last, 'correct-answer': item.answer.is_correct, 'incorrect-answer': !item.answer.is_correct}">
              <div *ngIf='!item.question.imageUrl' class="question-txt">
                <span>{{item.question.question}}</span>
              </div>

              <div *ngIf='item.question.imageUrl' class="question-img">
                <div class="question-img-txt">
                  <span>{{item.question.question}}</span>
                </div>

                <div class="img-cont">
                  <img src='{{item.question.imageUrl}}'>
                </div>
              </div>

              <div class="answer-cont">
                <span *ngIf='!item.answer.is_correct' class="incorrect">{{item.answer.details}}</span>
                <span>{{item.question.correctAnswer}}</span>
              </div>
            </div>
          </ion-col>
          <!-- question detail /-->
        </ion-row>
        <ion-row class="full-width">
          <app-button class='full-width' data-cy="answers-cont-btn" text="Continue" (click)="done()"></app-button>
        </ion-row>
      </ion-grid>
    </ng-container>     
  </div>    

  <div class="container full-width">
    
  </div>
</div>
<!-- container /-->
