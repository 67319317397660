import { Injectable } from '@angular/core';
import { DB_CONFIG } from '../app.firebase.config';
import { map } from 'rxjs/operators';
import { IQuestion } from '@common/interface/question.interface';
import { GameDifficultyEnum } from '@enums/DifficultyEnum';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})

export class QuestionService {
  private questionRef: AngularFirestoreCollection<IQuestion>;

  constructor(
    private afs: AngularFirestore
  ) { }
    

  /**
   * Get questions by type
   * @param limit a number representing the dataset limit
   * @param difficulty an enum representing the difficulty
   */
  getQuestionsByType(limit: number, difficulty: GameDifficultyEnum) {
    this.questionRef = this.afs.collection<IQuestion>(
      DB_CONFIG.yahootie_questions_endpoint,
      ref => ref.where('difficulty', '==', difficulty)); // .limit(limit)  <--- Removed by Eric so all of the questions from db are returned

    
  //  return this.getQuestionSnapshot();
    return this.questionRef.snapshotChanges()
    .pipe(
      map(actions => {
        return actions.map(a => {
          // Get document data
          const data = a.payload.doc.data() as IQuestion;

          // Get document id
          const id = a.payload.doc.id;

          // Use spread operator to add the id to the document data
          return { id, ...data };
        });
      })
    );
  }
}

