<div class="button-cont" (click)="clicked()" [style.margin]="margin">
    <button 
        [ngStyle]= '{"background-color": backgroundColor, 
        "border": border,
        "border-radius": borderRadius,
        "color": color, 
        "font-size": fontSize,
        "opacity": opacity, 
        "padding": padding, 
        "width": width}' 
        [disabled]="disabled">
        {{ text }}
    </button>
</div>