import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Utilities } from '@common/utilities';
import { AppEnum } from '@enums/AppEnum';
import { LevelingService } from '@services/leveling.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-score-display',
  templateUrl: './score-display.component.html',
  styleUrls: ['./score-display.component.scss'],
  animations: [
    trigger('numberFlyInLeft', [
      state('start', style({ transform: 'translateX(0%)', opacity: 1 })),
      state('end', style({ transform: 'translateX(100%)', opacity: 0 })),
      transition('start => end', animate('1000ms ease-out')),
    ]),
    trigger('numberShiftLeft', [
      state('start', style({ transform: 'translateX(0%)' })),
      state('end', style({ transform: 'translateX(100%)' })),
      transition('start => end', animate('1000ms ease-in')),
    ]),
    trigger('fadeOut', [
      state('start', style({ opacity: 1 })),
      state('end', style({ opacity: 0 })),
      transition('start => end', animate('500ms ease-out')),
    ]),
    trigger('numberFlyInRight', [
      state('start', style({ transform: 'translateX(0%)', opacity: 1.0})),
      state('end', style({ transform: 'translateX(-100%)', opacity: 0.0 })),
      transition('start => end', animate('500ms ease-out')),
    ]),
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition('void => *', animate('1000ms ease-in')),
    ]),
    trigger('flyUp', [
      state('start', style({ transform: 'translateY(0%)', opacity: 1.0})),
      state('end', style({ transform: 'translateY(-500%)', opacity: 0.0 })),
      transition('start => end', animate('1000ms ease-out')),
    ]),
  ]
})
export class ScoreDisplayComponent extends Utilities implements OnInit, OnChanges {

  multiplier = 0;
  totalAdded = 0;

  @Input() score;
  @Input() Game;
  @Input() app;

  constructor(
    private levelService: LevelingService
  ) { super(); }

  ngOnInit() {
    return;
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(this.score){
        this.calculate();
        this.hideDisplay = false;
      }
  }

  firstNumber = 0;
  secondNumber = 0;
  sum: number | null = null;
  addAnimationState = 'start';
  multiplyAnimationState;
  flyUpAnimationState = 'start';
  hideDisplay = true;

  async animateAndAdd() {
    const sleepAmt = 600;
    this.addAnimationState = 'end';
    await this.sleep(sleepAmt);
    this.summy()
  }

  async showMultiply() {
    this.multiplyAnimationState = 'start';
  }

  async animateAndMuliply() {
    const sleepAmt = 600;
    this.multiplyAnimationState = 'end';
    await this.sleep(sleepAmt);
    this.multiplied();
  }

  summy() {
    this.sum = this.firstNumber + this.secondNumber;
    return;
  }

  multiplied() {
    this.totalAdded = this.sum * this.multiplier;
    return;
  }

  flyUpEnd(){
    this.flyUpAnimationState = 'end'
  }

  // bug occurs after happy place and then brain game. level starts too soon. Level up also occured
  async fullAnimation() {
    const sleepAmt = 1100;
    const pauseTime= 3000;
    if(this.secondNumber){
      // completion bonus
      await this.sleep(sleepAmt + pauseTime);
      this.animateAndAdd();
      await this.sleep(sleepAmt + pauseTime);
      

      // no completion bonus
    } else {
      this.summy();

    }
    this.showMultiply();
    await this.sleep(sleepAmt + pauseTime);
    
    this.animateAndMuliply();
    await this.sleep(sleepAmt + pauseTime);
    this.flyUpEnd();
    await this.sleep(sleepAmt);
    this.levelService.timeToAddXp.next();
    this.hideDisplay = true;
    
    //fly away
  }

  async calculate(){
    const test = await this.levelService.calculatePoints(this.app, this.Game, this.score);
    this.firstNumber = test.baseValue;
    if(test.completionBonus){
      this.secondNumber = test.completionBonus;
    }
    this.multiplier = test.performanceMetric;
    this.fullAnimation();
    return;
  }

}
