<div class='mx-auto' style="color: red;
  text-align: center;
  font-size: 24px;">
  Average
</div>
<p class='mt-5'>
  <ngb-progressbar type="{{startColor}}" [value]="startScore" height="40px"></ngb-progressbar>
</p>
<div class="chart-lines-overall chart-average-overall"></div>
<div class="chart-lines-overall" style="left: 0%;"></div>
<div class="chart-lines-overall" style="left: 20%;"></div>
<div class="chart-lines-overall" style="left: 40%;"></div>
<div class="chart-lines-overall" style="left: 60%;"></div>
<div class="chart-lines-overall" style="left: 80%;"></div>
<div class="chart-lines-overall" style="left: 100%;"></div>

<p class='mt-5'>
  <ngb-progressbar type="{{todayColor}}" [value]="currentScore" height="40px"></ngb-progressbar>
</p>

<ion-row class="mt-5 p-0">
  <ion-col class="p-0 overall-chart-number" size="2.3">0 </ion-col>
  <ion-col class="p-0 overall-chart-number" size="2.4">2</ion-col>
  <ion-col class="p-0 overall-chart-number" size="2.4">4</ion-col>
  <ion-col class="p-0 overall-chart-number" size="2.4">6</ion-col>
  <ion-col class="p-0 overall-chart-number" size="1.25">8</ion-col>
  <ion-col class="p-0 overall-chart-number" size="1.25" style="text-align: right">10</ion-col>
</ion-row>