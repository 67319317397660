import { baseData } from "./base.model";

export class YahootieData extends baseData {
    exerciseTime    = [] as {val: any, time: Date}[];
    questionTime    = [] as {val: any, time: Date}[];
    Simple_MathTime = [] as {val: any, time: Date}[];
    Complex_MathTime= [] as {val: any, time: Date}[];
    PatternTime     = [] as {val: any, time: Date}[];
    StroopTime      = [] as {val: any, time: Date}[];
    FlankerTime     = [] as {val: any, time: Date}[];
    questionAcc     = [] as {val: number, time: Date}[];
    wrongYahootie   = [] as {val: number, time: Date}[];
    missedYahootie  = [] as {val: number, time: Date}[];
    correctYahootie = [] as {val: number, time: Date}[];
    skips           = [] as {val: number, time: Date}[];
    questionNum     = [] as {val: number, time: Date}[];


    constructor() {
        super('Yahootie');
    }
}