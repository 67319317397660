import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-form-actions',
  templateUrl: './form-actions.component.html',
  styleUrls: ['./form-actions.component.scss']
})
export class FormActionsComponent implements OnInit {
  @Input() showDelete = false;
  @Input() toggleDisabled = false;

  @Output() deleteClicked: EventEmitter<any> = new EventEmitter();
  @Output() toggleClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  /**
   * Emits that the delete component has been clicked. 
   */
  onDelete(): void {
    this.deleteClicked.emit();
  }

  /**
   * Emits that the toggler has been toggled. 
   * @param event The event that the toggler has been checked or unchecked.
   */
  onToggle(event): void {
    this.toggleClicked.emit(event.detail.checked);
  }

}
