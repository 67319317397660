import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ToastController, NavController, ModalController } from '@ionic/angular';
import { Utilities } from '@app/common/utilities';
import { Answer } from '@app/common/models/answer.model';
import { AdminService } from '@app/services/admin.service';
import { CorrectAnswerCategoryEnum } from '@app/common/enums/CorrectAnswerCategoryEnum';
import { CorrectAnswerSubCategoryEnum } from '@app/common/enums/CorrectAnswerSubCategoryEnum';
import { EventBusService } from '@services/event-bus.service';


@Component({
  selector: 'app-answer-form',
  templateUrl: './answer-form.component.html',
  styleUrls: ['./answer-form.component.scss'],
})
export class AnswerFormComponent extends Utilities implements OnInit {
  // @Input() value: string;

  form: FormGroup;
  errorMessage: string;
  isAnswer: boolean;
  disableToggle: boolean;

  categoryArray = [];
  subCategoryArray = [];

  answer: Answer;

  popoverClass = 'select-popover';

  popoverOptions: any = {
    header: 'Options',
    cssClass: this.popoverClass
  };

  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    public navCtrl: NavController,
    public toastController: ToastController,
    private adminService: AdminService,
    private eventBusService: EventBusService,
  ) { super(); }

  ngOnInit() {
    this.form = this.createFormControls();

    this.onCloseModal();

    // populate the arrays for each category
    this.setCategoryKeys();
    this.setSubCategoryKeys();
  }

  /**
   * Creates the form controls used to validate the details, category, and subcategory.
   * @returns The FormGroup to track the value and validity state of the forms.
   */
  createFormControls(): FormGroup {
    return this.formBuilder.group({
      details: [null, [Validators.required]],
      category: [null, [Validators.required]],
      sub_category: [null, [Validators.required]],
    });
  }

  /**
   * Gets the answer category enum values and stores them in an array.
   */
  setCategoryKeys(): void {
    this.categoryArray = Object.values(CorrectAnswerCategoryEnum);
  }

  /**
   * Gets the answer subcategory enum values and stores them in an array.
   */
  setSubCategoryKeys(): void {
    this.subCategoryArray = Object.values(CorrectAnswerSubCategoryEnum);
  }

  /**
   * Sets the answer state to the checked value and updates the database with it.
   * @param checked The state of the toggle.
   */
  toggleSelected(checked: boolean): void {
    // set the state
    this.answer.isActive = checked;

    // update DB
    this.adminService.updateAnswer(this.answer);
  }

  /**
   * Saves the answer info to the database.
   */
  save(): void {
    if (this.form.valid) {
      // add additional fields
      this.answer = this.form.value;

      // save answer to DB
      this.adminService.saveAnswer(this.answer).then(id => {
        this.answer.id = id;
        this.presentToast('Answer saved successfully!');
      }).catch(error => {
        this.errorMessage = error.message;
      });
      this.modalCtrl.dismiss(this.answer);
    }
  }

  /**
   * Resets the form for a new answer.
   */
  newAsnwer(): void {
    this.form.reset();
  }

  /**
   * Closes the modal.
   */
  onCloseModal(): void {
    this.eventBusService.modalCloseClicked.subscribe(() => {
      this.closeModal();
    });
  }
}

