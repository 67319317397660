<!-- sleep pattern -->
<ion-grid class="sleep-pattern">
  <!-- move this column outside of the row and change css/position to match correctly -->
  <div >
    <div class='labels-container'>
      <ion-text class="pattern-hour-label">≥{{max}} {{units}}</ion-text>
      <div style="top: 35px;" class="pattern-hour-line"></div>
    </div>
    <div class=" labels-container bottom-hour">
        <ion-text class="pattern-hour-label">{{min}} {{units}}</ion-text>
        <div style="bottom: 15px;" class="pattern-hour-line"></div>
    </div>
  </div>
  <ion-row>
    <ion-col *ngFor='let sleepDay of datas; let i = index' class="center-col">
      <div [ngClass]="{'default-colors': gradient}" *ngIf='sleepDay.val != undefined' class="pattern-rectangle">
        <div class="pattern-circle" [ngStyle]='{ "border": sleepDay.border_color, "top": sleepDay.top }'>
          <ion-text [ngClass]="{ 'greater-than-tweleve': sleepDay.val == '≥12' }" class="pattern-hours-number">{{ sleepDay.val }}</ion-text>
          <ion-text *ngIf='units' class="pattern-hours">{{units}}</ion-text>
        </div>
      </div>
      <ion-icon *ngIf='sleepDay.val == undefined' name="close" class="not-reported"></ion-icon>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col *ngFor='let sleepDay of datas'>
      <ion-text class="date-label">{{ sleepDay.date }}</ion-text>
    </ion-col>
  </ion-row>
</ion-grid>
<!-- sleep pattern /-->
<!-- sleep pattern key -->
<ion-grid>
  <ion-row class="key-row justify-content-center">
    <ion-col *ngFor="let key of keys">
      <div [ngStyle]='{ "background-color": key.color}' class="key-icon"></div>
      <ion-text>{{key.name}}</ion-text>
    </ion-col>
  </ion-row>
</ion-grid> 
<!-- sleep pattern key /-->
