<!-- main header -->
<div class="{{ headerClass }} content-center header-container" style='height: 84px;'>
  <div *ngIf='!onLogin' class='full-h white' style='position: absolute; top: 10px; left: 10px;' (click)=logout()>
    <ion-icon
      name="log-out" 
      mode="ios" 
      class="be-icon full-h half-w"
      style='width:50px; height: 50px'>
    </ion-icon>
  </div>
  <div class="{{ logoClass }}" style='max-width: 269px; margin: auto'></div>
  <div *ngIf="headerTitle" class="main-heading margin-top-20" [innerHTML]="headerTitle"></div>
</div>

<div class="background-primary" *ngIf="showBack">
  <a class="text-large" (click)="goBack()">
    <ion-icon name="arrow-back"></ion-icon>
  </a>
</div>
<!-- main header /-->
