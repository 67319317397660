<div class="close">
  <ion-grid>
    <ion-row>
      <ion-col class="close-icon">
        <ion-icon name="close"></ion-icon>
      </ion-col>
      <ion-col class="close-text">
        <ion-text>Close</ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
