import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() parent: FormGroup;
  @Input() name: string;
  @Input() value: string;
  @Input() selectionOptions: any;
  transform: string;
  valueIndex

  @Output() itemSelected = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    this.valueIndex = this.selectionOptions.findIndex(option => {
      return option === this.value;
    });
    return;
  }

  /**
   * Emits the value of the item detail.
   * @param item The item selected.
   */
  onItemSelected(item) {
    this.itemSelected.emit(item.target.value);
  }

  /**
   * Flips the arrow to point down
   */
  flipArrow(): void {
    this.transform = 'translate(-50%,-50%) rotate(-180deg)';
  }

  /**
   * Flips the arrow to point back up
   */
  flipArrowBack(): void {
    this.transform = 'translate(-50%,-50%) rotate(0deg)';
  }
}
