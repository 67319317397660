<!-- wrapper -->
<div class="centered">
  <ion-card>
    <!-- swiper -->
    <swiper #mySwiper [config]="swiperConfig" class="be-swiper flex-stretch-vertical" [style.height]='swiperHeight'>
      <div class="be-no-swiping" *ngFor="let quiz of quizObject; let i = index" >
        <!-- game title -->
        <div *ngIf="showGameTitle" class="full-height center-with-footer">

          <div class="container assessment-content">
            <div 
              class="game-image" 
              [ngStyle]="{'background-image': 'url(' + gameObject.imageUrl + ')' }">
            </div>

            <div class="background-padded">
              <!-- title -->
              <div class="step-title">
                {{ gameObject.name }}
              </div>
              <!-- title /-->

              <!-- instructions -->
              <div class="step-instructions">
                <div class="margin-top-10" [innerHTML]="gameObject.description">
                </div>
              </div>
              <!-- instructions /-->
            </div>
          </div>

          <ion-footer class="ion-padding">
            <app-button text="Next" (click)="hideGameTitle(i)"></app-button>
          </ion-footer>
        </div>
        <!-- game title -->

        <!-- lets practise -->
        <div *ngIf="showPracticeTitle" class="full-height">
          <div class="container content-center-vertical text-center full-width center-with-footer">
            <div class="background-padded full-width">
              <!-- title -->
              <div class="step-title">Let's Practice!</div>
              <!-- title /-->
            </div>
          </div>

          <ion-footer class="sticky-footer ion-padding">
            <app-button text="Next" (click)="hidePracticeTitle(i)"></app-button>
          </ion-footer>
        </div>
        <!-- lets practise -->

        <!-- ready for quiz -->
        <div *ngIf="showReadyTitle" class="full-height">
          <div class="container center-with-footer">
            <div class="background-padded">
              <!-- title -->
              <div class="step-title">Ready?</div>
              <!-- title /-->

              <!-- instructions -->
              <div class="step-instructions">
                <p class="margin-top-10">
                  When you’re ready, tap Start Quiz, and tap the buttons as fast as you can!
                </p>
              </div>
              <!-- instructions /-->
            </div>
          </div>

          <!-- Start Quiz button -->
          <ion-footer class="sticky-footer ion-padding">
            <div class="background-padded full-height">
              <app-button text="Start Quiz" (click)="hideResultTitle(i)"></app-button>
            </div>
          </ion-footer>
          <!-- Start Quiz button /-->
        </div>
        <!-- ready for quiz -->

        <!-- quiz -->
        <div class="full-height" *ngIf="
            !showPracticeTitle &&
            !showGameTitle &&
            !showReadyTitle &&
            !showFixationPoint">
          <div class="container center-with-footer" [ngClass]="{ 'full-height': quiz.image }">
            <div class="step-title margin-top-0 background-padded text-center" *ngIf="quiz.title">
              {{ quiz.title }}
            </div>
            <!-- quiz title /-->
            <!-- title /-->

            <!-- description -->
            <div class="description background-padded">
              {{ quiz.description }}
            </div>
            <!-- description /-->

            <!-- coordinates -->
            <div class="coordinates background-padded" [ngClass]="{ 'auto-height': quiz.image }"
              *ngIf="quiz.category != 'Grid'">
              <div *ngIf="!quiz.image" [ngClass]="quiz.coordinates"></div>
              <div *ngIf="quiz.image">
                <img src="{{ quiz.image }}" />
              </div>
            </div>
            <!-- coordinates /-->

            <!-- correct answer -->
            <div *ngIf="!isCorrect && quiz.category !== 'Grid'" class="text-center background-padded">
              The correct answer is {{ quiz.answer }}
            </div>
            <!-- correct answer /-->

            <!-- quiz -->
            <div class="background-padded ">
              <ion-grid>
                <ion-row *ngIf="quiz.options?.length === 2">
                  <ion-col col-6 *ngFor="let option of quiz.options; let o = index">
                    <button id="dynamicid_{{ option }}" class="be-button-tertiary round-button"
                      [ngClass]="{ 'float-right': o === 1 }" size="large" (click)="checkAnswer(i, option, quiz.answer)">
                      {{ option }}
                    </button>
                  </ion-col>
                </ion-row>

                <ion-row *ngIf="quiz.options?.length > 2" class="row">
                  <ion-col class="quiz-button-box" size-sm="6" size="12" *ngFor="let option of quiz.options; let j = index">
                    <button id="dynamicid_{{ option }}" 
                    attr.data-cy="option_{{j}}" 
                    class="quiz-button"
                      (click)="checkAnswer(i, option, quiz.answer)">
                      {{ option }}
                    </button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
            <!-- quiz /-->
          </div>
        </div>
        <!-- quiz -->

        <!-- fixation point -->
        <div *ngIf="showFixationPoint" class="content-center-vertical full-height">
          <div class="container content-center-vertical full-width center-with-footer">
            <div class="background-padded ion-text-center full-width">
              <ion-icon class="text-large" name="add"></ion-icon>
            </div>
          </div>
        </div>
        <!-- fixation point -->
      </div>
    </swiper>
  <!-- swiper /-->
  </ion-card>
</div>
<!-- wrapper /-->
