import { EventEmitter, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { AdminService } from './admin.service';
import { AuthenticationService } from './authentication.service';
import { DB_CONFIG } from '@app/app.firebase.config';
import { Utilities } from '@common/utilities';
import { OverallSessionService } from './overall-session.service';
import { SubscriberService } from './subscriber.service';



@Injectable({
  providedIn: 'root'
})
export class LevelingService extends Utilities {

  // emits each time to add xp to our level bar 
  timeToAddXp = new ReplaySubject<number>();
  // emits when players level or xp is updated
  playerLevelAndXp  = new ReplaySubject<{level, currentXp}>();
  // emits everytime a level up occurs
  levelUp = new EventEmitter();
  modalClosed = new EventEmitter();

  levelAndXp: {level: number, currentXp: number};

  summary = new ReplaySubject<{totalPoints, baseValue, completionBonus, performanceMetric}>();

  private baseValues = {
    braingame: 100,
    clubhouse: 0,
    happyplace: 100,
    yahootie: 100,
    completionOfTheDayBonus: 500
  };

  private gameValues = {
    slidingpuzzle: { value: 35, metric: 'finalTotalScore' },
    sudoku: { value: 50, metric: 'finalTotalScore' },
    lemonade: { value: 35, metric: 'finalTotalScore' },
    tower_defense: { value: 1000, metric: 'finalTotalScore' },
    trail_making: { value: 1500, metric: 'finalTotalScore' },
    large_sudoku: { value: 100, metric: 'finalTotalScore' },
    yahootie: { value: 40, metric: 'finalTotalScore' },
    happyplace: { value: 1, metric: 'finishingMood' }
  };

  private levelValues = [
    { level: 1, xpRequired: 1800},
    { level: 2, xpRequired: 500},
    { level: 3, xpRequired: 600},
    { level: 4, xpRequired: 700},
    { level: 5, xpRequired: 1000},
    { level: 10, xpRequired: 1250},
    { level: 20, xpRequired: 1500},
    { level: 30, xpRequired: 1800},
    { level: 1000, xpRequired: 1800},
  ]

  public levelArray = [
    { level: 1, title: "Neural Ninja", avatar: null },
    { level: 2, title: "Brain Beast", avatar: null },
    { level: 3, title: "Mental Magician", avatar: null },
    { level: 4, title: "Memory Mastermind", avatar: null },
    { level: 5, title: "Synapse Slayer", avatar: null },
    { level: 6, title: "IQ Impresario", avatar: null },
    { level: 7, title: "Cerebral Crusader", avatar: null },
    { level: 8, title: "Thought Tactician", avatar: null },
    { level: 9, title: "Grey Matter Guardian", avatar: null },
    { level: 10, title: "Brainy Badass", avatar: null },
    { level: 11, title: "Mental Marvelous", avatar: null },
    { level: 12, title: "Intelligence Intrepid", avatar: null },
    { level: 13, title: "Mind Maverick", avatar: null },
    { level: 14, title: "Cognitive Conqueror", avatar: null },
    { level: 15, title: "Brain Bulldozer", avatar: null },
    { level: 16, title: "Mental Megastar", avatar: null },
    { level: 17, title: "IQ Innovator", avatar: null },
    { level: 18, title: "Brain Brawler", avatar: null },
    { level: 19, title: "Mental Muscleman", avatar: null },
    { level: 20, title: "Synapse Strategist", avatar: null },
    { level: 21, title: "Cortex Crusader", avatar: null },
    { level: 22, title: "Brainiac Boss", avatar: null },
    { level: 23, title: "Mental Marathoner", avatar: null },
    { level: 24, title: "Memory Maestro", avatar: null },
    { level: 25, title: "IQ Iconoclast", avatar: null },
    { level: 26, title: "Cerebral Champion", avatar: null },
    { level: 27, title: "Synapse Surfer", avatar: null },
    { level: 28, title: "Thought Titaness", avatar: null },
    { level: 29, title: "Intelligence Idolizer", avatar: null },
    { level: 30, title: "Mind Mover", avatar: null },
    { level: 31, title: "Cognitive Captain", avatar: null },
    { level: 32, title: "Brain Baron", avatar: null },
    { level: 33, title: "Mental Mastery", avatar: null },
    { level: 34, title: "IQ Impressionist", avatar: null },
    { level: 35, title: "Brainstormer", avatar: null },
    { level: 36, title: "Mental Machine", avatar: null },
    { level: 37, title: "Memory Maven", avatar: null },
    { level: 38, title: "Synapse Sharpshooter", avatar: null },
    { level: 39, title: "Brain Buff", avatar: null },
    { level: 40, title: "Cerebral Connoisseur", avatar: null },
    { level: 41, title: "Intelligence Invincible", avatar: null },
    { level: 42, title: "Thought Tornado", avatar: null },
    { level: 43, title: "Grey Matter Genius", avatar: null },
    { level: 44, title: "Brain Bender", avatar: null },
    { level: 45, title: "Synapse Magus", avatar: null },
    { level: 46, title: "Cognitive Commander", avatar: null },
    { level: 47, title: "Brain Badass", avatar: null },
    { level: 48, title: "Mental Monster", avatar: null },
    { level: 49, title: "Memory Mogul", avatar: null },
    { level: 50, title: "Thought Terminator", avatar: null },
    { level: 51, title: "Intelligence Icon", avatar: null },
    { level: 52, title: "Synapse Savant", avatar: null },
    { level: 53, title: "Grey Matter Gladiator", avatar: null },
    { level: 54, title: "Brain Bombshell", avatar: null },
    { level: 55, title: "Mental Marvel", avatar: null },
    { level: 56, title: "Cerebral Crusade", avatar: null },
    { level: 57, title: "Thought Thief", avatar: null },
    { level: 58, title: "Brain Boxer", avatar: null },
    { level: 59, title: "Memory Mercenary", avatar: null },
    { level: 60, title: "Synapse Sniper", avatar: null },
    { level: 61, title: "Intelligence Insider", avatar: null },
    { level: 62, title: "Brain Boss", avatar: null },
    { level: 63, title: "Grey Matter God", avatar: null },
    { level: 64, title: "Brainy Braveheart", avatar: null },
    { level: 65, title: "Mind Magician", avatar: null },
    { level: 66, title: "Cognitive Connoisseur", avatar: null },
    { level: 67, title: "Brain Behemoth", avatar: null },
    { level: 68, title: "Synapse Sage", avatar: null },
    { level: 69, title: "Mental Megalith", avatar: null },
  ];

  private global_level_count = {
    levels: [
      {
        count: 0,
        user_ids: []
      }
    ]
  }

  user;
  

  constructor(
    private adminService: AdminService,
    private authService: AuthenticationService,
    private complianceService: OverallSessionService,
    private subService: SubscriberService
  ) {
  super();
  this.init();
 }

  init() {
    this.subToUser();
    this.subGlobalLevels();
  }

  getXpRequired(level){
    const levelValue = this.levelValues.find(lvlValue => {
      return lvlValue.level >= level;
    })
    return levelValue.xpRequired;
  }


  subToUser() {
    this.authService.userSubject.subscribe(user => {
      this.getLevelAndXpValues(user);
      this.user = user;
    })
  }
    /**
   * Calculates the total number of points earned by a user for completing a brain game.
   * @param game The type of brain game completed.
   * @param score The score achieved by the user in the brain game.
   * @param completionCount The number of times the user has completed the brain game that day.
   * @returns The total number of points earned by the user for completing the brain game.
   */
  async calculatePoints(app: string, game: string, score: number) {
    this.levelsUp = 0;
    const appCompliance = this.complianceService.getBaseComplianceByApp(app);
    game = game.toLowerCase().replace(/\s/g, "");
    app = app.toLowerCase().replace(/\s/g, "");
    // Get the base value for the game type.
    let completion = true;
    if(appCompliance.daysSinceLastUse === 0){
      completion = false;
    }
    
    const baseValue = this.baseValues[app];

    // Add a completion bonus if the user has completed the game for the first time that day.
    const completionBonus = completion ? this.baseValues.completionOfTheDayBonus : 0;

    // Get the game value and performance metric for the game type.
    const gameValue = this.gameValues[game].value;
    let performanceMetric = parseFloat((score / gameValue).toFixed(2));
    if(app === 'happyplace'){performanceMetric = 2}
    if(performanceMetric < 0){performanceMetric = 1}

    // Calculate the total number of points earned by the user for completing the brain game.
    const totalPoints = Math.round((baseValue + completionBonus) * performanceMetric);

    // makes sure level and xp is loaded so values for add xp can be calculated properly
    for(let i = 10; i > 0; i--){
      if(this.levelAndXp){
        break;
      }
      await this.sleep(500);
    }
    this.addXp(totalPoints);
    this.summary.next({totalPoints, baseValue, completionBonus, performanceMetric});
    return {totalPoints, baseValue, completionBonus, performanceMetric};
  }


  async getLevelAndXpValues(user?){
    if(user){
      let levelAndXp = await this.adminService.getEntryById(user.id, DB_CONFIG.level_endpoint);
      if(!levelAndXp){
        levelAndXp = {level: 1, currentXp: 0, id: user.id}
      }
      this.playerLevelAndXp.next(levelAndXp);
      this.levelAndXp = levelAndXp;
    } else {
      for(let i = 0; i < 5; i++){
        if(this.levelAndXp){
          return this.levelAndXp
        } else{
          await this.sleep(500)
        }
      }
    }
  }

  levelsUp = 0;

  async addXp(xp) {
    
      
    let currentLevelAndXp = this.levelAndXp;
    const total = xp + currentLevelAndXp.currentXp;
    const max = this.getXpRequired(currentLevelAndXp.level);
    if(total >= max){
      // level up occurs
      currentLevelAndXp.level++;
      currentLevelAndXp.currentXp = 0;
      this.levelAndXp = currentLevelAndXp;
      this.levelsUp++;
      // go until total is less than max
      if(total >= max){
        this.addXp(total-max);
      }
    } else {
      currentLevelAndXp.currentXp = total;
      this.playerLevelAndXp.next(currentLevelAndXp);
      // no level up or leveling finished so save data
      this.addLevelToTotal(currentLevelAndXp.level);
      this.saveXp();
    }
    return;
  }

  async saveXp() {
    await this.adminService.saveEntryById(this.levelAndXp, DB_CONFIG.level_endpoint);
    return true;
  }

  countsAdded = false;
  async addCountsToLevelArray(){
    if(this.countsAdded){
      return this.levelArray;
    } else {
      const users = this.subService.subUsers;
      await this.waitForMaps(users.map(async (user) => {
        const level = await this.adminService.getEntryById(user.id, DB_CONFIG.level_endpoint);
        if(!level){return;}
        // figure out if level already exists outside our defined levels
        const exists = this.levelArray.findIndex(level_arr => {
          return level_arr.level === level.level;
        })
        if(exists !== -1){
          if(this.levelArray[exists]['count']){
            this.levelArray[exists]['count']++;
          } else {
            this.levelArray[exists]['count'] = 1;
          }
        } else {
          this.levelArray.push({
            level: level.level,
            avatar: null,
            title: null,
            count: 1
          } as any)
        }
      }));
      this.countsAdded = true;
    }
  }

  async getGlobalLevels() {
    for(let i = 0; i < 5; i++){
      if(this.global_level_count['id']){
        return this.global_level_count;
      } else{
        await this.sleep(500)
      }
    }
    throw new Error('no global levels loaded')
  }

  subGlobalLevels() {
    this.adminService.getEntries(DB_CONFIG.global_level_endpoint).subscribe(levels => {
      this.global_level_count = levels[0];
    })
  }

  addLevelToTotal(level: number) {
    const level_obj = this.global_level_count.levels[level];
    let saved = true;
    return new Promise((res,rej) => {
      if(level_obj){
        // level already exists, check if current user is already counted here
        if(level_obj.user_ids.find(id => {return this.user.id === id})){
          // do nothing if already here
          saved = false;
          res(true)
        } else {
          // if new user to this level
          const prev_level = this.global_level_count.levels[level-1];
          // decrement count from previous level
          // keep track of user ids as well
          const prev_user_index = prev_level.user_ids.findIndex(id => {return this.user.id === id})
          if(prev_user_index){
            prev_level.count--;
            prev_level.user_ids.splice(prev_user_index, 1);
          } else {
            throw new Error('global levels arent being loaded')
          }
          // increment count for this level, + add user id
          level_obj.count++;
          level_obj.user_ids.push(this.user.id);
          res(true)
        }
      } else {
        // level doesnt exist, add new object count 1 with user id in array
        // need to make sure all previous levels exist with 0 counts no users
        const range_diff = level - this.global_level_count.levels.length;
        const concated_arr = new Array(range_diff).fill({count: 0, user_ids: []});
        concated_arr[concated_arr.length-1] = {
          count: 1,
          user_ids: [this.user.id]
        };
        const test = this.global_level_count.levels.concat(concated_arr);
        this.global_level_count.levels = test;
        res(true)
      }
    }).then(x => {
      // save
      if(saved){
        this.adminService.saveEntryById(this.global_level_count, DB_CONFIG.global_level_endpoint);
      }
      return;
    })
    
  }


}
