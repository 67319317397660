import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppEnum } from '@enums/AppEnum';
import { ModalController } from '@ionic/angular';
import { BrainGameControllerService } from '@services/brain-game-controller.service';
import { GameService } from '@services/game.service';
import { HappyPlaceService } from '@services/happy-place.service';
import { LevelingService } from '@services/leveling.service';
import { GameScoreModalComponent } from '../helpers/game-score/game-score.component';
import { MoodEnum } from '@enums/MoodEnum';

@Component({
  selector: 'app-summary-display',
  templateUrl: './summary-display.component.html',
  styleUrls: ['./summary-display.component.scss'],
})
export class SummaryDisplayComponent implements OnInit {

  appenums = AppEnum;

  // connected to GAME scores in leveling Service
  @Input() Game;
  
  @Output() extraClicked = new EventEmitter();

  constructor(
    private levelingService: LevelingService,
    private bgService: BrainGameControllerService,
    private hpService: HappyPlaceService,
    private yaService: GameService,
    private modalController: ModalController
    ) { }

  summary;
  game_score;


  ngOnInit() {
    this.levelingService.modalClosed.subscribe(x => {
      if(this.modal){
        this.closedModal();
      }
    })
    if(this.Game === this.appenums.HappyPlace){
      // get happy place mood
      // this.game_score = this.hpService.temp_tracking.mood;
      const nummood = this.hpService.temp_tracking.mood;
      this.game_score = MoodEnum[nummood];
    } else if (this.Game === this.appenums.Yahootie){
      // get yahootie score
      this.game_score = this.yaService.yahootieResults.overall_score;
    } else {
      // get bg score
      this.game_score = this.bgService.response.overall_score;
    }
    this.levelingService.summary.subscribe(summ => {
      this.summary = summ;
    });
  }

  seeExtra(): void {
    
    this.extraClicked.emit(null);
  }

  modal;
  async seeHelper() {
    this.modal = await this.modalController.create({
      component: GameScoreModalComponent,
      componentProps: {game: this.Game},
      cssClass: 'gameScore-modal',
      showBackdrop: true,
    });

    this.modal.present();
  }

  closedModal() {
    this.modal.dismiss();
  }

}
