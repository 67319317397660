<!-- wrapper -->
<div class="centered">
  <ion-card style="max-width: 1080px;">
    <!-- swiper -->
    <swiper #mySwiper [config]="swiperConfig" class="be-swiper flex-stretch-vertical" [style.height]='swiperHeight' style="width: 100%;">
      <div class="be-no-swiping" *ngFor="let quiz of quizObject; let i = index">
        <!-- game title -->
        <div *ngIf="showGameTitle" class="full-height">
          <div class="container assessment-content">
            <div 
              class="game-image" 
              [ngStyle]="{'background-image': 'url(' + gameObject.imageUrl + ')' }">
            </div>

            <div class="background-padded">
              <!-- title -->
              <div class="step-title">
                {{ gameObject.name }}
              </div>
              <!-- title /-->

              <!-- instructions -->
              <div class="step-instructions">
                <div class="margin-top-10" [innerHTML]="gameObject.description">
                </div>
              </div>
              <!-- instructions /-->
            </div>
          </div>

          <ion-footer class="ion-padding">
            <app-button text="Next" (click)="hideGameTitle(i)"></app-button>
          </ion-footer>
        </div>
        <!-- game title -->

        <!-- lets practise -->
        <div *ngIf="showPracticeTitle" class="full-height">
          <div class="container content-center-vertical text-center full-width practice-screen-height">
            <div class="background-padded full-width">
              <!-- title -->
              <div class="practice-title">Let's Practice!</div>
              <!-- title /-->
            </div>
          </div>

          <app-button text="Next" (click)="hidePracticeTitle(i)"></app-button>
        </div>
        <!-- lets practise -->

        <!-- ready for quiz -->
        <div *ngIf="showReadyTitle" class="full-height">
          <div class="container">
            <div class="background-padded">
              <!-- title -->
              <div class="step-title">Ready?</div>
              <!-- title /-->

              <!-- instructions -->
              <div class="step-instructions">
                <p class="margin-top-10">When you're ready, tap Start Quiz, and tap the buttons as fast as you can!</p>
              </div>
              <!-- instructions /-->
            </div>
          </div>

          <!-- Start Quiz button -->
          <ion-footer class="sticky-footer ion-padding">
              <app-button text="Start Quiz" (click)="hideResultTitle(i)"></app-button>
          </ion-footer>
          <!-- Start Quiz button /-->
        </div>
        <!-- ready for quiz -->

        <!-- quiz -->
        <div class="full-height" *ngIf="!showPracticeTitle && !showGameTitle && !showReadyTitle && !showFixationPoint && !isComplete">
          <app-grey-screen [message]="'Great! Let’s keep going!'" *ngIf="confettiBackground"></app-grey-screen>
          <div class="container" [ngClass]="{ 'full-height': quiz.image }">
            <!-- title -->
            <div class="step-title margin-top-0 background-padded" *ngIf="showPlay" [ngClass]="{ 'practice-section': showPractice }">
              <span *ngIf="showHowTo">How To Play</span>
              <span *ngIf="showPractice" [ngClass]="{ 'practice-title': showPractice }">Practice</span>
              <span *ngIf="!showPractice && !showHowTo">&nbsp;</span>
            </div>

            <!-- quiz title -->
            <div class="step-title margin-top-0 background-padded" *ngIf="quiz.title && !showPlay">
              {{ quiz.title }}
            </div>
            <!-- quiz title /-->
            <!-- title /-->

            <!-- coordinates -->
            <div class="coordinates background-padded" [ngClass]="{ 'auto-height': quiz.image }" *ngIf="quiz.category != 'Grid'">
              <div *ngIf="!quiz.image" [ngClass]="quiz.coordinates"></div>
              <div *ngIf="quiz.image">
                <img height="250" src="{{ quiz.image }}" />
              </div>
            </div>
            <!-- coordinates /-->

            <!-- grid -->
            <div *ngIf="quiz.category == 'Grid'" [ngClass]="{ 'h-75': !quiz.practice && !quiz.instruction}" class="coordinates mb-5">
              <ion-grid class="symmetry-grid">
                <ion-row class="h-100">
                  <ion-col
                    *ngFor="let number of numbers; let option = index"
                    class="cell"
                    size="3">
                    <button
                      id="dynamicid_{{ option }}"
                      attr.data-cy="dynamicid_{{ option }}"
                      class="be-button-tertiary grid-button"
                      [ngClass]="{red: quiz.coordinates == number + 1 || selectedCell[number]}"
                      (click)="selectedGrid(number, quiz.coordinates, i)">
                      <span *ngIf="quiz.instruction || (quiz.practice && !isCorrect)">{{ option + 1 }}</span>
                    </button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
            <!-- grid /-->

            <!-- description -->
            <div class="description background-padded" *ngIf='quiz.description'>
              {{ quiz.description }}
            </div>
            <!-- description /-->

            <!-- correct answer -->
            <div *ngIf="!isCorrect && quiz.category === 'Grid'" class="text-center background-padded correct">
              <ion-text>The correct answer is box # {{ quiz.answer }}</ion-text>
            </div>

            <div *ngIf="!isCorrect && quiz.category !== 'Grid'" class="text-center background-padded correct">
              <ion-text>The correct answer is {{ quiz.answer }}</ion-text>
            </div>
            <!-- correct answer /-->

            <!-- quiz -->
            <div class="button-spacing">
              <ion-grid class='full-width'>
                <ion-row *ngIf="quiz.options?.length === 2">
                  <ion-col size="6" class="d-flex" *ngFor="let option of quiz.options; let o = index">
                    <button id="dynamicid_{{ option }}" style="width: 130px;"
                    [ngClass]="{'pulsar': !isCorrect && option === quiz.answer}"
                    class="be-button-tertiary round-button m-auto py-5" size="large" (click)="checkAnswer(i, option, quiz.answer)">
                      {{ option }}
                    </button>
                  </ion-col>
                </ion-row>

                <ion-row *ngIf="quiz.options?.length > 2">
                  <ion-col size="12" *ngFor="let option of quiz.options">
                    <button id="dynamicid_{{ option }}" class="be-button-tertiary be-button button-full" (click)="checkAnswer(i, option, quiz.answer)">
                      {{ option }}
                    </button>
                  </ion-col>
                </ion-row>

                <ion-row *ngIf="quiz.category === 'Grid' && quiz.answer && isCorrect">
                  <ion-col size="12">
                    <app-button text="Done" (click)="checkGridAnswer(i)"></app-button>
                  </ion-col>
                </ion-row>

                <ion-row *ngIf="(quiz.category === 'Grid' && showQuiz3Continue) || (quiz.category === 'Symmetrical' && quiz.instruction) || (quiz.category === 'Grid' && quiz.answer && !isCorrect)">
                  <ion-col size="12">
                    <app-button text="Continue" (click)="goToNextSlide(i)"></app-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
            <!-- quiz /-->
          </div>
          <!-- play button /-->
        </div>
        <!-- quiz -->

        <!-- fixation point -->
        <div *ngIf="showFixationPoint" class="content-center-vertical full-height">
          <div class="container content-center-vertical full-width">
            <div class="background-padded ion-text-center full-width">
              <ion-icon class="text-large" name="add"></ion-icon>
            </div>
          </div>
        </div>
        <!-- fixation point -->
        <div *ngIf="isComplete" id='finished' class="container">
          <!-- title -->
          <div class="practice-title margin-top-0 background-padded">Finished!</div>
          <div class="background-padded align-center">
            <ion-text class="instruction">You have completed the Assessment. 
              <br>
              Let's start training!
            </ion-text>
          </div>
          <!-- card header /-->
          <div class="background-padded align-center">
            <app-button text="Continue" (click)="enterCoachPin()"></app-button>
          </div>
        </div>
      </div>
    </swiper>
  <!-- swiper /-->
  </ion-card>
</div>
<button (click)="confetti()" style="font-size: 3pt;">surprise</button>