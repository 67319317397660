import { Injectable } from '@angular/core';

import { Game } from '@models/game.model';
import { DB_CONFIG } from '../app.firebase.config';

import { IGameConfiguration } from '@common/interface/game-configuration.interface';
import { IPlayer } from '@common/interface/players.interface';
import { IApps } from '@common/interface/apps.interface';
import { IScore } from '@common/interface/score.interface';
import { IFeaturedActivities } from '@interfacefeatured-activities.interface';
import { YahootieResultsReport } from '@models/yahootie-results-report.model';

import { BehaviorSubject, Observable } from 'rxjs';

import { map } from 'rxjs/operators';

import { Utilities } from '@common/utilities';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import * as _ from 'underscore';
import { StreaksService } from './streaks.service';
import { AppEnum } from '@enums/AppEnum';
import { HighlightsService } from './highlights.service';
import { OverallSessionService } from './overall-session.service';
import { AdminService } from './admin.service';
import { DataTrackingService } from './data-tracking.service';

@Injectable({
  providedIn: 'root'
})
export class GameService {
  private gameCollection: AngularFirestoreCollection<Game>;
  private appCollection: AngularFirestoreCollection<IApps>;
  private activityCollection: AngularFirestoreCollection<IFeaturedActivities>;

  gameConfigSubject = new BehaviorSubject<IGameConfiguration>(null);
  // hold all players
  playersSubject = new BehaviorSubject<IPlayer[]>(null);
  selectedPlayersSubject = new BehaviorSubject<IPlayer[]>(null);
  scoreSubject = new BehaviorSubject<IScore[]>(null);
  yahootieSubject = new BehaviorSubject<YahootieResultsReport>(null);

  yahootieResults = new YahootieResultsReport();
  countDownTime: number;
  leaderIndex = 0;

  dataTrackerNeeds = {
    questionNum    : 0,
    correctQuestion: 0,
    wrongYahootie  : 0,
    missedYahootie : 0,
    correctYahootie: 0,
    skips          : 0,
  };
  timers = {};

  constructor(
    private afs: AngularFirestore,
    private streakService: StreaksService,
    private highlightService: HighlightsService,
    private overallSessionService: OverallSessionService,
    private adminService: AdminService,
    private dataService: DataTrackingService
  ) {}

  /**
   * 
   * @param score the score to save to the high/recent scores
   */
  endOfPath(score: number, leaderboard: number) {
    this.streakService.newStat(score, [AppEnum.Yahootie]);
    const highlightNeeds = {
      leaderboard,
      yahooties: this.dataTrackerNeeds.correctYahootie,
      score,
    }
    this.highlightService.endOfPath(AppEnum.Yahootie, highlightNeeds);
  }

  resetDataTrackerNeeds() {
    this.dataTrackerNeeds = {
      questionNum    : 0,
      correctQuestion: 0,
      wrongYahootie  : 0,
      missedYahootie : 0,
      correctYahootie: 0,
      skips          : 0,
    }
    this.timers = {};
  }

  /**
   * Gets the game's information by the provided id.
   * @param gameId The id of the game
   * @return The game's information
   */
  getGameById(gameId: string): Promise<Game> {
    this.gameCollection = this.afs.collection(DB_CONFIG.game_endpoint);

    return Promise.resolve(this.gameCollection.doc(gameId).ref.get().then((result) => {
      const game = result.data() as Game;
      return game;
    }));
  }

  /**
   * Stores the game configuration information in a
   * behavior subject so it can be accessed
   * throughout the application.
   * @param config The configuration data.
   * @returns The document reference id.
   */
  async saveGameConfig(config: IGameConfiguration) {
    try {
      // const res = await this.configCollection.add(config);
      // config.id = res.id;
      this.gameConfigSubject.next(config);
      return;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Gets all the application information for this game.
   * This will be used to display menu items for the
   * user to select which game they want to play
   */
  getApps(): Observable<any> {
    this.appCollection = this.afs.collection<IApps>(
      DB_CONFIG.apps_endpoint,
      ref => ref.orderBy('name')
    );

    return this.appCollection.snapshotChanges()
      .pipe(
        map(actions => {
          const data = Utilities.mapActions(actions);
          return data;
        })
      );
  }

  /**
   * Requests to the Firebase that will return details for one particular app.
   * @param appName The name of the application.
   * @returns The app's details.
   */
  getAppDetails(appName): Observable<any> {
    this.appCollection = this.afs.collection(DB_CONFIG.apps_endpoint, ref => ref.where('param', '==', appName));
    return this.appCollection.snapshotChanges()
    .pipe(
      map(actions => {
        const data = Utilities.mapActions(actions);
        return data;
      })
    );
  }
  
  /**
   * Gets the featured activities from the database.
   * @returns The featured activities.
   */
  getFeaturedActivities(): Observable<any> {
    this.activityCollection = this.afs.collection<IFeaturedActivities>(
      DB_CONFIG.featured_activities
    );

    return this.activityCollection.snapshotChanges()
      .pipe(
        map(actions => {
          const data = Utilities.mapActions(actions);
          return data;
        })
      );
  }

  /**
   * Saves the players information to a subject
   * to be accessed globally.
   * @param players The players array.
   */
  savePlayers(players: any[]): void {
    this.playersSubject.next(players);
  }

  /**
   * Filters the players subject and returns only
   * the selected players.
   * @returns The selected players.
   */
  getSelectedPlayers(): IPlayer[] {
    let selectedPlayers: IPlayer[];

    if (this.playersSubject.value) {
      selectedPlayers = this.playersSubject.value
        .filter(player => player.isChecked === true);
    }
    
    return selectedPlayers;
  }

  /**
   * Saves the score information to a subject
   * to be accessed globally.
   * @param score The scores array.
   */
  saveScores(scores: IScore[]): void {
    this.scoreSubject.next(scores);
  }

  /**
   * Gets the selected players' scores. 
   * @returns The scores of the selected players.
   */
  getPlayerScores(): IScore[] {
    let selectedPlayersScores: IScore[];

    if (this.scoreSubject.value) {
      selectedPlayersScores = this.scoreSubject.value
        .filter((player: any) => player.isChecked === true);
    }
    
    return selectedPlayersScores;
  }

  /**
   * Saves the Yahootie results into the Yahootie subject.
   * @param res The Yahootie Results report.
   */
  saveYahootieResults(res: YahootieResultsReport): void {
    this.yahootieSubject.next(res);
  }

  /**
   * Saves the Yahootie results report to the database
   * @param result The Yahootie Results report.
   * @returns The result report id.
   */
  async saveResultReport(result: YahootieResultsReport) {
    result = {...result, ...this.dataTrackerNeeds, timers: this.timers};
    this.overallSessionService.saveOverallSessionApp(AppEnum.Yahootie);
    this.adminService.saveEntryById(result, DB_CONFIG.yahootie_result_report_endpoint);
    this.resetDataTrackerNeeds();
  }

  /**
   * Resets the game configurations for the next game.
   */
  resetGameConfigs(): void {
    const selectedPlayers = this.getSelectedPlayers();
    const playerScore = this.getPlayerScores();
    this.yahootieResults = new YahootieResultsReport();

    // clear score and deselect players
    _.each(playerScore, (player: any) => {
      player.score = 0;
      player.rank = 0;
      player.isChecked = false;
    });

    // deselect players
    _.each(selectedPlayers, (player: any) => {
      player.isChecked = false;
    });
  }

  async endTimers(field) {
    return new Promise((res,rej) => {
      this.dataService.endTimer(field).then(timer => {
        if(!this.timers[field]){
          this.timers[field] = {timer, count: 1};
        } else {
          this.timers[field].timer += timer;
          this.timers[field].count++;
        }
        res(true);
      });
    })
  }
}

