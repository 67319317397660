import { Component, Input, OnInit } from '@angular/core';
import { DB_CONFIG } from '@app/app.firebase.config';
import { AdminService } from '@services/admin.service';
import { HomeService } from '@services/home.service';

@Component({
  selector: 'app-didyouknow',
  templateUrl: './didyouknow.component.html',
  styleUrls: ['./didyouknow.component.scss'],
})
export class DidyouknowComponent implements OnInit {

  @Input() week;
  @Input() id;
  
  didyouknows;
  displayed_dyk;

  constructor(
    private adminService: AdminService,
    private homeService: HomeService
  ) { }

  ngOnInit() {
    this.adminService.getEntries(DB_CONFIG.did_you_know_endpoint).subscribe(dyks => {
      this.didyouknows = dyks;
      if(this.id){
        // spontaneous release
        this.displayed_dyk = this.didyouknows.find(dyk => {
          return dyk.id === this.id;
        })
      } else if (this.week){
        // weekly release
        this.displayed_dyk = this.didyouknows.find(dyk => {
          return dyk.week === this.week;
        })
      }
    })
    
  }

  articleClicked(link) {
    if(link){
      window.alert('you are being navigated away');
      window.open(link, '_blank')
    }
    console.log(link)
  }

  index = 0;
  prev() {
    this.index--;
    this.displayed_dyk = this.didyouknows[this.index];
    // if(this.week){
    //   this.week--;
    //   this.ngOnInit();
    // }
  }

  seeNext(){
    this.index++;
    this.displayed_dyk = this.didyouknows[this.index];
    // if(this.week){
    //   this.week++;
    //   this.ngOnInit();
    // }
  }

  closeModal(){
    console.log('closed');
    this.homeService.didYouKnowModalClosed.emit();
  }
}
