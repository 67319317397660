import { GeneralData } from './general.model';

export class DataTracker {
    data = [];
    generalData = {...new GeneralData()};
    writeCounter = 0;
    appsVisited = [];

    //these are all things that can be filled from the get go
    userId: string;
    created_date: Date;
    modified_date: Date;
    deviceType: string;
    operatingSystem: string;
    

    constructor(userId: string, deviceType: string, operatingSystem: string) {
        this.userId = userId;
        this.deviceType = deviceType;
        this.operatingSystem = operatingSystem;
    }
}