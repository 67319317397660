<ion-header color="primary-green">
  <!-- toolbar -->
  <ion-toolbar color="primary-green">

    <ion-buttons slot="start">
      <ion-back-button
        *ngIf="showBack || showMenu"
        defaultHref="/registration/team"
        class="zoom">
      </ion-back-button>
    </ion-buttons>
    
    <!-- main header shifted to right for menu/back -->
    <div 
      class="pt-2 content-center"
      [ngClass]="{'content-centerright': showMenu || showBack}">
      <div class="brain-game-logo"></div>
      <app-branding appTitle="Brain Game"></app-branding>
    </div>
    <!-- main header right shift /-->

    <ion-buttons slot="end" *ngIf="showClose">
      <ion-button class="button-clear" (click)="onCloseModal()">Close</ion-button>
    </ion-buttons>
    

  </ion-toolbar>
</ion-header>