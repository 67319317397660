import { Component, OnInit } from '@angular/core';
import { BrainGameResultsComponent } from '@common/components/modal/brain-game/results/brain-game-results.component';
import { Utilities } from '@common/utilities';
import { ModalController } from '@ionic/angular';
import { BrainGameControllerService } from '@services/brain-game-controller.service';
import { LeaderboardService } from '@services/leaderboard.service';
import { brainGameDefaultConfig } from '@app/app.constants.config';

@Component({
  selector: 'app-bg-answers',
  templateUrl: './answers.component.html',
  styleUrls: ['./answers.component.scss'],
})
export class AnswersComponent extends Utilities implements OnInit {
  summary = [];

  constructor(
    private bgController: BrainGameControllerService,
    private leaderService: LeaderboardService,
    public modalController: ModalController
  ) { super(); }

  ngOnInit() {
    // get the answer summary
    this.summary = this.bgController.response.question_answer;
    const start = this.bgController.questionsTotal - this.bgController.questionsThisRound;
    const end = this.bgController.questionsTotal;
    this.summary = this.summary.slice(start, end);
    this.spreadSummaryWithCorrectAnswer();
    this.showResults();
  }

  /**
   * iterate summary, get the correct answer then add it to the new array
   * @param summary object containing answer summary
   */
  spreadSummaryWithCorrectAnswer() {
    this.summary.forEach((item) => {
      const correctAnswer = item.question.answers.filter(q => q.is_correct === true);

      if (correctAnswer && correctAnswer.length > 0) {
        item.question['correctAnswer'] = correctAnswer[0]['details'];
      }

    });
  }

  /* Reset sudoku game data */
  resetSudokuGameData() {
    localStorage.removeItem('_oPreservedStateBoolean');
    localStorage.removeItem('_oPreservedState');
    localStorage.sudokuSolved = false;
  }

  done() {
    this.bgController.roundNum++;
    this.bgController.response.round_game_score = 0;
    this.bgController.response.round_trivia_score = 0;
    
    if(this.bgController.roundNum > brainGameDefaultConfig.number_of_rounds) {
      this.resetSudokuGameData();
      this.bgController.navAway('leaderboard');
    }
    else {
      this.bgController.navAway('trivia');
    }
  }

  showResults(): void {
    this.presentModal(BrainGameResultsComponent, 'results-modal', {});
  }
}

