import { Component, Input, OnInit } from '@angular/core';
import { DB_CONFIG } from '@app/app.firebase.config';
import { Utilities } from '@common/utilities';
import { AdminService } from '@services/admin.service';
import { DataTrackingService } from '@services/data-tracking.service';
import { HomeService } from '@services/home.service';
import moment from 'moment';
import { NavController } from '@ionic/angular';
import { IUser } from '@interface/user.interface';

@Component({
  selector: 'app-moodsleep',
  templateUrl: './moodsleep.component.html',
  styleUrls: ['./moodsleep.component.scss'],
})
export class MoodsleepComponent extends Utilities implements OnInit {

  @Input() title?: string;
  @Input() user: any = {} as IUser;
  @Input() showSleep?: boolean = true;
  @Input() destination?: string;
  
  moods = [
    {
      enabled: false,
      imageUrl: '../../../../../assets/images/mood/verysad.png',
      name: 'vsad',
      value: 0
    },
    {
      enabled: false,
      imageUrl: '../../../../../assets/images/mood/sad.png',
      name: 'sad',
      value: 1
    },
    {
      enabled: false,
      imageUrl: '../../../../../assets/images/mood/meh.png',
      name: 'meh',
      value: 2
    },
    {
      enabled: false,
      imageUrl: '../../../../../assets/images/mood/smile.png',
      name: 'happy',
      value: 3
    },
    {
      enabled: false,
      imageUrl: '../../../../../assets/images/mood/veryhappy.png',
      name: 'vhappy',
      value: 4
    },
  ];

  hours = [];

  prevEnabledMood = -1;
  prevEnabledHour = '0';

  cobranding: any;
  errorMsg: string;
  transform: string;

  constructor(
    private homeService: HomeService,
    private NavCtrl: NavController
  ) { super(); }

  ngOnInit() {
    this.configureStyles();
    this.setHours(12);

    if (this.title === undefined) {
      this.title = `Hi, ${this.user.name}! How have you been?`;
    }
  }

  /**
   * Gets the cobranding styles from the HomeService.
   */
  configureStyles(): void {
    this.homeService.getCobranding().subscribe(cobrand => {
      this.cobranding = cobrand;
    });;
  }

  /**
   * Selects the mood. If the user clicks on the already selected mood, deselect it.
   * @param index The number index of the mood in the moods array.
   */
  moodClicked(index: number): void {
    if (this.prevEnabledMood === index && this.moods[this.prevEnabledMood].enabled) {
      this.moods[index].enabled = false;
      return;
    }
    else if (this.prevEnabledMood !== -1) {
      this.moods[this.prevEnabledMood].enabled = false;
    }

    this.errorMsg = '';
    this.moods[index].enabled = true;
    this.prevEnabledMood = index;
  }

  /**
   * Gets the number of hours selected.
   */
  hoursClicked(): void {
    this.prevEnabledHour = (<HTMLInputElement>document.getElementById('sleepHours')).value;
  }

  /**
   * Sets the HomeService's sleep modal closed event emitter 
   * with the hours and mood.
   */
  done(): void {
    if (!(this.prevEnabledMood > -1)) {
      this.errorMsg = 'Select your Mood';
      return;
    }

    let returneer: { hours?: number, mood: number };

    if (this.showSleep) {
      returneer = {hours: this.hours[this.prevEnabledHour].name, mood: this.moods[this.prevEnabledMood].value};
    }
    else {
      returneer = {mood: this.moods[this.prevEnabledMood].value};
    }
    this.homeService.sleepModalClosed.next(returneer);
    
    if (this.destination !== undefined) {
      this.NavCtrl.navigateForward(this.destination);
    }

    this.closeModal();
  }

  /**
   * Sets the hours array with the hour selections.
   * @param maxHours The maximum amount of hours to set. 
   */
  setHours(maxHours: number): void {
    for (let i = 0; i <= maxHours ; i++) {
      if (i == maxHours) {
        this.hours.push(
          { 
            string: '≥' + i,
            name: i
          });
      }
      else {
        this.hours.push(
          { 
            string: i.toString(),
            name: i
          });
      }
    }
  }

  /**
   * Flips the sleep select arrow downwards.
   */
  flipArrow(): void {
    this.transform = 'translate(-50%,-50%) rotate(-180deg)';
  }

  /**
   * Flips the sleep select arrow upwards.
   */
  flipArrowBack(): void {
    this.transform = 'translate(-50%,-50%) rotate(0deg)';
  }
}
