import { GameDifficultyEnum } from '@enums/DifficultyEnum';
import { PuzzleGameEnum } from '@enums/PuzzleGameEnum';

export class BrainGameReport {
    round_number    = 0 as number;
    game_name       = '' as PuzzleGameEnum;
    question_answer = [] as any[];
    trivia_score    = 0 as number;
    game_score      = 0 as number;
    overall_score   = 0 as number;
    user_name       = '' as string;  
    round_trivia_score    = 0 as number;
    round_game_score      = 0 as number;
    trivia_time     = 0;
    game_time       = 0;
    difficulty: GameDifficultyEnum
    id: string;
    created_date: string;
    modified_date: string;

    constructor(id, name) {
        this.id = id;
        this.user_name = name;
    }
}
