<!-- container -->
<div class="p-t-0">
  <!-- background -->
  <div class="full-height">

    <div 
      *ngIf="yahootieStatus === yahootieStatusEnum.Correct" 
      class="yahootie-hooray">
    </div>

    <ion-slides [options]="slideOpts">
      <ion-slide data-cy="yahootieSlide" *ngFor="let question of content; let questionIndex = index">
        <!-- question -->
        <div 
          class="animated rubberBand fast question m-t-20"
          data-cy='yahootieQuestion'>
          <div
          [innerHTML]="question.question">
          </div>
        </div>
        <!-- question /-->

        <!-- answer -->
        <ion-grid class="full-width m-t-20">
          <ion-row>
              <ion-col *ngFor="let option of question.options; let optionIndex = index" size='6'>
                <div class="answer" attr.data-cy='yahootie-ans-{{optionIndex}}' (click)="onAnswerSelect(option, question)">
                  <ion-label>{{alphabet[optionIndex]}}) {{option}}</ion-label>

                  <!-- not using <ion-radio-group> because it forces the radio options to be vertically displayed -->
                  <ion-radio 
                    slot="end" 
                    [value]="option"
                    color="secondary"
                    mode="ios">
                  </ion-radio>
                </div>
              </ion-col>
          </ion-row>
        </ion-grid>
        <!-- answer /-->
        
        

      </ion-slide>
    </ion-slides>
    <ion-grid class="button-cont" id="yahootieCol">
      <ion-row>
        <!-- skip -->
        <ion-col>
          <app-button  data-cy='yahootie' disabled={{yahootieDisabled}} text="Yahootie!" (click)="yahootieClicked($event)"></app-button>
        </ion-col>
        <!-- skip /-->
        <!-- yahootie -->
        <ion-col>
          <app-button backgroundColor="white" text="Skip Question" (click)="moveToNext(false)"></app-button>
        </ion-col>
        <!-- yahootie /-->
      </ion-row>
    </ion-grid>
  </div>
  <!-- background /-->

</div>
<!-- container /-->