<ion-card>
  <ion-grid>
    <ion-row>
      <ion-col size="2">
        <ion-icon name="bed"></ion-icon>
      </ion-col>
      <ion-col size="10">
        <!-- <app-date-range data-cy="sleep-date-range" isSleep=true (selectedWeekDates)="setWeek($event)"></app-date-range> -->
      </ion-col>
    </ion-row>
  </ion-grid>
    <div class="centered">
      <div class="light"></div>
      <h2>Average Sleep</h2>
      <div class="circle-container">
        <ion-text class="num">{{hours.toFixed(1)}}</ion-text>
        <ion-text class="hours-label">Hours</ion-text>
      </div>
      <!-- Used to give the button the same height as the mood average button -->
      <ion-text class="height-text">Height</ion-text>
      <app-button
        backgroundColor="transparent" 
        borderColor="white"
        color="white"
        fontSize="1.25rem"
        text="How can you improve?" 
        (click)="showSleepFlyout()">
      </app-button>
    </div>
</ion-card>
