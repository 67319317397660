import { Component, OnInit, Input } from '@angular/core';
import { NavController, MenuController } from '@ionic/angular';
import { Router } from '@angular/router';

import { EventBusService } from '@services/event-bus.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit {
  @Input() headerTitle: string;
  @Input() height = 20;
  @Input() showMenu?: boolean;
  @Input() showClose?: boolean;

  showBack: boolean;
  showNothing: boolean;
  
  constructor(
    public navCtrl: NavController,
    public router: Router,
    public menu: MenuController,
    private eventBusService: EventBusService
  ) { }

  ngOnInit() {
    this.showBack = this.router.url.indexOf('players') > - 1;
    this.showMenu = this.showMenu === undefined ?
      this.router.url.indexOf('team') > - 1 : this.showMenu;
    this.showNothing = this.router.url.indexOf('login') > - 1;
  }

  /**
   * Emits the the modal has been closed.
   */
  onCloseModal() {
    this.eventBusService.modalCloseClicked.emit();
  }

}
