<div class="text-center pad-4 position-relative">
  <div class="d-flex justify-content-center">
    <h2>Game Score </h2>
    <i class="fa-solid fa-circle-info" (click)="seeHelper()"></i>
  </div>

  <div>
    <p>{{game_score}}</p>
  </div>

  <div>
    <!-- happy place -->
    <app-button *ngIf="Game === appenums.HappyPlace" text="See Averages" (click)="seeExtra()" backgroundColor="white"></app-button>
    <!-- others -->
    <app-button *ngIf="Game !== appenums.HappyPlace" text="See Leaderboard" (click)="seeExtra()" backgroundColor="white"></app-button>
  </div>

</div>
