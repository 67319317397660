<div class="cont">
  <app-close (click)="closeModal()"></app-close>
  <div class="title-cont">
    <img data-cy="brain" src="../../../../../../assets/images/modal.png" alt="A happy brain" [ngStyle]= "{ 'left': currentTrainingOption.left }">
    <app-title text={{title}}></app-title>
  </div>
  <app-date-title text={{week}}></app-date-title>
  <hr>
  <ion-grid>
    <ion-row>
      <ion-col>
        <app-tile score={{score}}></app-tile>
      </ion-col>
      <ion-col size="8">
        <app-training-list [assessmentsOn]="assessmentOn" improve={{title}} [trainingItems]=currentTrainingOption.trainingItems (close)="closeSelf()"></app-training-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
