import { Component, Input, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';
import { HappyPlaceService } from '@services/happy-place.service';

@Component({
  selector: 'app-voice-selection-flyout',
  templateUrl: './voice-selection-flyout.component.html',
  styleUrls: ['./voice-selection-flyout.component.scss'],
})
export class VoiceSelectionFlyoutComponent extends Utilities implements OnInit {

  constructor(
    private hpService: HappyPlaceService
  ) { super(); }

  ngOnInit() {
    // this.hpService.voiceSelection.next('male');
    // this.closeModal();
  }

  voiceSelection(option: string) {
    // Emitting the voice selection
    this.hpService.voiceSelection.next(option);
    this.closeModal();
  }
}
