<div class="h-100">
  <!-- image  -->
  <div class="h-100" [hidden]='showLoading'>
    <img class="h-100 show" (load)='test()' src='{{ src }}'>
  </div>
  <!-- image/ -->

  <!-- image  -->
  <div [hidden]='!showLoading' class="loading">
    <span>loading</span>
  </div>
  <!-- image/ -->
</div>
