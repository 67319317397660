import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Utilities } from '@common/utilities';
import { AppEnum } from '@enums/AppEnum';
import { PuzzleGameEnum } from '@enums/PuzzleGameEnum';
import { BrainGameControllerService } from '@services/brain-game-controller.service';
import { LevelingService } from '@services/leveling.service';


@Component({
  selector: 'app-leveling-screen',
  templateUrl: './leveling-screen.component.html',
  styleUrls: ['./leveling-screen.component.scss'],
})
export class LevelingScreenComponent extends Utilities implements OnInit, OnChanges {

  appenums = AppEnum;

  // connected to BASE scores in leveling Service
  @Input() App;

  // connected to GAME scores in leveling Service
  @Input() Game;

  // score for whatever we just finished
  @Input() score;

   // if its a phaser game
   @Input() phaser;

   @Input() context;

  @Output() doneClicked = new EventEmitter();
  @Output() retryClicked = new EventEmitter();
  @Output() extraClicked = new EventEmitter();

  showSummary = false;

  constructor(
    private levelService: LevelingService,
    private bgController: BrainGameControllerService
  ) { super(); }

  ngOnInit() {
    this.levelService.timeToAddXp.subscribe(sub => {
      this.showSummary = true;
    })
    if(this.score === 0){
      console.error(`Score is 0 on ${this.App}, ${this.context}, ${this.Game}`)
    }
    return
  };

  async ngOnChanges(changes: SimpleChanges) {
      if(this.score){
        const test = await this.levelService.calculatePoints(this.App, this.Game, this.score);
      }
      if(this.score === 0){
        console.error(`Score is 0 on ${this.App}, ${this.context}, ${this.Game}`)
      }
  }

  seeAverages(): void {
    // Placeholder code for the seeAverages() function
    this.extraClicked.emit(null);
  }
  
  seeLeaderboard(): void {
    // Placeholder code for the seeLeaderboard() function
    this.extraClicked.emit(null);
  }
  
  done(): void {
    if(this.phaser){
      this.bgController.closeLeaderboardModal.next(true);
    }
    this.doneClicked.emit(null);
  }
  
  retry(): void {
    this.retryClicked.emit(null);
  }

  backToGame() {
    this.bgController.closeLeaderboardModal.next(true);
    return;
  }
  
  changeSummary() {
    this.showSummary = !this.showSummary;
  }
  
  

}
