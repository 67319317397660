import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-progress-detail',
  templateUrl: './progress-detail.component.html',
  styleUrls: ['./progress-detail.component.scss'],
})
export class ProgressDetailComponent implements OnInit, OnChanges {

  // Speed
  currentSpeed = 3;
  previousSpeed = 1;
  @Input() AssIndex= 0;

  @Input() scoreFactor = {
    title: 'Speed',
    score: Math.round(3 / 10),
    description: 'how quickly you can make use of information when it is presented to you',
    less_than: 'you may notice that you are more easily overwhelmed by tasks, and it takes you longer to complete complicated tasks',
    inbetween: 'you may notice that you are slightly less easily overwhelmed by tasks, and it takes you slightly less time to complete complicated tasks',
    greater_than: 'you may notice that you are less easily overwhelmed by tasks, and it takes you less time to complete complicated tasks',
    average: 'closer to your peer average (5)',
    ideal: 'closer to ideal',
    perfect: 'You should strive to continue to maintain your Speed abilities.',
    reason: 'tasks feel less overwhelming, and you will be able to complete complicated tasks more quickly',
    reason_perfect: 'continue to help make tasks feel less overwhelming, and you will be able to consistently complete complicated tasks more quickly',
    priority: 'balancing how quickly and accurately you complete Yahootie and Brain Game',
    category: 1,
    img: 'assets/images/report/runningicon.svg',
    current: this.currentSpeed,
    previous: this.previousSpeed,
    improvement: 0,
    below: 'you are more overwhelmed by tasks or can do tasks requiring brain resources more quickly',
    between: 'you are about as overwhelmed by tasks and can do tasks requiring brain resources in about the same amount of time',
    above: 'you are less overwhelmed by tasks or can do tasks requiring brain resources more quickly',
    help: 'you feel less overwhelmed by tasks and complete them more quickly',
    low: 'one of your lowest scored areas, so this will be prioritized for you in Brain Charge',
    middle: 'not one of your lowest scored areas, but there is still room for improvement, so you will still see items related to it in Brain Charge',
    high: 'scored at the maximum possible level, so you will only see a few items related to it in Brain Charge to help you maintain this high level of performance',
    startCompliance: 400,
  };

  trajectoryColor = '#ffb8a7';
  trajectoryText = '';
  trajectoryBorder = '';
  trajectoryTitle = '';

  comparisonColor = '#ffb8a7';
  comparisonText = '';

  goalColor = '#ffb8a7';
  goalText = '';
  goalScore = '';

  @Input() allData = []
  datas = [];
  perfect= false;

  constructor() {

  }

  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.allData) {
      this.barsOverTimeConfig(this.allData);
    }
    if(changes.AssIndex){
      this.scoreFactor.current = this.allData[this.AssIndex].display;
      this.scoreFactor.previous = this.allData[this.AssIndex - 1].display;
      this.scoreFactor.improvement = Math.floor((this.allData[this.AssIndex].number - this.allData[this.AssIndex - 1].number) / this.allData[this.AssIndex - 1].number * 100);
      this.trajectoryConfig(this.scoreFactor.improvement);
      this.comparisonConfig(this.scoreFactor.score);
      this.goalConfig(this.scoreFactor.score);
      this.goalScoreConfig(this.scoreFactor.score);
    }
  }

  async barsOverTimeConfig(data) {
    if(!data) {
      return;
    }
    const promises = data.map(dat => {
      this.datas.push({val: dat.display, date: dat.date});
      return true;
    });
    await Promise.all(promises);
    // needed to make ngOnChanges fire for arrays
    this.datas = this.datas.slice();
  }

  trajectoryConfig(improvement: number) {
    // red
    if (improvement < -5) {
      this.trajectoryColor = '#ffb8a7';
      this.trajectoryText = this.scoreFactor.below;
      this.trajectoryBorder = '3px solid #d8674b'
      this.trajectoryTitle = 'Declined'
      // yellow
    } else if (improvement <= 5) {
      this.trajectoryColor = '#f3ecd4';
      this.trajectoryText = this.scoreFactor.between;
      this.trajectoryBorder = '3px solid #d9bd60'
      this.trajectoryTitle = 'No Change'
      // green
    } else {
      this.trajectoryColor = '#CDFFBB';
      this.trajectoryText = this.scoreFactor.above;
      this.trajectoryBorder = '3px solid #b2f059'
      this.trajectoryTitle = 'Improved'
    }
  }

  comparisonConfig(score: number) {
    // red
    if (score < 5) {
      this.comparisonColor = '#ffb8a7';
      this.comparisonText = this.scoreFactor.less_than;
      // yellow
    } else if (score < 7) {
      this.comparisonColor = '#f3ecd4';
      this.comparisonText = this.scoreFactor.inbetween;
      // green
    } else {
      this.comparisonColor = '#CDFFBB';
      this.comparisonText = this.scoreFactor.greater_than;
    }
  }

  goalConfig(score: number) {
    this.perfect = false;
    // red
    if (score < 5) {
      this.goalColor = '#ffb8a7';
      this.goalText = this.scoreFactor.average;
      this.goalScore = this.scoreFactor.low;
      // yellow
    } else if (score < 9.9) {
      this.goalColor = '#f3ecd4';
      this.goalText = this.scoreFactor.ideal;
      this.goalScore = this.scoreFactor.middle;
      // green
    } else {
      this.perfect = true;
      this.goalColor = '#CDFFBB';
      this.goalText = this.scoreFactor.perfect;
      this.goalScore = this.scoreFactor.high;
    }
  }

  goalScoreConfig(score: number) {
    // red
    if (score < 3) {
      this.goalScore = this.scoreFactor.low;
      // yellow
    } else if (score < 10) {
      this.goalScore = this.scoreFactor.middle;
      // green
    } else {
      this.goalScore = this.scoreFactor.high;
    }
  }

}
