import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-instructions-header',
  templateUrl: './instructions-header.component.html',
  styleUrls: ['./instructions-header.component.scss'],
})
export class InstructionsHeaderComponent implements OnInit {
  @Input() headerTitle?: string;
  @Input() headerClass = 'background-primary-green';
  @Input() appTitle = 'Brain Grame';
  @Input() logoClass = 'brain-game-logo';
  
  @Output()
  instructionEnd: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  ngOnInit() {}

  /**
   * Emits an event when the last instructions are displayed.
   */
  onInstructionEnded(): void {
    this.instructionEnd.emit();
  }

}
