<div class='main-circle'>
  <div class='inner-cont'>

    <div class='text-cont'>
      <div class='descriptor'>
        <span>{{descriptor}}</span>
      </div>

      <div class='score'>
        <span>{{score.toFixed(1)}}</span>
      </div>

      <!-- <div class='compliance'>
        <span>Compliance: {{compliance}}%</span>
      </div> -->
    </div>

    <div class='color-wheel'>
      <img src='../../../../assets/images/colorwheel.png'>
    </div>

    <div 
      class='pointer'
      [ngStyle]= '{ "left": pointer.left,
        "bottom": pointer.bottom,
        "background-color": pointer.backgroundColor,
        "transform": pointer.transform }'>
    </div>
  </div>
</div>
