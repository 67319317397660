<div class="following-container">
  <div *ngIf="assessmentsOn">
  <ion-text *ngIf="improve === 'mood' || improve === 'sleep'" class="focus-text">Focusing on the following training items may improve your {{improve}}:</ion-text>
  <ion-text *ngIf="improve !== 'mood' && improve !== 'sleep'" class="focus-text">Focusing on the following training items may improve your {{improve}} rating:</ion-text>
  </div>
  <div class="apps-list">
    <div class="app" *ngFor="let item of trainingItems">
      <ion-text class="app-focus">
        <ion-text class="apps-name" (click)="goToApp(item.app)">{{item.app}}</ion-text> 
        - {{item.guide}}
      </ion-text>
    </div>
  </div>
</div>