export class SpecialStats {
  /**
   * Make braingame create its own app objects using Object.values(PuzzleGameEnum)
   */
  BrainGame: {
    Overall: {
      recent: number,
      top: number
    }
    Lemonade: {
      recent: number,
      top: number
    },
    Sudoku: {
      recent: number,
      top: number
    },
    Large_Sudoku: {
      recent: number,
      top: number
    },
    The_Fame_Game: {
      recent: number,
      top: number
    },
    Puzzle_Master: {
      recent: number,
      top: number
    },
    Brain_Defense: {
      recent: number,
      top: number
    },
    Synapse_Scramble: {
      recent: number,
      top: number
    },
  };
  Yahootie:{
    recent: number,
    top: number
  };
  Clubhouse: {
    meetingsAttended: number,
    lastDate: Date
  };
  HappyPlace: {
    currentStreak: number,
    topStreak: number,
    lastDate: Date
  };
  id: string;

  constructor(uid: string){
    this.id = uid;
    this.BrainGame = {
      Overall: {
        recent: 0,
        top: 0,
      },
      The_Fame_Game: {
        recent: 0,
        top: 0,
      },
      Lemonade: {
        recent: 0,
        top: 0,
      },
      Large_Sudoku: {
        recent: 0,
        top: 0,
      },
      Sudoku: {
        recent: 0,
        top: 0,
      },
      Puzzle_Master: {
        recent: 0,
        top: 0,
      },
      Brain_Defense: {
        recent: 0,
        top: 0,
      },
      Synapse_Scramble: {
        recent: 0,
        top: 0,
      }
    };
    this.Yahootie = {
      recent: 0,
      top: 0
    };
    this.Clubhouse = {
      meetingsAttended: 0,
      lastDate: new Date()
    };
    this.HappyPlace = {
      currentStreak: 0,
      topStreak: 0,
      lastDate: new Date()
    }
  }
}
  