import { Component, Input, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
})
export class LegalComponent extends Utilities implements OnInit {

  @Input() pdf: string;

  constructor() {
    super();
  }

  ngOnInit() { }
}
