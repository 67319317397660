import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-scorechart',
  templateUrl: './scorechart.component.html',
  styleUrls: ['./scorechart.component.scss'],
})
export class ScorechartComponent implements OnInit {

  startColor ;

  todayColor  ;

  @Input() currentScore  ;

  @Input() startScore ;

  constructor() { }

  ngOnInit() {
    this.startColor = this.setColor(this.startScore)
    this.todayColor = this.setColor(this.currentScore)
  }
  setColor(score:number) {
    if(score >= 40 && score <= 60) {
      return 'warning';
    } 
    else if (score <= 30) {
      return 'danger';
    }
    else if (score >= 60) {
      return 'success'
    }
  }
}
