import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { NavController } from '@ionic/angular';
import { EventBusService } from '@services/event-bus.service';


@Component({
  selector: 'app-global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss']
})
export class GlobalHeaderComponent implements OnInit {
  @Input() headerTitle: string;
  @Input() showBack = false;
  @Input() showTimer = false;
  @Input() countDownTime: number;
  @Output() timeExpire: EventEmitter<any> = new EventEmitter();
  @Input() showLogo = true;
  @Input() headerColor = 'secondary';
  @Input() logoClass = 'logo small';
  
  constructor(
    public navCtrl: NavController,
    private eventBusService: EventBusService) { }

  ngOnInit() { }

  /**
   * Goes back to the previous page.
   */
  goBack(): void {
    this.navCtrl.back();
  }

  /**
   * Emits that the time has expired.
   */
  timerExpiredHandler(): void {
    this.timeExpire.emit();
  }
}
