<div class='background centered' style="overflow: auto" data-cy='mood_sleep_modal'>
  <ion-text>
    <h1>{{title}}</h1>
  </ion-text>
  <div class='text-center' style='color: red' *ngIf='errorMsg'>
    <span>{{errorMsg}}</span>
  </div>
  <div class="why">
    <ion-text>Why do we ask?</ion-text>
  </div>
  <div class="info">
    <ion-text>Sleep and mood are important elements of a fit brain. Enter your sleep and mood to see how they change as you train.</ion-text>
  </div>
  <h2>How are you feeling?</h2>
  <!-- mood images container -->
  <ion-grid>
    <ion-row class="justify-content-center">
      <ion-col *ngFor='let mood of moods; let i = index' size="12" size-sm="4" size-md="2.4" attr.data-cy='mood{{i}}'>
        <div *ngIf='mood.enabled'>
          <img src='{{mood.imageUrl}}' [ngStyle]="{'background-color': cobranding.primary}" (click)='moodClicked(i)'>
        </div>
        <div *ngIf='!mood.enabled'>
          <img src='{{mood.imageUrl}}' (click)='moodClicked(i)'>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <!-- hour images container -->
  <div *ngIf="showSleep">
    <h2>How much sleep did you get last night?</h2>
    <div class='custom-selector'>
      <select name="sleepHours" id="sleepHours" data-cy="sleepHours" (change)='hoursClicked();flipArrowBack()' (select)="flipArrowBack()" (focus)="flipArrow()" (blur)="flipArrowBack()">
        <option *ngFor="let hour of hours" data-cy="hour{{hour.name}}" value={{hour.name}}>{{hour.string}}</option>
      </select>
      <span class="custom-arrow" [ngStyle]="{'transform': transform }"></span>
    </div>
  </div>
  <app-button text="Continue" data-cy='contButton' (click)="done()"></app-button>
</div>
