export enum AppEnum {
  Yahootie = 'Yahootie',
  Assessment = 'Assessment',
  BrainGame = 'Brain Game',
  HappyPlace = 'Happy Place',
  Clubhouse = 'Clubhouse',
  Settings = 'Settings',
  General = 'General',
  OutsideTraining = 'Outside Training'
}
