import { Component, Input, OnInit } from '@angular/core';
import { AssessmentService } from '@services/assessment.service';
import { Chart, ChartConfiguration, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';

@Component({
  selector: 'app-compliance-chart',
  templateUrl: './compliance-chart.component.html',
  styleUrls: ['./compliance-chart.component.scss'],
})
export class ComplianceChartComponent implements OnInit {
  CompChart2;
  @Input() assessments = [];

  constructor(
    private assessmentService: AssessmentService
  ) { }

  ngOnInit() {
    Chart.register(...registerables)
    const chart= <HTMLCanvasElement> document.getElementById('myChart');
    this.CompChart2 = new Chart(chart, this.mySecondChartOptions);
    this.assessments.forEach((data,index) => {
      this.assessmentService.getCompliancesBy3Months(data.created_date).then(compliance => {
        let label = data.display_date;
        if(index === 0) {label = "Baseline"}
        this.addData(this.CompChart2,label,~~compliance.toFixed(0));
      })
    })
  }

  addData(chart, label, data) {
    chart.data.labels.push(label);
    chart.data.datasets.forEach((dataset) => {
        dataset.data.push(data);
    });
    chart.update();
  }

  mySecondChartOptions = {
    plugins: [ChartDataLabels],
    type: 'line',
    data: {
      labels: [],
      datasets: [{ 
        label: 'Compliance',
        data: [],
        fill: false,
        borderColor: 'rgb(42, 140, 240)',
        backgroundColor: 'rgb(42, 140, 240)',
      }]
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          font: {
            size: 20,
            weight: 900
          },
          align: "top",
          color: "black"
        },
        legend: {
          display: false
        }
      },
      scales: {
        y: {
          max: 100,
          min: 0
        },
        x: {
          offset: true,
          display: true
        }
      }
    }
  }as ChartConfiguration;
}
