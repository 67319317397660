import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { HomeService } from '@services/home.service';
import { LegalComponent } from '../modal/legal/legal.component';
import { Utilities } from '@common/utilities';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends Utilities implements OnInit {

  authUser: any;
  cobranding: any;

  currentYear = new Date().getFullYear();

  constructor(
    public modalController: ModalController,
    private homeService: HomeService,
    private navController: NavController,
  ) { super(); }

  ngOnInit() {
    this.configureStyles();
  }

  /**
   * Gets the cobranding styles from the HomeService.
   */
  configureStyles(): void {
    this.homeService.getCobranding().subscribe(cobrand => {
      this.cobranding = cobrand;
    });;
  }

  /**
   * Navigates to the landing page.
   */
  navHome(): void {
      this.navController.navigateForward('landing');
  }

  /**
   * Presents the legal component.
   * @param pdf The string title of the pdf to be presented.
   */
  openPdf(pdf: string): void {
    this.presentModal(LegalComponent, 'legal-modal', {
      pdf: pdf
    });
  }
}
