import { statNumTracker } from "./statNumTracker.model";

export class bcScore{
    speed         : any;
    intelligence  : any;
    problemSolving: any;
    focus         : any;
    memory        : any;
    mood          : any;
    sleep         : any;
    bcScore       : any;
    bcCompliance  : any;
    created_date: Date;

    constructor() {
        this.speed         = 0;
        this.intelligence  = 0;
        this.problemSolving= 0;
        this.focus         = 0;
        this.memory        = 0;
        this.mood          = 0;
        this.sleep         = 0;
        this.bcScore       = 0;
    }
}