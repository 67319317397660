<div class="cont">
  <app-close (click)="closeModal()"></app-close>
  <div class="title-cont">
    <ion-icon name="warning"></ion-icon>
    <app-title text="Skip Training"></app-title>
    <ion-icon name="warning"></ion-icon>
  </div>
  <ion-text>We notice that you have been skipping <strong>{{ name }}</strong> a lot.</ion-text>
  <ion-text>Please help us improve your experience by telling us why.</ion-text>
  <ion-text class="skip-bc">I usually skip <strong>{{ name }}</strong> because:</ion-text>
  <div class='text-center' style='color: red' *ngIf='errorMsg'>
    <span>{{errorMsg}}</span>
  </div>
  <div class="custom-selector">
    <select name="skip-select" id="skip-select" data-cy="skip-select" (change)="flipArrowBack();reasonSelected()" (select)="flipArrowBack();reasonSelected()" (focus)="flipArrow()" (blur)="flipArrowBack()">
      <option value="none" selected disabled hidden>Reason For Skipping</option>
      <option *ngFor="let reason of reasons; let i = index" [value]="i">{{ reason }}</option>
    </select>
    <span class="custom-arrow" [ngStyle]="{'transform': transform }"></span>
  </div>
  <ion-grid class="button-cont">
    <ion-row>
      <ion-col>
        <app-button backgroundColor="white" text="do not skip" (click)="closeModal()"></app-button>
      </ion-col>
      <ion-col>
        <app-button text="continue" (click)="toApp()"></app-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
