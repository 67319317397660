<div class="flex-wrapper overflow-none full-height">

  <div class="flex-stretch-vertical">
    <!-- content -->
    <div 
      class="container" 
      id='slide-container'>
      <div 
        class='slides icon-con'
        (click)='moveToPrev()'>
        <ion-icon 
          class='slides iconic' 
          id='icon-left' 
          name="arrow-round-back">
        </ion-icon>
      </div>

      <div 
        class='slides icon-con leftaholics'
        (click)='moveToNext()'>
        <ion-icon 
          class='slides iconic forward-arrow' 
          id='icon-right' 
          name="arrow-round-forward">
        </ion-icon>
      </div>
  
      <!-- swiper -->
      <swiper 
        [config]="swiperConfig" 
        class="be-swiper" 
        (indexChange)="onIndexChange($event)">
        <div *ngFor="let instruction of instructions">
          <div class='slides pos-rel'>
            <p 
              class="instruction" 
              [innerHTML]="instruction.text"
              class="margin-0">
            </p>
            <img 
              *ngIf="instruction.imageURL" 
              [src]="instruction.imageURL"
              alt=""
              class="instruction-img">
          </div>
        </div>  
      </swiper>
      <!-- swiper /-->
  
    </div>
    <!-- content /-->
  
    <!-- continue button -->
    <div 
      class="background-primary background-padded full-height" 
      *ngIf="showRemove">
      <app-button text="Remove Slide" (click)="removeSlide()"></app-button>
    </div>
    <!-- continue button /-->
  </div>
</div>


