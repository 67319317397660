import { Injectable } from '@angular/core';
import { DB_CONFIG } from '@app/app.firebase.config';
import { Platform } from '@ionic/angular';
import { DataTracker } from '@models/dataTrackerModels/dataTracker2.model';
import { AdminService } from './admin.service';
import * as _ from 'underscore';
import { statNumTracker } from '@models/dataTrackerModels/statNumTracker.model';
import { take } from 'rxjs/operators';
import { AppEnum } from '@enums/AppEnum';
import { AssessmentData } from '@models/dataTrackerModels/assessment.model';
import { BrainGameData } from '@models/dataTrackerModels/braingame.model';
import { ClubhouseData } from '@models/dataTrackerModels/clubhouse.model';
import { YahootieData } from '@models/dataTrackerModels/yahootie.model';
import { AuthenticationService } from './authentication.service';
import { PuzzleGameEnum } from '@enums/PuzzleGameEnum';
import { StatNumbers } from '@models/dataTrackerModels/statNums.model';
import { HappyPlaceData } from '@models/dataTrackerModels/happyplace.model';

@Injectable({
  providedIn: 'root'
})
export class DataTrackingService {

  newTracker = true;
  dataTracker: DataTracker;
  specialStatTracker: statNumTracker;
  deviceType: string;
  currentDataObj: AssessmentData | BrainGameData | ClubhouseData | YahootieData | HappyPlaceData = null as any;
  currentDataType = '';
  timers = [];
  bounceable = false;
  trigger = [];
  authUser: any;

  constructor(
    private adminService: AdminService,
    private platform: Platform,
    private authService: AuthenticationService
  ) { this.init() }

  /**
   * initialization function, sets up our special stat tracker
   */
  private init() {
    this.getDeviceType();
    this.getUser();
  }

  /**
   * Gets the device type being used currently.
   */
  private getDeviceType(): void {
    const devices = this.platform.platforms();

    if (devices[0]) {
      if (_.contains(devices, 'tablet')) {
        this.deviceType = 'tablet';
      } else if (_.contains(devices, 'mobile')) {
        this.deviceType = 'mobile';
      } else if (_.contains(devices, 'desktop')) {
        this.deviceType = 'desktop';
      } else {
        this.deviceType = 'none';
      }
    } else {
      this.deviceType = 'none';
    }
  }

  /**
   * Gets the authenticated user from the AuthenticationService.
   */
  private getUser(): void {
    this.authService.userSubject.subscribe(user => {
      this.authUser = user;
      if(!user){return;}
      this.getDataTracker(user.id);
    })
  }

  /**
   * Gets the special stat tracker from the database and 
   * creates a new tracker for this session for raw data.
   * @param userId The userId of the user to track.
   */
  getDataTracker(userId: string): void {
    // TODO: FIGURE OUT HOW TO KNOW OS
    this.dataTracker = new DataTracker(userId, this.deviceType, 'test');
    const table = DB_CONFIG.stat_tracker_endpoint;

    this.adminService.getEntryById(userId, table).then(data => {
      // initial stat tracker creation
      if(!data) {
        this.specialStatTracker = new statNumTracker(userId);
        this.adminService.saveEntryById(this.specialStatTracker, table).then(id => {
          this.specialStatTracker = Object.assign({id}, this.specialStatTracker);
        })
      } else {
        if(data.id !== this.authUser.uid) {
          this.specialStatTracker = this.adminService.replaceIdWithUid(data, table);
        } else {
          this.specialStatTracker = data;
          if(!this.specialStatTracker.HappyPlace.moodNum){
            this.specialStatTracker.HappyPlace.moodNum = {...new StatNumbers()};
            this.adminService.saveEntryById(this.specialStatTracker, table).then(id => {
              this.specialStatTracker = Object.assign({id}, this.specialStatTracker);
            })
          }
        }
      }
    });
  }

  /**
   * Must call this when starting down a path to track that path,
   * creates a data app object for our tracker based on which app is given.
   * If a path is already being tracked it saves/overwrites it.
   * @param AppName App that is being tracked now, likely a path has started.
   */
  createAppObject(AppName: AppEnum): void {
    this.dataTracker.generalData.bounces.push({val: 1, time: new Date()});
    this.bounceable = true;

    if(this.currentDataObj) {
      this.currentDataObj.endTracking();
      let objCopy = new Object();
      Object.assign(objCopy, this.currentDataObj);
      objCopy = objCopy as Object;
      // push whatever we got to the object
      this.dataTracker.data.push(objCopy);
      this.updateStatTracker(objCopy);
    }
    switch(AppName) {
      case AppEnum.Assessment:{
        this.currentDataObj = new AssessmentData(); 
        this.currentDataType = AppEnum.Assessment;
        break;
      }
      case AppEnum.BrainGame:{
        this.currentDataObj = new BrainGameData();
        this.currentDataType = 'BrainGame';
        break;
      }
      case AppEnum.Clubhouse:{
        this.currentDataObj = new ClubhouseData();
        this.currentDataType = AppEnum.Clubhouse;
        break;
      }
      case AppEnum.HappyPlace:{
        this.currentDataObj = new HappyPlaceData();
        this.currentDataType = AppEnum.HappyPlace;
        break;
      }
      case AppEnum.Yahootie:{
        this.currentDataObj = new YahootieData();
        this.currentDataType = AppEnum.Yahootie;
        break;
      }
    }
  }

  /**
   * Adds a path and timestamp for whenever our user navigates the app.
   * @param path The path to add to general data tracker.
   */
  addPath(path: string): void {
    // if statements are because listener in app.component triggers 
    // multiple times per router event so we need to be safe of errors
    if(this.dataTracker) {
      const lastIndex = this.dataTracker.generalData.pathTaken.length - 1;
      const lastPath = this.dataTracker.generalData.pathTaken[lastIndex];
      if(!lastPath || path !== lastPath.val) {
        this.dataTracker.generalData.pathTaken.push({val: path, time: new Date()});
      }
    }
  }

  /**
   * Keeps track of timers when we send them in based on field name.
   * @param fieldName The field that is being saved ex: "triviaSpeed".
   */
  startTimer(fieldName: string): void {
    console.log('timer started for ' + fieldName);
    const currentTime = new Date();
    this.timers.push({fieldName, currentTime});
  }

  /**
   * Ends the timer. Can cumulatively add to a timer. Needed in cases such as triviaSpeed
   * and hometimeSpent.
   * @param fieldName The field being timed ex: "triviaSpeed".
   * @param general Whether the data being saved is part of our general data.
   */
  async endTimer(fieldName: string, general?: boolean): Promise<number> {
    console.log('timer ended for' + fieldName);
    const timerIndex = this.findTimer(fieldName);
    const currTime = new Date().getTime();
    if(timerIndex === null) {
      throw new Error('cant find timer for' + fieldName);
    }

    // make sure timer index exists
    if (this.timers[timerIndex]) {
      const timer = currTime - this.timers[timerIndex].currentTime.getTime();
      this.timers.splice(timerIndex, 1);
  
      // save timer 
      this.addValue(fieldName, timer, general);
      return timer;
    } else {
      throw new Error(`Timer doesn't exist at ${fieldName} and index ${timerIndex}`);
    }
  }

  /**
   * Finds a timer in our one timer list.
   * @param fieldName The field being searching for.
   * @returns The amount of time left.
   */
  private findTimer(fieldName: string): number {
    for (let i = 0; i < this.timers.length; i++) {
      const timer = this.timers[i];
      if (timer.fieldName === fieldName) {
        return i;
      }
    }
  }

  /**
   * Adds the value to the data tracker. Cumulutively will save data if we've already saved it once before.
   * Unless you create a new data object.
   * @param fieldName The field to be saved to ex: "triviaAcc".
   * @param val The value to save to given field.
   * @param general The set to true if the field is in the general struct.
   * @param cumulative The set to true if you dont want it to be cumulatively added.
   */
  addValue(fieldName: string, val: any, general?: boolean): void {
    if(general) {
      this.dataTracker.generalData[fieldName].push({val, time: new Date()});
    }
    else if (!this.currentDataObj) {
      console.log(`this.currentDataObj doesnt exist!`);
    }
    else if (!this.currentDataObj[fieldName]) {
      console.log(`this.currentDataObj[${fieldName}] doesnt exist!`);
    }
    else {
      if(this.currentDataObj) {
        this.currentDataObj[fieldName].push({val, time: new Date()});
      } else if(this.currentDataType) {
        this.createAppObject(this.currentDataType as AppEnum);
        this.currentDataObj[fieldName].push({val, time: new Date()});
      } else {
        throw new Error('add value cant find dataobj or datatype :(');
      }
    }
  }

  /**
   * Updates the stat tracker with the current data object, 
   * Relies on only being called once an end of path is achieved or when a bounce occurs.
   * A bounce will likely lead to some poor stats.
   * @param dataObj The current object of data.
   */
  async updateStatTracker(dataObj?: any): Promise<void> {
    let dataType = this.currentDataType;
    if(!dataObj) {
      dataObj = this.dataTracker.generalData;
      dataType = 'General';
    }
    const keys = Object.keys(dataObj);
    this.dataTracker.appsVisited.push(dataType);

    try {
      for (let key of keys) {
        const data = dataObj[key];
        if(typeof dataObj[key] !== 'object') {
          continue;
        }
        for(let entry of data) {
          let newKey = key;
          // converts generic game score to a specific game score
          if(key === 'gameScore') {
            switch(dataObj.gameName[0].val) {
              case PuzzleGameEnum.Lemonade: {
                newKey = 'lemonScore';
                break;
              }
              case PuzzleGameEnum.Sliding: {
                newKey = 'slidingScore';
                break;
              }
              case PuzzleGameEnum.Sudoku: {
                newKey = 'sudokuScore';
                break;
              }
              case PuzzleGameEnum.Trail: {
                newKey = 'trailScore';
                break;
              }
              case PuzzleGameEnum.Tower: {
                newKey = 'towerScore';
                break;
              }
            }
          }
          // strings cant be added to means
          if(typeof entry.val === 'string') {
            continue;
          } else {
            await this.addToStatNums(entry.val, this.specialStatTracker[dataType][newKey], newKey).then((newStats: any) => {
              this.specialStatTracker[dataType][newKey] = newStats;
            });
          }
          
        }
      }
    } catch(err) {
      throw err;
    } finally {
      this.saveDataTrackers();
    }
  }

  /**
   * Saves the data trackers into the database.
   */
  saveDataTrackers(): void {
    // saving logic
    // this.adminService.saveEntryById(this.specialStatTracker, DB_CONFIG.stat_tracker_endpoint);
    this.dataTracker.writeCounter++;
    let objCopy = new Object();
    Object.assign(objCopy, this.dataTracker);
    if (this.newTracker) {
      this.adminService.saveEntryById(objCopy, DB_CONFIG.data_tracker_endpoint).then(id => {
        this.dataTracker['id'] = id;
      });
      this.newTracker = false;
    } else {
      this.adminService.updateEntry(objCopy, DB_CONFIG.data_tracker_endpoint);
    }
  }

  /**
   * Takes a value and adds it to an existing statnum {mean, n, stdDev}.
   * @param val The value to add.
   * @param statNum The existing statnum to modify.
   * @param key The key needed to add to triggers.
   * @returns A statnum with value added to it.
   */
  private addToStatNums(val: number, statNum: any, key: string) {
    if(!statNum){ statNum = new StatNumbers()}
    const mean = statNum.mean;
    const n = statNum.n;
    const stdDev = statNum.stdDev;
    const outlierCheck = stdDev * 3;
    const absStat = Math.abs(val - mean);

    // likely check for bounce occuring so we dont send out alerts
    if (absStat > outlierCheck && this.trigger.length < 3) {
      this.findTrigger(key).then(i => {
        if(!i) {
          const existsAlready = this.trigger.some((trig) => {
            return trig === false
          });
          if(existsAlready) {
            console.log('TRIGGER EXISTS ALREADY');
          } else {
            console.log({key, val});
            this.trigger.push({app: this.currentDataType, key, val, statNum});
            if (this.trigger.length === 3) {
              // send email
              console.log('OUTLIER');
              this.sendAcuteAlert();
            }
          }
        }
      });
    }

    return new Promise((res,rej) => {
        let newMean = mean * n;
        const newN = n + 1;
        newMean += val;
        newMean /= newN;

        // new summation, (o^2 + u^2) * N
        let newStdDev = (Math.pow(stdDev, 2) + Math.pow(mean, 2)) * n;
        // do std dev now, ((Ex^2 + xi^2) / N+1) - ui^2
        newStdDev = ((newStdDev + Math.pow(val, 2)) / newN) - Math.pow(newMean, 2);
        newStdDev = Math.sqrt(newStdDev);
        res({mean: newMean, stdDev: newStdDev, n: newN});
    });
  }

  /**
   * Finds the trigger given a key.
   * @param fieldName The field we are searching for.
   */
  private findTrigger(key: string) {
    return new Promise(async (res, rej) => {
      for (let i = 0; i < this.trigger.length; i++) {
        const trigger = this.trigger[i];
        if (trigger.key === key) {
          await res(i);
        }
      }
      res(false);
    });
  }

  /**
   * Pops a bounce, calculates sesstime, and updates stat tracker.
   * Called at the end of any path for our apps.
   */
  endOfPath(): void {
    this.dataTracker.generalData.bounces.pop();
    this.bounceable = false;
    if(this.currentDataObj) {
      this.currentDataObj.endTracking();
      let objCopy = new Object();
      Object.assign(objCopy, this.currentDataObj);
      objCopy = objCopy as Object;
      // push whatever we got to the object
      this.dataTracker.data.push(objCopy);
      // this.updateStatTracker(objCopy);
      this.currentDataObj = null;
    } 
  }

  /**
   * Called when a bounce needs to be lost but its not neccessarily at the end of path.
   * Currently only used in Clubhouse as tracking end of path is very difficult.
   */
  loseBounce(): void {
    if(this.bounceable) {
      this.dataTracker.generalData.bounces.pop();
      this.bounceable = false;
    }
  }

  /**
   * Used by sendAcuteAlert to format an email with all the stats that we have
   * determined to pass our trigger limits.
   * @returns The message 
   */
  private async formatEmailMessage(): Promise<string> {
    let msg = '<b>' + this.authUser.username + '</b> ' as string;
    for(let stat of this.trigger) {
      msg += stat.app + '/' + stat.key;
      msg = await msg.concat(
      '\n has this stat value: ' + stat.val + 
      '\n compared to this mean: ' + stat.statNum.mean + 
      '\n with this n: ' + stat.statNum.n +
      '\n and this stdDev: ' + stat.statNum.stdDev
      );
    }
    return msg;
  }

  /**
   * Sends an email to Ellen with the stats we have sensed as off charts.
   */
  private async sendAcuteAlert(): Promise<void> {
    // TODO: send to the subscriber of the user as well
    const msg = await this.formatEmailMessage();
    // generic response
    const msg2 = '<b>' + this.authUser.username + '</b> may need assistance. Their Brain Charge data indicates that they may' +
    ' be experiencing an acute issue. Specifically, we are seeing evidence of sudden' +
    ' changes to their alertness/fatigue, mood, and clarity. Please check on them as soon' +
    ' as you are able.' + this.authUser.id + '  ' + this.dataTracker.created_date;
    const email = {
      to: 'ellen@brainevolved.com',
      // to: 'clarkeyt624@gmail.com',
      message: {
        html: msg,
        subject: 'ACUTE ALERT STATS'
      }
    }
    const email2 = {
      // to: 'eclarke86@gmail.com',
      to: 'clarkeyt624@gmail.com',
      message: {
        html: msg2,
        subject: 'Brain Evolved Generic Alert'
      }
    }
    // this.adminService.saveEntry(email, DB_CONFIG.email_endpoint);
    // this.adminService.saveEntry(email2, DB_CONFIG.email_endpoint);
    this.trigger = [];
  }
}
