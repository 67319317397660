import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-get-ready',
  templateUrl: './get-ready.component.html',
  styleUrls: ['./get-ready.component.scss'],
})
export class GetReadyComponent implements OnInit {
  @Output()
  counterEnd: EventEmitter<any> = new EventEmitter();
  
  counter = 3;
  showCounter = false;

  constructor() { }

  ngOnInit() {
    let interval: any;

    setTimeout(() => {
      this.showCounter = true;
    }, 1000);
    
    interval = setInterval(() => {
      this.counter--;

      if (this.counter === 1) {
        // Stop the interval
        clearInterval(interval);
        this.counterEnd.emit();
      }
    }, 2000);
  }

}
