import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'be-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {

  @Input() src: string;

  @Output() load = new EventEmitter<boolean>();

  showLoading = true;

  constructor() { }

  ngOnInit() {}

  async test() {
    const wait = (delay, ...args) => new Promise(resolve => setTimeout(resolve, delay, ...args));
    await wait(250).then( () => {
      this.showLoading = false;
      this.load.emit(true);
    });
  }

}
