<ion-header color="primary-grey">
  <!-- toolbar -->
  <ion-toolbar color="primary-grey">

    <ion-buttons slot="start">
      <ion-menu-button *ngIf="showMenu"></ion-menu-button>
      <ion-back-button
        *ngIf="showBack && !showNothing"
        defaultHref="/registration/team"
        class="zoom">
      </ion-back-button>
    </ion-buttons>

    <!-- main header shifted to right for menu/back -->
    <div 
      class="padding-top-20 content-center height-{{ height }}"
      [ngClass]="{'content-centerright': showMenu || showBack}">
      <div class="logo club-house"></div>
      <app-branding appTitle="Club House"></app-branding>
    </div>
    <!-- main header right shift /-->
    
    <!-- rollcall/successes/present -->
    <div>
      <ion-grid>
        <ion-row class="ion-text-center">
          <!-- <ion-col size = '1.5'> </ion-col> -->
          <ion-col size = '4' (click)='navigate("rollcall")'>
            <p [ngClass]="{'greyed': !rollcall}">Roll Call</p>
          </ion-col>
          <ion-col size = '4' (click)='navigate("successes")'>
            <p [ngClass]="{'greyed': !successes}">Successes</p>
          </ion-col>
          <ion-col size = '4' (click)='navigate("present")'>
            <p [ngClass]="{'greyed': !presentation}">Present</p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <!-- rollcall/successes/present / -->
  </ion-toolbar>
</ion-header>