<div class="h-100">
  <div class="didyouknow-header">
    <img src="assets/images/didyouknowheader.png">
    <app-close (click)="closeModal()"></app-close>
  </div>
  <div *ngIf="displayed_dyk.title !== 'weekly_report'">
    <div class="text-title">
      <h2> {{displayed_dyk?.title}}</h2>
    </div>
  
    <div class="content-container">
      <div class="col-sm-12 text-part">
        {{displayed_dyk?.text}}
      </div>
      <div class="col-sm-12">
        <div class="d-flex justify-content-center" (click)="articleClicked(displayed_dyk?.link)">
          <img class="article" src="{{displayed_dyk?.image}}">
        </div>
      </div>
    </div>
    <div class="done-btn">
      <app-button text="see next" (click)="seeNext()"></app-button>
      <app-button text="see prev" (click)="prev()"></app-button>
      <app-button text="Done" (click)="closeModal()"></app-button>
    </div>
  </div>



  <div *ngIf="displayed_dyk.title === 'weekly_report'">
    <div class="text-title">
      <h2>You have a Weekly Progress Report Available!!</h2>
    </div>
  
    <div class="content-container">
      <div class="col-sm-12 col-md-6">
        <div class="col-sm-12 text-part">
          <h2>How to view it</h2>
          <p>Click on the Progress Tab and then the Weekly Sub tab</p>
        </div>
        <div class="col-sm-12">
          <div class="d-flex justify-content-center" (click)="articleClicked(displayed_dyk?.link)">
            <img class="article" src="https://firebasestorage.googleapis.com/v0/b/brain-evolved-dev.appspot.com/o/didyouknow_images%2F1699633898364_weeklyy_report.png?alt=media&token=a9f26d9e-5d11-4a5c-a7a8-2ced8f3bbea8">
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="col-sm-12 text-part">
          <h2>Why view it</h2>
          <p>The Weekly Progress Report lets you know how you did on your training during the past week and allows you to compare it to previous weeks. This way you can frequently check in on your training progress.
          </p>
        </div>
      </div>
      
    </div>
    <div class="done-btn">
      <app-button text="see next" (click)="seeNext()"></app-button>
      <app-button text="see prev" (click)="prev()"></app-button>
      <app-button text="Done" (click)="closeModal()"></app-button>
    </div>
  </div>
  
</div>


