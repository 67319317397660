import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import { SwiperModule } from 'ngx-swiper-wrapper';

import { AssessmentPage } from './assessment.page';

import { AppCommonModule } from '@common/app-common.module';
import { DisclaimerPage } from '@common/components/modal/disclaimer/disclaimer.page';
import { AssessmentComponentsModule } from '@app/assessment/components/components.module';
import { ReportPage } from './report/report.page';
import { QuizoneComponent } from './quiz-one/quiz-one.component';
import { QuiztwoComponent } from './quiz-two/quiz-two.component';
import { QuizthreeComponent } from './quiz-three/quiz-three.component';
import { SetupComponent } from './setup/setup.component';
import { HomeComponentsModule } from '@app/home/components/homecomponents.module';

const routes: Routes = [
  {
    path: '',
    component: AssessmentPage
  },
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    AppCommonModule,
    SwiperModule,
    RouterModule.forChild(routes),
    AssessmentComponentsModule,
    HomeComponentsModule
  ],
  declarations: [
    AssessmentPage,
    DisclaimerPage,
    ReportPage,
    QuizoneComponent,
    QuiztwoComponent,
    QuizthreeComponent,
    SetupComponent
  ],
  exports: [
    ReportPage
  ]
})
export class AssessmentPageModule {}
