<div class="number-container" *ngIf="!hideDisplay">
  <!-- add -->
  <div class="d-flex" *ngIf="!sum">
    <div class="number text-center ml-5 mr-5" [@numberFlyInLeft]="addAnimationState">
      <div>
        Base
      </div>
      <div>
        {{firstNumber}}
      </div>
      
    </div>
    <div class="plus"   [@fadeOut]="addAnimationState">+</div>
    <div class="number" [@numberFlyInRight]="addAnimationState">
      <div>
        Completion
      </div>
      <div>
        {{secondNumber}}
      </div>
    </div>
  </div>
  <div *ngIf="sum && !totalAdded" class="sum text-center" [@numberFlyInLeft]="multiplyAnimationState" [@fadeIn]
  [ngClass]="{'ml-5': multiplyAnimationState, 'mr-5': multiplyAnimationState}">
    <div>
      Sum
    </div>
    <div>
      {{sum}}
    </div>
  </div>
  <div *ngIf="multiplyAnimationState && !totalAdded" [@fadeOut]="multiplyAnimationState" [@fadeIn] class="plus">*</div>
  <div *ngIf="multiplyAnimationState && !totalAdded" class="number text-center" [@numberFlyInRight]="multiplyAnimationState" [@fadeIn]>
    <div>
      Multiplier
    </div>
    <div>
      {{multiplier.toFixed(2)}}
    </div>
  </div>
  <div *ngIf="totalAdded" [@flyUp]="flyUpAnimationState" [@fadeIn] class="number">
    <div>
      Total
    </div>
    <div>
      {{totalAdded.toFixed(2)}}
    </div>
  </div>
</div>
<!-- <button (click)="animateAndAdd()">Add</button>
<button (click)="showMultiply()">show Multiply</button>
<button (click)="animateAndMuliply()">Multiply</button>
<button (click)="flyUpEnd()">Fly Up</button>
<button (click)="fullAnimation()">Full Animation</button> -->
