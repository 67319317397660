import { Injectable, OnInit } from '@angular/core';
import { DB_CONFIG } from '@app/app.firebase.config';
import { AppEnum } from '@enums/AppEnum';
import { PuzzleGameEnum } from '@enums/PuzzleGameEnum';
import { Highlights } from '@interfacehighlights.interface';
import { bcScore } from '@models/dataTrackerModels/bcScore.model';
import { statNumTracker } from '@models/dataTrackerModels/statNumTracker.model';
import moment from 'moment';
import { any } from 'underscore';
import { AdminService } from './admin.service';
import { DataTrackingService } from './data-tracking.service';
import { TrackerProcessingService } from './tracker-processing.service';

@Injectable({
  providedIn: 'root'
})
export class HighlightsService {

  highlights = [
    {
      app: AppEnum.BrainGame,
      new_val: 10,
      old_val: 5,
      stat_name: 'leaderboard',
      title: 'Leaderboard',
      display_text: '',
      icon: 'podium-outline',
    },
    {
      app: AppEnum.BrainGame,
      new_val: 0.5,
      old_val: 0.3,
      stat_name: 'triviaAcc',
      title: 'Trivia Accuracy',
      display_text: '',
      icon: 'checkmark-circle-outline',
    },
    {
      app: AppEnum.BrainGame,
      new_val: 8,
      old_val: 2.77,
      stat_name: 'game_score',
      title: 'Game Score',
      display_text: '',
      icon: 'trending-up-outline',
    },
    {
      app: AppEnum.Yahootie,
      new_val: 8,
      old_val: 2.77,
      stat_name: 'yahooties',
      title: 'Yahooties',
      display_text: '',
      icon: 'sparkles-outline',
    },
    {
      app: AppEnum.Yahootie,
      new_val: 8,
      old_val: 2.77,
      stat_name: 'score',
      title: 'Game Score',
      display_text: '',
      icon: 'trending-up-outline',
    },
    {
      app: AppEnum.Yahootie,
      new_val: 8,
      old_val: 2.77,
      stat_name: 'leaderboard',
      title: 'Leaderboard',
      display_text: '',
      icon: 'podium-outline',
    },
    {
      app: AppEnum.Clubhouse,
      new_val: 8,
      old_val: 2.77,
      stat_name: 'TODO',
      display_text: '',
      icon: '',
    },
    {
      app: AppEnum.HappyPlace,
      new_val: 8,
      old_val: 2.77,
      stat_name: 'moodNum',
      title: 'Mood',
      display_text: '',
      icon: 'happy-outline',
    },
    {
      app: AppEnum.General,
      new_val: 8,
      old_val: 2.77,
      stat_name: 'memory',
      title: 'Memory Score',
      display_text: '',
      icon: 'bulb-outline',
    },
    {
      app: AppEnum.General,
      new_val: 8,
      old_val: 2.77,
      stat_name: 'speed',
      title: 'Speed Score',
      display_text: '',
      icon: 'walk-outline',
    },
    {
      app: AppEnum.General,
      new_val: 8,
      old_val: 2.77,
      stat_name: 'focus',
      title: 'Focus Score',
      display_text: '',
      icon: 'eye-outline',
    },
    {
      app: AppEnum.General,
      new_val: 8,
      old_val: 2.77,
      stat_name: 'intelligence',
      title: 'Intelligence Score',
      display_text: '',
      icon: 'library-outline',
    },
  ] as Highlights[];
  orig_bc_scores: bcScore;
  orig_stat_tracker: statNumTracker;
  private checks = {
    'Brain Game': [
      'triviaAcc',
      'game_score',
      'leaderboard'
    ],
    'Yahootie': [
      'yahooties',
      'score',
      'leaderboard',
    ],
    'Clubhouse': [
      'TODO'
    ],
    'Happy Place': [
      'moodNum'
    ],
  }

  constructor(
    private progressService: TrackerProcessingService,
    private dataService: DataTrackingService,
    private adminService: AdminService
  ) {
    this.getOldStats();
  }

  getOldStats() {
    // probably will need to assign this with object thing
    let copyObj = {} as bcScore;
    Object.assign(copyObj, this.progressService.bcScore);
    this.orig_bc_scores = copyObj;
    this.orig_stat_tracker = this.dataService.specialStatTracker;
    this.getHighlights();
  }

  /**
   * Checks whatever path or app('Brain Game') if the most recent performance was better than average
   * @param app 
   * @param stats 
   * @returns 
   */
  checkPathStats(app: AppEnum, stats: any) {
    return new Promise<any[]>(async (res, rej) => {
      let orig;
      if (app === AppEnum.BrainGame) { orig = this.orig_stat_tracker['BrainGame'] }
      else if (app === AppEnum.HappyPlace) { orig = this.orig_stat_tracker['HappyPlace'] }
      else { orig = this.orig_stat_tracker[app]; }
      const highlights = [] as Highlights[];
      await this.checks[app].forEach(element => {
        let statEl = element;
        // check if they actually exist, this should throw an error so we can fix this discrepancy
        // but returns allow me to check if the main parts work
        if (element === 'game_score') {
          switch (stats.game_name) {
            case PuzzleGameEnum.Lemonade:
              element = 'lemonScore';
              break;
            case PuzzleGameEnum.Sliding:
              element = 'slidingScore';
              break;
            default:
              element = '${stats.game_name}Score';
              break;
          }
        }
        if (!orig[element]) { return }
        if (!stats[statEl]) { return }
        // to add a new tag to the data tracker you must add it to the model(braingameTracker) and specialStatTracker model
        // then simply use dataService.addValue(field, val) at the end of the path you want
        // you also must delete the stattracker to add new values, hopefully this could be fixed but idk how
        if (stats[statEl] >= orig[element].mean && element !== 'leaderboard') {
          const highlight = {
            app,
            new_val: stats[statEl],
            old_val: orig[element].mean,
            stat_name: element,
          } as Highlights;
          highlights.push(highlight);
        }
        if (stats[statEl] <= orig[element].mean && element === 'leaderboard' && stats[statEl] >= 1) {
          const highlight = {
            app,
            new_val: stats[statEl],
            old_val: orig[element].mean,
            stat_name: element,
          } as Highlights;
          highlights.push(highlight);
        }
      });
      res(highlights);
    })
  }

  async checkBCScores() {
    // update bcScore
    // await this.progressService.getBCScores(0);
    const new_bc_score = this.progressService.bcScore;
    return new Promise<any>(async (res, rej) => {
      let ret = { new_val: 0, old_val: 1 } as Highlights;
      const bcScoreKeys = Object.keys(this.orig_bc_scores);
      for (let key of bcScoreKeys) {
        let oldVal = this.orig_bc_scores[key];
        const newVal = new_bc_score[key];
        if (await newVal > oldVal) {
          if (oldVal === 0) { oldVal = 0.001; }
          const highlight = {
            app: 'General',
            new_val: newVal,
            old_val: oldVal,
            stat_name: key,
          } as Highlights;
          if ((await newVal / oldVal) > (ret.new_val / ret.old_val)) { ret = highlight; }
        }
      }
      res(ret)
    })
  }

  /**
   * limits highlights to x amount per path and combines bcScores if new one added
   */
  async limiting() {
    await this.checkForDuplicates(this.highlights);
    return;
  }

  async checkForDuplicates(arr: any[]) {
    const test = {};
    const promises = arr.map((obj, index) => {
      if (test[`${obj.app}_${obj.stat_name}`]) {
        const oldObj = test[`${obj.app}_${obj.stat_name}`]
        if ((obj.new_val / obj.old_val) > (oldObj.new_val / oldObj.old_val)) {
          // delete old from highlights and 
          const oldIndex = this.highlights.findIndex(oldObj);
          test[`${obj.app}_${obj.stat_name}`] = obj;
          return oldIndex;
        } else {
          return index;
        }
      } else {
        test[`${obj.app}_${obj.stat_name}`] = obj;
      }
    });
    await Promise.all(promises);
    await Promise.all(promises.slice(0).reverse().map(promise => {
      if(!promise){return;}
      this.highlights.splice(promise, 1)
    }));
  }

  addHighlight(highlight) {
    this.highlights.push(highlight);
  }

  /**
   * Call when an path finishes to decide what highlights should be added
   * @param app the appenum of whatever to be checked for highlights
   * @param stats the stats that will be checked. {triviaAcc = 80}
   */
  async endOfPath(app: AppEnum, stats: any) {
    if (!this.orig_stat_tracker) {
      this.orig_stat_tracker = this.dataService.specialStatTracker;
    }

    const paths = await this.checkPathStats(app, stats);
    if (paths.length > 0) {
      paths.forEach(path => {
        this.addHighlight(path);
      })
      // if(paths.length > 1) {
      //   this.saveHighlights();
      //   return;
      // }
    }
    const bcScores = await this.checkBCScores();
    if (bcScores.new_val !==  0) {
      this.addHighlight(bcScores);
    }
    await this.limiting();
    this.displayText();
    this.saveHighlights();
  }

  saveHighlights() {
    this.adminService.saveEntryByUserId({highlights: this.highlights}, DB_CONFIG.highlights_endpoint);
  }

  getHighlights() {
    this.adminService.getEntryByUserId(DB_CONFIG.highlights_endpoint).then(data => {
      if (data) {
        // only if the highlights are from the same day
        const now = moment().dayOfYear();
        // const now = moment().subtract(1,'d').dayOfYear();
        if (moment(data.modified_date).dayOfYear() === now) {
          this.highlights = data.highlights;
        } else {
          this.highlights = [];
        }
      } else {
        this.highlights = [];
      }
    })
  }

  displayText() {
    return this.highlights.map((highlight, index) => {
      const oldVal = parseFloat(highlight.old_val+'').toFixed(2);
      const newVal = parseFloat(highlight.new_val+'').toFixed(2);
      if(highlight.new_val === 0){
        this.highlights.splice(index, 1);
        return;
      }
      switch (highlight.app) {
        case AppEnum.BrainGame:
          switch (highlight.stat_name) {
            case "leaderboard":
              highlight['title'] = `Leaderboard`;
              highlight.display_text = `Your leaderboard position went from ${oldVal} to ${newVal}! `;
              highlight.icon = 'podium-outline';
              break;
            case "triviaAcc":
              highlight['title'] = `Trivia Accuracy`;
              highlight.display_text = `Your trivia accuracy improved from ${oldVal} to ${newVal}! `;
              highlight.icon = 'checkmark-circle-outline';
              break;
            case "game_score":
              highlight.display_text = `Your Brain Game score improved from ${oldVal} to ${newVal}! `;
              highlight.icon = 'trending-up-outline';
              break;
            case "lemonScore":
              highlight['title'] = `Lemonade Score`;
              highlight.display_text = `Your Lemonade score improved from ${oldVal} to ${newVal}! `;
              highlight.icon = 'trending-up-outline';
              break;
            case "slidingScore":
              highlight['title'] = `Sliding Score`;
              highlight.display_text = `Your Sliding score improved from ${oldVal} to ${newVal}! `;
              highlight.icon = 'trending-up-outline';
              break;
            case "sudokuScore":
              highlight['title'] = `Sudoku Score`;
              highlight.display_text = `Your Sudoku score improved from ${oldVal} to ${newVal}! `;
              highlight.icon = 'trending-up-outline';
              break;
          }
          break;
        case AppEnum.Yahootie:
          switch (highlight.stat_name) {
            case "yahooties":
              highlight['title'] = `Yahooties`;
              highlight.display_text = `The amount of yahooties you earned improved from ${oldVal} to ${newVal}! `;
              highlight.icon = 'sparkles-outline';
              break;
            case "score":
              highlight['title'] = `Game Score`;
              highlight.display_text = `Your Yahootie score improved from ${oldVal} to ${newVal}! `;
              highlight.icon = 'trending-up-outline';
              break;
            case "leaderboard":
              highlight['title'] = `Leaderboard`;
              highlight.display_text = `Your leaderboard position went from ${oldVal} to ${newVal}! `;
              highlight.icon = 'podium-outline';
              break;
          }
          break;
        case AppEnum.HappyPlace:
          highlight['title'] = `${highlight.stat_name[0].toUpperCase()}${highlight.stat_name.slice(1)}`;
          switch (highlight.stat_name) {
            case "moodNum":
              highlight['title'] = `Mood`;
              highlight.display_text = `Your mood improved from ${oldVal} to ${newVal}! `;
              highlight.icon = 'happy-outline';
              break;
          }
          break;
        case AppEnum.General:
          highlight['title'] = `${highlight.stat_name[0].toUpperCase()}${highlight.stat_name.slice(1)} Score`;
          switch (highlight.stat_name) {
            case "memory":
              highlight.display_text = `Your memory score improved from ${oldVal} to ${newVal}! `;
              highlight.icon = 'bulb-outline';
              break;
            case "speed":
              highlight.display_text = `Your speed score improved from ${oldVal} to ${newVal}! `;
              highlight.icon = 'walk-outline';
              break;
            case "focus":
              highlight.display_text = `Your focus score improved from ${oldVal} to ${newVal}! `;
              highlight.icon = 'eye-outline';
              break;
            case "intelligence":
              highlight.display_text = `Your intelligence score improved from ${oldVal} to ${newVal}! `;
              highlight.icon = 'library-outline';
              break;
            case "problemSolving":
              highlight['title'] = `Problem Solving Score`;
              highlight.display_text = `Your problem solving score improved from ${oldVal} to ${newVal}! `;
              highlight.icon = 'library-outline';
              break;
            case "bcCompliance":
              highlight['title'] = `Compliance`;
              highlight.display_text = `Your Compliance improved from ${oldVal}% to ${newVal}%! `;
              highlight.icon = 'library-outline';
              break;
            default:
              highlight['title'] = `Highlight Error`;
              highlight.display_text = `You had an uncovered highlight from ${oldVal} to ${newVal}! `;
              highlight.icon = 'help-outline';
              break;
          }
          break;
        default:
          highlight['title'] = `Highlight Error`;
          highlight.display_text = `You had an uncovered highlight from ${oldVal} to ${newVal}! stat name was ${highlight.stat_name} `;
          highlight.icon = 'help-outline';
          break;
      }
    })
  }


}
