<nav class="navbar navbar-expand-lg navbar-light header p-0 pl-4" [ngStyle]='{ "background-color": cobranding?.primary }'>
  <a class="navbar-brand">
    <img *ngIf="cobranding?.logoUrl && !showNavigation" (click)="nav({path: false, url: 'login'})" src='{{cobranding?.logoUrl}}' alt="Your organization's logo2">
    <img *ngIf="cobranding?.logoUrl && showNavigation" (click)="nav({path: false, url: 'profile'})" src='{{cobranding?.logoUrl}}' alt="Your organization's logo2">
    <img *ngIf="!cobranding?.logoUrl" (click)="nav({path: false, url: 'login'})" src='../../../../../assets/images/Brain_Charge_White.png' alt="Your organization's logo">
  </a>
  <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" [attr.aria-expanded]="!isCollapsed" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="h-100 w-100 d-flex" *ngIf="showNavigation">
    <div class="collapse navbar-collapse justify-content-end align-items-start" style="height: 100%" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav" style="background-color: rgba(255,255,255,0.1);">
        <li class="nav-item align-items-center" *ngFor="let path of paths"
          [ngClass]=" {'current-item': path.path, 'path-item': !path.path }"
          (click)='nav(path)'>
          <ion-text [ngClass]=" {'current path': path.path, 'header-item path': !path.path }">{{ path.name }}</ion-text>
          <ion-icon  class="path-icon" [ngClass]=" {'current path': path.path, 'header-item path': !path.path }" name='{{ path.icon }}'></ion-icon>
        </li>
      </ul>
    </div>
  </div>
  
</nav>