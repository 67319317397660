import { Injectable } from '@angular/core';
import { IBGLeaderboard } from '@interface/bgLeaderboard.interface';
import { IBGLeaderboardEntry } from '@interface/bgleaderentry.interface';
import { AdminService } from './admin.service';
import { DB_CONFIG } from '../app.firebase.config';
import { Subscription } from 'rxjs';
import { BrainGameReport } from '@models/bgReport.interface';
import { brainGameDefaultConfig } from '@app/app.constants.config';
import { PuzzleGameEnum } from '@enums/PuzzleGameEnum';
import { GameDifficultyEnum } from '@enums/DifficultyEnum';
import moment from 'moment';
import { format } from 'path';

@Injectable({
  providedIn: 'root'
})
export class LeaderboardService {
  private leaderboard: IBGLeaderboard;

  display =  [] as IBGLeaderboardEntry[];
  currentRound: number;
  limit = 100;
  isNew = false;
  leaderSub: Subscription;
  clearSub: Subscription;

  constructor(
    private adminService: AdminService,
  ) { this.init() }

  /**
   * does nothing right now
   */
  init() {

  }

  /**
   * Get All LeaderBoards
   */
  getAllLeaderBoards() {
    
  }

  // admin method for clearing a leaderboard
  clearBoard(id: string) {

  }

  // gets the display version of the leaderboard
  getDisplay() {
    // this.updateLeaderboard();
    return this.leaderboard.board;
  }

  // creates the template for a new board
  newBoard(game_name: PuzzleGameEnum|string, difficulty: GameDifficultyEnum, subscriber_id: string) {
    const leaderboard = {
      board: [],
      game_name,
      difficulty,
      subscriber_id,
    } as IBGLeaderboard;
    return leaderboard;
  }

  global_leaderboard;
  getGlobalLeaderboards(game_name: PuzzleGameEnum|string, difficulty: GameDifficultyEnum) {
    const subscriber_id = 'global';
    const table = DB_CONFIG.brain_game_leaderboard_endpoint;
    this.leaderSub = this.adminService.getEntries(table, 'modified_date', 'subscriber_id', subscriber_id).subscribe(async (leaderboards: IBGLeaderboard[]) => {
      const leaderboard = leaderboards.filter(leaderboard => {
        return leaderboard.game_name === game_name && leaderboard.difficulty === difficulty;
      })
      if (leaderboard.length === 0) {
        this.global_leaderboard = this.newBoard(game_name, difficulty, subscriber_id);
      } else {
        this.global_leaderboard = leaderboard[0];
        if(leaderboard.length > 1) {console.error('Multiple Leaderboards detected with the same game name, difficulty, and sub_id');}
      }
    });
  }

  /**
   * generate the leaderboard based on the summary given sorts by game, trivia, and overall score
   * @param id 
   * @param roundNum 
   */
  getLeaderboard(game_name: PuzzleGameEnum|string, difficulty: GameDifficultyEnum, subscriber_id: string) {
    this.getGlobalLeaderboards(game_name, difficulty);
    const table = DB_CONFIG.brain_game_leaderboard_endpoint;
    this.leaderSub = this.adminService.getEntries(table, 'modified_date', 'subscriber_id', subscriber_id).subscribe(async (leaderboards: IBGLeaderboard[]) => {
      const leaderboard = leaderboards.filter(leaderboard => {
        return leaderboard.game_name === game_name && leaderboard.difficulty === difficulty;
      })
      if (leaderboard.length === 0) {
        this.leaderboard = this.newBoard(game_name, difficulty, subscriber_id);
      } else {
        this.leaderboard = leaderboard[0];
        if(leaderboard.length > 1) {console.error('Multiple Leaderboards detected with the same game name, difficulty, and sub_id');}
      }
    });
  }

  /**
   * generate the leaderboard based on the summary given sorts by game, trivia, and overall score.
   * Does by a promise instead of a subscription
   * @param id 
   * @param roundNum 
   */
  getLeaderboard2(game_name: PuzzleGameEnum|string, difficulty: GameDifficultyEnum, subscriber_id: string) {
    return new Promise((res, rej) => {
      this.adminService.getEntries(DB_CONFIG.brain_game_leaderboard_endpoint).subscribe(async (boards) => {
        const filteredBoards = boards.filter( board => {
          return (
            board.game_name === game_name &&
            board.difficulty === difficulty &&
            board.subscriber_id === subscriber_id
          );
        });
        if(filteredBoards.length !== 1){
          rej(game_name + difficulty + subscriber_id)
        } else {
          res(filteredBoards[0]);
        }
      })
    })
    
  }

  // changes format from large summary answer to smaller leaderboard entry
  async formatEntry(entry: BrainGameReport|any) {
    if(entry.overall_score || entry.overall_score === 0){
      const newEntry = {
        overall_score: entry.overall_score,
        game_score: entry.game_score,
        trivia_score: entry.trivia_score,
        user_id: entry.id,
        user_name: entry.user_name,
        created_date: new Date().toJSON(),
      } as any;
      return newEntry;
    } else {
      const newEntry = {
        overall_score: entry.score,
        user_name: entry.playerName,
        created_date: new Date().toJSON(),
      } as any;
      return newEntry;
    }
  }

  async newInsert(entry, board, global){
    const left = board.filter(enter => { return enter.overall_score > entry.overall_score}) as any[]
    const right = board.filter(enter => { return enter.overall_score <= entry.overall_score});
    const index = left.length;
    left.push(entry);
    if(global){
      this.global_leaderboard.board = left.concat(right);
    } else {
      this.leaderboard.board = left.concat(right);
    }
    return index;
  }

  /**
   * 
   * @param entry the entry we are inserting
   */
  async insert(entry: BrainGameReport|any) {
    let formattedEntry = await this.formatEntry(entry);
    const index = await this.newInsert(formattedEntry, this.leaderboard.board, false);
    const globalIndex = await this.newInsert(formattedEntry, this.global_leaderboard.board, true);
    this.updateLeaderboard();
    return index+1;
  }

  // removes the last entry from the roundNumber leaderboard array
  removeLast(index: number) {
    this.leaderboard.board.splice(index, 1);
  }

  // saves leaderboard to the database, either updates or saves a new one based on if
  // we had to format for a new event
  updateLeaderboard() {
    const boardEntries = this.leaderboard.board.map((obj) => Object.assign({}, obj));
    this.leaderboard.board = boardEntries;
    if (!this.leaderboard.id) {
      this.adminService.saveEntryById(this.leaderboard, DB_CONFIG.brain_game_leaderboard_endpoint);
    } else {
      this.adminService.updateEntry(this.leaderboard, DB_CONFIG.brain_game_leaderboard_endpoint);
    }

    if (!this.global_leaderboard.id) {
      this.adminService.saveEntryById(this.global_leaderboard, DB_CONFIG.brain_game_leaderboard_endpoint);
    } else {
      this.adminService.updateEntry(this.global_leaderboard, DB_CONFIG.brain_game_leaderboard_endpoint);
    }
  }

  // a debug function
  async randomTest() {
    for ( let i = 0; i < 100; i++) {
      const entry = {
        overall_score: Math.random() * 12,
        trivia_score: 0,
        game_score: 0,
        id: 'AGN40DzRsUyvZ2oczYvb'
      } as BrainGameReport;
      this.display  = [];
      console.log({entry});
      await this.insert(entry);
    }
  }
}
