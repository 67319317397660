import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  private defaultPath = 'happy_place/';

  constructor(
    private afStorage: AngularFireStorage,
  ) { }

  getFromStorage(path: string) {
    const pathway = this.afStorage.ref(this.defaultPath + path);
    return pathway.getDownloadURL();
  }
}
