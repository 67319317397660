import { baseData } from "./base.model";

export class GeneralData extends baseData {
    homeScreenTime      = [] as {val: any, time: Date}[];
    moodNum             = [] as {val: number, time: Date}[];
    sleepTime           = [] as {val: any, time: Date}[];
    instructionTime     = [] as {val: any, time: Date}[];
    appDetailScreenTime = [] as {val: any, time: Date}[];
    pathTaken           = [] as {val: string, time: Date}[];
    bounces             = [] as {val: number, time: Date}[];


    constructor() {
        super('General');
    }
}