import { Component, OnInit } from '@angular/core';

import { Platform, NavController, MenuController } from '@ionic/angular';
import { Router } from '@angular/router';

import { IUser } from './common/interface/user.interface';

import { AuthenticationService } from './services/authentication.service';

import { RoleEnum } from '@enums/RoleEnum';
import { RoutePathEnum } from '@enums/RoutePathEnum';
import { Location } from '@angular/common';
import { DataTrackingService } from '@services/data-tracking.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  rootPage: any  = 'QuestionPage';
  routePathEnum = RoutePathEnum;
  user: IUser;
  userObs: any;
  role = RoleEnum;

  menuItems = [
    {
      title: 'Home',
      icon: 'home',
      path: this.routePathEnum.Home,
      role: RoleEnum.Subscriber,
      isAdmin: true
    },
    {
      title: 'Assessment',
      icon: 'help-circle-outline',
      path: this.routePathEnum.Assessment,
      role: RoleEnum.Subscriber,
      isAdmin: true
    },
    {
      title: 'Brain Game',
      icon: 'logo-game-controller-b',
      path: this.routePathEnum.BrainGame,
      role: RoleEnum.Subscriber,
      isAdmin: true
    },
    {
      title: 'Clubhouse',
      icon: 'easel',
      path: this.routePathEnum.Clubhouse,
      role: RoleEnum.Subscriber,
      isAdmin: true
    },
    {
      title: 'Happy Place',
      icon: 'images',
      path: this.routePathEnum.HappyPlace,
      role: RoleEnum.Subscriber,
      isAdmin: true
    },
    {
      title: 'Yahootie',
      icon: 'fitness',
      path: this.routePathEnum.Yahootie,
      role: RoleEnum.Subscriber,
      isAdmin: true
    },
    {
      title: 'General',
      icon: 'people',
      path: this.routePathEnum.General,
      role: RoleEnum.Subscriber,
      isAdmin: true
    },
  ];
  
  constructor(
    private platform: Platform,
    private navController: NavController,
    private authService: AuthenticationService,
    private menu: MenuController,
    private dataService: DataTrackingService,
    private router: Router,
    private location: Location
  ) {
    this.initializeApp();
    this.router.events.subscribe(val => {
      this.dataService.addPath(location.path())
    })
  }

  ngOnInit() {
    localStorage.clear();
    
    // get the logged in user info so we can
    // display it in admin tab
    this.authService.userSubject
      .subscribe((user: IUser) => {
      if (user) {
        this.user = user;
      }
    });
  }

  async timeConsume(n: number) {
    const nums = [];
    for (let i = 0; i < n ; i++) {
      for (let j = 0; j < n; j++) {
        await nums.push(i * j);
      }
    }
    return nums;
  }

  initializeApp() {
  }

  /** navigates to route path */
  openPage(path: string) {
    this.navController.navigateRoot(path);
  }

  /** logs out the user */
  logOut() {
    this.authService.signOut();
  }
}
