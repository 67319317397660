import { Component, Input, OnInit } from '@angular/core';
import { BrainGameLeaderboardComponent } from '@common/components/modal/brain-game/leaderboard/brain-game-leaderboard.component';
import { Utilities } from '@common/utilities';
import { AppEnum } from '@enums/AppEnum';
import { ModalController, NavController } from '@ionic/angular';
import { BrainGameControllerService } from '@services/brain-game-controller.service';

@Component({
  selector: 'app-end-screen',
  templateUrl: './end-screen.component.html',
  styleUrls: ['./end-screen.component.scss'],
})
export class EndScreenComponent extends Utilities implements OnInit {

  appEnum = AppEnum;
  @Input() phaser;

  leaderboard = false;
  score;
  game;


  constructor(
    private bgService: BrainGameControllerService,
    private navController: NavController,
    public modalController: ModalController
  ) {super();}

  ngOnInit() {
    this.score = this.bgService.response.overall_score;
    if(this.score === 0){
      console.error(`score is 0`)
      console.log(this.bgService.response);
    }
    this.game = this.bgService.game;
  }

  showLeaderboard() {
    this.bgService.navAway("leaderboard")
    this.presentModal(BrainGameLeaderboardComponent, 'leaderboard-modal')
  }

  done() {
    if(this.phaser){return}
    this.endOfPath();
    this.navController.navigateBack('apps-menu');
  }

  retry() {
    if(this.phaser){return}
    this.endOfPath();
    this.bgService.navAway('trivia');
  }

  endOfPath(){
    this.bgService.endOfPath();
  }

}
