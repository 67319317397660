import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-reactive-box',
  templateUrl: './reactive-box.component.html',
  styleUrls: ['./reactive-box.component.scss'],
})
export class ReactiveBoxComponent implements OnInit, OnChanges {

  @Input () message;
  @Input() number;
  @Input() color;
  class;

  constructor() { }

  ngOnInit() {
    this.determineColor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.determineColor();
  }

  determineColor() {
    switch(this.color){
      case 'red':
        this.class = 0;
        break;
      case 'yellow':
        this.class = 1;
        break;
      case 'green':
        this.class = 2;
        break;
    }
  }

}
