<div class="counter">
  <h1 class="animated tada fast" *ngIf="!showCounter">Get Ready!</h1>
  <h1 
    class="animated jello fast" 
    *ngIf="showCounter">
    {{counter}} <span *ngIf="counter === 1">!</span>
  </h1>
</div>

<ion-footer class="sticky-footer footer-panel">
  <div class="full-height background-secondary">
  </div>
</ion-footer>
