import { Component, Input, OnInit } from '@angular/core';
import { AppEnum } from '@enums/AppEnum';
import { MoodEnum } from '@enums/MoodEnum';
import { BrainGameControllerService } from '@services/brain-game-controller.service';
import { GameService } from '@services/game.service';
import { HappyPlaceService } from '@services/happy-place.service';
import { HomeService } from '@services/home.service';
import { LevelingService } from '@services/leveling.service';

@Component({
  selector: 'app-game-score',
  templateUrl: './game-score.component.html',
  styleUrls: ['./game-score.component.scss'],
})
export class GameScoreModalComponent implements OnInit {

  game_score = 10;
  trivia_score = 10;
  total = 110;
  appenums = AppEnum;
  @Input() game;
  mood;
  startMood;

  constructor(
    private lvlService: LevelingService,
    private bgController: BrainGameControllerService,
    private yaService: GameService,
    private hpService: HappyPlaceService,
    private homeService: HomeService
  ) { }

  ngOnInit() {
    if(this.game === AppEnum.HappyPlace){
      this.getHPScores();
    } else if(this.game === AppEnum.Yahootie){
      this.getYAScores();
    } else {
      this.getBGScores();
    }
    
  }

  getYAScores(){
    this.trivia_score = this.yaService.yahootieResults.question_score;
    this.game_score = this.yaService.yahootieResults.yahootie_score;
    this.total = this.game_score + this.trivia_score;
  }

  getHPScores() {
    const nummood = this.hpService.temp_tracking.mood;
    this.mood = MoodEnum[nummood];
    const landingMood = this.homeService.moodSleep.responses[this.homeService.moodSleep.responses.length-1];
    this.startMood = MoodEnum[landingMood.mood];
  }

  getBGScores() {
    this.game_score = this.bgController.response.game_score;
    this.trivia_score = this.bgController.response.trivia_score;
    this.total = this.game_score + this.trivia_score;
  }

  closeModal() {
    this.lvlService.modalClosed.emit();
  }

}
