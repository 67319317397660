import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HomeService } from '@services/home.service';
import { TrackerProcessingService } from '@services/tracker-processing.service';
import moment from 'moment';

@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss'],
})
export class ComplianceComponent implements OnInit, OnChanges {

  firstBCScore = 0;
  secondBCScore = Math.round(this.progressService.bcScore.bcScore / 10);

  @Input() score = 0;
  @Input() weeksBack = 0;

  bcImprovement = this.secondBCScore - this.firstBCScore; 

  firstCompliancePercentage = '0';
  secondCompliancePercentage = '0';

  complianceImprovement = parseInt(this.secondCompliancePercentage) - parseInt(this.firstCompliancePercentage); 

  constructor(
    private progressService: TrackerProcessingService,
    private homeService: HomeService
  ) { }

  ngOnInit() {
    this.compliance();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.compliance();
    this.secondBCScore = this.score;
    this.bcImprovement = this.secondBCScore - this.firstBCScore;
    this.complianceImprovement = parseInt(this.secondCompliancePercentage) - parseInt(this.firstCompliancePercentage);
  }

  compliance() {
    return new Promise((res,rej) =>{
      Promise.resolve().then((ress => {
        if(this.weeksBack === 0) {
          const startDate = moment().day(7).subtract(7,'d').toDate();
          const endDate =  moment().toDate();
          this.homeService.getApps3(startDate, endDate).then((compliances: any) => {
            let sum = 0;
            compliances.forEach(compliance => {
              sum += compliance.compliancePercentage;
            })
            this.secondCompliancePercentage = (sum/compliances.length).toFixed(0);
          })
        } else {
          this.homeService.getApps2(this.weeksBack).then((compliances: any) => {
            let sum = 0;
            compliances.forEach(compliance => {
              sum += compliance.compliancePercentage;
            })
            this.secondCompliancePercentage = (sum/compliances.length).toFixed(0);
          })
        }
      })).then((ress => {
        this.homeService.getApps2(this.weeksBack+1).then((compliances:any) => {
          let sum = 0;
          compliances.forEach(compliance => {
            sum += compliance.compliancePercentage;
          })
          this.firstCompliancePercentage = (sum/compliances.length).toFixed(0);
          res(true);
        });
      }))
    });
  }

}
