<div style="padding: 20px 0">
  <div class="d-flex">
    <app-level-display [ngClass]="{'mx-auto': !showSummary}" [noText]="!App"></app-level-display>
  </div>

  <div class="d-flex justify-content-center">
    <app-summary-display *ngIf="Game" [Game]="Game" (extraClicked)="seeLeaderboard()" class="w-50"></app-summary-display>
    <app-leveling-bar class="w-50"></app-leveling-bar>
  </div>

  <!-- buttons -->
  <div class="mt-4" *ngIf="App">
    <!-- constants -->
    <app-button text="Done" (click)="done()"></app-button>
    <app-button *ngIf="phaser" text="Back To Game" data-cy='retry' (click)="backToGame()" backgroundColor="white"></app-button>
    <app-button *ngIf="!phaser" text="Retry" data-cy='retry' (click)="retry()" backgroundColor="white"></app-button>
  </div>
</div>







