import { Injectable } from '@angular/core';
import { Lemonade } from '@interface/lemonade.interface';

@Injectable({
  providedIn: 'root'
})
export class LemonadeService {

  private result = {
    popularity: 0,
    day: 0,
    lastNetProfit: 0,
    lastTotalNetProfit: '0',
    totalNetProfit: '0',
    satisfaction: 0,
    averageSatisfaction: 0,
    xSatisfaction: 0,
    priceDiff: 0,
    customers: 0
  } as Lemonade;

  neededSugar = 0;
  neededLemon = 0;

  constructor() { }

  /**
   * Gets the Lemonade results.
   * @returns The Lemonade results.
   */
  getResult(): Lemonade {
    return this.result;
  }

  /**
   * Sets the Lemonade results
   * @param result The Lemonade results.
   */
  setResult(result: Lemonade): void {
    this.result = result;
  }

  /**
   * Clears the Lemonade results for the next game.
   */
  clear(): void {
    this.result = {
      popularity: 0,
      day: 0,
      lastNetProfit: 0,
      lastTotalNetProfit: '0',
      totalNetProfit: '0',
      satisfaction: 0,
      averageSatisfaction: 0,
      xSatisfaction: 0,
      priceDiff: 0,
      customers: 0
    };
  }
}
