<ion-content class="animated zoomIn fast">
  <div class="cont">
    <app-close (click)="closeModal()"></app-close>
    <h1>Selected Players</h1>
    <!-- instructions -->
    <ion-list-header class="m-t-20">
      <ion-label>
        Select the player(s) that answered
      </ion-label>
    </ion-list-header>
    <!-- instructions /-->

    <!-- Display Players -->
    <ion-list>
      <ion-radio-group>
        <ion-item *ngFor="let player of selectedPlayers; let i = index">
          <ion-label><h2>{{ player.name }}</h2></ion-label>
          <ion-radio
            slot="start"
            color="secondary"
            (ionSelect)="onChange(i)">
          </ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>
    <!-- Display Players /-->
  </div>
</ion-content>

<ion-footer class="animated zoomIn fast ion-padding">
  <div class="background-padded full-height">
    <app-button text="Check Answer" (click)="closePlayerModal()"></app-button>
  </div>
</ion-footer>