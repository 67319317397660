import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FIREBASE_CONFIG } from './app.firebase.config';
import { AuthenticationService } from './services/authentication.service';
import { ErrorMessageService } from './services/error-message.service';
import { AuthGuardService } from './services/guard/auth-guard.service';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { CountdownModule } from 'ngx-countdown';
import { GamePageModule } from './yahootie/pages/game/game/game.module';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AppCommonModule } from './common/app-common.module';
import { AssessmentPageModule } from './assessment/pages/assessment.module';
import { Utilities } from './common/utilities';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(FIREBASE_CONFIG),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AppCommonModule,
    SwiperModule,
    CountdownModule,
    GamePageModule,
    AssessmentPageModule,
    HttpClientModule,
    NgbModule,
    BrowserAnimationsModule,
  ],
  providers: [
    HttpClient,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthenticationService,
    AngularFirestore,
    ErrorMessageService,
    AuthGuardService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    Utilities
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
