import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReturningComponent } from './modal/returning/returning.component';
import { ReportChartComponent } from './report-chart/report-chart.component';
import { AppCommonModule } from '@common/app-common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IonicModule } from '@ionic/angular';
import { ScorechartComponent } from './scorechart/scorechart.component';
import { ComplianceChartComponent } from './compliance-chart/compliance-chart.component';
import { BarsOverTimeComponent } from './bars-over-time/bars-over-time.component';
import { AssessmentScoreChartComponent } from './assessment-score-chart/assessment-score-chart.component';
import { TrajectoryComparisonComponent } from './trajectory-comparison/trajectory-comparison.component';
import { ProgressDetailComponent } from './progress-detail/progress-detail.component';
import { CurrentScoreSummaryComponent } from './current-score-summary/current-score-summary.component';
import { ChartLegendComponent } from './chart-legend/chart-legend.component';


@NgModule({
  declarations: [
    ReturningComponent,
    ReportChartComponent,
    ScorechartComponent,
    ComplianceChartComponent,
    BarsOverTimeComponent,
    AssessmentScoreChartComponent,
    TrajectoryComparisonComponent,
    ProgressDetailComponent,
    CurrentScoreSummaryComponent,
    ChartLegendComponent,
  ],
  exports: [
    ReturningComponent,
    ReportChartComponent,
    ScorechartComponent,
    ComplianceChartComponent,
    BarsOverTimeComponent,
    AssessmentScoreChartComponent,
    TrajectoryComparisonComponent,
    ProgressDetailComponent,
    CurrentScoreSummaryComponent,
    ChartLegendComponent,
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    NgbModule,
    IonicModule
  ]
})
export class AssessmentComponentsModule { }
