<app-admin-header [title]="'Answers'"></app-admin-header>

<ion-content class="modal-background">

  <div class="container">
    <!-- main error message -->
    <app-error
      [errorMessage]="errorMessage"
      [isValidation]="false">
    </app-error>
    <!-- main error message /-->

    <!-- list of answers -->
    <ion-list
      *ngFor="let answer of answers; let i = index"
      class="padding-right-10">

      <ion-grid>
        <div
          class="overlay"
          [ngClass]="{'hidden': answer.isActive}"
          (click)="answer['selected'] = !answer['selected']; onAdd(answer)">
        </div>
        <ion-row class="content-center-vertical" (click)="onAdd(answer)">
          <!-- number -->
          <ion-col size="2">
            <div class="number">{{ i + 1 }}</div>
          </ion-col>
          <!-- number /-->

          <!-- question description -->
          <ion-col
            size="8"
            (click)="answer['selected'] = !answer['selected'];">
            <ion-item>
              <ion-label>
                <h2>{{ answer.details }}</h2>
                <p>{{ answer.modified_date | date }}</p>
              </ion-label>
            </ion-item>
          </ion-col>
          <!-- question description /-->

        </ion-row>
      </ion-grid>
    </ion-list>
    <!-- list of answers /-->

    <ion-infinite-scroll (ionInfinite)="doInfinite($event)" threshold="100px">
      <ion-infinite-scroll-content
        loadingSpinner="bubbles"
        loadingText="Loading more data...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>

</ion-content>

