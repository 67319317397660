import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { EventBusService } from '@services/event-bus.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-trivia-header',
  templateUrl: './trivia-header.component.html',
  styleUrls: ['./trivia-header.component.scss']
})
export class TriviaHeaderComponent implements OnInit, OnDestroy {
 
  @Input() useConfig = true;
  @Input() originalTime: number;
  @Input() showTimer: boolean;
  @Input() roundNumber: number;
  @Input() score: number;
  @Output() timeExpire: EventEmitter<any> = new EventEmitter();
  
  configId: string;
  subscription = new Subscription();

  constructor(
    private eventBusService: EventBusService,
  ) { }

  ngOnInit() {
    
    // update the score
    // Commented this function due to error thrown
    const scoreSub = this.eventBusService.scoreSubject.subscribe((value: number) => {
      this.score = value;
    });

    this.subscription.add(scoreSub);

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * emit an event will be subscribed to,
   * to execute an action
   */
  timeUpEventhandler() {
    this.timeExpire.emit();
  }
}
