<!-- correct -->
<div class="result d-flex align-items-center justify-content-center">
  <div class="container" *ngIf="!loading">
    <div class="row mb-5">
      <div class="col-12">
        <h1>{{message}}</h1>
      </div>
    </div>
    <div class="row" *ngIf="button_message">
      <div class="col-12">
        <app-button backgroundColor="white" text="{{button_message}}" (click)="click_function()"></app-button>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="loading">
    <div class="row mb-5">
      <div class="col-12">
        <img id="grey_screen_loading" class="loading" src="../../../../assets/images/logo.1.png">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h1>Loading</h1>
      </div>
    </div>
  </div>
</div>
<!-- correct/ -->