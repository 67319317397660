import { baseData } from "./base.model";

export class HappyPlaceData extends baseData {

    moodNum  = [] as {val: number, time: Date}[];

    // this class doesnt do anything because happy place is getting a rework. 3/4/2021
    constructor() {
        super('HappyPlace');
    }
}