<div class="text-center pad-4 position-relative">
  <div class="d-flex justify-content-center">
    <h2>Xp Earned </h2>
    <i class="fa-solid fa-circle-info" (click)="seeHelper()"></i>
  </div>
  
  <div>
    <p>{{displayXp}}</p>
  </div>
  
  <div>
    <div style="margin: 0 3%; position: relative">
      <div class="level-bar" [style.width.%]="currentXp"></div>
      <div class="grey"></div>
    </div>
    
    <div class="progress-labels">
      <span class="min-value">Level <br>{{currentLvl}}</span>
      <span class="max-value">Level <br>{{maxLvl}}</span>
    </div>
  </div>

  
</div>

<!-- <div class="progress-labels">
  <span class="min-value">0</span>
  <span class="max-value">{{maxXp}}</span>
</div> -->

<!-- <button (click)="addXpSlowly(10)">add 10 xp</button>
<button (click)="addXpSlowly(50)">add 50 xp</button>
<button (click)="addXpSlowly(100)">add 100 xp</button>
<button (click)="setXpZero()">reset xp</button> -->
