import { GameDifficultyEnum } from '@common/enums/DifficultyEnum';

export class YahootieResultsReport {
  id: string;
  difficulty: GameDifficultyEnum;
  sessionInterval: number;
  rounds: number;
   // tslint:disable-next-line:variable-name
  created_date: Date = new Date(); 
  // tslint:disable-next-line:variable-name
  modified_date: Date = new Date();
  questionsAsked: any[];
  players: any[];
  playerIds: any[];
  currentRound: number = 0;
  round_question_score: number = 0;
  round_yahootie_score: number = 0;
  overall_score: number = 0;
  question_score: number = 0;
  yahootie_score: number = 0;
  timers: any;
}


