import { Component, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';
import { AssessmentService } from '@services/assessment.service';

@Component({
  selector: 'app-returning',
  templateUrl: './returning.component.html',
  styleUrls: ['./returning.component.scss'],
})
export class ReturningComponent implements OnInit {

  constructor(
    private assService: AssessmentService
  ) {}

  ngOnInit() {}

  /**
   * call when a button is clicked
   * @param value the value to be returned(aka 'new' or 'return')
   */
  clicked(value) {
    this.assService.returningModalClosed.emit(value);
  }

}
