import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-chart',
  templateUrl: './report-chart.component.html',
  styleUrls: ['./report-chart.component.scss'],
})
export class ReportChartComponent implements OnInit {

  @Input() score = 7;

  // todo
  @Input() average = 5;

  @Input() color = ''

  constructor() { }

  ngOnInit() {
    if(this.score >= 4 && this.score <= 6) {
      this.color = 'warning';
    } 
    else if (this.score <= 3) {
      this.color = 'danger';
    }
    else if (this.score > 6) {
      this.color = 'success'
    }
  }

}
