import { Component, Input, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';
import { AppEnum } from '@enums/AppEnum';
import { MoodEnum } from '@enums/MoodEnum';

@Component({
  selector: 'app-mood-factor',
  templateUrl: './mood-factor.component.html',
  styleUrls: ['./mood-factor.component.scss'],
})
export class MoodFactorComponent extends Utilities implements OnInit {

  @Input() week: string;
  @Input() mood: MoodEnum = MoodEnum.VerySad;

  trainingItems = [
    {
      app: AppEnum.HappyPlace,
      guide: 'Full training'
    },
    {
      app: AppEnum.Yahootie,
      guide: 'Physical exercises'
    },
    {
      app: AppEnum.Clubhouse,
      guide: 'Attending meetings'
    }
  ];

  currentMoodSetting = {
    mood: MoodEnum.VerySad,
    text: 'Awful',
    face: 'verysad'
  };

  moodImageLocation = '../../../../../assets/images/mood/';

  moodSettings = [
    {
      mood: MoodEnum.VerySad,
      text: 'Awful',
      face: 'verysad'
    },
    {
      mood: MoodEnum.Sad,
      text: 'Down',
      face: 'sad'
    },
    {
      mood: MoodEnum.Meh,
      text: 'Okay',
      face: 'meh'
    },
    {
      mood: MoodEnum.Happy,
      text: 'Good',
      face: 'smile'
    },
    {
      mood: MoodEnum.VeryHappy,
      text: 'Great',
      face: 'veryhappy'
    },
  ];

  constructor() {
    super();
  }

  ngOnInit() {
    this.setCurMood();
  }

  /**
   * Sets the current mood setting and mood image location based on the inputted mood
   */
  setCurMood(): void {
    this.currentMoodSetting = this.moodSettings.find(moodSetting => this.mood == moodSetting.mood);
    this.moodImageLocation += this.currentMoodSetting.face + '.png';
  }

  /**
   * Closes the modal
   */
  closeSelf(): void {
    this.closeModal();
  }
}
