import { Component, Input, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';
import { ModalController } from '@ionic/angular';
import { HomeService } from '@services/home.service';
import { TrackerProcessingService } from '@services/tracker-processing.service';
import * as moment from "moment";

@Component({
  selector: 'app-compliance-flyout',
  templateUrl: './compliance-flyout.component.html',
  styleUrls: ['./compliance-flyout.component.scss'],
})
export class ComplianceFlyoutComponent extends Utilities implements OnInit {

  @Input() week: any = {
    start: new Date(),
    end: new Date(),
    text: ''
  };
  @Input() weeksBack = 0;
  @Input() compliancePercentage;
  @Input() overallScore;

  compliances = [] as {
    appName: string,
    compliance: number,
  }[];


  constructor(
    public modalController: ModalController,
    private progressService: TrackerProcessingService,
    private homeService: HomeService
  ) { super(); }

  ngOnInit() {
    this.setCompliances();
  }

  setCompliances(): void {
    const startDate = moment().day(7).subtract(7,'d').toDate();
    const endDate =  moment().toDate();
    this.homeService.getApps3(startDate, endDate).then((compliances: any) => {
      this.compliances = compliances;
    });
  }

  getCompHeight(app): string {
    if (app.compliancePercentage !== 0) {
      // adds a minimum height to the compliance as less than 5% looks odd
      if (app.compliancePercentage < 5) {
        return '5%';
      }
      else {
        return app.compliancePercentage + '%';
      }
    }
  }
}
