<div class="d-flex justify-content-center flex-wrap ">
  <div 
  class="col p-2 col-sm-12 col-md-4" 
  (click)="onClick(button, i)" 
  *ngFor="let button of buttons; let i = index" 
  [ngClass]="{'active': active_flag === i}">

    <div class="collection-btn">
      <div class="text-center">
        <!-- icon -->
        <i class="{{button.icon}}"></i>

        <!-- title -->
        <h1>{{button.title}}</h1>
      </div>
    </div>

  </div>
</div>
