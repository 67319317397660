import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {

  private errorCodeMap = new Map<string, string>();

  constructor() {
    this.setErrorCodeMapping();
  }

  /**
   * Sets the error code mappings.
   */
  setErrorCodeMapping(): void {
    /** createUserWithEmailAndPassword error code mappings*/
    this.errorCodeMap.set('auth/email-already-in-use', 'email is already in use');
    this.errorCodeMap.set('auth/invalid-email', 'email is invalid');
    this.errorCodeMap.set('auth/operation-not-allowed', 'An unexpected error has occured');

    /** signInWithEmailAndPassword error code mappings*/
    this.errorCodeMap.set('auth/user-disabled', 'user is currently disabled');
    this.errorCodeMap.set('auth/user-not-found', 'email does not exist');
    this.errorCodeMap.set('auth/wrong-password', 'email or password is invalid');

    /** addPlayers error code mappings */
    this.errorCodeMap.set('register/no-players', 'You must add atleast one player');

    /** generic errors */
    this.errorCodeMap.set('server-error', 'A server error has occurred. Try again later.');
    this.errorCodeMap.set('generic', 'An error occurred. Try again later');
  }

  /**
   * Gets the error code mapping.
   * @param error The error message.
   * @returns The error code map.
   */
  getErrorCodeMapping(error: string): string {
    return this.errorCodeMap.get(error);
  }
}
