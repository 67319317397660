import { EventEmitter, Injectable } from '@angular/core';
import { DB_CONFIG } from '../app.firebase.config';
import { take } from 'rxjs/operators';
import { AdminService } from './admin.service';
import { AuthenticationService } from './authentication.service';
import { OverallSessionService } from './overall-session.service';
import { AppEnum } from '@enums/AppEnum';

@Injectable({
  providedIn: 'root'
})
export class HappyPlaceService {

  voiceSelection = new EventEmitter<string>();

  happy_place_tracking= {
    data: [] as {
      video_id,
      audio_id,
      mood,
      time?
    }[],
    id: null,
  }
  temp_tracking = {
    video_id: '',
    audio_id: '',
    mood: 0,
    time: 0,
  }
  videos = [];
  femaleAudios = [];
  maleAudios = [];

  constructor(
    private authService: AuthenticationService,
    private adminService: AdminService,
    private sessionService: OverallSessionService
  ) { this.init() }

  init() {
    this.setupTracking()
    this.getHappyPlaces();
  }

  setupTracking() {
    this.adminService.getEntryByUserId(DB_CONFIG.happy_place_tracking_endpoint).then(tracking =>{
      if(tracking) {
        this.happy_place_tracking = tracking;
      } else {
        this.happy_place_tracking.data = [];
        this.getUser();
      }
    })
  }

  getUser() {
    this.authService.getAuthUser().pipe(take(1)).subscribe(user => {
      this.happy_place_tracking.id = user[0].id;
    })
  }

  getHappyPlaces() {
    this.adminService.getEntries(DB_CONFIG.happy_place_endpoint).pipe(take(1)).subscribe(data => {
      this.videos = data.filter(e =>{return e.type === 'video'});
      const audios = data.filter(e =>{return e.type === 'audio'});
      this.maleAudios = audios.filter(e =>{return e.voice === 'male'});
      this.femaleAudios = audios.filter(e =>{return e.voice === 'female'});
    })
  }

  getVideoId() {
    return this.temp_tracking.video_id;
  }

  setVideoId(newId: string) {
    this.temp_tracking.video_id = newId;
  }

  getAudioId() {
    return this.temp_tracking.audio_id;
  }

  setAudioId(newId: string) {
    this.temp_tracking.audio_id = newId;
  }

  setTimer(time) {
    this.temp_tracking.time = time;
  }

  endOfPath(mood) {
    this.temp_tracking.mood = mood;
    const temptemp = Object.assign(new Object(), this.temp_tracking)
    this.happy_place_tracking.data.push(temptemp);
  }

  async storeTracking() {
    const table = DB_CONFIG.happy_place_tracking_endpoint;
    this.adminService.saveEntryById(this.happy_place_tracking, table);
    this.sessionService.saveOverallSessionApp(AppEnum.HappyPlace);
  }
}
