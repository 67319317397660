import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RoleEnum } from '@enums/RoleEnum';
import { AuthenticationService } from '@services/authentication.service';
import * as auth from 'firebase/auth';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  userData;

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private ngZone: NgZone,
    private authService: AuthenticationService
  ) {
    // Setting logged in user in localstorage else null
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        this.authService.getUserByUID(user.uid);
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user')!);
      } else {
        localStorage.setItem('user', 'null');
        JSON.parse(localStorage.getItem('user')!);
      }
    }); }

   /**
   * Checks if the user is authenticated and 
   * redirects them to login page if not.
   * Used on the routes for access control.
   * @param next
   * @param state
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const expectedRole = route.data.expectedRole;
    return new Promise((res, rej) => {
      this.afAuth.authState.subscribe((async (userFirebase: any) => {
        if(!userFirebase){
          this.router.navigate(['/login']);
          res(true);
          return;
        }
        const user = await this.authService.getUserByUID(userFirebase.uid);
        if (user) {
          if(!expectedRole){
            res(true);
          } else if(user.role === expectedRole){
            res(true);
          } else if(user.role === RoleEnum.Admin){
            res(true);
          }
        } else {
          this.router.navigate(['/login']);
          res(true);
        }
      }));
    })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== 'null' ? true : false;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result: any) => {
        this.ngZone.run(() => {
          this.router.navigate(['user-profile']);
        });
      })
      .catch((error: any) => {
        window.alert(error);
      });
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['login']);
    });
  }
}
