<ion-row>
  <div class="header-title" size="3">{{scoreFactor.title}}:</div>
  <div>.
    <p class="header-text">{{scoreFactor.description}}.</p>
  </div>
</ion-row>

<ion-row class="score-chart p-0">
  <ion-col size="11" class="p-0">
    <app-bars-over-time [datas]="datas"></app-bars-over-time>
  </ion-col>
</ion-row>

<ion-row class="score-para">
  <div class="score-para-container">
    Your <b>{{scoreFactor.title}}</b> score before starting Brain Charge is a
    <b>{{scoreFactor.score}}</b> out of 10.
  </div>
</ion-row>

<!-- TRAJECTORY AND COMPARISON COMPONENT -->

<ion-row>
  <ion-col size="6" class="trajectory">
    <div [ngStyle]='{ "background-color": trajectoryColor}' class="trajectory-background">
      <h2>Trajectory</h2>
      <div class="trajectory-text">
        <p>Compared to yourself when you were younger, {{trajectoryText}}</p>
      </div>
    </div>
  </ion-col>
  
  <ion-col size="6" class="comparison">
    <div [ngStyle]='{ "background-color": comparisonColor}' class="trajectory-background">
      <h2>Comparison to Peers</h2>
      <div class="trajectory-text">
        <p>Compared to your peers, {{comparisonText}}</p>
      </div>
    </div>
  </ion-col>
</ion-row>

<!-- GOAL COMPONENT -->

<ion-row class="goal">
  <div [ngStyle]='{ "background-color": goalColor}' class="trajectory-background">
    <h2>Goal</h2>
    <div class="trajectory-text">
      <p>Your goal for your next training session is to bring your
        {{scoreFactor.title}} {{goalText}}. This will help
        {{scoreFactor.reason}}. {{scoreFactor.title}} was {{goalScore}}.
        You can also work on {{scoreFactor.title}} by working on {{scoreFactor.priority}}.
      </p>
    </div>
  </div>
</ion-row>