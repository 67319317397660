import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { EventBusService } from '@services/event-bus.service';
import { brainGameDefaultConfig } from '@app/app.constants.config';
import { BrainGameControllerService } from '@services/brain-game-controller.service';
import { Subject } from 'rxjs';
import { HomeService } from '@services/home.service';
import { GameService } from '@services/game.service';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {

  @Input() pause = false;
  @Input() reset: Subject<boolean> = new Subject<boolean>();
  @Input() isYahootie?: boolean;

  @Output() timeUp: EventEmitter<any> = new EventEmitter();

  originalTime: number;
  timeDisplay: string;
  seconds: number;
  minutes: number;
  stop = false;
  dashArray: string;
  cobrand: any;
  
  private timeInSeconds: number;
  private defaultInterval = 1000; // Default interval of one second
  private interval: any; // Store setInteval so that it can be terminated
  
  constructor(
    private bgController: BrainGameControllerService,
    private eventBusService: EventBusService,
    private homeService: HomeService,
    private gameService: GameService
  ) {
  }

  ngOnInit() {
    this.eventBusService.roundCompleted.subscribe(() => {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.timeInSeconds = this.originalTime;
    });
    this.reset.subscribe((eh) => {
      this.stop = false;
      // if (this.interval) {
      //   clearInterval(this.interval);
      // }
      this.timeInSeconds = this.originalTime;
    })
    this.isYahootie ? this.originalTime = this.gameService.countDownTime : this.originalTime = brainGameDefaultConfig.duration_of_round;
    this.timeInSeconds = this.originalTime;
    this.updateTimer();
    this.configureStyles();
  }

  /**
   * Gets the cobranding styles from the HomeService.
   */
  configureStyles(): void {
    this.homeService.getCobranding().subscribe(cobrand => {
      this.cobrand = cobrand;
    });;
  }
  
  /**
   * Updates the time display in a 1:00 format.
   * @param time The time in seconds.
   */
  updateTimeDiplay(time: number): void {
    if (time > 0) {
      if (!this.seconds || this.minutes === 0) {
        this.minutes = Math.floor(time / 60);
      }
      const minutes = this.minutes;
      const seconds = time - minutes * 60;
      this.seconds = seconds;

      const minutesString = minutes;
      const secondsString =  seconds < 10 ? '0' + seconds : seconds;
      
      this.timeDisplay = minutesString  + ':' + secondsString;
    } else {
      this.timeDisplay = '0:00';
    }
    this.updateDashArray();
  }

  /**
   * Updates the time and emits that the time has ended.
   */
  updateTimer(): void {
    this.bgController.timerLoaded.next();
    this.interval = setInterval(() => {
      // check if the timer is paused
      // if not then update the timer
      if (!this.pause) {
        this.timeInSeconds -= 1;

        if (this.timeInSeconds < 0 && !this.stop) {
          this.timeUp.emit(true);
          this.stop = true;
          // Stop set interval
        } else {
          this.updateTimeDiplay(this.timeInSeconds);
        }
      }
      
    }, this.defaultInterval);
  }

  /**
   * Updates the dash array based on the percentage time left.
   */
  updateDashArray(): void {
    const percentage =  this.timeInSeconds / this.originalTime * 100;
    this.dashArray = `${percentage}, 100`;
  }
}
