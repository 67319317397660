import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { VideoService } from '@services/video.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit, OnDestroy {

  @Input() name: string;
  @Input() description: string;
  @Input() imgPath: string;

  source: string;
  sub = new Subscription();

  constructor(
    private videoController: VideoService,
  ) { }

  ngOnInit() {
    const thumbSub = this.videoController.getFromStorage(this.imgPath + '.png').subscribe(img => {
      this.source = img;
    });
    this.sub.add(thumbSub);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
