import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

import { ModalController, NavController, LoadingController} from '@ionic/angular';

import { IGameConfiguration } from '@common/interface/game-configuration.interface';
import { IExercise } from '@common/interface/exercise.interface';
import { IQuestion } from '@common/interface/question.interface';
import { ILeaderBoard } from '@common/interface/leaderboard.interface';
import { YahootieResultsReport } from '@models/yahootie-results-report.model';

import { BrainGameLeaderboardComponent } from '@common/components/modal/brain-game/leaderboard/brain-game-leaderboard.component';

import { GameService } from '@services/game.service';
import { ExerciseService } from '@services/exercise.service';
import { EventBusService } from '@services/event-bus.service';
import { QuestionService } from '@services/question.service';

import { Utilities } from '@common/utilities';

import { ExerciseTypeEnum } from '@enums/ExerciseTypeEnum';
import { GameDifficultyEnum } from '@enums/DifficultyEnum';

import { roundTimer, questionLimit } from '@app/app.constants.config';

import { Subscription } from 'rxjs';

import * as _ from 'underscore';
import { take } from 'rxjs/operators';
import { DataTrackingService } from '@services/data-tracking.service';
import { BrainGameResultsComponent } from '@common/components/modal/brain-game/results/brain-game-results.component';
import { LeaderboardService } from '@services/leaderboard.service';
import { AuthenticationService } from '@services/authentication.service';
import { AppEnum } from '@enums/AppEnum';
import { NavigationEnum } from '@enums/NavigationEnum';

@Component({
  selector: 'app-game',
  templateUrl: './game.page.html',
  styleUrls: ['./game.page.scss'],
})
export class GamePage extends Utilities implements OnInit, AfterViewInit, OnDestroy  {
  countDownTime = 4;
  progressValue: number;
  gameConfig: IGameConfiguration = {} as IGameConfiguration;
  exercises: IExercise[];
  questions = [] as IQuestion[];
  round = 0;
  showQuestion = false;
  showExercise = false;
  isRoundsComplete = false;
  roundLimit = 4; // exercises shown
  activityType: string;
  roundTimer: any = [];
  showTimer: boolean;
  subscription = new Subscription();
  playerScoreSummary: any = [];
  sortedPlayerScoreSummary: ILeaderBoard[];
  currentItemDisplayed: string;
  playerScore: any;
  yahootieResults = new YahootieResultsReport();
  currentModal: HTMLIonModalElement;

  constructor(
    private gameService: GameService,
    private eventBusService: EventBusService,
    private exerciseService: ExerciseService,
    private questionService: QuestionService,
    public navCtrl: NavController,
    public modalController: ModalController,
    public loadingCtrl: LoadingController,
    private dataService: DataTrackingService,
    private leaderService: LeaderboardService,
    private authService: AuthenticationService
    ) { super(navCtrl); }

  ngOnInit() {
    this.countDownTime = 60; // changed to 60 seconds per dr. clarke's request
    this.gameService.countDownTime = 60;
    this.roundTimer = roundTimer;
    this.getGameConfig();
    this.manageRounds(null);
  }

  getUser() {
    this.authService.getAuthUser().pipe(take(1)).subscribe(user => {
      this.leaderService.getLeaderboard('yahootie', this.gameConfig.difficulty, user[0].subscriber_id);
    })
  }

  ngAfterViewInit() {
    // activity finished
    this.subscription.add(
      this.eventBusService.onActivityFinished.subscribe((value: string) => {
        if (!this.isRoundsComplete) {
          this.manageRounds(value);
        }
      })
    );

    // question finished
    this.subscription.add(
      this.eventBusService.onQuestionsFinished.subscribe(() => {
        this.showResults();
      })
    );

    this.onGameContinue();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Listens for when the game should proceed and continues the game. 
   * Only appears on the leaderboard when the user finished with it.
   */
  onGameContinue(): void {
    this.subscription.add(
      this.eventBusService.onContinueGame.subscribe(() => {
        this.manageRounds('question');
      })
    );
  }

  /**
   * Manages the rounds, knows when to set up stretch/exercise/questions and when to end.
   */
  manageRounds(value: any): void {
    if (value === ExerciseTypeEnum.Stretch || value === ExerciseTypeEnum.Exercise) {
      this.showExercise = false;
      this.dataService.startTimer('questionTime');
    } else {
      this.gameConfig.currentRound = this.gameConfig.currentRound + 1;
      this.gameService.yahootieResults.currentRound = this.gameService.yahootieResults.currentRound + 1;
      this.setRoundProgress();
      this.showQuestion = false;
    }

    const lastRound   = this.gameConfig.currentRound === this.gameConfig.rounds ? true : false;
    const firstRound  = this.gameConfig.currentRound === 1 ? true : false;

    // Logic for the First and lastRound
    if ( firstRound && !this.isRoundsComplete || ((lastRound) && !this.isRoundsComplete)) {
        if (value === ExerciseTypeEnum.Stretch || value === ExerciseTypeEnum.Exercise) {
          // this.showLoading();
          if (this.gameConfig.currentRound === this.gameConfig.rounds) {
            this.isRoundsComplete = true;
          }
          // Retrieve Questions
          if (firstRound) {
            this.getQuestions(GameDifficultyEnum.Hard);
          } else if (lastRound) {
            this.currentItemDisplayed = ExerciseTypeEnum.Question;
            this.showTimer = true;
            this.eventBusService.resetTimer.emit('question');
            this.showQuestion = true;
            // this.hidLoading();
          }
          
        } else {
          // this.showLoading();
          // Retrieve Exercises
          if (firstRound) {
            this.getExercises(ExerciseTypeEnum.Stretch);
          } else if (lastRound) {
            this.getNextExercises(ExerciseTypeEnum.Exercise);
          }
        }
    } else {
      // get exercise of type exercise and difficulty
      if (value === ExerciseTypeEnum.Stretch || value === ExerciseTypeEnum.Exercise) {
        this.currentItemDisplayed = ExerciseTypeEnum.Question;
        this.showTimer = true;
        this.eventBusService.resetTimer.emit('question');
        this.showQuestion = true;
      } else {
        this.getNextExercises(ExerciseTypeEnum.Exercise);
      }
    }
  }

  /**
   * Gets the current game configuration from the GameService.
   */
  getGameConfig(): void {
    this.gameService.gameConfigSubject
      .subscribe((gameConfig: IGameConfiguration) => {
        if (gameConfig) {
          this.gameConfig = gameConfig;
        }
        this.getUser();
      });
  }

  /**
   * Handles the timeUp event and emits the appropriate event.
   */
  async timeUpEventHandler() {
    if (this.showQuestion) {
      await this.gameService.endTimers('questionTime');
      this.eventBusService.onQuestionTimerExpired.emit();
    }
    if (this.showExercise) {
      // await this.gameService.endTimers('exerciseTime');
      this.eventBusService.onActivityTimerExpired.emit();
    }
  }
   
  /**
   * Gets all the exercises by type.
   * @param type An enum representing the type of exercise.
   */
  getExercises(type: ExerciseTypeEnum): void {
    this.subscription.add(
      this.exerciseService.getExercisesByType(type, this.roundLimit)
        .subscribe((exerciseData: IExercise[]) => {
          this.nextExerciseSetup(exerciseData, type);
        })
    );
  }

  /**
   * Gets the next set of exercises by type.
   * @param type An enum representing the type of exercise.
   */
  getNextExercises(type: ExerciseTypeEnum): void {
    // this.dataService.startTimer('exerciseTime');
    this.subscription.add(
      this.exerciseService.getNextExercisesByType(type, this.roundLimit)
        .subscribe((exerciseData: IExercise[]) => {
          if (exerciseData.length < this.roundLimit) {
            this.exerciseService.getExercisesByType(type, this.roundLimit)
            .subscribe((newExerciseData: IExercise[]) => {
              this.nextExerciseSetup(newExerciseData, type);
            });
          } else {
            this.nextExerciseSetup(exerciseData, type);
          }
        })
    );
  }
  
  /**
   * Gets the questions from the QuestionService.
   * @param difficulty An enum representing the difficulty.
   */
  getQuestions(difficulty: GameDifficultyEnum): void {
    // Get exercise based on difficulty and type
    this.subscription.add(this.questionService.getQuestionsByType(questionLimit, difficulty)
      .pipe(take(1))
      .subscribe((questionData: IQuestion[]) => {
        this.nextQuestionSetup(questionData);
      }));
  }

  /**
   * Calculates the round progress value.
   */
  setRoundProgress(): void {
    this.progressValue = ((this.gameConfig.currentRound / this.gameConfig.rounds) * 100) / 100;
  }
  
  /**
   * Assigns the exercises.
   * @param exerciseData The next set of exercises array.
   * @param type The type of exercise.
   */
  nextExerciseSetup(exerciseData: IExercise[], type: ExerciseTypeEnum): void {
    this.convertDataTimestamps(exerciseData);
    this.exercises = exerciseData;
    this.activityType = type;
    this.currentItemDisplayed = type;
    this.showTimer = true;
    this.eventBusService.resetTimer.emit(type);
    this.showExercise = true;
    // this.hidLoading();
  }

  /**
   * Assigns the questions.
   * @param questionData The next set of questions array.
   */
  nextQuestionSetup(questionData: IQuestion[]): void {
    this.convertDataTimestamps(questionData);
    this.questions = questionData;
    this.currentItemDisplayed = ExerciseTypeEnum.Question;
    this.showTimer = true;
    this.eventBusService.resetTimer.emit('question');
    this.showQuestion = true;
    // this.hidLoading();
  }

  /**
   * Adds the question accuracy, skips, and Yahootie statistics to the DataService.
   * @param dataNeeds The GameService's DataTrackerNeeds
   */
  processDataNeeds(dataNeeds: any): void {
    this.dataService.addValue('questionAcc', (dataNeeds.questionNum/dataNeeds.correctQuestion));
    this.dataService.addValue('questionNum', (dataNeeds.questionNum));
    this.dataService.addValue('skips', (dataNeeds.skips));
    this.dataService.addValue('wrongYahootie', (dataNeeds.wrongYahootie));
    this.dataService.addValue('missedYahootie', (dataNeeds.missedYahootie));
    this.dataService.addValue('correctYahootie', (dataNeeds.correctYahootie));
    console.log(this.dataService.currentDataObj);
  }

  /**
   * Aggregates the scores and displays the BrainGameResults or Leaderboard modal
   */
  async showResults(): Promise<void> {
    this.playerScoreSummary = [];
    this.playerScore = [];
    this.sortedPlayerScoreSummary = [];
    if (this.isRoundsComplete) {
      this.gameService.yahootieSubject
      .subscribe((res: YahootieResultsReport) => {
        this.yahootieResults = res;
      });
      this.gameService.saveResultReport(this.yahootieResults).then(() => {
        // Results Saved
      }).catch(error => {
        console.log('error', error);
      });
    }

    const dataNeeds = this.gameService.dataTrackerNeeds;
    this.processDataNeeds(dataNeeds);
    

    this.playerScore = this.gameService.getPlayerScores();
    const rank = 0;

    _.each(this.playerScore, (player: any) => {
      const playerName = player.name;
      const playerPoints = player.score;
      this.playerScoreSummary.push({playerName, score: playerPoints, rank});
    });

    this.sortedPlayerScoreSummary = this.playerScoreSummary.sort((n1, n2): number => {
      return n2.score - n1.score;
    });

    this.sortedPlayerScoreSummary.forEach((player, index) => {
      player.rank = index + 1;
    });

    this.currentModal = await this.modalController.create({
      component: BrainGameResultsComponent,
      componentProps: { isYahootie: true },
      showBackdrop: true,
      cssClass: 'results-modal'
    });
    this.currentModal.present();

    await this.currentModal.onDidDismiss();

    if (this.isRoundsComplete) {
      // const promises = this.sortedPlayerScoreSummary.map(player => {
      //   this.leaderService.insert(player);
      // });
      // await Promise.all(promises);
      const entry = {
        score: this.gameService.yahootieResults.overall_score,
        playerName: this.gameService.playersSubject.value[0].name
      };
      this.gameService.leaderIndex = await this.leaderService.insert(entry);
      this.showLeveling();
    }
    else {
      this.gameService.yahootieResults.round_question_score = 0;
      this.gameService.yahootieResults.round_yahootie_score = 0;
      this.eventBusService.onContinueGame.emit();
    }
  }

  appEnum = AppEnum
  leveling = false;
  score;
  showLeveling() {
    this.score = this.gameService.yahootieResults.overall_score;
    this.leveling = true;
    this.showExercise = false;
    this.showQuestion = false;
  }

  done() {
    this.endOfPathProcessing();
    this.navCtrl.navigateBack('apps-menu');
  }

  retry() {
    this.endOfPathProcessing();
    this.navigateToPath('yahootie/game-configuration', NavigationEnum.Back);
  }

  endOfPathProcessing() {
    // Yahootie End of Path
    this.gameService.resetGameConfigs();
    this.gameService.endOfPath(this.gameService.yahootieResults.overall_score, this.gameService.leaderIndex);
  }

  /**
   * Shows the Leaderboard modal
   */
  async showLeaderBoard(): Promise<void> {
    this.dataService.endOfPath();
    const display = this.leaderService.getDisplay();
    this.presentModal(BrainGameLeaderboardComponent, 'leaderboard-modal', {
      isModal: true,
      yahootieLeaderboard: display,
    }, false);
  }
}
