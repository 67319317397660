<div class="m-3 position-relative text-center">
  <h1 class="text-center mb-3">Xp Earned</h1>
  <i class="fa-solid fa-xmark" (click)="closeModal()"></i>
  
  <div class="p-3" *ngIf="total_xp">
    <div class="d-flex">
      <div class="col-4">
        <div>
          <p>Completed Training</p>
        </div>
      </div>

      <div class="col-4">
        <div>
          <p>Completed Training Path</p>
        </div>
      </div>

      <div class="col-4">
        <div>
          <p>Completion Points</p>
        </div>
      </div>
    </div>
    

    <div class="d-flex p-3">
      <div class="col-4 position-relative">
        <div>
          <span>{{base.toFixed(1)}}</span>
        </div>
        <span class="operator">+</span>
      </div>
  
      <div class="col-4 position-relative">
        <div>
          <span>{{completion.toFixed(1)}}</span>
        </div>
        <span class="operator">=</span>
      </div>
  
      <div class="col-4 position-relative">
        <div>
          <span>{{sum.toFixed(1)}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="p-3 mt-4" *ngIf="total_xp">
    <div class="d-flex">
      <div class="col-4">
        <div>
          <p>Completion Points</p>
        </div>
      </div>

      <div class="col-4">
        <div>
          <p>Performance Boost</p>
        </div>
      </div>

      <div class="col-4 text-bold">
        <div>
          <p>Total XP</p>
        </div>
      </div>
    </div>
    

    <div class="d-flex p-3">
      <div class="col-4 position-relative">
        <div>
          <span>{{sum.toFixed(1)}}</span>
        </div>
        <span class="operator">*</span>
      </div>
  
      <div class="col-4 position-relative">
        <div>
          <span>{{multiplier.toFixed(1)}}</span>
        </div>
        <span class="operator">=</span>
      </div>
  
      <div class="col-4 text-bold">
        <div>
          <span>{{total_xp.toFixed(1)}}</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!total_xp">
    <h2>No XP Earned</h2>
  </div>
</div>


    <!-- <div class="col-4">
      <div>
        <p>Game Score</p>
      </div>
      <div>
        <span>{{game_score.toFixed(2)}}</span>
      </div>
    </div> -->