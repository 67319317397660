
  <ion-grid class="inner background-white">
    <ion-row>
      <ion-col style="margin-left: 10%">
        <img (click)='navHome()' src='../../../../assets/images/be-logo-vert.png' alt="Your organization's logo">
      </ion-col>
      <ion-col>
        <ion-row>
          <ion-col>
            <a class="link" (click)='openPdf("Disclaimers")'>Disclaimers</a>
          </ion-col>
          <ion-col>
            <a class="link" (click)='openPdf("Privacy Policy")'>Privacy Policy</a>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <a class="link" (click)='openPdf("Data Exchange Consent")'>Data Exchange Consent</a>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col>
        <ion-text class="copyright">Copyright © Brain Evolved {{currentYear}}</ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>

