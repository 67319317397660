import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-close',
  templateUrl: './close.component.html',
  styleUrls: ['./close.component.scss'],
})
export class CloseComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
