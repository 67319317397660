<div class="head-cont" data-cy='leaderboard'>
  <h1>Leaderboard</h1>
  <h2 *ngIf="game">Game: {{game}}, Difficulty: {{difficulty}}</h2>
</div>
<ion-grid class="cont">
  <ion-row class="h-100">
    <ion-col class="h-100" size="12">
      <ion-grid class="h-100">
        <div style="height: 75%;">
          <app-leaderboard [game]="game" [difficulty]="difficulty" [subscriber_id]="subscriber_id" [yahootieLeaderboard]="yahootieLeaderboard" [update]="update"></app-leaderboard>
        </div>
        

        <ion-row>
          <ion-col>
            <ion-item class="be-chip full-width">
              <ion-label>Anonymize?</ion-label>
              <ion-checkbox 
                slot="start"
                (ionChange)="anonUser()">
              </ion-checkbox>
            </ion-item>
          </ion-col>
        </ion-row>

        <ion-row>
          <app-button text="Done" id="continueBGLeaderboard" (click)="done()"></app-button>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
  
</ion-grid>
