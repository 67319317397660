import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

import { Utilities } from '@common/utilities';

import { NavigationEnum } from '@enums/NavigationEnum';
import { RoleEnum } from '@enums/RoleEnum';
import { RoutePathEnum } from '@enums/RoutePathEnum';

import { IUser } from '@interface/user.interface';

import { AuthenticationService } from '@services/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-tab',
  templateUrl: './admin-tab.component.html',
  styleUrls: ['./admin-tab.component.scss']
})
export class AdminTabComponent extends Utilities implements OnInit, OnDestroy {

  routeStatusArray = {
    isQuestionsRoute: true,
    isOverviewRoute: false,
    isAnswersRoute: false,
    isEventsRoute: false,
    isConfigRoute: false,
    isInstructionsRoute: false,
    isSubscriberRoute: false,
    isUsersRoute: false,
  };
  subscription = new Subscription();

  user: IUser;
  role = RoleEnum;
  routePathEnum = RoutePathEnum;

  constructor(
    public router: Router,
    public navCtrl: NavController,
    private authService: AuthenticationService
  ) { super(); }

  ngOnInit() {
    const userSub = this.authService.userSubject.subscribe((user: IUser) => {
      if (user) {
        this.user = user;
      }
    });
    this.subscription.add(userSub);

    this.checkRoutes();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Checks the route and set a boolean so
   * the button on the tab can be highlighted.
   */
  checkRoutes(): void {
    switch (true) {
      case (this.router.url.indexOf('question') > - 1): {
        this.setRouteStatus(true, false, 'isQuestionsRoute');
        break;
      }

      case (this.router.url.indexOf('overview') > - 1): {
        this.setRouteStatus(true, false, 'isOverviewRoute');
        break;
      }

      case (this.router.url.indexOf('answer') > - 1): {
        this.setRouteStatus(true, false, 'isAnswersRoute');
        break;
      }

      case (this.router.url.indexOf('event') > - 1): {
        this.setRouteStatus(true, false, 'isEventsRoute');
        break;
      }

      case (this.router.url.indexOf('config') > - 1): {
        this.setRouteStatus(true, false, 'isConfigRoute');
        break;
      }

      case (this.router.url.indexOf('instructions') > - 1): {
        this.setRouteStatus(true, false, 'isInstructionsRoute');
        break;
      }

      case (this.router.url.indexOf('subscriber') > - 1): {
        this.setRouteStatus(true, false, 'isSubscriberRoute');
        break;
      }

      default: {
        this.setRouteStatus(false, true);
        break;
      }
    }
  }

  /**
   * Checks if the route has the word 'form'.
   * If it does then set the direction of the navigation.
   * @param path The string of the route path.
   */
  navigateRoute(path: string) {
    if (this.router.url.indexOf('form') > -1) {
      this.navigateToPath(path, NavigationEnum.Back);
    } else {
      this.navigateToPath(path, NavigationEnum.Forward);
    }
  }

  /**
   * Sets the status of the route booleans.
   * @param status The status to set the route to.
   * @param setAll Whether to set all the route status.
   * @param routeToSet The name of the route to set.
   */
  setRouteStatus(status: boolean, setAll = false, routeToSet?: string, ) {
    if (!setAll) {
      this.setRouteStatus(false, true);
      this.routeStatusArray[routeToSet] = status;
    } else {
      for (const prop in this.routeStatusArray) {
        if (prop) {
          this.routeStatusArray[prop] = status;
        }
      }
    }
  }

}
