<!-- header -->
<div class="{{ headerClass }} padding-top-0 padding-bottom-0">
  <ion-grid>
    <ion-row class="flex-vertical-center">
      <ion-col size="2"></ion-col>

      <!-- branding -->
      <ion-col size="8" class="padding-15 content-center ion-text-center">
        <div class="{{ logoClass }}"></div>
        <app-branding [appTitle]="appTitle"></app-branding>
      </ion-col>
      <!-- branding /-->

      <!-- skip -->
      <ion-col size="2" class="ion-text-right">
        <a class="be-button-tertiary" id='skip' (click)="onInstructionEnded()">
          <ion-icon slot="icon-only" name="arrow-round-forward"></ion-icon>
        </a>
      </ion-col>
      <!-- skip /-->
    </ion-row>
  </ion-grid>
</div>
<!-- header /-->