<app-admin-header 
  [title]="'Users'"
  [isModal]="true">
</app-admin-header>

<ion-content class="background-white">
  <div class="container padding-top-0">
    <!-- list /-->
    <ion-list>
      <ion-item 
        class="be-item bordered" 
        *ngFor="let user of users"
        (click)="itemClicked(user)">
        <ion-label class="admin">
          <h2>{{ user.name }}</h2>
          <p>Username: <b>{{ user.username }}</b></p>
        </ion-label>
      </ion-item>
    </ion-list>
    <!-- list /-->
  </div>
</ion-content>