<div class="m-3 position-relative">
  <h1 class="text-center mb-3">Game Score</h1>
  <i class="fa-solid fa-xmark" (click)="closeModal()"></i>

  <div *ngIf="game !== appenums.Yahootie && game !== appenums.HappyPlace">
    <div class="d-flex p-3" >
      <div class="col-6">
          <p>Game Score</p>
      </div>
      <div class="col-6 text-right">
        <span>{{game_score.toFixed(2)}}</span>
      </div>
    </div>
    
    <div class="d-flex p-3 border-bottom">
      <div class="col-6">
          <p>Trivia Score</p>
      </div>
      <div class="col-6 text-right">
        <span>+   {{trivia_score.toFixed(2)}}</span>
      </div>
    </div>
    
    <div class="d-flex p-4">
      <div class="col-6">
          <p>Total Score</p>
      </div>
      <div class="col-6 text-right">
        <span>{{total.toFixed(2)}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="game === appenums.HappyPlace">
    
    <div class="d-flex p-4">
      <div class="col-6">
          <p>Mood</p>
      </div>
      <div class="col-6 text-right">
        <span>{{startMood}} → {{mood}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="game === appenums.Yahootie">
    <div class="d-flex p-3" >
      <div class="col-6">
          <p>Yahootie Score</p>
      </div>
      <div class="col-6 text-right">
        <span>{{game_score.toFixed(2)}}</span>
      </div>
    </div>
    
    <div class="d-flex p-3 border-bottom">
      <div class="col-6">
          <p>Question Score</p>
      </div>
      <div class="col-6 text-right">
        <span>+   {{trivia_score.toFixed(2)}}</span>
      </div>
    </div>
    
    <div class="d-flex p-4">
      <div class="col-6">
          <p>Total Score</p>
      </div>
      <div class="col-6 text-right">
        <span>{{total.toFixed(2)}}</span>
      </div>
    </div>
  </div>

  
</div>


    <!-- <div class="col-4">
      <div>
        <p>Game Score</p>
      </div>
      <div>
        <span>{{game_score.toFixed(2)}}</span>
      </div>
    </div> -->