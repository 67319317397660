import { baseData } from "./base.model";

export class AssessmentData extends baseData {
    triSquareSwitchCost = [] as {val: number, time: Date}[];
    triSquareAccuracy   = [] as {val: number, time: Date}[];
    d2dTime             = [] as {val: number, time: Date}[];
    d2dAccuracy         = [] as {val: number, time: Date}[];
    squaresAccuracy     = [] as {val: number, time: Date}[];
    symmAccuracy        = [] as {val: number, time: Date}[];

    constructor() {
        super('Assessment');
    }
}