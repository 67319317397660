import { Component, OnInit, OnDestroy } from '@angular/core';

import { NavController } from '@ionic/angular';

import { IUser } from '@interface/user.interface';

import { RoleEnum } from '@enums/RoleEnum';

import { Utilities } from '@common/utilities';

import { EventBusService } from '@services/event-bus.service';
import { AdminService } from '@services/admin.service';
import { AuthenticationService } from '@services/authentication.service';

import * as _ from 'underscore';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends Utilities implements OnInit, OnDestroy {
  users: IUser[];
  subscription = new Subscription();

  constructor(
    public navCtrl: NavController,
    private eventBusService: EventBusService,
    private adminService: AdminService,
    private authService: AuthenticationService
  ) { super(); }

  ngOnInit() {
     this.getAuthUser();
     this.onCloseModal();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Gets the currently authenticated user from the AuthenticationService.
   * Also gets the subscriber info if the user is a subscriber.
   */
  getAuthUser(): void {
    const userSub = this.authService.userSubject.subscribe((user: IUser) => {
      if (user) {

        if (user.role === RoleEnum.Admin) {
          this.getUsers();
        } else {
          this.getUsersBySubscriber(user.subscriber_id);
        }
        
      }
    });
    this.subscription.add(userSub);
  }

  /**
   * Gets a list of users currently logged in subscriber from the AdminService.
   * @param subscriberId A string of the subscriber id.
   */
  getUsersBySubscriber(subscriberId: string): void {
    const userSub2 = this.adminService.getUserBySubscriber(subscriberId)
      .subscribe((users: IUser[]) => {
        this.users = _.filter(users, (o) => {
          return o.role === RoleEnum.Staff || o.role === RoleEnum.Client;
        });
      });
    this.subscription.add(userSub2);
  }

  /**
   * Gets a list of users from the AdminService.
   */
  getUsers(): void {
    const userSub3 = this.adminService.getUsers().subscribe(data => {
      this.users = data;
    });
    this.subscription.add(userSub3);
  }

  /**
   * Emits the subscriber info and then closes the modal.
   * @param item The item to be emitted to the EventBusService.
   */
  itemClicked(item: IUser): void {
    this.eventBusService.listItemClicked.emit(item);
    this.closeModal();
  }

  /**
   * Closes the modal.
   */
  onCloseModal(): void {
    const closeSub = this.eventBusService.modalCloseClicked.subscribe(() => {
      this.closeModal();
    });
    this.subscription.add(closeSub);
  }

}
