<div class="cont">
  <app-close (click)="closeModal()"></app-close>
  <div class="title-cont">
    <img data-cy="brain" src="../../../../../../assets/images/modal.png" alt="A happy brain">
    <app-title text="Your Mood"></app-title>
  </div>
  <app-date-title text={{week}}></app-date-title>
  <hr>
  <ion-grid>
    <ion-row>
      <ion-col>
        <div class="centered">
          <img src={{moodImageLocation}} alt="A smiley face of your mood"/>
          <ion-text class="mood-description">{{currentMoodSetting.text}}</ion-text>
        </div>
      </ion-col>
      <ion-col size="8">
        <app-training-list improve="mood" [trainingItems]=trainingItems (close)="closeSelf()"></app-training-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
