<ion-card [ngStyle]="{'background': currentMoodSetting.background }">
  <ion-grid>
    <ion-row>
      <ion-col size="2">
        <ion-icon name="happy"></ion-icon>
      </ion-col>
      <ion-col size="10">
        <!-- <app-date-range data-cy="mood-date-range" (selectedDate)="setWeek($event)"></app-date-range> -->
      </ion-col>
    </ion-row>
  </ion-grid>
    <div class="centered">
      <h2>Average Mood</h2>
      <div class="circle-container">
        <img src={{moodImageLocation}} alt="A smiley face of your mood"/>
      </div>
      <ion-text class="mood-description">{{currentMoodSetting.text}}</ion-text>
      <app-button
        backgroundColor="transparent" 
        borderColor="#004473"
        color="#004473"
        fontSize="1.25rem"
        text="How can you improve?" 
        (click)="showMoodFactor()">
      </app-button>
    </div>
</ion-card>
