<div class="background-white margin-bottom-20">
  <!-- global actions -->
  <ion-grid>
    <!-- disable -->
    <ion-row class="content-center-vertical">
      <ion-col size="6" class="p-l-30 text-x-small">Disable Selection</ion-col>
      <ion-col size="6" class="ion-text-right">
        <ion-toggle
          (ionChange)="onToggle($event)"
          checked
          [disabled]="toggleDisabled"
          class="be-toggle"></ion-toggle>
      </ion-col>
    </ion-row>
    <!-- disable /-->
    <hr />

    <!-- remove -->
    <ion-row *ngIf="showDelete" class="content-center-vertical padding-5">
      <ion-col size="6" class="p-l-30 text-x-small">Remove Selection</ion-col>
      <ion-col size="6" class="p-r-15 ion-text-right">
        <app-delete (deleteClicked)="onDelete()"></app-delete>
      </ion-col>
    </ion-row>
    <!-- remove /-->
  </ion-grid>
  <hr class="primary">
</div>