export enum CorrectAnswerSubCategoryEnum {
  MusicRhyme = 'Music, Rhyme (ock)',
  RelationRhyme = 'Relation, Rhyme (eam)',
  PersonFemale = 'Person - female',
  PersonMale = 'Person - male',
  Nouns1 = 'Animal, Clothing, Toy, Food, Technology, Machine, Ball, Tools, Pet',
  Nouns2 = 'Clothing, Toy, Technology, Machine, Ball, Tools, Pet, body',
  Gerund = 'Gerund',
  Toy = 'Toy',
  Food = 'Food',
  Decade = 'Decade',
  Animal = 'Animal',
  Color = 'Color',
  Category = 'Category',
  Technology = 'Technology',
  FieldOfStudy = 'Field of Study',
  Number = 'Number',
  Country = 'Country',
  Music = 'Music',
  Relation = 'Relation',
  Continent = 'Continent',
  State = 'State',
  Condition = 'Condition',
  Percentage = 'Percentage',
  BodyPart = 'Body Part',
  MusicNature = 'Music, Nature',
  Letter= 'Letter',
  Noun = 'Noun',
}
