
  <div class="center d-flex justify-content-center">
    <ion-card style="max-width: 1080px;">
      <div class="flex-wrapper overflow-hidden" style="min-height: 575px;">
        <!-- swiper -->
        <swiper
          [config]="swiperConfig"
          class="flex-stretch-vertical"
          (indexChange)="onIndexChange($event)">
          <div class="swiper-no-swiping container">        
            <div class="background-padded mood-container">
              <!-- title -->
              <div class="step-title">
                Hi! Let's check in
              </div>
              <!-- title /-->

              <!-- question -->
              <ion-label class="question">
                How are you feeling today?
              </ion-label>
              <!-- question /-->

              <!-- main error message -->
              <div 
                class="margin-top-20"            
                *ngIf="errorMessage">

                <app-error
                  [errorMessage]="errorMessage"
                  [isValidation]="false">
                </app-error>
              </div>          
              <!-- main error message /-->

              <!-- mood -->
              <div class="mood">
                <ion-grid>
                  <ion-row class="justify-content-center">
                    <ion-col 
                      size="12" 
                      size-md="4" 
                      size-lg="2.4" 
                      *ngFor='let mood of moods; let i = index' 
                      attr.data-cy="mood_{{i}}" 
                      id='mood{{i}}'
                      style="padding: 15px;"
                      class="d-flex justify-content-center">
                      <div *ngIf='mood.enabled'>
                        <img src='{{mood.imageUrl}}' style="background-color: rgb(10, 66, 59)" (click)='moodClicked(i)'>
                      </div>
                      <div *ngIf='!mood.enabled'>
                        <img src='{{mood.imageUrl}}' (click)='moodClicked(i)'>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>
              <!-- mood /-->
            </div>    
          </div>

          <div>
            <!-- container -->
            <div class="swiper-no-swiping container">
              <div class="background-padded">
                <!-- title -->
                <div class="step-title">
                  Assessment              
                </div>          
                <!-- title /-->
      
                <!-- instructions -->
                <div class="step-instructions">
                  <p class="margin-top-10 assessment-para">
                    This three-quiz assessment will examine areas of brain fitness.<br>
                    Once a quiz is finished you cannot go back.<br><br>
                    When you complete all three quizzes you will receive a report with your results.<br><br>
                    These results are also used to set recommendations for your upcoming 12 week Brain Charge training session.<br>
                  </p>  
                  
                  <p class="assessment-para"><b>Relax and have fun!</b></p>
                </div>
                <!-- instructions /-->
              </div>  
            </div> 
            <!-- container /-->         
          </div>
        </swiper>
      </div>
      <!-- next button -->
      <div class="background-padded padding-bottom-20">
        <app-button text="Next" (click)="moveToNextStep()"></app-button>
      </div> 
      <!-- next button /-->  
      </ion-card>
    </div>