import { Component, Input, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';
import { NavigationEnum } from '@enums/NavigationEnum';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-button-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class ButtonCollectionComponent extends Utilities implements OnInit {

  @Input() buttons: {
    title: '',
    icon: '',
    path: '',
    callback: any
  }[]
  @Input() radio = false;

  active_flag = null;

  constructor(
    public navCtrl: NavController
  ) { super(); }

  ngOnInit() {
    
  }

  onClick(button, index){
    if(this.radio){
      this.active_flag = index;
    }
    button.callback(button);
    if(button.path){
      this.navigateToPath(button.path, NavigationEnum.Forward)
    } else{
      return;
    }
    
    return;
  }

}
