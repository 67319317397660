import { Component, Input, OnInit } from '@angular/core';
import { Utilities } from '@common/utilities';
import { ModalController } from '@ionic/angular';
import { AppEnum } from '@enums/AppEnum';
import { TrackerProcessingService } from '@services/tracker-processing.service';
import * as moment from "moment";

@Component({
  selector: 'app-sleep-flyout',
  templateUrl: './sleep-flyout.component.html',
  styleUrls: ['./sleep-flyout.component.scss'],
})
export class SleepFlyoutComponent extends Utilities implements OnInit {

  @Input() hours: number;
  @Input() week: any = {
    start: new Date(),
    end: new Date(),
    text: ''
  };
  
  trainingItems = [
    {
      app: AppEnum.HappyPlace,
      guide: 'Full training before bed and when wake at night'
    },
    {
      app: AppEnum.Yahootie,
      guide: 'Physical exercises'
    },
    {
      app: AppEnum.Clubhouse,
      guide: 'Attending meetings'
    }
  ];

  // red, yellow and blue
  borders = ['solid 4px #c94a43', 'solid 4px #f6b341', 'solid 4px #34868e'];

  sleepDays = [];

  constructor(
    public modalController: ModalController,
    private progressService: TrackerProcessingService
  ) 
  { super(); }

  ngOnInit() {
    this.setSleepDays();
    this.setCircles();
  }

  /**
   * Sets the circles' margin top, border and hours
   */
  setCircles(): void {
    const daysWithHours = this.sleepDays.filter(day => day.val != undefined);

    daysWithHours.forEach(day => {
      const circleProperties = this.getCircleProperties(day.val);
      day['val'] = circleProperties.text;
      day['border'] = circleProperties.border;
      day['marginTop'] = circleProperties.marginTop;
    });
  }

  /**
   * Assigns the margin top and border color based on the hours 
   * @param hours The amount of hours slept that day
   * @returns The circle properties for that day
   */
  getCircleProperties(hours: number): { text: string; marginTop: string; border: string; } {
    const circleProperty = {
      text: '',
      marginTop: '',
      border: ''
    };

    if (hours != 12) {
      circleProperty.text = hours + '';
    }
    else {
      circleProperty.text = '≥12';
    }

    if (hours <= 5 || hours >= 9) {
      circleProperty.border = this.borders[0];

      if (hours <= 5) {
        circleProperty.marginTop = `${163 - (11 * hours)}px`;
      }
      else {
        circleProperty.marginTop = `${-5 + Math.abs(11 * (12 - hours))}px`;
      }
    }
    else if (hours == 7 || hours == 8) {
      circleProperty.border = this.borders[2];

      if (hours == 7) {
        circleProperty.marginTop = '80px';
      }
      else {
        circleProperty.marginTop = '58px';
      }
    }
    else {
      circleProperty.marginTop = '98px';
      circleProperty.border = this.borders[1];
    }

    return circleProperty;
  }

  /**
   * Closes the modal
   */
  closeSelf(): void {
    this.closeModal();
  }

  /**
   * Sets the sleep days for the current week and removes duplicates
   */
  setSleepDays(): void {
    // add the dates of the week
    let day = new Date(this.week.start.setDate(this.week.start.getDate()));
    while (day.getTime() <= this.week.end.getTime()) {
      this.sleepDays.push({
        date: moment(day).format('MMM DD'),
        dayOfWeek: moment(day).format('ddd'),
        dayWithYear: moment(day).format('MMM DD, YYYY')
      });
      day = new Date(day.setDate(day.getDate() + 1));
    }

    // convert the epoch times from the DB into human readable format
    const sleepDays = JSON.parse(JSON.stringify(this.progressService.sleepTimes)).filter(day => {return day.val > 0});
    const buggedSleepDays = JSON.parse(JSON.stringify(this.progressService.sleepTimes)).filter(day => {return day.val === 0});
    sleepDays.forEach(sleepDay =>  { 
      const epochTime = moment.unix(sleepDay.time.seconds);
      sleepDay['date'] = epochTime.format('MMM DD');
      sleepDay['dayOfWeek'] = epochTime.format('ddd');
      sleepDay['dayWithYear'] = epochTime.format('MMM DD, YYYY')
    });

    // ignore duplicate sleep data from the DB 
    const uniqueSleepDays = sleepDays.map(e => e['dayWithYear'])
      
    const test1 = uniqueSleepDays.map((e, i, final) => final.indexOf(e) === i && i)
    const test2 = test1.filter((e) => sleepDays[e])
    const test3 = test2.map(e => sleepDays[e]);

    // find the common days between the sleep days of the week and the sleep days from the DB
    test3.forEach(uniqueDay => {
      const commonDayIndex = this.sleepDays.findIndex(sleepDay => uniqueDay.date === sleepDay.date);
      if (commonDayIndex != -1) {
        this.sleepDays[commonDayIndex] = uniqueDay;
      }
    });
  }
}
