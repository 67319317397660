<div class="cont">
  <app-close (click)="closeModal()"></app-close>
  <app-title text="Add Outside Training"></app-title>
  <hr>
  <div class='text-center' style='color: red' *ngIf='errorMsg'>
    <span>{{errorMsg}}</span>
  </div>
  <ion-text class="label">Name of training (e.g., Water Aerobics):</ion-text>
  <div class="training-text">
    <input type="text" data-cy="training-name" id="training-name" name="training-name" (keypress)='clearErrorMsg()'>
  </div>
  <ion-text class="label">Which category or categories best describe the activity (select all that apply)?</ion-text>
  <ion-grid>
    <ion-row class="category-row">
      <ion-col class="centered" *ngFor="let categorySetting of categorySettings; let i = index">
        <div class="category" [ngStyle]="newOutsideTraining.categories[i].selected && {'background-color': categorySetting.background }" [style.borderColor]='categorySetting.iconColor' (click)='categoryClicked(i)'>
          <ion-icon name='{{categorySetting.icon}}'></ion-icon>
          <ion-text class="type">{{categorySetting.id}}</ion-text>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <app-button text="Add" (click)='submitOutsideTraining()'></app-button>
</div>
