import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit {
  
  @Input() isSleep?: boolean = false;
  @Input() isDays?: boolean = false;

  @Output() selectedDate = new EventEmitter();
  @Output() selectedValue = new EventEmitter();
  @Output() selectedWeekDates = new EventEmitter(); 

  transform: string;

  // how many dates to show
  numOfDates = 7;

  dates = [];
  
  constructor() { }

  async ngOnInit() {
    await this.setCurrentDate();
    this.setDates();
    this.selectedDate.emit(this.getDateText(0));
    this.selectedValue.emit(0);
    this.sendWeekDates(0);
  }

  /**
   * If days is true, sets the dates array of days, back numOfDates 
   * If days is false, sets the dates array with numOfDates objects with the start of the week, 
   * end of the week and the week text format
   */
  setDates(): void {
    const today = moment().toDate();
    
    if (this.isDays) {
      for (let i = 1; i < this.numOfDates; i++) {
        const day = new Date(today.setDate(today.getDate() - 1));
        this.dates.push({ text: moment(day).format('MMM DD, YYYY') });
      }
    }
    else {
      for (let i = 2; i < this.numOfDates; i++) {
        const today2 = moment().day(-1).subtract(i-1, 'w');
        const sunday = moment().day(0).subtract(i, 'w');
        let text: string;

        if (i === 2) {
          text = `${sunday.format('MMM DD')} - ${today2.format('MMM DD')}`;
        }
        else {
          text = `${sunday.format('MMM DD')} - ${today2.format('MMM DD')}`;
        }

        this.dates.push({
          start: sunday,
          end: today2,
          text: text
        });
      }
    }
  }

  /**
   * Sets the current day or the current week
   */
  async setCurrentDate(): Promise<void> {
    let today = moment().toDate();

    if (this.isDays) {
      this.dates.push({ text: moment(today).format('MMM DD, YYYY') });
    }
    else {
      const today2 = moment().day(-1);
      const sunday = moment().day(0).subtract(1, 'w');
      this.dates.push({
        start: sunday,
        end: today2,
        text: `${sunday.format('MMM DD')} - ${today2.format('MMM DD')}`
      });
    }
  }

  /**
   * Gets the selected date text
   * @param i The value of the selected 
   * @returns The full date string 
   */
  getDateText(i: any): string {
    return this.dates[parseInt(i)].text;
  }

  /**
   * Emits the date, value and week dates
   * @param event The new day or week selected 
   */
  sendDate(event: { target: { value: string; }; }): void {
    this.selectedDate.emit(this.getDateText(event.target.value));
    this.selectedValue.emit(parseInt(event.target.value));
    this.sendWeekDates(parseInt(event.target.value));
  }

  /**
   * Emits the week date object 
   */
  sendWeekDates(i: number): void {
    if (!this.isDays) {
      this.selectedWeekDates.emit(this.dates[i]);
    }
  }

  /**
   * Returns the date in the preferred week format (MMM DD)
   * @param date The date to be converted
   * @returns The date in the week text format
   */
  toWeekFormat(date: Date): string {
    return date.toLocaleString('default', { month: 'short', day: '2-digit'});
  }

  /**
   * Flips the arrow to point down
   */
  flipArrow(): void {
    this.transform = 'translate(-50%,-50%) rotate(-180deg)';
  }

  /**
   * Flips the arrow to point back up
   */
  flipArrowBack(): void {
    this.transform = 'translate(-50%,-50%) rotate(0deg)';
  }
}
