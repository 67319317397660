import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-analytics',
  templateUrl: './google-analytics.component.html',
  styleUrls: ['./google-analytics.component.scss']
})
export class GoogleAnalyticsComponent implements OnInit {

  public googleTagManagerId: string;
  public googleAnalyticsId: string;

  constructor() {
    this.googleTagManagerId = 'GTM-PPJBL66';
    this.googleAnalyticsId = 'UA-127752477-1';
  }

  ngOnInit() {
  }

}
