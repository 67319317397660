<div class="container p-t-0">
  <div class="background-padded full-height">
    <ion-slides [options]="slideOpts">
      <ion-slide *ngFor="let exercise of content; let i = index; trackBy: trackByFn">
        <!-- Exercise Image -->
        <!-- <div *ngIf="exercise.image" class="m-t-20 text-center">
          <img [src]="exercise.image" class="slide-image animated pulse fast" />
        </div> -->

        <!-- Exercise GIF -->
        <!-- <div *ngIf="exercise.gif" class="m-t-20 text-center full-width">
              <img [src]="exercise.gif" class="slide-image animated pulse fast" />
            </div>  -->
        <ion-grid fixed>
          <ion-row>
            <ion-col>
              <div *ngIf="exercise.video" class="video-container">
                <video crossorigin="*" attr.data-cy="my-video-{{ i }}" id="'my-video-'{{ i }}" class="video-js vjs-default-skin vjs-big-play-centered video-resize" data-setup="{}" controls playsinline>
                  <source src="{{ exercise.video }}" type="video/mp4" />
                  <track label="English" kind="subtitles" srclang="en" src="{{ exercise.subtitles }}" default>
                </video>
              </div>
            </ion-col>
          </ion-row>

          <!-- Exercise Title -->
          <ion-row class="ion-justify-content-center">
            <ion-col>
              <h1 class="animated zoomIn fast exercise-title">{{ exercise.title }} 
                <ion-text *ngIf="exercise.legs">(10 reps per leg)</ion-text>
              </h1>
            </ion-col>
          </ion-row>



          <!-- container for the instructions header -->
          <ion-row>
            <ion-col class="centered" size="12">
              <div class="text-instructions">
                <div class="expand-heading">
                  <button class="expand-btn" attr.data-cy="{{ exercise.name }}-btn" id="{{ exercise.name }}-btn" (click)="expandDetails(exercise.name)">+</button>
                  <h2 class="expand-text" (click)="expandDetails(exercise.name)">Text Instructions</h2>
                </div>
              </div>
            </ion-col>
          </ion-row>

          <!-- Exercise Description -->
          <ion-row>
            <ion-col class="centered">
              <div attr.data-cy="{{ exercise.name }}" id="{{ exercise.name }}" class="animated zoomIn fast collapse">
                <ol class="exercise-instructions">
                  <li *ngFor="let description of exercise.description" class="instruction">{{ description }}</li>
                </ol>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-slide>
    </ion-slides>
    <!-- container for button to continue to next exercise -->
    <div>
      <app-button data-cy='10reps' id='10reps' text="I've done 10 repetitions!" (click)="moveToNext()"></app-button>
    </div>
  </div>
</div>
